import {
  formatPhoneNumber,
  formatEINNumber,
  formatZipCode,
  isAValidEmail,
  isAValidUSPhoneNumber,
  isAValidEINNumber,
  isAValidZipCode,
} from '../../utils';

import { legalFirmInfo, accountingFirmInfo, USStates } from '../../utils/globals';

export const userInfoFormContent = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    isGiven: true,
    formatChecker: isAValidEmail,
    helperText: 'Invalid email format',
  },
  {
    name: 'phoneNumber',
    label: 'Phone number',
    type: 'tel',
    formatChecker: isAValidUSPhoneNumber,
    helperText: 'Invalid US Phone number format',
    formatter: formatPhoneNumber,
  },
];

export const companyInfoFormContent = [
  {
    label: 'Company name',
    name: 'companyName',
    type: 'text',
    className: 'formSection2',
  },
  {
    label: 'Company street address',
    name: 'companyStreetAddress',
    type: 'text',
    className: 'formSection3',
  },
  {
    label: 'City',
    name: 'city',
    type: 'text',
    className: 'formSection4',
  },
  {
    label: 'State',
    name: 'state',
    className: 'formSection5',
    isSelect: true,
    menuItems: USStates.map((state) => (
      {
        label: `${state.name}`,
        value: `${state.abbreviation}`,
      }
    )),
  },
  {
    label: 'Zip code',
    name: 'zipCode',
    type: 'text',
    formatChecker: isAValidZipCode,
    helperText: 'Check zip',
    formatter: formatZipCode,
    className: 'formSection6',
  },
  {
    label: 'EIN',
    name: 'ein',
    type: 'text',
    formatChecker: isAValidEINNumber,
    helperText: 'Invalid EIN format',
    formatter: formatEINNumber,
    className: 'formSection7',
    isOptional: true,
  },
  {
    label: 'DBA',
    name: 'dba',
    type: 'text',
    className: 'formSection8',
    isOptional: true,
  },
  {
    label: 'Company website',
    name: 'companyWebsite',
    type: 'text',
    className: 'formSection9',
    isOptional: true,
  },
];

export const extraUserFormContent = [
  {
    name: 'firstName',
    label: 'First Name',
    type: 'text',
  },
  {
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
  },
  {
    name: 'email',
    label: 'Email',
    type: 'email',
    formatChecker: isAValidEmail,
    helperText: 'Invalid e-mail format',
  },
  {
    name: 'accountPermission',
    label: 'Account permissions',
    isSelect: true,
    menuItems: [{
      label: 'View',
      value: 'view',
    },
    {
      label: 'Edit',
      value: 'edit',
    },
    {
      label: 'Admin',
      value: 'admin',
    }],
  },
];

export const legalFirmInfoFormContent = [
  {
    label: 'Name of firm',
    name: 'legalFirmName',
    isAutocomplete: true,
    options: legalFirmInfo,
    isOptional: true,
  },
  {
    label: 'Name of advisor (your point of contact)',
    name: 'legalAdvisorName',
    type: 'text',
    isOptional: true,
  },
  {
    label: 'Advisor email',
    name: 'legalAdvisorEmail',
    type: 'email',
    formatChecker: isAValidEmail,
    helperText: 'Invalid e-mail format',
    isOptional: true,
  },
  {
    label: 'Their permissions on your account',
    name: 'legalAdvisorPermission',
    // TODO Remove line when needed after amvp
    className: 'hidden-for-mvp',
    isSelect: true,
    isOptional: true,
    menuItems: [{
      label: 'View',
      value: 'view',
    },
    {
      label: 'Edit',
      value: 'edit',
    },
    {
      label: 'Admin',
      value: 'admin',
    }],
  },
];

export const accountingFirmInfoFormContent = [
  {
    label: 'Name of firm',
    name: 'accountingFirmName',
    isAutocomplete: true,
    options: accountingFirmInfo,
    isOptional: true,
  },
  {
    label: 'Name of advisor (your point of contact)',
    name: 'accountingAdvisorName',
    type: 'text',
    isOptional: true,
  },
  {
    label: 'Advisor email',
    name: 'accountingAdvisorEmail',
    type: 'email',
    isOptional: true,
    formatChecker: isAValidEmail,
    helperText: 'Invalid e-mail format',
  },
  {
    label: 'Their permissions on your account',
    name: 'accountingAdvisorPermission',
    // TODO Remove line when needed after amvp
    className: 'hidden-for-mvp',
    isSelect: true,
    isOptional: true,
    menuItems: [{
      label: 'View',
      value: 'view',
    },
    {
      label: 'Edit',
      value: 'edit',
    },
    {
      label: 'Admin',
      value: 'admin',
    }],
  },
];

function assignDefaultValues(formContent) {
  let defaultValues = {};
  formContent.forEach((data) => {
    defaultValues = { ...defaultValues, [data.name]: '' };
  });
  if (formContent === companyInfoFormContent) {
    defaultValues = { ...defaultValues, extraUsers: [] };
  }
  return defaultValues;
}

export const userInfoDefaultValues = assignDefaultValues(userInfoFormContent);
export const companyInfoDefaultValues = assignDefaultValues(companyInfoFormContent);
export const legalFirmInfoDefaultValues = assignDefaultValues(legalFirmInfoFormContent);
export const accountingFirmInfoDefaultValues = assignDefaultValues(accountingFirmInfoFormContent);
export const extraUserDefaultValues = assignDefaultValues(extraUserFormContent);

const errorDefaultValues = {
  ...userInfoDefaultValues,
  ...companyInfoDefaultValues,
  ...legalFirmInfoDefaultValues,
  ...accountingFirmInfoDefaultValues,
};
Object.keys(errorDefaultValues).forEach((value) => {
  if (!Array.isArray(errorDefaultValues[value])) {
    errorDefaultValues[value] = false;
  }
});

export const hasErrorDefaultValues = errorDefaultValues;

export default {
  userInfoFormContent,
  companyInfoFormContent,
  legalFirmInfoFormContent,
  extraUserFormContent,
  userInfoDefaultValues,
  companyInfoDefaultValues,
  legalFirmInfoDefaultValues,
  accountingFirmInfoDefaultValues,
};
