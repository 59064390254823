import React from 'react';
import { Route, Routes } from 'react-router-dom';

import Home from './containers/Home/Home';
import Login from './containers/Home/Login';
import Signup from './containers/Signup/index';
import VerifyEmail from './containers/Signup/VerifyEmail';
import ResetPassword from './containers/Signup/ResetPassword';
import InfoForm409A from './containers/409aInfo/index';
import FinalHomePage from './containers/FinalHomePage/index';
import Support from './containers/Support';
import Demo from './containers/Support/Demo';
import NotFound from './components/NotFound';
import Sandbox from './containers/Sandbox/index';

// Auth/UnAuth container components
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import PriceSandbox from './containers/PriceSandbox';

export default function RouterProvider() {
  return (
    <Routes>
      {/* Routes logged-in OR logged-out users can access */}
      <Route path="/" element={<Home />} />
      <Route
        path="/final-homepage"
        element={<FinalHomePage />}
      />
      {/* Routes only logged-out users can access */}
      <Route
        exact
        path="/login"
        element={(
          <UnauthenticatedRoute>
            <Login />
          </UnauthenticatedRoute>
        )}
      />
      <Route
        exact
        path="/signup"
        element={(
          <UnauthenticatedRoute>
            <Signup />
          </UnauthenticatedRoute>
        )}
      />
      <Route
        exact
        path="/verify-email"
        element={(
          <UnauthenticatedRoute>
            <VerifyEmail />
          </UnauthenticatedRoute>
        )}
      />
      <Route
        exact
        path="/login/reset"
        element={(
          <UnauthenticatedRoute>
            <ResetPassword />
          </UnauthenticatedRoute>
        )}
      />
      {/* Routes only logged-in users can access */}
      <Route
        path="/409a-price-sandbox"
        element={(
          <AuthenticatedRoute>
            <PriceSandbox />
          </AuthenticatedRoute>
        )}
      />
      <Route
        path="/409a-sandbox"
        element={(
          <AuthenticatedRoute>
            <Sandbox />
          </AuthenticatedRoute>
        )}
      />
      <Route
        exact
        path="/support"
        element={(
          <AuthenticatedRoute>
            <Support />
          </AuthenticatedRoute>
        )}
      />
      <Route
        exact
        path="/demo"
        element={(
          <AuthenticatedRoute>
            <Demo />
          </AuthenticatedRoute>
        )}
      />
      <Route
        exact
        path="/409a-info"
        element={(
          <AuthenticatedRoute>
            <InfoForm409A />
          </AuthenticatedRoute>
        )}
      />
      {/* Catch all unmatched routes */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
