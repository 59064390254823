import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';

export default function CapTable({
  capTableData,
  pagination,
  hasDividends,
  numberOfPages,
  pageNumber,
  isLastPage,
  moveTitleToHeader,
}) {
  const tableMetaData = [
    {
      dataName: 'Class',
      cellWidth: '200px',
    },
    {
      dataName: 'Shares',
      cellWidth: hasDividends ? '104px' : '112px',
    },
    {
      dataName: 'Issue price',
      cellWidth: hasDividends ? '96px' : '104px',
    },
    {
      dataName: 'Exercise price',
      cellWidth: '128px',
    },
    {
      dataName: 'Liquidation preference',
      cellWidth: hasDividends ? '160px' : '176px',
    },
    {
      dataName: 'Conversion ratio',
      cellWidth: hasDividends ? '128px' : '152px',
    },
    {
      dataName: 'Dividends',
      cellWidth: '96px',
    },
  ];

  if (hasDividends) {
    tableMetaData.push({
      dataName: 'Dividend Percentage',
      cellWidth: '160px',
    });
  }

  function returnCellData(cellData, dataName) {
    if (dataName === 'Shares') return cellData.Outstanding;
    if (dataName === 'Liquidation preference') return cellData['Liquidation ratio'];
    return cellData[dataName];
  }
  const addOne = isLastPage ? 0 : 1;
  function rowHeight() {
    if (capTableData.tableData.length >= 9 + addOne && capTableData.tableData.length <= 14 + addOne) return '32px';
    if (capTableData.tableData.length >= 15 + addOne) return '24px';
    return '48px';
  }
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative' }}
    >
      <TablePageHeader
        pageName={`Capitalization Table ${numberOfPages > 1 ? `(page ${pageNumber} of ${numberOfPages})` : ''}`}
        moveTitleToHeader={moveTitleToHeader}
      />
      <View
        style={{
          marginTop: '26px',
          marginLeft: '80px',
          display: 'flex',
          flexDirection: 'column',
          width: '1064px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: rowHeight(),
            backgroundColor: '#F0F0F3',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          {tableMetaData.map((tableData) => {
            return (
              <Text
                key={tableData.dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: `${tableData.cellWidth}`,
                  marginTop: 'auto',
                  paddingBottom: '4px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                {tableData.dataName}
              </Text>
            );
          })}
        </View>
        {capTableData.tableData.map((cellData, cellDataIndex) => {
          return (
            <View
              key={cellData.Class}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: `${cellDataIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              {
                tableMetaData.map((tableData) => {
                  return (
                    <Text
                      key={`${tableData.dataName}&_&_`}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        color: '#49454F',
                        width: `${tableData.cellWidth}`,
                        paddingLeft: '16px',
                        letterSpacing: '0.4px',
                      }}
                    >
                      {returnCellData(cellData, tableData.dataName) || 'N/A'}
                    </Text>
                  );
                })
              }
            </View>
          );
        })}
        {isLastPage && (
          <>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: `${capTableData.tableData.length % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                borderTop: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '200px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                Total Outstanding
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: hasDividends ? '104px' : '112px',
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {capTableData.totalData.Outstanding}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: `${capTableData.tableData.length % 2 ? '#FAFAFD' : '#F0F0F3'}`,
                borderTop: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '200px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                Total Fully Diluted
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: hasDividends ? '104px' : '112px',
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {capTableData.totalData['Fully diluted']}
              </Text>
            </View>
          </>
        )}
      </View>
      <BottomPagination
        pageNumberLeft={pagination[0]}
        pageNumberRight={pagination[1]}
      />
    </Page>
  );
}

CapTable.propTypes = {
  capTableData: PropTypes.object,
  pagination: PropTypes.array,
  hasDividends: PropTypes.bool,
  numberOfPages: PropTypes.number,
  pageNumber: PropTypes.number,
  isLastPage: PropTypes.bool,
  moveTitleToHeader: PropTypes.bool,
};
