import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';
// MUI
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import RadioGroup from '@mui/material/RadioGroup';
import IconButton from '@mui/material/IconButton';
// Icons
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LoopingIcon from '../../components/LoopingIcon';
import { ReactComponent as GreenCheckMark } from '../../images/icons/green_checkmark.svg';
// In-project Imports
import ProgressIndicatorLine from '../../components/ProgressIndicatorLine';
import { AuthContext, ErrorMessageContext } from '../../lib/contextLib';
import {
  copy,
  isAValidEmail,
  ifValInLocalStorageSetVal,
  createAuthHeaders,
  setUserInfoToLocalStorage,
  removeUserInfoFromLocalStorage,
  removeAllInitioUserInfoSignedOutUsers,
} from '../../utils';
import FormControlRadioBtn from './FormControlRadioBtn';
import GenPasswordReqList from './GenPasswordReqList';
import { firmInfo } from '../../utils/globals';
import './index.scss';

const clientPartnerPrompt = [
  {
    val: 'partner',
    description: '- you\'re from a legal consultancy, law firm or accounting firm.',
  },
  {
    val: 'client',
    description: '- you\'re a client of a legal consultancy, law firm or accounting firm.',
  },
];

const inputStyles = {
  m: 1,
  marginLeft: '0px',
  marginRight: '0px',
  width: '100%',
  marginBottom: '20px',
};

function LoginButton({ text = 'Login', customStyles = {}, destination = '/login' }) {
  return (
    <Link
      style={{
        display: 'block',
        textAlign: 'center',
        paddingTop: '24px',
        paddingBottom: '24px',
        fontWeight: 500,
        color: '#4295F3!important',
        ...customStyles,
      }}
      to={destination}
    >
      {text}
    </Link>
  );
}

LoginButton.propTypes = {
  text: PropTypes.string.isRequired,
  customStyles: PropTypes.object,
  destination: PropTypes.string,
};

/** *********************************************************** */

export default function Signup() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);
  const [isLoading, setIsLoading] = useState(false);
  // TODO remove this
  // eslint-disable-next-line no-unused-vars
  const [isConfirmationInProgress, setIsConfirmationInProgress] = useState(false);
  const [navigatingToHomepage, setNavigatingToHomepage] = useState(false);
  const [queryParam, setQueryParam] = useState('');
  // no query string, Step #1
  const [clientOrPartner, setClientOrPartner] = useState('client');
  // no query string, Step #2
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showInvalidEmailError, setShowInvalidEmailError] = useState(false);
  const [emailAlreadyAssociatedWithAccount, setEmailAlreadyAssociatedWithAccount] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [passwordCapIsOn, setPasswordCapIsOn] = useState(false);
  const [passwordHasError, setPasswordHasError] = useState(false);
  // no query string, Step #3
  const [companyName, setCompanyName] = useState('');
  const [showCompanyNameError, setShowCompanyNameError] = useState(false);
  const authorizedSigDefaultVal = 'Yes';
  // eslint-disable-next-line
  const [isAuthorizedSignatory, setIsAuthorizedSignatory] = useState(authorizedSigDefaultVal);
  const [signatoryName, setSignatoryName] = useState('');
  const [showSignatoryNameErr, setShowSignatoryNameErr] = useState(false);
  const [signatoryEmail, setSignatoryEmail] = useState('');
  const [isSignatoryEmailValid, setIsSignatoryEmailValid] = useState(false);
  const [showSignatoryEmailErr, setShowSignatoryEmailErr] = useState(false);
  // no query string, Step #4
  const [sendEmailTextMarketing, setSendEmailTextMarketing] = useState(false);
  const [userAgreementPrivacy, setUserAgreementPrivacy] = useState(true);
  const [firmName, setFirmName] = useState('');
  const [firmEditable, setFirmEditable] = useState(true);
  // Account Validation
  const [validationCodeSent, setValidationCodeSent] = useState(false);
  const [validationCode, setValidationCode] = useState('');
  const [codeVerified, setCodeVerified] = useState(false);
  // Signup Wizard - steps
  const clientTotalStepsWithClientPartnerChoiceStep = 4;
  const partnerTotalStepsWithClientPartnerChoiceStep = 3;
  const [steps, setSteps] = useState({
    currentStep: 1,
    totalSteps: 3, // client, no choice step
  });

  // localStorage Variables
  const emailLocalStorage = 'email';
  const passwordLocalStorage = 'password';
  const validationCodeSentLocalStorage = 'validationCodeSent';

  const nav = useNavigate();

  async function handleConfirmationSubmit(
    event,
    optionalVerificationCode = validationCode,
    userEmail = email,
  ) {
    if (event) event.preventDefault();
    setIsConfirmationInProgress(true);

    try {
      await Auth.confirmSignUp(userEmail.trim(), optionalVerificationCode.trim());
      removeUserInfoFromLocalStorage(validationCodeSentLocalStorage);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsConfirmationInProgress(false);
    }
  }

  useEffect(() => {
    async function initializePage() {
      const gotCodeSent = ifValInLocalStorageSetVal(
        validationCodeSentLocalStorage,
        setValidationCodeSent,
        true,
      );
      const gotEmail = ifValInLocalStorageSetVal(emailLocalStorage, setEmail);
      const gotPass = ifValInLocalStorageSetVal(passwordLocalStorage, setPassword);

      const verificationCode = new URLSearchParams(window.location.search)?.get('verificationCode');
      const validValFromLocalStorage = (val) => (typeof val === 'string') && (val.length > 1);

      if (
        verificationCode && validValFromLocalStorage(verificationCode) &&
        gotPass && validValFromLocalStorage(gotPass) &&
        gotEmail && validValFromLocalStorage(gotEmail) &&
        gotCodeSent
      ) {
        setValidationCode(verificationCode);
        await handleConfirmationSubmit(false, verificationCode, gotEmail);
        setCodeVerified(true);
      } else {
        const associatedFirm = new URLSearchParams(window.location.search)?.get('associatedFirm');

        if (associatedFirm !== null) {
          setQueryParam(associatedFirm);
          setSteps({
            currentStep: 1,
            totalSteps: 2,
          });
          firmInfo.some(({ value, name }) => {
            if (associatedFirm === value) {
              setFirmName(name);
              setFirmEditable(false);
              return true;
            }

            setFirmEditable(true);
            return false;
          });
        }
      }
    }

    initializePage();
  }, []);

  useEffect(() => {
    setUserInfoToLocalStorage(emailLocalStorage, email);

    firmInfo.some(({ domain, name }) => {
      if (new RegExp(domain, 'ig').test(email)) {
        setFirmName(name);
        setFirmEditable(false);
        return true;
      }

      return false;
    });
  }, [email]);

  async function checkForEmailErrors() {
    const isEnteredEmailValid = isAValidEmail(email);
    setIsEmailValid(isEnteredEmailValid);
    if (isEnteredEmailValid) {
      setShowInvalidEmailError(false);
      try {
        // eslint-disable-next-line max-len
        const emailDedupe = await fetch(`${process.env.REACT_APP_BACKEND_URL}/accounts/duplicates/${email}?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`, {
          method: 'GET',
          header: {
            'Content-Type': 'application/json',
          },
        });
        const emailDedupeParsed = await emailDedupe.json();
        const { isAlreadyUser } = emailDedupeParsed;
        setEmailAlreadyAssociatedWithAccount(!!isAlreadyUser);
        setShowInvalidEmailError(!!isAlreadyUser);
      } catch (e) {
        setShowErrorMessage(e.toString());
      }
    } else {
      setShowInvalidEmailError(true);
      setEmailAlreadyAssociatedWithAccount(false);
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      const cognitoResponse = await Auth.signUp({
        username: email.trim(),
        password,
        attributes: {
          email: email.trim(),
        },
      });
      const acctData = {
        cognitoUuid: cognitoResponse.userSub,
        isClient: 1,
        email,
        companyName,
        marketingOptIn: sendEmailTextMarketing,
        isAuthSignatory: true,
      };
      const [{ val: partnerVal }] = clientPartnerPrompt;
      if (clientOrPartner === partnerVal) {
        acctData.companyName = firmName;
        acctData.isClient = 0;
      }
      let allUserAccountData;

      try {
        const requestHeaders = await createAuthHeaders('post', acctData);
        const accountInfoCreated = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/accounts?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
          requestHeaders,
        );
        allUserAccountData = (await accountInfoCreated.json()).Body;
      } catch (e) {
        setShowErrorMessage(e.toString());
        return;
        // TODO what happens if we fail to create an account ??? ¯\_(ツ)_/¯
      }

      const { companyId, newAccount, accountId } = allUserAccountData;

      setUserInfoToLocalStorage('companyId', companyId);
      setUserInfoToLocalStorage('isAdmin', /initio/ig.test(newAccount.accountType), accountId);
      setUserInfoToLocalStorage('isClient', !!newAccount.isClient, accountId);
      setUserInfoToLocalStorage('accountId', accountId);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsLoading(false);
    }
  }

  function helperTextForEmailValidation(isTheEmailValid, shouldWeShowTheEmailError) {
    const iconSize = { fontSize: '14px' };
    const iconSpace = { marginLeft: '5px' };
    const useYourEmailAddress = (
      <span
        key="Use-your-company-email-address."
        style={{
          display: shouldWeShowTheEmailError ? 'none' : 'block',
          color: 'rgba(0, 0, 0, 0.6)',
        }}
      >
        Use your company email address.
      </span>
    );

    // TODO implement later, need custom lambda call to enable
    if (emailAlreadyAssociatedWithAccount && shouldWeShowTheEmailError) {
      const errorColor = '#B3261E';
      return ([
        <ErrorIcon
          style={{
            ...iconSize,
            color: errorColor,
          }}
          key="error-icon-invalid-email-already-associated-14px"
        />,
        <span
          style={{
            ...iconSpace,
            color: errorColor,
          }}
          key="error-email-already-associated-text-5px"
        >
          This email is already associated with an account.
          <br />
          <LoginButton
            text="Forgot password?"
            customStyles={{
              display: 'inline-block',
              textAlign: 'left',
              marginLeft: '20px',
              paddingTop: '0px',
              paddingBottom: '0px',
              fontWeight: 400,
              color: errorColor,
            }}
            destination="/login/reset"
          />
        </span>,
        useYourEmailAddress,
      ]);
    }

    const emailErrorMessage = () => {
      if (email === '') return 'Please enter your email address.';
      if (!email.includes('@') || email.split('@').length > 2) return 'An email address must contain a single @';
      if (!email.split('@')[1].includes('.')) return 'The domain portion of the email address is invalid (the portion after @)';
      return 'Invalid email';
    };

    if (shouldWeShowTheEmailError) {
      return ([
        <span className="error-message" key="error-invalid-email-text-5px">
          <ErrorIcon style={iconSize} key="error-icon-invalid-email-14px" />
          {emailErrorMessage()}
        </span>,
        useYourEmailAddress,
      ]);
    }
    if (isTheEmailValid && !shouldWeShowTheEmailError) {
      return ([
        <CheckIcon style={iconSize} key="check-icon-done-14px" />,
        <span style={iconSpace} key="check-icon-done-5px-msg">Done</span>,
      ]);
    }
    return useYourEmailAddress;
  }

  function emailAndPasswordInput() {
    function emailMarginBottom(isEmailValidTF, showInvalidEmailE) {
      if (!isEmailValidTF && showInvalidEmailE) {
        return '34px';
      }

      // TODO implement later, with dedicated lambda
      // if (emailAlreadyAssociatedWithAccount) {
      //   return '49px';
      // }

      return '34px';
    }

    return (
      <div>
        <FormControl
          sx={{
            ...inputStyles,
            marginBottom: emailMarginBottom(isEmailValid, showInvalidEmailError),
          }}
          variant="outlined"
        >
          <InputLabel error={showInvalidEmailError} htmlFor="email">
            Email
          </InputLabel>
          <OutlinedInput
            id="email"
            label="Email"
            type="text"
            value={email}
            error={showInvalidEmailError}
            onChange={(e) => setEmail(e.target.value)}
            onFocus={() => setShowInvalidEmailError(false)}
            onBlur={() => email && checkForEmailErrors()}
          />
          <FormHelperText
            error={showInvalidEmailError}
          >
            {helperTextForEmailValidation(isEmailValid, showInvalidEmailError)}
          </FormHelperText>
        </FormControl>

        <FormControl className={passwordHasError ? 'has-error' : ''} sx={inputStyles} variant="outlined">
          <InputLabel htmlFor="password">Password</InputLabel>
          <OutlinedInput
            id="password"
            type={showPassword ? 'text' : 'password'}
            value={password}
            error={passwordHasError}
            onChange={(e) => {
              e.preventDefault();
              setPassword(e.target.value);
            }}
            onFocus={() => setPasswordHasError(false)}
            onBlur={() => (password ?
              (!isPasswordValid && setPasswordHasError(true)) : setPasswordHasError(false)
            )}
            onKeyDown={(e) => {
              if (e.getModifierState('CapsLock')) {
                setPasswordCapIsOn(true);
              } else {
                setPasswordCapIsOn(false);
              }
            }}
            endAdornment={(
              <InputAdornment position="end">
                {passwordCapIsOn && <KeyboardCapslockIcon />}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                  edge="end"
                >
                  {
                    showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />
                  }
                </IconButton>
              </InputAdornment>
            )}
            label="Password"
          />
          <FormHelperText>
            {passwordHasError && (
              <span className="error-message">
                <ErrorIcon />
                Password doesn&apos;t meet requirements
              </span>
            )}
          </FormHelperText>
        </FormControl>

        <GenPasswordReqList
          setIsPasswordValidHandler={setIsPasswordValid}
          isParentPasswordValid={isPasswordValid}
          password={password}
        />

        <Button
          variant="contained"
          className="continue-button"
          onClick={() => {
            if (password && isPasswordValid &&
              email && isEmailValid && !emailAlreadyAssociatedWithAccount) {
              steps.currentStep += 1;
              setSteps(copy(steps));
            } else {
              checkForEmailErrors();
              if (!isPasswordValid) {
                setPasswordHasError(true);
              } else {
                setPasswordHasError(false);
              }
            }
          }}
        >
          Continue
        </Button>
        <Button
          className="login-btn"
          onClick={() => nav('/login')}
        >
          Already have an account? Login
        </Button>
      </div>
    );
  }

  function releaseFirmNameAndFinalClick() {
    const emailAndTextMarketing = 'email-text-marketing';
    const userAgreementAndPrivacyPolicy = 'user-agreement-and-privacy';
    const marketingAndRelease = [
      {
        val: '',
        id: emailAndTextMarketing,
        description: (
          <span className="email-text-marketing-text">
            Yes, email or text me so that I can receive the
            latest news and information about events,
            products and services from initio.
          </span>
        ),
        checked: sendEmailTextMarketing,
      },
      {
        val: '',
        id: userAgreementAndPrivacyPolicy,
        description: (
          <span className="user-agreement-and-privacy-text">
            By creating and signing in to an initio account,
            I acknowledge that I have reviewed the initio
            {' '}
            <a
              href="https://initio-frontend-assets.s3.us-west-2.amazonaws.com/Initio_Software_Inc.-Terms_and_Conditions.fd1cbcd7e03c8342f6d4.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="conditions-link"
            >
              Terms and Conditions for Services
              {' '}
              <OpenInNewIcon
                sx={{
                  display: 'inline-block',
                  height: '18px',
                  width: '18px',
                }}
              />
            </a>
            {' '}
            and agree to be bound by the terms and conditions therein.
          </span>
        ),
        checked: userAgreementPrivacy,
      },
    ];
    const displayFirms = /partner/ig.test(clientOrPartner) || queryParam.length > 0;

    return (
      <div>
        {
          displayFirms && (
            <FormControl
              sx={{
                ...inputStyles,
                marginTop: '20px',
              }}
              variant="outlined"
            >
              <InputLabel htmlFor="firmName">
                Firm Name
              </InputLabel>
              <OutlinedInput
                disabled={!firmEditable}
                id="firmName"
                label="Firm Name"
                type="text"
                value={firmName}
                onChange={(e) => {
                  e.preventDefault();
                  setFirmName(e.target.value);
                }}
              />
              {
                firmEditable && (
                  <FormHelperText style={{ height: '18px' }}>
                    Please enter your firm&apos;s name
                  </FormHelperText>
                )
              }
            </FormControl>
          )
        }

        <FormControl
          sx={{ display: 'block' }}
          onChange={(e) => {
            e.preventDefault();
            const { id } = e.target.parentNode.parentNode;

            if (new RegExp(emailAndTextMarketing, 'ig').test(id)) {
              setSendEmailTextMarketing(!sendEmailTextMarketing);
            } else {
              setUserAgreementPrivacy(!userAgreementPrivacy);
            }
          }}
        >
          {
            marketingAndRelease.map(({
              val, description, checked, id,
            }, i) => {
              const { children, 'aria-label': ariaLabel } = description.props;
              const childText = (
                Array.isArray(children) ?
                  children.filter((e) => e instanceof String).join('') :
                  children
              );

              return (
                <FormControlRadioBtn
                  key={(ariaLabel + childText).replaceAll(' ', '-')}
                  value={val}
                  index={i}
                  customIdName={id}
                  desc={description}
                  radio={false}
                  checked={checked}
                />
              );
            })
          }
        </FormControl>

        <div
          className={
            `user-agreement-error ${userAgreementPrivacy ? 'user-agreement-error-hidden' : ''}`
          }
        >
          <ErrorIcon style={{ color: 'red', marginTop: '5x', fontSize: '16px' }} />
          <FormHelperText style={{ marginTop: '0px', marginLeft: '5px', color: 'red' }}>
            You must accept the
            &lsquo;End User Agreement and Privacy Statement&rsquo; to proceed.
          </FormHelperText>
        </div>
        <Button
          variant="contained"
          disabled={!userAgreementPrivacy || isLoading}
          className="sign-up-button"
          onClick={async (e) => {
            await handleSubmit(e);
            setValidationCodeSent(true);
            setUserInfoToLocalStorage(validationCodeSentLocalStorage, true);
          }}
        >
          {
            isLoading ? <LoopingIcon /> : 'Sign up'
          }
        </Button>
        <Button
          className="back-btn"
          onClick={() => {
            steps.currentStep -= 1;
            setSteps(copy(steps));
          }}
        >
          Back
        </Button>
      </div>
    );
  }

  function signupFlow() {
    if (validationCodeSent) {
      if (codeVerified) {
        return (
          <div className="code-verified-wrapper">
            <div className="code-verified">
              <GreenCheckMark className="code-verified-check-icon" />
              <span className="code-verified-msg">Email successfully verified</span>
            </div>
            <Button
              className="nav-to-homepage"
              disabled={navigatingToHomepage}
              onClick={async () => {
                setNavigatingToHomepage(true);

                try {
                  await Auth.signIn(email.trim(), password.trim());
                } catch (e) {
                  setShowErrorMessage(e.toString());
                }
                setIsAuthenticated(true);
                removeAllInitioUserInfoSignedOutUsers();
                setNavigatingToHomepage(false);

                nav('/');
              }}
            >
              {
                navigatingToHomepage ?
                  <LoopingIcon className="logging-in-icon" /> :
                  'Navigate to portal'
              }
            </Button>
          </div>
        );
      }

      return (
        <div className="email-sent-wrapper">
          <p>
            To complete the sign up process you must verify your email address. We have
            sent a verification email to
            {' '}
            {email}
            .
          </p>
        </div>
      );
    }

    if (queryParam.length === 0) {
      if (steps.currentStep === 0) { // Partner or client??
        return (
          <div className="first-step">
            <FormControl className="client-partner-form-control">
              <FormLabel id="demo-radio-butts-group-label">
                Are you a Partner or a Client?
              </FormLabel>
              <RadioGroup
                sx={{ marginLeft: 2 }}
                aria-labelledby="demo-radio-buttons-group-label"
                onChange={(event) => {
                  setClientOrPartner(event.target.value);

                  setSteps({
                    currentStep: 1,
                    totalSteps: (
                      /partner/ig.test(event.target.value) ?
                        partnerTotalStepsWithClientPartnerChoiceStep :
                        clientTotalStepsWithClientPartnerChoiceStep
                    ),
                  });
                }}
                name="radio-buttons-group"
              >
                {
                  clientPartnerPrompt.map(({ val, description }, i) => (
                    <FormControlRadioBtn
                      addA
                      index={i}
                      key={description}
                      value={val}
                      desc={description}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl>
            <Button
              variant="contained"
              disabled={clientOrPartner === null}
              className="confirm-client-or-partner-btn"
              onClick={() => {
                steps.currentStep += 1;
                setSteps(copy(steps));
              }}
            >
              Continue
            </Button>
            <LoginButton text="Already have an account? Login" />
          </div>
        );
      }
      if (steps.currentStep === 1) { // Email && Password
        return emailAndPasswordInput();
      }
      if (steps.currentStep === 4 && /no/ig.test(isAuthorizedSignatory)) { // Email Alex
        return (
          <div style={{ marginTop: '25px' }}>
            <p style={{ textAlign: 'center' }}>
              We&apos;ll email you an account verification code
              as soon as your account request has been
              approved by your company&apos;s authorized
              signatory,
              {' '}
              {signatoryName}
              .
            </p>
          </div>
        );
      }
      if (steps.currentStep === 4 ||
        (steps.currentStep === 3 && /partner/ig.test(clientOrPartner))
      ) {
        return releaseFirmNameAndFinalClick();
      }
      if (steps.currentStep === 2) { // comp. name & is authorized signer ???
        return (
          <div>
            <FormControl
              sx={inputStyles}
              variant="outlined"
            >
              <InputLabel
                htmlFor="companyName"
                error={showCompanyNameError}
              >
                Company name
              </InputLabel>
              <OutlinedInput
                id="companyName"
                label="Company name"
                type="text"
                value={companyName}
                error={showCompanyNameError}
                onChange={(e) => setCompanyName(e.target.value)}
                onFocus={() => setShowCompanyNameError(false)}
              />
              <FormHelperText>
                {showCompanyNameError && (
                  <span className="error-message">
                    <ErrorIcon />
                    Please enter your company name
                  </span>
                )}
                Please use your company&apos;s full legal name
              </FormHelperText>
            </FormControl>

            {/* <FormControl sx={{ display: 'block' }}>
              <FormLabel
                id="is-user-an-authorized-signer-for-company"
                style={{ color: 'rgba(0, 0, 0, 0.6);!important' }}
              >
                Are you an authorized signatory for this company?
              </FormLabel>
              <RadioGroup
                aria-labelledby="is-user-an-authorized-signer-for-company"
                onChange={(e) => {
                  setIsAuthorizedSignatory(e.target.value);
                }}
                defaultValue={isAuthorizedSignatory}
                name="is-user-an-authorized-signer-for-company"
              >
                {
                  [
                    {
                      val: authorizedSigDefaultVal,
                      description: <span />,
                    },
                    {
                      val: 'No',
                      description: <span />,
                    },
                  ].map(({ val, description }) => (
                    <FormControlRadioBtn
                      key={(val + description).replaceAll(' ', '-')}
                      value={val}
                      desc={description}
                    />
                  ))
                }
              </RadioGroup>
            </FormControl> */}
            {
              /no/ig.test(isAuthorizedSignatory) && (
                <div>
                  <p>
                    To proceed please provide the name
                    and email address of your company&apos;s authorized signatory.
                  </p>

                  <FormControl
                    sx={inputStyles}
                    variant="outlined"
                  >
                    <InputLabel
                      error={showSignatoryNameErr}
                      htmlFor="companySignatoryName"
                    >
                      Signatory Name
                    </InputLabel>
                    <OutlinedInput
                      id="companySignatoryName"
                      label="Signatory Name"
                      type="text"
                      value={signatoryName}
                      error={showSignatoryNameErr}
                      onChange={(e) => {
                        setSignatoryName(e.target.value);
                        if (showSignatoryNameErr && e.target.value.trim().length > 1) {
                          setShowSignatoryNameErr(false);
                        }
                      }}
                      onBlur={(e) => {
                        if (e.target.value.trim().length === 0) {
                          setShowSignatoryNameErr(true);
                        }
                      }}
                    />
                    <FormHelperText
                      error={showSignatoryNameErr}
                      style={{
                        height: '18px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Name of person able to sign on behalf of the company
                    </FormHelperText>
                  </FormControl>

                  <FormControl
                    sx={inputStyles}
                    variant="outlined"
                  >
                    <InputLabel
                      error={showSignatoryEmailErr}
                      htmlFor="signatoryEmail"
                    >
                      Signatory Email
                    </InputLabel>
                    <OutlinedInput
                      id="signatoryEmail"
                      label="Signatory Email"
                      type="email"
                      value={signatoryEmail}
                      error={showSignatoryEmailErr}
                      onChange={(e) => {
                        setSignatoryEmail(e.target.value);
                        setIsSignatoryEmailValid(isAValidEmail(e.target.value));

                        if (showSignatoryEmailErr && isAValidEmail(e.target.value)) {
                          setShowSignatoryEmailErr(false);
                        }
                      }}
                      onBlur={(e) => {
                        const isEnteredEmailValid = isAValidEmail(e.target.value);

                        if (isEnteredEmailValid) {
                          setIsSignatoryEmailValid(isEnteredEmailValid);
                          setShowSignatoryEmailErr(false);
                        } else {
                          setShowSignatoryEmailErr(true);
                        }
                      }}
                    />
                    <FormHelperText
                      error={showSignatoryEmailErr}
                      style={{ height: '18px' }}
                    >
                      {helperTextForEmailValidation(isSignatoryEmailValid, showSignatoryEmailErr)}
                    </FormHelperText>
                  </FormControl>
                </div>
              )
            }
            <Button
              variant="contained"
              className="continue-button"
              onClick={async () => {
                if (companyName.trim().length === 0) {
                  setShowCompanyNameError(true);
                  return;
                }

                setShowCompanyNameError(false);

                if (/no/ig.test(isAuthorizedSignatory) && isAValidEmail(email)) {
                  // 'They are a client'
                  // email
                  // signatoryName
                  // signatoryEmail
                  // companyName
                  // TODO send all above info to Alex
                  try {
                    const leadData = {
                      companyName,
                      fullName: signatoryName,
                      email: signatoryEmail,
                      signatoryEmail: '',
                      signatoryName: '',
                    };
                    const leadRequestOptions = await createAuthHeaders('post', leadData);
                    // eslint-disable-next-line max-len
                    await fetch(`${process.env.REACT_APP_BACKEND_URL}/accounts/lead?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`, leadRequestOptions);
                  } catch (e) {
                    setShowErrorMessage(e.toString());
                  } finally {
                    setIsLoading(false);
                  }
                }
                steps.currentStep += 1;
                setSteps(copy(steps));
              }}
            >
              Continue
            </Button>
            <Button
              className="back-btn"
              onClick={() => {
                steps.currentStep -= 1;
                setSteps(copy(steps));
              }}
            >
              Back
            </Button>
          </div>
        );
      }
      if (steps.currentStep === 3) {
        return releaseFirmNameAndFinalClick();
      }
    }

    if (queryParam.length > 0) {
      if (steps.currentStep === 1) {
        return emailAndPasswordInput();
      }
      if (steps.currentStep === 2) {
        return releaseFirmNameAndFinalClick();
      }
    }


    return (
      <div>
        <h1>This is a test, and should never render :-)</h1>
      </div>
    );
  }

  function renderMaterialForm() {
    const completeButNotAuthorizedSig = (steps.currentStep === 4) && /no/ig.test(isAuthorizedSignatory);

    return (
      <div className="signup-white-wrapper">
        {
          (!codeVerified && (completeButNotAuthorizedSig || validationCodeSent)) && (
            <div className="top-icon">
              <MarkEmailReadOutlinedIcon />
            </div>
          )
        }
        <h4>
          {
            validationCodeSent ? (
              codeVerified ? 'Successful verification' : 'Verification email sent'
            ) : `Sign up${completeButNotAuthorizedSig ? ' request complete' : ''}`
          }
        </h4>
        <div style={{
          width: '352px',
          display: 'block',
          margin: '0 auto',
        }}
        >
          {
            !(
              validationCodeSent ||
              (steps.currentStep === 4 && /no/ig.test(isAuthorizedSignatory))
            ) && (
              <ProgressIndicatorLine
                stepUserIsCurrentlyOn={steps.currentStep}
                totalSteps={steps.totalSteps}
                customStyles={{ marginBottom: '40px' }}
                customBackgroundColor="linear-gradient(90deg, #4295F3 2.86%, #3D7BC6 100.86%)"
                customBorderColor="#4295F3"
              />
            )
          }
          {signupFlow()}
        </div>
      </div>
    );
  }

  return (
    <div className="Signup">
      {renderMaterialForm()}
    </div>
  );
}
