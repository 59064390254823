import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  NavBarStateContext,
  HomepageStateContext,
  FirstAndLastNameContext,
  IsSigningOutContext,
  ReloadHomepageContext,
  ErrorMessageContext,
  HasOldSandboxContext,
} from './contextLib';

export default function ContextWrapper({ children }) {
  const [navBarState, setNavBarState] = useState({ userType: 'client' });
  const [globalHomepageState, setGlobalHomepageState] = useState('');
  const [globalFirstAndLastName, setGlobalFirstAndLastName] = useState(null);
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [reloadHomepage, setReloadHomepage] = useState(null);
  const [showErrorMessage, setShowErrorMessage] = useState(null);
  const [hasOldSandbox, setHasOldSandbox] = useState(false);

  return (
    <NavBarStateContext.Provider value={{ navBarState, setNavBarState }}>
      <HomepageStateContext.Provider value={{ globalHomepageState, setGlobalHomepageState }}>
        <HasOldSandboxContext.Provider value={{ hasOldSandbox, setHasOldSandbox }}>
          <ErrorMessageContext.Provider value={{ showErrorMessage, setShowErrorMessage }}>
            <FirstAndLastNameContext.Provider value={{ globalFirstAndLastName, setGlobalFirstAndLastName }}>
              <IsSigningOutContext.Provider value={{ isSigningOut, setIsSigningOut }}>
                <ReloadHomepageContext.Provider value={{ reloadHomepage, setReloadHomepage }}>
                  {children}
                </ReloadHomepageContext.Provider>
              </IsSigningOutContext.Provider>
            </FirstAndLastNameContext.Provider>
          </ErrorMessageContext.Provider>
        </HasOldSandboxContext.Provider>
      </HomepageStateContext.Provider>
    </NavBarStateContext.Provider>
  );
}

ContextWrapper.propTypes = { children: PropTypes.element };
