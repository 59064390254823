import moment from 'moment';

import { assignDefaultValues } from './calcTablesUtils';

import {
  commaEvery3rdChar,
  formatUSCurrencyWithFourDecimals,
  onlyNums,
  concatCharacter,
  removeCommas,
} from '../../utils';

export function createPreferredTable(
  preferred,
  transactionDate,
  shares,
  isDividends,
  dividendRate,
  dividendCompounding,
  issuePrice,
  liquidationPriority,
  liquidationPreference,
  participationRights,
  participationCap,
  conversionRatio,
) {
  return {
    preferred,
    transactionDate,
    shares,
    isDividends,
    dividendRate,
    dividendCompounding,
    issuePrice,
    liquidationPriority,
    liquidationPreference,
    participationRights,
    participationCap,
    conversionRatio,
  };
}

export const preferredTableSchema = [
  {
    headTitle: 'Preferred',
    name: 'preferred',
    value: '',
    cellWidth: '200px',
  },
  {
    headTitle: 'Date of transaction',
    name: 'transactionDate',
    value: null,
    formatter: (value) => moment(value, 'YYYY-MM-DD'),
    dbFormat: (value) => value.format('YYYY-MM-DD'),
    isDate: true,
    cellWidth: '160px',
  },
  {
    headTitle: 'Shares',
    name: 'shares',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    dbFormat: (value) => parseInt(removeCommas(value), 10),
    cellWidth: '120px',
  },
  {
    headTitle: 'Mandatory dividends',
    name: 'isDividends',
    value: 0,
    formatter: (value) => value || 0,
    isSelect: true,
    cellWidth: '85px',
    menuItems: [
      {
        label: 'Yes',
        value: 1,
      },
      {
        label: 'No',
        value: 0,
      },
    ],
  },
  {
    headTitle: 'Dividend Rate',
    name: 'dividendRate',
    value: '',
    formatter: (value) => {
      if (value.includes('0.')) {
        return concatCharacter((value * 100), '%');
      }
      return concatCharacter(value, '%');
    },
    dbFormat: (value) => (parseFloat(value) / 100).toString(),
    charLimit: 3,
    valLimit: 100,
    cellWidth: '82px',
  },
  {
    headTitle: 'Dividend compounding',
    name: 'dividendCompounding',
    isSelect: true,
    value: 'N/A',
    formatter: (value) => value || 'N/A',
    cellWidth: '150px',
    menuItems: [
      {
        label: 'Simple',
        value: 'N/A',
      },
      {
        label: 'Yearly',
        value: '1',
      },
      {
        label: 'Semi-Annually',
        value: '2',
      },
      {
        label: 'Quarterly',
        value: '4',
      },
      {
        label: 'Monthly',
        value: '12',
      },
      {
        label: 'Daily',
        value: '365',
      },
    ],
  },
  {
    headTitle: 'Issue Price',
    name: 'issuePrice',
    value: '',
    formatter: (value) => {
      return formatUSCurrencyWithFourDecimals.format(parseFloat(onlyNums(value.toString())));
    },
    dbFormat: (value) => parseFloat(value.replace(/[$,]/g, '')).toString(),
    cellWidth: '90px',
  },
  {
    headTitle: 'Liquidation priority (1 = top priority)',
    name: 'liquidationPriority',
    isSelect: true,
    value: 1,
    cellWidth: '116px',
    menuItems: [
      {
        label: '1', // TODO How many of those options?
        value: 1,
      },
      {
        label: '2',
        value: 2,
      },
      {
        label: '3',
        value: 3,
      },
    ],
  },
  {
    headTitle: 'Liquidation preference',
    name: 'liquidationPreference',
    value: '',
    formatter: (value) => concatCharacter(value, 'x'),
    dbFormat: (value) => value.replaceAll('x', ''),
    charLimit: 1,
    cellWidth: '78px',
  },
  {
    headTitle: 'Participation Rights',
    name: 'participationRights',
    isSelect: true,
    value: 'non-participating',
    cellWidth: '160px',
    menuItems: [
      {
        label: 'Participating',
        value: 'participating',
      },
      {
        label: 'Non-participating',
        value: 'non-participating',
      },
      {
        label: 'Capped',
        value: 'capped',
      },
    ],
  },
  {
    headTitle: 'Participation cap (multiple of issue price)',
    name: 'participationCap',
    value: '',
    formatter: (value) => concatCharacter(value, 'x'),
    dbFormat: (value) => value.replaceAll('x', ''),
    charLimit: 1,
    cellWidth: '144px',
  },
  {
    headTitle: 'Conversion ratio',
    name: 'conversionRatio',
    value: '',
    formatter: (value) => concatCharacter(value, 'x'),
    dbFormat: (value) => value.replaceAll('x', ''),
    charLimit: 1,
    cellWidth: '70px',
  },
];

export function createSafeConvertibleTable(
  safeConvertible,
  transactionDate,
  principalAmount,
  valuationCap,
  preMoneyPostMoney,
  interestRate,
  interestCompounding,
  maturityDate,
) {
  return {
    safeConvertible,
    transactionDate,
    principalAmount,
    valuationCap,
    preMoneyPostMoney,
    interestRate,
    interestCompounding,
    maturityDate,
  };
}

export const safeConvertibleTableSchema = [
  {
    headTitle: 'Safe / Convertible note',
    name: 'safeConvertible',
    value: '',
    cellWidth: '200px',
  },
  {
    headTitle: 'Date of transaction',
    name: 'transactionDate',
    value: null,
    formatter: (value) => moment(value, 'YYYY-MM-DD'),
    dbFormat: (value) => value.format('YYYY-MM-DD'),
    isDate: true,
    cellWidth: '160px',
  },
  {
    headTitle: 'Principal amount',
    name: 'principalAmount',
    value: '',
    formatter: (value) => {
      if (!value.toString().includes('$')) return `$${commaEvery3rdChar(value.toString())}`;
      return commaEvery3rdChar(value.toString());
    },
    dbFormat: (value) => parseInt(removeCommas(value.replace('$', '')), 10),
    cellWidth: '132px',
  },
  {
    headTitle: 'Valuation cap',
    name: 'valuationCap',
    value: '',
    formatter: (value) => {
      if (!value.toString().includes('$')) return `$${commaEvery3rdChar(value.toString())}`;
      return commaEvery3rdChar(value.toString());
    },
    dbFormat: (value) => parseInt(removeCommas(value.replace('$', '')), 10),
    cellWidth: '112px',
  },
  {
    headTitle: 'Pre-money / post-money',
    name: 'preMoneyPostMoney',
    value: 'pre-money',
    isSelect: true,
    cellWidth: '140px',
    menuItems: [
      {
        label: 'Pre-money',
        value: 'pre-money',
      },
      {
        label: 'Post-money',
        value: 'post-money',
      },
    ],
  },
  {
    headTitle: 'Interest rate',
    name: 'interestRate',
    value: '',
    formatter: (value) => {
      if (!value.toString().includes('%')) return `${commaEvery3rdChar(value.toString())}%`;
      return commaEvery3rdChar(value.toString());
    },
    dbFormat: (value) => parseInt(removeCommas(value), 10),
    cellWidth: '100px',
  },
  {
    headTitle: 'Interest Compounding',
    name: 'interestCompounding',
    isSelect: true,
    value: 'N/A',
    cellWidth: '150px',
    menuItems: [
      {
        label: 'Simple',
        value: 'N/A',
      },
      {
        label: 'Yearly',
        value: '1',
      },
      {
        label: 'Semi-Annually',
        value: '2',
      },
      {
        label: 'Quarterly',
        value: '4',
      },
      {
        label: 'Monthly',
        value: '12',
      },
      {
        label: 'Daily',
        value: '365',
      },
    ],
  },
  {
    headTitle: 'Maturity date',
    name: 'maturityDate',
    value: null,
    formatter: (value) => moment(value).format('YYYY-MM-DD'),
    dbFormat: (value) => value.format('YYYY-MM-DD'),
    isDate: true,
    cellWidth: '160px',
  },
];

export function createCommonTable(
  common,
  outstanding,
) {
  return {
    common,
    outstanding,
  };
}

export const commonTableSchema = [
  {
    headTitle: 'Common',
    name: 'common',
    value: '',
    cellWidth: '180px',
    menuItems: [ // TODO unsure about those dropdown values...
      {
        label: 'Common A',
        value: 'Common A',
      },
      {
        label: 'Series A',
        value: 'Series A',
      },
      {
        label: 'Series B',
        value: 'Series B',
      },
    ],
  },
  {
    headTitle: 'Outstanding',
    name: 'outstanding',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    dbFormat: (value) => parseInt(removeCommas(value), 10),
    cellWidth: '132px',
  },
];

export function createOptionsTable(
  options,
  outstanding,
  strikePrice,
) {
  return {
    options,
    outstanding,
    strikePrice,
  };
}

export const optionsTableSchema = [
  {
    headTitle: 'Options',
    name: 'options',
    value: '',
    cellWidth: '350px',
  },
  {
    headTitle: 'Outstanding',
    name: 'outstanding',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    dbFormat: (value) => parseInt(removeCommas(value), 10),
    cellWidth: '132px',
  },
  {
    headTitle: 'Exercise Price',
    name: 'strikePrice',
    value: '',
    formatter: (value) => {
      return formatUSCurrencyWithFourDecimals.format(parseFloat(onlyNums(value.toString())));
    },
    dbFormat: (value) => parseFloat(value.replaceAll('$', '')).toString(),
    cellWidth: '132px',
  },
];

export function createWarrantsTable(
  warrants,
  outstanding,
  strikePrice,
) {
  return {
    warrants,
    outstanding,
    strikePrice,
  };
}

export const warrantsTableSchema = [
  {
    headTitle: 'Warrants',
    name: 'warrants',
    value: '',
    cellWidth: '350px',
  },
  {
    headTitle: 'Outstanding',
    name: 'outstanding',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    dbFormat: (value) => parseInt(removeCommas(value), 10),
    cellWidth: '132px',
  },
  {
    headTitle: 'Exercise Price',
    name: 'strikePrice',
    value: '',
    formatter: (value) => {
      return formatUSCurrencyWithFourDecimals.format(parseFloat(onlyNums(value.toString())));
    },
    dbFormat: (value) => parseFloat(value.replaceAll('$', '')).toString(),
    cellWidth: '132px',
  },
];

export function createRemainingOptionsPoolTable(
  remainingOptionPool,
  available,
  ntmOptionPercent,
  ntmOptions,
) {
  return {
    remainingOptionPool,
    available,
    ntmOptionPercent,
    ntmOptions,
  };
}

export const remainingOptionPoolTableSchema = [
  {
    headTitle: 'Remaining Option Pool',
    name: 'remainingOptionPool', // TODO Option with an 's' or no?
    value: '',
    cellWidth: '180px',
    nonEditable: true,
  },
  {
    headTitle: 'Available',
    name: 'available',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    dbFormat: (value) => parseInt(removeCommas(value), 10),
    cellWidth: '132px',
  },
  {
    headTitle: 'NTM Option %',
    name: 'ntmOptionPercent',
    value: '',
    cellWidth: '132px',
    nonEditable: true,
  },
  {
    headTitle: 'NTM Options',
    name: 'ntmOptions',
    value: '',
    cellWidth: '132px',
    nonEditable: true,
  },
];

export const defaultPreferredTableData =
  createPreferredTable(...assignDefaultValues(preferredTableSchema, 1));
export const defaultSafeConvertibleTableData =
  createSafeConvertibleTable(...assignDefaultValues(safeConvertibleTableSchema, 1));
export const defaultCommonTableData =
  createCommonTable(...assignDefaultValues(commonTableSchema, 1));
export const defaultOptionsTableData =
  createOptionsTable(...assignDefaultValues(optionsTableSchema, 1));
export const defaultWarrantsTableData =
  createWarrantsTable(...assignDefaultValues(warrantsTableSchema, 1));
export const defaultRemainingOptionsPoolTableData =
  createRemainingOptionsPoolTable(...assignDefaultValues(remainingOptionPoolTableSchema, 1));

export default {
  preferredTableSchema,
  createPreferredTable,
  defaultPreferredTableData,
  safeConvertibleTableSchema,
  createSafeConvertibleTable,
  defaultSafeConvertibleTableData,
  commonTableSchema,
  createCommonTable,
  defaultCommonTableData,
  optionsTableSchema,
  createOptionsTable,
  defaultOptionsTableData,
  warrantsTableSchema,
  createWarrantsTable,
  defaultWarrantsTableData,
  remainingOptionPoolTableSchema,
  createRemainingOptionsPoolTable,
  defaultRemainingOptionsPoolTableData,
};
