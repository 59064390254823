import React from 'react';
import PropTypes from 'prop-types';

import {
  View,
  Text,
  Image,
} from '@react-pdf/renderer';

import initioTexture from '../assets/images/header-initio-texture.png';

export default function TopHeader({
  leftTitle,
  rightTitle,
}) {
  return (
    <View
      style={{
        position: 'absolute',
        top: '0px',
        backgroundColor: '#303D5C',
        height: '104px',
        width: '100%',
      }}
    >
      <Image
        src={initioTexture}
        style={{
          position: 'absolute',
          height: '104px',
          width: '273px',
        }}
      />
      <Text
        style={{
          fontFamily: 'Roboto',
          fontSize: '24px',
          color: '#FFFFFF',
          position: 'absolute',
          top: '34px',
          left: '48px',
        }}
      >
        {leftTitle}
      </Text>
      {rightTitle && (
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '24px',
            color: '#FFFFFF',
            position: 'absolute',
            top: '34px',
            left: '660px',
          }}
        >
          {rightTitle}
        </Text>
      )}
    </View>
  );
}


TopHeader.propTypes = {
  leftTitle: PropTypes.string,
  rightTitle: PropTypes.string,
};
