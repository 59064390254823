import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import PropTypes from 'prop-types';

import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

import Payment from '../../components/Payment';

import './MakePayment.scss';

import { dropdownAnimationTimeMS } from '../../utils/globals';
import { commaEvery3rdChar } from '../../utils';


export default function MakePayment({
  makePaymentButtonRef,
  setPaymentFormIsLoading,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
}) {
  const [displayAmount, setDisplayAmount] = useState({});
  const [removedAmount, setRemovedAmount] = useState('');
  const [height, setHeight] = useState(0);

  useEffect(() => {
    setHeight(displayAmount.removedAmount ? 'auto' : 0);
    setRemovedAmount(!displayAmount.removedAmount ? '' : commaEvery3rdChar(displayAmount.removedAmount));
  }, [displayAmount]);

  return (
    <div className="MakePayment">
      <h4>Payment</h4>
      <p>
        After your payment is approved, you can immediately start filling out a 409A valuation form.
        Once you submit the form our seasoned experts will begin processing your valuation.
        Our process takes between 5 to 7 business days.
      </p>

      <div className="checkout-cols-container">
        <div className="checkout-col">
          <Payment
            makePaymentButtonRef={makePaymentButtonRef}
            setPaymentFormIsLoading={setPaymentFormIsLoading}
            setPaymentIsProcessing={setPaymentIsProcessing}
            setPaymentIsCompleted={setPaymentIsCompleted}
            setDisplayAmount={setDisplayAmount}
          />
          <p className="legal-text">
            *409A valuations cover Pre-Seed, Seed or Series A rounds.
            <br />
            <span>&copy;</span>
            {' '}
            {new Date().getFullYear()}
            {' '}
            initio software. All rights reserved.
          </p>
        </div>
        <div className="checkout-col">
          <div className="order-summary">
            <h6>Order summary</h6>
            <hr />
            <p className="summary-bold">1 initio Company Account Subscription</p>
            <p>$2,500 per year</p>
            <hr />
            <AnimateHeight
              duration={dropdownAnimationTimeMS}
              height={height}
            >
              <p className="summary-bold" style={{ color: '#B3261E' }}>Discount</p>
              <p style={{ color: '#B3261E' }}>
                -$
                {removedAmount}
              </p>
              <hr />
            </AnimateHeight>
            <p className="summary-bold">1 409A valuation - free with subscription* </p>
            <p>
              Any additional 409A valuations requested within the subscription year will
              be charged full price at $2,500 per valuation.
            </p>
            <hr />
            <span className="total" style={{ display: 'block' }}>Total</span>
            <span className="total-price" style={{ display: 'block' }}>
              <AttachMoneyIcon
                sx={{
                  display: 'inline-block',
                  marginTop: '-5px',
                  marginRight: '-5px',
                }}
              />
              {`${!displayAmount?.chargeAmount ? '2,500' : commaEvery3rdChar(displayAmount?.chargeAmount)} per year`}
            </span>
            <hr className="total-separator" />
            <div className="subscription-includes">
              <p style={{ textDecoration: 'underline' }}>Subscription Includes:</p>
              <ul>
                <li>1 free 409A valuation per year</li>
                <li>Additional users join for free</li>
                <li>
                  Our proprietary 409A valuation sandbox:
                  <ul className="sub-list">
                    <li>Customized for your company</li>
                    <li>
                      Designed with sliders that, in real-time,
                      show you which factors have the most
                      impact on your company&apos;s share price and audit risk
                    </li>
                    <li>Allows you to finalize your 409A valuation when you&apos;re ready</li>
                  </ul>
                </li>
              </ul>
              <ul>
                <li>Scenario modeling (coming soon)</li>
                <li>Market data (coming soon)</li>
                <li>Marketplace insights (coming soon)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

MakePayment.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  setPaymentFormIsLoading: PropTypes.func.isRequired,
};
