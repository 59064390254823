import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';


import LightBlueGradient from '../components/LightBlueGradient';
import BottomPagination from '../components/BottomPagination';

import initioTexture from '../assets/images/header-initio-texture.png';
import profitIcon from '../assets/images/profit-icon.png';

function BlueDataBlock({
  dataName,
  dataNumber,
  isSandboxBlock,
}) {
  return (
    <View
      style={{
        backgroundColor: `${isSandboxBlock ? '#575E71' : '#303D5C'}`,
        borderRadius: '12px',
        width: '192px',
        height: '104px',
        paddingLeft: '12px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <Text
        style={{
          fontFamily: 'RobotoMed',
          fontSize: '14px',
          color: `${isSandboxBlock ? '#EFF0F3' : '#D0D9EE'}`,
          lineHeight: '0.5mm',
        }}
      >
        {dataName}
      </Text>
      <Text
        style={{
          fontFamily: 'RobotoBold',
          fontSize: '16px',
          color: '#FDFEFF',
          marginTop: '4px',
        }}
      >
        {dataNumber}
      </Text>
    </View>
  );
}

BlueDataBlock.propTypes = {
  dataName: PropTypes.string,
  dataNumber: PropTypes.string,
  isSandboxBlock: PropTypes.bool,
};


export default function SandboxVal({ sandboxData }) {
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative' }}
    >
      <View
        style={{
          position: 'absolute',
          top: '0px',
          backgroundColor: '#303D5C',
          height: '104px',
          width: '100%',
        }}
      >
        <Image
          src={initioTexture}
          style={{
            position: 'absolute',
            height: '104px',
            width: '273px',
          }}
        />
        <View
          style={{
            position: 'absolute',
            top: '24px',
            left: '48px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: '15px',
          }}
        >
          <Image
            src={profitIcon}
            style={{
              width: '48px',
              height: '48px',
            }}
          />
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              color: '#FFFFFF',
            }}
          >
            409A Valuation Summary
          </Text>
        </View>
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: -1,
          height: '100%',
        }}
      >
        <View
          style={{
            width: '50%',
            borderRight: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          <View
            style={{
              position: 'absolute',
              top: '126px',
              right: '0px',
              width: '238px',
              height: '72px',
              backgroundColor: '#42A179',
              borderTopLeftRadius: '100px',
              borderBottomLeftRadius: '100px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
            }}
          >
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '32px',
                color: '#FFFFFF',
              }}
            >
              {`$${sandboxData.perSharePrice}`}
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '16px',
                color: '#FFFFFF',
              }}
            >
              per share
            </Text>
          </View>
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              color: '#49454F',
              marginTop: '149px',
              marginLeft: '48px',
            }}
          >
            Concluded value
          </Text>
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              color: '#49454F',
              marginTop: '73px',
              marginLeft: '54px',
            }}
          >
            Finalized sandbox values
          </Text>
          <View
            style={{
              marginTop: '36px',
              marginLeft: '90px',
              borderLeft: '4px',
              borderColor: '#40C486',
              paddingLeft: '24px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                rowGap: '12px',
                columnGap: '24px',
              }}
            >
              <BlueDataBlock
                dataName="Term"
                dataNumber={`${sandboxData.term} years`}
                isSandboxBlock
              />
              <BlueDataBlock
                dataName="NTM Options"
                dataNumber={`${sandboxData.ntmOptionPercent}%`}
                isSandboxBlock
              />
              <BlueDataBlock
                dataName="Volatility"
                dataNumber={`${sandboxData.volatility}%`}
                isSandboxBlock
              />
              <BlueDataBlock
                dataName="DLOM"
                dataNumber={`${sandboxData.dlom}%`}
                isSandboxBlock
              />
            </View>
          </View>
          <View
            style={{
              marginTop: '26px',
              marginLeft: '90px',
              borderLeft: '4px',
              borderColor: '#40C486',
              paddingLeft: '16px',
              width: '412px',
            }}
          >
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                color: '#49454F',
                lineHeight: '0.55mm',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '14px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                {sandboxData.totalOutcomes}
                {' '}
                calculations were ran
              </Text>
              {' '}
              by initio&apos;s proprietary software to help determine your company&apos;s share price.
              It processed multiple methodologies, that our valuation experts reviewed and approved.
            </Text>
          </View>
          <View
            style={{
              marginTop: '24px',
              marginLeft: '90px',
              borderLeft: '4px',
              borderColor: '#40C486',
              paddingLeft: '16px',
              width: '412px',
            }}
          >
            <Text
              style={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                color: '#49454F',
                lineHeight: '0.55mm',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '14px',
                  color: '#49454F',
                  lineHeight: '0.55mm',
                }}
              >
                {sandboxData.selectedPriceOutcomes}
                {' '}
                defensible outcomes,
              </Text>
              {' '}
              this is the number of times your concluded share price of
              {' '}
              {`$${sandboxData.perSharePrice}`}
              {' '}
              was
              the outcome of an iteration calculated by our software.
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '50%',
          }}
        >
          <View
            style={{
              height: '100%',
            }}
          >
            <LightBlueGradient
              width="612px"
              height="690px"
              customPosition={{
                bottom: '0px',
                right: '0px',
              }}
            />
            <View
              style={{
                marginLeft: '54px',
                marginTop: '126px',
                paddingTop: '22px',
                paddingLeft: '48px',
                width: '504px',
                height: '595px',
                backgroundColor: '#FAFAFD',
                borderRadius: '24px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  color: '#49454F',
                }}
              >
                Insights
              </Text>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '24px',
                }}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    marginTop: '36px',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      color: '#49454F',
                    }}
                  >
                    Company data
                  </Text>
                  <BlueDataBlock
                    dataName="Concluded percent of preferred"
                    dataNumber={`${sandboxData.concludedPercentOfPreferred}%`}
                  />
                  <BlueDataBlock
                    dataName="Average option exercise price"
                    dataNumber={`$${sandboxData.averageOptionExercise}`}
                  />
                  <BlueDataBlock
                    dataName="Prior 409A Value"
                    dataNumber={sandboxData.prior409A ? `$${sandboxData.prior409A}` : 'N/A'}
                  />
                  <BlueDataBlock
                    dataName="Latest round issue price"
                    dataNumber={`$${sandboxData.latestRoundIssued}`}
                  />
                </View>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '12px',
                    marginTop: '36px',
                  }}
                >
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '16px',
                      color: '#49454F',
                    }}
                  >
                    Market data
                  </Text>
                  <BlueDataBlock
                    dataName="Median Market % of preferred"
                    dataNumber={`${sandboxData.medianMarketPercOfPreferred}%`}
                  />
                  <BlueDataBlock
                    dataName="Average industry volatility"
                    dataNumber={`${sandboxData.averageIndustryVolatility}%`}
                  />
                </View>
              </View>
            </View>
          </View>
        </View>
      </View>
      <BottomPagination
        pageNumberLeft={7}
        pageNumberRight={8}
      />
    </Page>
  );
}

SandboxVal.propTypes = {
  sandboxData: PropTypes.object,
};
