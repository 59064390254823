import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import ErrorIcon from '@mui/icons-material/Error';
import UpdateIcon from '@mui/icons-material/Update';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';

import { ReactComponent as WarningIcon } from '../../images/icons/warning_icon.svg';
import { ReactComponent as GreenCheckMark } from '../../images/icons/green_checkmark.svg';

import LoopingIcon from '../../components/LoopingIcon';
import useFormFields from '../../lib/hooksLib';
import { isAValidEmail } from '../../utils';
import {
  AuthContext,
  FirstAndLastNameContext,
  HomepageStateContext,
  ErrorMessageContext,
  NavBarStateContext,
} from '../../lib/contextLib';

import './Login.scss';

export default function Login({
  hasVerifiedEmail = false,
  verifiedEmail,
}) {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setGlobalFirstAndLastName } = useContext(FirstAndLastNameContext);
  const { setGlobalHomepageState } = useContext(HomepageStateContext);
  const { setNavBarState } = useContext(NavBarStateContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showErrorHeader, setShowErrorHeader] = useState(false);
  const [passwordCapIsOn, setPasswordCapIsOn] = useState(false);

  const [fields, handleFieldChange] = useFormFields({
    email: '',
    password: '',
  });
  const [emailHasError, setEmailHasError] = useState({
    status: false,
    errorMessage: '',
  });
  const [passwordHasError, setPasswordHasError] = useState({
    status: false,
    errorMessage: '',
  });

  const [showInactivityMessage, setShowInactivityMessage] = useState(true);
  const [isSendingNewCode, setIsSendingNewCode] = useState(false);
  const [newCodeHasBeenSent, setNewCodeHasBeenSent] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    localStorage.clear();
    setGlobalFirstAndLastName(null);
    setGlobalHomepageState('');
    setNavBarState({ userType: 'client' });

    if (verifiedEmail) {
      handleFieldChange({
        target: {
          value: verifiedEmail,
          id: 'email',
        },
      });
    }

    setShowInactivityMessage(new URLSearchParams(window.location.search).get('inactivity'));
  }, []);

  function checkForEmailErrors() {
    let emailInputHasError = true;
    if (fields.email === '') {
      setEmailHasError({
        status: true,
        errorMessage: 'Please enter your email address.',
      });
    } else if ((!fields.email.includes('@') || fields.email.split('@').length > 2)) {
      setEmailHasError({
        status: true,
        errorMessage: 'An email address must contain a single @',
      });
    } else if (!fields.email.split('@')[1].includes('.')) {
      setEmailHasError({
        status: true,
        errorMessage: 'The domain portion of the email address is invalid (the portion after @)',
      });
    } else if (!isAValidEmail(fields.email)) {
      setEmailHasError({
        status: true,
        errorMessage: 'Invalid email entered...',
      });
    } else {
      setEmailHasError({
        status: false,
        errorMessage: '',
      });
      emailInputHasError = false;
    }
    return emailInputHasError;
  }

  function checkForPasswordErrors() {
    if (fields.password === '') {
      setPasswordHasError({
        status: true,
        errorMessage: 'No password entered',
      });
    } else if (fields.password.length < 8) {
      setPasswordHasError({
        status: true,
        errorMessage: (
          <span>
            The password you entered is incorrect. Password must be 8 characters minimum.
            {' '}
            <Button className="forgot-password-error-link" onClick={() => nav('/login/reset')}>Forgot password?</Button>
          </span>
        ),
      });
    } else {
      setPasswordHasError({
        status: false,
        errorMessage: '',
      });
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();

    if (fields.email === '' || fields.password === '') {
      if (fields.email === '') {
        setEmailHasError({
          status: true,
          errorMessage: 'Please enter your email address.',
        });
      }
      if (fields.password === '') {
        setPasswordHasError({
          status: true,
          errorMessage: 'No password entered',
        });
      }
      return;
    }

    if (passwordHasError.status === true || emailHasError.status === true) return;

    setIsLoading(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      setIsAuthenticated(true);
      nav('/');
    } catch (e) {
      setShowErrorHeader(true);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="Login">
      {newCodeHasBeenSent && (
        <div className="new-verif-email">
          <MarkEmailReadOutlinedIcon className="top-icon" />
          <h4>Verification email sent</h4>
          <p>{`To complete the sign up process you must verify your email address. We have sent a verification email to ${fields?.email}.`}</p>
        </div>
      )}
      {!newCodeHasBeenSent && (
        <form
          className="login-container"
          onSubmit={handleSubmit}
        >
          {showInactivityMessage && <UpdateIcon className="inactive-icon" />}
          <h4>{hasVerifiedEmail ? 'Log in to continue' : showInactivityMessage ? 'You\'ve been logged out due to inactivity' : 'Login'}</h4>
          {showInactivityMessage && (
            <p className="inactivity-text">
              All of your progress has been saved, log in to continue.
            </p>
          )}
          {hasVerifiedEmail && (
            <div className="email-verified-header">
              <div>
                <GreenCheckMark className="code-verified-check-icon" />
              </div>
              <p>
                {`Great! Your email ${verifiedEmail} has been verified. Please enter your password to continue.`}
              </p>
            </div>
          )}
          {showErrorHeader && (
            <div className="error-header">
              <div className="warning-icon">
                <WarningIcon />
              </div>
              <p>
                We&apos;re having trouble signing you in. Please double check your email
                address and password are correct, then try again.
              </p>
            </div>
          )}
          <FormControl
            className={emailHasError.status ? 'has-error' : ''}
            variant="outlined"
          >
            <TextField
              variant="outlined"
              id="email"
              label="Email"
              type="text"
              value={fields.email || ''}
              onChange={handleFieldChange}
              InputLabelProps={{ shrink: true }}
              onFocus={() => {
                setEmailHasError({
                  status: false,
                  errorMessage: '',
                });
              }}
              // InputLabelProps={{
              //   shrink: !!fields.email,
              // }}
              onBlur={async () => {
                if (fields?.email) {
                  checkForEmailErrors();
                  if (!checkForEmailErrors()) {
                    try {
                      let isEmailVerified = await fetch(
                        // eslint-disable-next-line max-len
                        `${process.env.REACT_APP_BACKEND_URL}/accounts/is-verified/${fields?.email}?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
                      );
                      isEmailVerified = await isEmailVerified.json();

                      if (isEmailVerified?.detail) { // .detail = 'Not Found'
                        return;
                      }

                      if (
                        (isEmailVerified?.isVerified === 0) &&
                        (isEmailVerified?.userExists === 1)
                      ) {
                        setEmailHasError({
                          status: true,
                          errorMessage: (
                            <span>
                              Please verify your email address to proceed.
                              {' '}
                              <Button
                                className="resend-code-error-link"
                                disabled={isSendingNewCode}
                                onClick={async () => {
                                  setIsSendingNewCode(true);
                                  try {
                                    await Auth.resendSignUp(fields?.email);
                                  } catch (e) {
                                    setShowErrorMessage(e.toString());
                                  } finally {
                                    setIsSendingNewCode(false);
                                    setNewCodeHasBeenSent(true);
                                  }
                                }}
                              >
                                Resend code?
                              </Button>
                            </span>
                          ),
                        });
                      } else if (
                        (isEmailVerified?.isVerified === 1) &&
                        (isEmailVerified?.userExists === 1)
                      ) {
                        setEmailHasError({
                          status: false,
                          errorMessage: '',
                        });
                      }
                    } catch (e) {
                      setShowErrorMessage(e.toString());
                    }
                  }
                }
              }}
              error={emailHasError.status}
            />
            <FormHelperText>
              {emailHasError.status && (
                <span className="error-message">
                  <ErrorIcon />
                  {emailHasError.errorMessage}
                </span>
              )}
              {!hasVerifiedEmail && 'Use your company email address'}
            </FormHelperText>
          </FormControl>

          <FormControl
            className={passwordHasError.status ? 'has-error' : ''}
            variant="outlined"
          >
            <TextField
              id="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={fields.password}
              onChange={handleFieldChange}
              InputLabelProps={{ shrink: true }}
              onFocus={() => {
                setPasswordHasError({
                  status: false,
                  errorMessage: '',
                });
              }}
              onBlur={() => fields.password && checkForPasswordErrors()}
              error={passwordHasError.status}
              onKeyDown={(e) => setPasswordCapIsOn(e.getModifierState('CapsLock'))}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {passwordCapIsOn && <KeyboardCapslockIcon />}
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                      edge="end"
                    >
                      {
                        showPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />
                      }
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <FormHelperText>
              {passwordHasError.status && (
                <span className="error-message">
                  <ErrorIcon />
                  {passwordHasError.errorMessage}
                </span>
              )}
            </FormHelperText>
            {/* TODO Handle Remember me logic */}
            {/* <FormControlLabel
            label="Remember me"
            control={
              <Checkbox label="Remember me" />
            }
          /> */}
          </FormControl>
          <Button
            variant="contained"
            disabled={isLoading}
            type="submit"
            className="confirm-login-btn"
          >
            {
              isLoading ? <LoopingIcon /> : 'Login'
            }
          </Button>
          {!hasVerifiedEmail && (
            <>
              <Button
                className="reset-password-link"
                onClick={() => nav('/login/reset')}
              >
                Forgot password?
              </Button>
              {!showInactivityMessage && (
                <Button
                  className="sign-up-link"
                  onClick={() => nav('/signup')}
                >
                  No account? Sign-up
                </Button>
              )}
            </>
          )}
        </form>
      )}
    </div>
  );
}

Login.propTypes = {
  hasVerifiedEmail: PropTypes.bool,
  verifiedEmail: PropTypes.string,
};
