import React from 'react';
import PropTypes from 'prop-types';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './RotatingChevron.scss';

export default function RotatingChevron({
  chevronWithCircle = false,
  rotationSpeed = 0.5,
  customStyles = {},
  downwards0Condition,
}) {
  const classes = `RotatingChevron ${(downwards0Condition === 0) ? 'downward' : 'upward'}-chevron`;
  const chevronStyles = {
    transition: `transform ${rotationSpeed}s linear`,
    ...customStyles,
  };

  return (
    <div className="chevron-wrapper">
      {
        chevronWithCircle ? (
          <ExpandMoreIcon className={classes} sx={chevronStyles} /> // TODO return other chevron
        ) : (
          <ExpandMoreIcon className={classes} sx={chevronStyles} />
        )
      }
    </div>
  );
}

RotatingChevron.propTypes = {
  chevronWithCircle: PropTypes.bool,
  rotationSpeed: PropTypes.number,
  customStyles: PropTypes.object,
  downwards0Condition: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};
