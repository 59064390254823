import React from 'react';
import Radio from '@mui/material/Radio';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';
import { toTitleCase } from '../../utils';
import './FormControlRadioBtn.scss';

export default function FormControlRadioBtn({
  value, desc, radio = true, checked, customIdName = '', addA = false, index = 0,
}) {
  return (
    <FormControlLabel
      value={value}
      sx={{
        marginTop: `${(index === 0) ? '0' : '20'}px`,
      }}
      id={customIdName}
      className="FormControlRadioBtn"
      control={(
        radio ?
          <Radio className="ctrl" /> : (
            <Checkbox
              className="ctrl"
              size="small"
              checked={checked}
            />
          )
      )}
      label={(
        <span aria-label={value}>
          {
            radio && (
              <b>
                {addA ? 'A ' : ''}
                {toTitleCase(value)}
              </b>
            )
          }
          {' '}
          {desc}
        </span>
      )}
    />
  );
}

FormControlRadioBtn.propTypes = {
  customIdName: PropTypes.string,
  value: PropTypes.string.isRequired,
  desc: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]).isRequired, // React node OR string
  radio: PropTypes.bool,
  checked: PropTypes.bool,
  addA: PropTypes.bool,
  index: PropTypes.number,
};
