import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ReviewDropdown from './ReviewDropdown';
import ReviewInfoTile from './ReviewInfoTile';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';
import { ReactComponent as WarningIcon } from '../../images/icons/warning_icon.svg';
import './ReviewAndCheckout.scss';

export default function ReviewAndCheckout({
  submissionInfoSucceeded,
  stepsParent,
  currentStep,
  openNewWizard,
  areAll4DataWizardsComplete,
  // Wiz1
  whenIsExitLikelyToHappen,
  whatTypeOfExit,
  industryVertical,
  // Wiz2
  wiz2Data,
  EBITDAChecked,
  // Wiz3
  chosenFunding,
  currentFundingDate,
  totalSafeOrConvertible,
  totalAmountRaised,
  interestRate,
  valuationCap,
  preOrPostMoneyNotes,
  receivedFutureTerms,
  anySecondaryCommonStockTransactions,
  percentageSecondaryCommonStockSold,
  secondaryCommonStockTransactionTotalAmount,
  // Wiz4
  capTableProvider,
  capTableFilesUploaded,
  optionLedgerFilesUploaded,
  financialsFilesUploaded,
  articlesOfIncorporationUploaded,
  secondaryTransactionDocs,
  safeOrConvertibleForCurrentOrPreviousRounds,
  safeOrConvertibleNoteAgreement,
  termsSheets,
  showErrors,
  setShowErrors,
  show409AReview,
  showDataLoadingOverlay,
}) {
  const [creditCardNumber, setCreditCardNumber] = useState('');
  const [nameOnCard, setNameOnCard] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [expirationDateErr, setExpirationDateErr] = useState(false);
  const [cvv, setCvv] = useState('');

  function arrayToStringOfNoLengthIfLength0(arr) {
    return ((arr.length === 0) ? '' : arr);
  }

  if (currentStep === 1) {
    return (
      <div className="ReviewAndCheckout">
        {showDataLoadingOverlay && (
          <div className="review-loading-wrapper">
            <LoadingSpinner className="custom-loading-spinner" />
          </div>
        )}
        {(showErrors && !areAll4DataWizardsComplete) && (
          <div className="warning-block">
            <WarningIcon className="warning-icon" />
            <div className="warning-message">
              <h2>Failed to submit information</h2>
              <p>
                All required information must be provided before you can submit your information.
              </p>
            </div>
          </div>
        )}
        {show409AReview ? (
          <div className="review-header-row">
            <h3>Submitted 409A valuation form</h3>
            <p>
              This information has been submitted, edits are no longer possible.
              We are in the process of reviewing this information and building your customized 409A valuation sandbox.
            </p>
          </div>
        ) : (
          <div className="page-header-row">
            <h3>Review and submit information</h3>
            <Button
              variant="contained"
              className="top-submit-info-btn"
              onClick={() => {
                if (!areAll4DataWizardsComplete) {
                  setShowErrors(true);
                } else {
                  setShowErrors(false);
                  submissionInfoSucceeded();
                  // openNewWizard(4, stepsParent[stepsParent.length - 1].totalSteps(), 2);
                }
              }}
            >
              Submit information
            </Button>
          </div>
        )}
        <hr />
        <ReviewDropdown
          stepsParent={stepsParent}
          openNewWizard={openNewWizard}
          activeWiz={0}
          headline={stepsParent[0].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <ReviewInfoTile
                activeWizard={0}
                wizardTotalSteps={stepsParent[0].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={1}
                title="Company industry"
                data={industryVertical?.title}
                showErrors={showErrors}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={0}
                  wizardTotalSteps={stepsParent[0].totalSteps()}
                  openNewWizard={openNewWizard}
                  currentStep={2}
                  title="Most likely type of exit event"
                  data={whatTypeOfExit}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
                <ReviewInfoTile
                  activeWizard={0}
                  wizardTotalSteps={stepsParent[0].totalSteps()}
                  openNewWizard={openNewWizard}
                  currentStep={3}
                  title="Most likely timing of exit event"
                  data={whenIsExitLikelyToHappen}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
              </div>
            </div>
          )}
        />
        <ReviewDropdown
          stepsParent={stepsParent}
          openNewWizard={openNewWizard}
          activeWiz={1}
          headline={stepsParent[1].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={stepsParent[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Total current cash"
                  data={wiz2Data.totalCurrentCash}
                  showIcon={wiz2Data.totalCurrentCash ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />

                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={stepsParent[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Current monthly cash burn"
                  data={wiz2Data.currentMonthlyCashBurn}
                  showIcon={wiz2Data.currentMonthlyCashBurn ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={stepsParent[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Total non-convertible debt"
                  data={wiz2Data.totalNonConvertibleDebt}
                  showIcon={wiz2Data.totalNonConvertibleDebt ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />

                <ReviewInfoTile
                  activeWizard={1}
                  wizardTotalSteps={stepsParent[1].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Total convertible debt"
                  data={wiz2Data.totalConvertibleDebt}
                  showIcon={wiz2Data.totalConvertibleDebt ? 'AttachMoneyIcon' : ''}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
              </div>
              {!!(EBITDAChecked ||
                wiz2Data.totalCurrentFiscalYearRevenue ||
                wiz2Data.totalNextFiscalYearRevenue ||
                wiz2Data.totalCurrentFiscalYearEBITDA ||
                wiz2Data.totalNextFiscalYearEBITDA) &&
                (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={stepsParent[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total current fiscal year revenue"
                        data={wiz2Data.totalCurrentFiscalYearRevenue}
                        showIcon={wiz2Data.totalCurrentFiscalYearRevenue ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={stepsParent[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total next fiscal year revenue"
                        data={wiz2Data.totalNextFiscalYearRevenue}
                        showIcon={wiz2Data.totalNextFiscalYearRevenue ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        justifyContent: 'space-between',
                      }}
                    >
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={stepsParent[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total current fiscal year EBITDA"
                        data={wiz2Data.totalCurrentFiscalYearEBITDA}
                        showIcon={wiz2Data.totalCurrentFiscalYearEBITDA ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                      <ReviewInfoTile
                        activeWizard={1}
                        wizardTotalSteps={stepsParent[1].totalSteps()}
                        openNewWizard={openNewWizard}
                        currentStep={2}
                        title="Total next fiscal year EBITDA"
                        data={wiz2Data.totalNextFiscalYearEBITDA}
                        showIcon={wiz2Data.totalNextFiscalYearEBITDA ? 'AttachMoneyIcon' : ''}
                        customContainerStyles={{ width: '50%' }}
                        showErrors={EBITDAChecked ? showErrors : !showErrors}
                      />
                    </div>
                  </>
                )}
              <ReviewInfoTile
                activeWizard={1}
                wizardTotalSteps={stepsParent[1].totalSteps()}
                openNewWizard={openNewWizard}
                title="Precent of remaining option pool you intend to issue within the next 12 months"
                data={`${(wiz2Data.ntmOptionsPerc).toString()}%`}
                showErrors={showErrors}
              />
            </div>
          )}
        />
        <ReviewDropdown
          stepsParent={stepsParent}
          openNewWizard={openNewWizard}
          activeWiz={2}
          headline={stepsParent[2].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <ReviewInfoTile
                activeWizard={2}
                wizardTotalSteps={stepsParent[2].totalSteps()}
                openNewWizard={openNewWizard}
                title="Total amount raised"
                data={totalAmountRaised}
                currentStep={1}
                showIcon={totalAmountRaised ? 'AttachMoneyIcon' : ''}
                customContainerStyles={{ width: '100%' }}
                showErrors={showErrors}
              />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'nowrap',
                  justifyContent: 'space-between',
                }}
              >
                <ReviewInfoTile
                  activeWizard={2}
                  wizardTotalSteps={stepsParent[2].totalSteps()}
                  openNewWizard={openNewWizard}
                  title="Current funding type"
                  data={chosenFunding}
                  currentStep={2}
                  customContainerStyles={{ width: '50%' }}
                  showErrors={showErrors}
                />
                {chosenFunding !== 'No funding' && (
                  <ReviewInfoTile
                    activeWizard={2}
                    wizardTotalSteps={stepsParent[2].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="Date of current round of funding"
                    data={currentFundingDate}
                    currentStep={3}
                    customContainerStyles={{ width: '50%' }}
                    showErrors={showErrors}
                  />
                )}
              </div>

              {
                (chosenFunding === 'SAFE Note' || chosenFunding === 'Convertible Note') && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={stepsParent[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Total amount raised"
                      data={totalSafeOrConvertible}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showIcon="AttachMoneyIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={stepsParent[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Interest rate"
                      data={interestRate}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showIcon="PercentIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={stepsParent[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Valuation Cap"
                      data={valuationCap}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showIcon="AttachMoneyIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={stepsParent[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Pre or Post money"
                      data={preOrPostMoneyNotes}
                      currentStep={3}
                      customContainerStyles={{ width: '25%' }}
                      showErrors={showErrors}
                    />
                  </div>
                )
              }

              <ReviewInfoTile
                activeWizard={2}
                wizardTotalSteps={stepsParent[2].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={4}
                title="Has the company received any term sheets for future rounds of funding?"
                data={(
                  receivedFutureTerms === null ?
                    '' :
                    (receivedFutureTerms === true) ? 'Yes' : 'No'
                )}
                showErrors={showErrors}
              />

              <ReviewInfoTile
                activeWizard={2}
                wizardTotalSteps={stepsParent[2].totalSteps()}
                openNewWizard={openNewWizard}
                currentStep={5}
                title="Has the company had any secondary common stock transactions?"
                data={(
                  anySecondaryCommonStockTransactions === null ?
                    '' :
                    (anySecondaryCommonStockTransactions === true) ? 'Yes' : 'No'
                )}
                showErrors={showErrors}
              />

              {
                (anySecondaryCommonStockTransactions === true) && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                    }}
                  >
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={stepsParent[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Total amount of secondary stock transaction"
                      data={secondaryCommonStockTransactionTotalAmount}
                      currentStep={5}
                      customContainerStyles={{ width: '50%' }}
                      showIcon="AttachMoneyIcon"
                      showErrors={showErrors}
                    />
                    <ReviewInfoTile
                      activeWizard={2}
                      wizardTotalSteps={stepsParent[2].totalSteps()}
                      openNewWizard={openNewWizard}
                      title="Percent of common stock sold"
                      data={(
                        percentageSecondaryCommonStockSold.length === 0 ?
                          '' :
                          `${percentageSecondaryCommonStockSold}%`
                      )}
                      currentStep={5}
                      customContainerStyles={{ width: '50%' }}
                      showErrors={showErrors}
                    />
                  </div>
                )
              }

            </div>
          )}
        />
        <ReviewDropdown
          stepsParent={stepsParent}
          openNewWizard={openNewWizard}
          activeWiz={3}
          headline={stepsParent[3].label}
          isReadOnly={show409AReview}
          content={(
            <div className="review-block">
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={stepsParent[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="Who's your Cap table provider?"
                data={capTableProvider}
                currentStep={1}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={stepsParent[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Cap Table provider"
                data={(capTableFilesUploaded.length === 0) ? '' : capTableFilesUploaded}
                // currentStep={2}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={stepsParent[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Option Ledger (as applicable)"
                data={(optionLedgerFilesUploaded.length === 0) ? 'N/A' : optionLedgerFilesUploaded}
                currentStep={2}
                showErrors={false}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={stepsParent[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Financials"
                data={(financialsFilesUploaded.length === 0) ? '' : financialsFilesUploaded}
                currentStep={2}
                showErrors={showErrors}
              />
              <ReviewInfoTile
                activeWizard={3}
                wizardTotalSteps={stepsParent[3].totalSteps()}
                openNewWizard={openNewWizard}
                title="File upload: Articles of incorporation"
                data={arrayToStringOfNoLengthIfLength0(articlesOfIncorporationUploaded)}
                // currentStep={3}
                showErrors={showErrors}
              />
              {
                anySecondaryCommonStockTransactions && (
                  <ReviewInfoTile
                    activeWizard={3}
                    wizardTotalSteps={stepsParent[3].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="File upload: Secondary transaction documents"
                    data={arrayToStringOfNoLengthIfLength0(secondaryTransactionDocs)}
                    currentStep={4} // TODO ¯\_(ツ)_/¯ ???
                    showErrors={showErrors}
                  />
                )
              }
              {
                safeOrConvertibleForCurrentOrPreviousRounds && (
                  <ReviewInfoTile
                    activeWizard={3}
                    wizardTotalSteps={stepsParent[3].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="File upload: SAFE or convertible note agreement documents"
                    data={arrayToStringOfNoLengthIfLength0(safeOrConvertibleNoteAgreement)}
                    currentStep={5} // TODO ¯\_(ツ)_/¯ ???
                    showErrors={showErrors}
                  />
                )
              }
              {
                receivedFutureTerms && (
                  <ReviewInfoTile
                    activeWizard={3}
                    wizardTotalSteps={stepsParent[3].totalSteps()}
                    openNewWizard={openNewWizard}
                    title="File upload: Future terms sheets documents"
                    data={arrayToStringOfNoLengthIfLength0(termsSheets)}
                    currentStep={6} // TODO ¯\_(ツ)_/¯ ???
                    showErrors={showErrors}
                  />
                )
              }
            </div>
          )}
        />
      </div>
    );
  }

  if (currentStep === 2) {
    return (
      <div className="ReviewAndCheckout">
        <h3>Payment</h3>
        <p>
          After your payment is approved, our seasoned 409A valuation experts
          will begin working on your 409A valuation.
          <br />
          Our average turn-around time for valuations is between 5 and
          7 business days.
        </p>
        <hr />

        <div className="checkout-cols-container">
          <div className="checkout-col">
            {
              [
                {
                  title: 'Credit card number',
                  val: creditCardNumber,
                  setter: setCreditCardNumber,
                  id: 'credit-card-number',
                  helperText: '',
                  helperTextErr: false,
                  formatter: (num) => {
                    let justNum = num.trim().replace(/\D/ig, '');
                    if (justNum.length > 16) {
                      justNum = justNum.slice(0, -1);
                    }

                    return justNum.match(/.{1,4}/g).join(' ');
                  },
                },
                {
                  title: 'Name on card',
                  val: nameOnCard,
                  setter: setNameOnCard,
                  id: 'name-on-card',
                  helperText: '',
                  formatter: (name) => name,
                  helperTextErr: false,
                },
                {
                  title: 'Expiration date',
                  val: expirationDate,
                  setter: setExpirationDate,
                  id: 'expiration-date',
                  helperText: 'MM/YY',
                  helperTextErr: expirationDateErr,
                  formatter: (expDate) => {
                    let justNum = expDate.trim().replace(/\D/ig, '');
                    if (justNum === '') return justNum;

                    if (expDate.length > 5) {
                      justNum = justNum.slice(0, -1);
                    }
                    const date = justNum.match(/.{1,2}/g);
                    const [month, year] = date;
                    const tenYrInFuture = (
                      parseInt(new Date().getFullYear().toString().slice(2, 4), 10) + 10
                    );

                    const expirationDateErrVal = (
                      (parseInt(month, 10) > 12) ||
                      (parseInt(year, 10) > tenYrInFuture)
                    );
                    if (expirationDateErrVal !== expirationDateErr) {
                      setExpirationDateErr(expirationDateErrVal);
                    }

                    return date.join('/');
                  },
                },
                {
                  title: 'CVV',
                  val: cvv,
                  setter: setCvv,
                  id: 'cvv',
                  helperText: '3 numbers on back of card, Amex: 4 on front',
                  helperTextErr: false,
                  formatter: (num) => {
                    let justNum = num.trim().replace(/\D/ig, '');
                    if (justNum.length > 4) {
                      justNum = justNum.slice(0, -1);
                    }

                    return justNum;
                  },
                },
              ].map(({
                title, val, setter, id, helperText, formatter, helperTextErr,
              }) => {
                return (
                  <FormControl
                    className="checkout-ctrl"
                    sx={{
                      m: 1,
                      marginBottom: `${(helperText.length > 0 ? '0' : '8')}px`,
                    }}
                    key={title.replaceAll(' ', '++-++--')}
                  >
                    <InputLabel htmlFor={id}>
                      {title}
                    </InputLabel>
                    <OutlinedInput
                      id={id}
                      value={val}
                      onChange={(e) => {
                        e.preventDefault();
                        setter(formatter(e.target.value));
                      }}
                      label={title}
                    />
                    {
                      (helperText.length > 0) && (
                        <FormHelperText error={helperTextErr}>
                          {helperText}
                        </FormHelperText>
                      )
                    }

                  </FormControl>
                );
              })
            }
          </div>
          <div className="checkout-col">
            <div className="order-summary">
              <h6>Order summary</h6>
              <hr />
              <p>Price includes our proprietary 409A sandbox:</p>
              <ul>
                {
                  [
                    'customized for your company',
                    'designed to help you better understand what factors have the most impact on your company\'s share price and audit risk',
                    'you can finalize your 409A when ready',
                  ].map((reason) => (
                    <li key={reason.replaceAll(' ', '-+-+')}>{reason}</li>
                  ))
                }
              </ul>
              <hr />
              <span style={{ display: 'block' }}>Total</span>
              <span style={{ display: 'block', fontSize: '22px' }}>
                <AttachMoneyIcon
                  sx={{ display: 'inline-block', marginTop: '-5px', marginRight: '-5px' }}
                />
                2,500
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="ReviewAndCheckout">
      <h1>ReviewAndCheckout - error</h1>
    </div>
  );
}

ReviewAndCheckout.propTypes = {
  submissionInfoSucceeded: PropTypes.func.isRequired,
  areAll4DataWizardsComplete: PropTypes.bool.isRequired,
  wiz2Data: PropTypes.object.isRequired,
  EBITDAChecked: PropTypes.bool,
  openNewWizard: PropTypes.func.isRequired,
  stepsParent: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentStep: PropTypes.number.isRequired,
  whenIsExitLikelyToHappen: PropTypes.string.isRequired,
  whatTypeOfExit: PropTypes.string.isRequired,
  industryVertical: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  // Wiz3
  chosenFunding: PropTypes.string.isRequired,
  currentFundingDate: PropTypes.string.isRequired,
  totalAmountRaised: PropTypes.string.isRequired,
  totalSafeOrConvertible: PropTypes.string.isRequired,
  interestRate: PropTypes.string,
  valuationCap: PropTypes.string,
  preOrPostMoneyNotes: PropTypes.string,
  receivedFutureTerms: PropTypes.bool,
  anySecondaryCommonStockTransactions: PropTypes.bool,
  percentageSecondaryCommonStockSold: PropTypes.string.isRequired,
  secondaryCommonStockTransactionTotalAmount: PropTypes.string.isRequired,
  // Wiz4
  capTableProvider: PropTypes.string.isRequired,
  capTableFilesUploaded: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionLedgerFilesUploaded: PropTypes.arrayOf(PropTypes.object).isRequired,
  financialsFilesUploaded: PropTypes.arrayOf(PropTypes.object).isRequired,
  articlesOfIncorporationUploaded: PropTypes.arrayOf(PropTypes.object).isRequired,
  secondaryTransactionDocs: PropTypes.arrayOf(PropTypes.object).isRequired,
  safeOrConvertibleForCurrentOrPreviousRounds: PropTypes.bool,
  safeOrConvertibleNoteAgreement: PropTypes.arrayOf(PropTypes.object).isRequired,
  termsSheets: PropTypes.arrayOf(PropTypes.object).isRequired,
  showErrors: PropTypes.bool.isRequired,
  setShowErrors: PropTypes.func.isRequired,
  // Read only review
  show409AReview: PropTypes.bool,
  showDataLoadingOverlay: PropTypes.bool,
};
