import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js';
import { Auth } from 'aws-amplify';

import { FormControl, FormHelperText, TextField } from '@mui/material';

import { createAuthHeaders } from '../utils';

import { ReactComponent as RedExclamationPointCircle } from '../images/icons/exclamation_point_red.svg';
import { ReactComponent as GreenCheckmark } from '../images/icons/green_checkmark.svg';

import { ErrorMessageContext } from '../lib/contextLib';

import './CheckoutForm.scss';

const inputStyle = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: '16px',
  color: '#49454F',
};

export default function CheckoutForm({
  makePaymentButtonRef,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  clientSecret,
  setDisplayAmount,
}) {
  const stripe = useStripe();
  const elements = useElements();

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [cardHolderName, setCardHolderName] = useState('');

  const [message, setMessage] = useState(null);
  const [codeMessage, setCodeMessage] = useState(null);
  const [validCode, setValidCode] = useState(null);
  const [codeError, setCodeError] = useState(null);
  const [discountCode, setDiscountCode] = useState('');

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setPaymentIsProcessing(true);

    try {
      // card number element as the card element
      const cardNumberElement = elements?.getElement(CardNumberElement);
      const currentUserInfo = await Auth.currentAuthenticatedUser({ bypassCache: true });

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        elements._commonOptions.clientSecret.clientSecret,
        {
          payment_method: {
            type: 'card',
            card: cardNumberElement,
            billing_details: {
              name: cardHolderName,
            },
          },
          receipt_email: currentUserInfo?.attributes?.email?.trim(),
        },
      );

      if (!error && paymentIntent.status === 'succeeded') {
        setPaymentIsCompleted(true);
        setPaymentIsProcessing(false);
        return;
      }

      if (error?.type === 'card_error' || error?.type === 'validation_error') {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred.');
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setPaymentIsProcessing(false);
    }
  };

  function setDiscountCodeOnChange(value) {
    setDiscountCode(value);
  }

  useEffect(() => {
    const timeId = setTimeout(async () => {
      const discountCodeData = {
        client_secret: clientSecret,
        discount_code: discountCode,
      };
      const headers = await createAuthHeaders('post', discountCodeData, true);
      const codeCheckResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/stripe/discount-check`,
        headers,
      );
      const { Message, Body } = await codeCheckResponse.json();
      setDisplayAmount(Body);
      if (discountCodeData.discount_code !== '') {
        if (Message !== 'Referral Source Code Applied') {
          setCodeMessage(Message);
          setCodeError(Message);
          setValidCode(null);
        } else {
          setCodeMessage(null);
          setCodeError(null);
          setValidCode(Message);
        }
      }
    }, 1250);
    if (!discountCode.length) {
      setCodeError(null);
      setCodeMessage(false);
      setValidCode(null);
    }
    return () => clearTimeout(timeId);
  }, [discountCode]);

  return (
    <form id="payment-form">
      <CardNumberElement
        className="card-number"
        options={{
          style: {
            base: inputStyle,
          },
          placeholder: 'Credit card number',
        }}
      />
      <TextField
        type="text"
        className="name-input"
        placeholder="Name on card"
        onChange={(e) => setCardHolderName(e.target.value)}
      />
      <div>
        <CardExpiryElement
          className="exp-date"
          options={{
            style: {
              base: inputStyle,
            },
            placeholder: 'Expiration date',
          }}
        />
        <span className="helper-text">MM/YY</span>
      </div>
      <div>
        <CardCvcElement
          className="cvv"
          options={{
            style: {
              base: inputStyle,
            },
            placeholder: 'CVV',
          }}
        />
        <span className="helper-text">3 numbers on back of card, Amex: 4 on front</span>
      </div>
      <FormControl>
        <TextField
          className={`discountInput ${validCode ? 'codeValid' : ''}`}
          type="text"
          placeholder="Referral source code (optional)"
          value={discountCode}
          onChange={(e) => {
            setDiscountCodeOnChange(e.target.value);
          }}
        />
        <FormHelperText className="codeError">
          {codeError && (
            <>
              <RedExclamationPointCircle className="red-exclamation-point-circle" />
              <span className="title-required">
                {codeMessage}
              </span>
            </>
          )}
          {validCode && (
            <>
              <GreenCheckmark className="red-exclamation-point-circle" />
              <span className="title-required" style={{ color: 'green' }}>
                {validCode}
              </span>
            </>
          )}
        </FormHelperText>
      </FormControl>
      <button
        type="button"
        onClick={() => handleSubmit()}
        aria-label="hidden-pay-button"
        ref={makePaymentButtonRef}
        style={{ display: 'none' }}
      />
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}

CheckoutForm.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  clientSecret: PropTypes.string,
  setDisplayAmount: PropTypes.func,
};
