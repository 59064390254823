import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
// Icons
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DoneIcon from '@mui/icons-material/Done';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
// Inside Project
import { ReactComponent as SuccessCheckmark } from '../../images/success_checkmark.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';
import rocketShip from '../../images/RocketShipGraphic.svg';
import ProgressIndicatorLine from '../../components/ProgressIndicatorLine';
import {
  AuthContext,
  HomepageStateContext,
  IsSigningOutContext,
  ReloadHomepageContext,
  ErrorMessageContext,
} from '../../lib/contextLib';
import {
  copy,
  createAuthHeaders,
  grabCompanyIdAndName,
  getUserId,
  getCompanyIdFromBackend,
  getTransactionIdFromBackend,
  getDBTransactionData,
  getClientStatus,
  getUserInfoFromLocalStorage,
  sendHtmlEmail,
} from '../../utils';
import Wiz1 from './Wiz1';
import Wiz2 from './Wiz2';
import Wiz3 from './Wiz3';
import Wiz4 from './Wiz4';
import ReviewAndCheckout from './ReviewAndCheckout';
import {
  SAFE,
  convertibleNote,
  noFunding,
} from '../../utils/globals';
import './index.scss';

export default function InfoForm409A() {
  const valuationFormRef = useRef(null);

  const { setIsAuthenticated } = useContext(AuthContext);
  const { globalHomepageState, setGlobalHomepageState } = useContext(HomepageStateContext);
  const { setIsSigningOut } = useContext(IsSigningOutContext);
  const { setReloadHomepage } = useContext(ReloadHomepageContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [retrievedCompanyId, setRetrievedCompanyId] = useState('');
  const [retrievedCompanyName, setRetrievedCompanyName] = useState('');
  const [stepLabelStyles, setStepLabelStyles] = useState({ display: 'inline-block' });
  const [activeWizard, setActiveWizard] = useState(0);
  const [inWiz, setInWiz] = useState(false);
  const [hasVisitedReviewAndCheckoutWiz4, setHasVisitedReviewAndCheckoutWiz4] = useState(false);
  const [showDataLoadingOverlay, setShowDataLoadingOverlay] = useState(false);
  const [hasStarted, setHasStarted] = useState(false);
  const [checkForLastCompletedStep, setCheckForLastCompletedStep] = useState(false);
  const [EBITDAChecked, setEBITDAChecked] = useState(false);

  // Wiz 1
  const [whenIsExitLikelyToHappen, setWhenIsExitLikelyToHappen] = useState('');
  const [whatTypeOfExit, setWhatTypeOfExit] = useState('');
  const [industryVertical, setIndustryVertical] = useState(null);
  // Wiz 2
  const [wiz2Data, setWiz2Data] = useState({
    ntmOptionsPerc: 50,
    totalCurrentCash: '',
    currentMonthlyCashBurn: '',
    totalNonConvertibleDebt: '',
    totalConvertibleDebt: '',
    totalCurrentFiscalYearRevenue: '',
    totalNextFiscalYearRevenue: '',
    totalCurrentFiscalYearEBITDA: '',
    totalNextFiscalYearEBITDA: '',
  });

  // Wiz 3
  // >>> Step #1
  const [chosenFunding, setChosenFunding] = useState('');
  // >>> Step #2
  const [currentFundingDate, setCurrentFundingDate] = useState(moment());
  const [previousFundingRoundDate, setPreviousFundingRoundDate] = useState(moment());
  const [totalAmountRaised, setTotalAmountRaised] = useState('');
  const [totalSafeOrConvertible, setTotalSafeOrConvertible] = useState('');
  // -- spacer --
  const [interestRate, setInterestRate] = useState('');
  const [valuationCap, setValuationCap] = useState('');
  const [hasRaisedPreviousRound, setHasRaisedPreviousRound] = useState(null);
  const [preOrPostMoneyNotes, setPreOrPostMoneyNotes] = useState(null);
  // >>> Step #3
  const [isThisCompanysFirstRound, setIsThisCompanysFirstRound] = useState(null);
  const [previousFundingRoundType, setPreviousFundingRoundType] = useState('');
  // >>> Step #4
  const [receivedFutureTerms, setReceivedFutureTerms] = useState(null);
  // >>> Step #5
  const [
    anySecondaryCommonStockTransactions,
    setAnySecondaryCommonStockTransactions,
  ] = useState(null);
  const [
    secondaryCommonStockTransactionTotalAmount,
    setSecondaryCommonStockTransactionTotalAmount,
  ] = useState('');
  const [
    percentageSecondaryCommonStockSold,
    setPercentageSecondaryCommonStockSold,
  ] = useState('');
  // Wiz 4
  const [capTableProvider, setCapTableProvider] = useState('');
  const [capTableFilesUploaded, setCapTableFilesUploaded] = useState([]);
  const [optionLedgerFilesUploaded, setOptionLedgerFilesUploaded] = useState([]);
  const [financialFilesUploaded, setFinancialFilesUploaded] = useState([]);
  const [
    articlesOfIncorporationUploaded,
    setArticlesOfIncorporationUploaded] = useState([]);
  const [
    secondaryTransactionDocs,
    setSecondaryTransactionDocs] = useState([]);
  const [termsSheets, setTermsSheets] = useState([]);
  const [safeOrConvertibleNoteAgreement, setSafeOrConvertibleNoteAgreement] = useState([]);

  // ReviewAndCheckout
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  // TransactionId needed to post to Dynamo
  const [transactionId, setTransactionId] = useState('');

  const [show409AReview, setShow409AReview] = useState(false);

  // Wiz controller
  const [stepsVisited, setStepsVisited] = useState([]);
  const [wizSteps, setWizSteps] = useState({
    currentStep: 1,
    totalSteps: 3,
  });
  const nav = useNavigate();
  const safeOrConvertibleForCurrentOrPreviousRounds = (
    (previousFundingRoundType === SAFE) ||
    (previousFundingRoundType === convertibleNote) ||
    (chosenFunding === SAFE) ||
    (chosenFunding === convertibleNote)
  );
  const steps = [
    {
      label: 'Industry & expected exit',
      totalSteps: () => 3,
      isComplete: () => {
        return (
          whenIsExitLikelyToHappen.length > 0 &&
          whatTypeOfExit.length > 0 &&
          (
            (typeof industryVertical === 'object') &&
            (industryVertical !== null)
          )
        );
      },
    },
    {
      label: 'Financials & option pool',
      totalSteps: () => 3,
      isComplete: () => {
        const {
          totalCurrentCash,
          currentMonthlyCashBurn,
          totalNonConvertibleDebt,
          totalConvertibleDebt,
          totalCurrentFiscalYearRevenue,
          totalNextFiscalYearRevenue,
          totalCurrentFiscalYearEBITDA,
          totalNextFiscalYearEBITDA,
        } = wiz2Data;

        let returnEBITDAComplete;
        if (EBITDAChecked) {
          returnEBITDAComplete = (
            (totalCurrentCash.trim().length > 0) &&
            (currentMonthlyCashBurn.trim().length > 0) &&
            (totalNonConvertibleDebt.trim().length > 0) &&
            (totalConvertibleDebt.trim().length > 0) &&
            (totalCurrentFiscalYearRevenue ? totalCurrentFiscalYearRevenue.trim().length > 0 : false) &&
            (totalNextFiscalYearRevenue ? totalNextFiscalYearRevenue.trim().length > 0 : false) &&
            (totalCurrentFiscalYearEBITDA ? totalCurrentFiscalYearEBITDA.trim().length > 0 : false) &&
            (totalNextFiscalYearEBITDA ? totalNextFiscalYearEBITDA.trim().length > 0 : false)
          );
        } else {
          returnEBITDAComplete = (
            (totalCurrentCash.trim().length > 0) &&
            (currentMonthlyCashBurn.trim().length > 0) &&
            (totalNonConvertibleDebt.trim().length > 0) &&
            (totalConvertibleDebt.trim().length > 0)
          );
        }

        return returnEBITDAComplete;
      },
    },
    {
      label: 'Funding & secondary transactions',
      totalSteps: () => 5,
      isComplete: () => {
        // ************************************************
        // >>> Step 1
        if (totalAmountRaised.trim().length === 0) {
          return false;
        }

        // ************************************************
        // >>> Step 2
        if (chosenFunding.trim().length === 0) {
          return false;
        }

        // ************************************************
        // >>> Step 3
        if ((chosenFunding === SAFE) || (chosenFunding === convertibleNote)) {
          if (
            (!currentFundingDate._isAMomentObject) ||
            (totalSafeOrConvertible.trim().length === 0) ||
            (interestRate.trim().length === 0) ||
            (valuationCap.trim().length === 0) ||
            (preOrPostMoneyNotes === null)
          ) {
            return false;
          }
        }

        if (
          (chosenFunding.trim().length > 0) &&
          (chosenFunding !== noFunding)
        ) {
          if (!currentFundingDate._isAMomentObject) {
            return false;
          }
        }

        // ************************************************
        // >>> Step 4
        if (receivedFutureTerms === null) {
          return false;
        }

        // ************************************************
        // >>> Step 5
        if (anySecondaryCommonStockTransactions === null) {
          return false;
        }

        if (anySecondaryCommonStockTransactions) {
          if (
            (secondaryCommonStockTransactionTotalAmount.trim().length === 0) ||
            (percentageSecondaryCommonStockSold.trim().length === 0)
          ) {
            return false;
          }
        }

        return true;
      },
    },
    {
      label: 'Capitalization table & upload',
      totalSteps: () => {
        let defaultSteps = 5;

        if (anySecondaryCommonStockTransactions) {
          defaultSteps += 1;
        }

        if (safeOrConvertibleForCurrentOrPreviousRounds) {
          defaultSteps += 1;
        }

        if (receivedFutureTerms) {
          defaultSteps += 1;
        }

        return defaultSteps;
      },
      isComplete: () => {
        if (capTableProvider.length === 0) {
          return false;
        }

        if (capTableFilesUploaded.length === 0) {
          return false;
        }

        if (financialFilesUploaded.length === 0) {
          return false;
        }

        if (articlesOfIncorporationUploaded.length === 0) {
          return false;
        }

        if (anySecondaryCommonStockTransactions && (
          secondaryTransactionDocs.length === 0
        )) {
          return false;
        }

        if (safeOrConvertibleForCurrentOrPreviousRounds && (
          safeOrConvertibleNoteAgreement.length === 0
        )) {
          return false;
        }

        if (receivedFutureTerms && (
          termsSheets.length === 0
        )) {
          return false;
        }

        return true;
      },
    },
    {
      label: 'Review',
      totalSteps: () => 1, // TODO set back to 2 to show payments page
      isComplete: () => paymentCompleted,
    },
  ];

  function determineIcon(isWizardComplete, index) {
    const isCurrentWizComplete = isWizardComplete();

    let status = 'not-started';
    if (isCurrentWizComplete) status = 'completed';
    else if (activeWizard === index) status = 'is-next';
    else if (stepsVisited.includes(index)) status = 'has-started';

    return {
      icon: isCurrentWizComplete && <DoneIcon />,
      status,
    };
  }

  function reDoStepLabelOffset() {
    const { offsetLeft = 10 } = valuationFormRef.current;
    if (offsetLeft > 20) {
      const newStepLabelStyles = {
        paddingLeft: `${offsetLeft - 155}px`,
        display: 'inline-block',
      };
      setStepLabelStyles(newStepLabelStyles);
    }
  }

  async function retrieveAndSetLocalCompanyIdAndName() {
    const { companyId, companyName } = await grabCompanyIdAndName();
    setRetrievedCompanyId(companyId);
    setRetrievedCompanyName(companyName);
    return { companyId, companyName };
  }

  async function setAndOrGetLocalStorageIDValue(value, storageKey, DBFetchFunction, IDarg) {
    if (!value) {
      const localStorageValue = getUserInfoFromLocalStorage(storageKey);
      if (!localStorageValue) {
        const fetchedId = await DBFetchFunction(IDarg || null);
        return fetchedId;
      }
      return localStorageValue;
    }
    return value;
  }

  useEffect(() => {
    // For managing URL params
    /* ********************************************************************* */
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const paramCurrentStep = urlParamsOnPgLoad.get('currentStep');
    const paramTotalSteps = urlParamsOnPgLoad.get('totalSteps');
    const paramActiveWizard = urlParamsOnPgLoad.get('activeWizard');

    // Checking if 409A is complete
    const completed409AStates = ['409A-Completed', 'Sandbox-Open', 'Sandbox-Finalized', 'HasReport'];
    const completed409A = completed409AStates.includes(globalHomepageState);
    if (completed409A) {
      setShow409AReview(true);
      setActiveWizard(4);
      setInWiz(true);
    }

    // Fetching Data from DB
    setShowDataLoadingOverlay(true);
    async function getTransactionData() {
      // Check for existing or set Company ID
      let currentCompanyId = getUserInfoFromLocalStorage('companyId');
      if (!currentCompanyId) currentCompanyId = await getCompanyIdFromBackend();

      // Check for existing or set Transaction ID
      const currentTransactionId =
        await setAndOrGetLocalStorageIDValue(transactionId, 'transactionId', getTransactionIdFromBackend, currentCompanyId);
      setTransactionId(currentTransactionId);

      // Checking if 409A is already completed
      const userId = await getUserId();
      let homepageState = getUserInfoFromLocalStorage('homepageState');
      if (!homepageState) homepageState = await getClientStatus(currentCompanyId);

      const DBData = await getDBTransactionData(currentCompanyId, currentTransactionId, userId);

      function updateAllTransactionData() {
        function isTrueOrFalseOrNull(value) {
          if (value === 1) return true;
          if (value === 0) return false;
          return null;
        }
        if ((DBData !== null) && (typeof DBData === 'object')) {
          const alreadyStarted = Object.keys(DBData).some((DataKey) => DBData[DataKey] !== null);
          setHasStarted(alreadyStarted);
          // Wiz 1
          setWhenIsExitLikelyToHappen(DBData.whenIsExitLikelyToHappen);
          setWhatTypeOfExit(DBData.whatTypeOfExit);
          setIndustryVertical(DBData.industryVertical ? {
            ...industryVertical,
            title: DBData.industryVertical,
            category: DBData.verticalCategory,
            subcategory: DBData.verticalSubcategory,
          } : null);
          // Wiz 2
          // if pulled from DB to resume transaction and values exist in EBITDA section then check EBITDA checkbox
          if (
            DBData.totalCurrentFiscalYearRevenue ||
            DBData.totalNextFiscalYearRevenue ||
            DBData.totalCurrentFiscalYearEBITDA ||
            DBData.totalNextFiscalYearEBITDA
          ) setEBITDAChecked(true);
          setWiz2Data({
            ...wiz2Data,
            ntmOptionsPerc: parseInt(DBData.ntmOptionsPerc, 10),
            totalCurrentCash: DBData.totalCurrentCash,
            currentMonthlyCashBurn: DBData.currentMonthlyCashBurn,
            totalNonConvertibleDebt: DBData.totalNonConvertibleDebt,
            totalConvertibleDebt: DBData.totalConvertibleDebt,
            totalCurrentFiscalYearRevenue: DBData.totalCurrentFiscalYearRevenue,
            totalNextFiscalYearRevenue: DBData.totalNextFiscalYearRevenue,
            totalCurrentFiscalYearEBITDA: DBData.totalCurrentFiscalYearEBITDA,
            totalNextFiscalYearEBITDA: DBData.totalNextFiscalYearEBITDA,
          });
          // Wiz 3
          // >>> Step #1
          setChosenFunding(DBData.chosenFunding);
          // >>> Step #2
          setCurrentFundingDate(moment(DBData.currentFundingDate));
          setTotalSafeOrConvertible(DBData.totalSafeOrConvertible);
          setPreviousFundingRoundDate(moment(DBData.previousFundingRoundDate));
          setTotalAmountRaised(DBData.totalAmountRaised);
          // -- spacer --
          setInterestRate(DBData.interestRate);
          setValuationCap(DBData.valuationCap);
          setHasRaisedPreviousRound(isTrueOrFalseOrNull(DBData.hasRaisedPreviousRound));
          setPreOrPostMoneyNotes(DBData.preOrPostMoneyNotes);
          // >>> Step #3
          setIsThisCompanysFirstRound(isTrueOrFalseOrNull(DBData.isThisCompanysFirstRound));
          setPreviousFundingRoundType(DBData.previousFundingRoundType);
          // >>> Step #4
          setReceivedFutureTerms(isTrueOrFalseOrNull(DBData.receivedFutureTerms));
          // >>> Step #5
          setAnySecondaryCommonStockTransactions(
            isTrueOrFalseOrNull(DBData.anySecondaryCommonStockTransactions),
          );
          setSecondaryCommonStockTransactionTotalAmount(
            DBData.secondaryCommonStockTransactionTotalAmount,
          );
          setPercentageSecondaryCommonStockSold(DBData.percentageSecondaryCommonStockSold);
          // Wiz 4
          setCapTableProvider(DBData.capTableProvider);
          setCapTableFilesUploaded(DBData.documents.cap_table_files);
          setOptionLedgerFilesUploaded(DBData.documents.option_ledger_files || []);
          setFinancialFilesUploaded(DBData.documents.financial_files || []);
          setArticlesOfIncorporationUploaded(DBData.documents.articles_of_incorporation);
          setSecondaryTransactionDocs(DBData.documents.secondary_transaction);
          setTermsSheets(DBData.documents.term_sheets);
          setSafeOrConvertibleNoteAgreement(DBData.documents.safe_or_convertible_note_agreement);
        }
        if (paramCurrentStep && paramTotalSteps && paramActiveWizard) {
          setWizSteps({
            currentStep: parseInt(paramCurrentStep, 10),
            totalSteps: parseInt(paramTotalSteps, 10),
          });
          setActiveWizard(parseInt(paramActiveWizard, 10));
          setInWiz(true);
        } else {
          setCheckForLastCompletedStep(true);
        }
        setShowDataLoadingOverlay(false);
      }
      updateAllTransactionData();
    }

    getTransactionData();

    // For getting the offset of the Step Top label correct
    reDoStepLabelOffset();

    window.addEventListener('resize', reDoStepLabelOffset);

    return function cleanupListener() {
      window.removeEventListener('resize', reDoStepLabelOffset);
    };
  }, []);

  useEffect(() => {
    if (checkForLastCompletedStep && !show409AReview) {
      setActiveWizard(steps.findIndex(({ isComplete }) => !isComplete()));
    }
    setCheckForLastCompletedStep(false);
  }, [checkForLastCompletedStep]);


  useEffect(() => {
    if (inWiz && !show409AReview) {
      const { currentStep, totalSteps } = wizSteps;
      nav(`?currentStep=${currentStep}&totalSteps=${totalSteps}&activeWizard=${activeWizard}`);
    }
  }, [wizSteps, inWiz, activeWizard]);

  function openNewWizard(
    activeWizIndex,
    numTotalSteps,
    startOnThisStep = 1,
    isInTheWiz = true,
  ) {
    setInWiz(isInTheWiz);
    setActiveWizard(activeWizIndex);
    wizSteps.totalSteps = numTotalSteps;
    wizSteps.currentStep = startOnThisStep;
    setWizSteps(copy(wizSteps));
  }

  function isInReviewAndCheckoutFirstStepFinalWizard() {
    return (
      (activeWizard === (steps.length - 1)) &&
      (wizSteps.currentStep === 1) // currentStep uses normal human indexing, starting @ 1
    );
  }

  function isInReviewAndCheckoutSecondStepFinalWizard() {
    return (
      (activeWizard === (steps.length - 1)) &&
      (wizSteps.currentStep === 2) // currentStep uses normal human indexing, starting @ 1
    );
  }

  function all4DataWizardsComplete() {
    return steps.every((step, i) => {
      if (i === steps.length - 1) {
        return true;
      }
      return step.isComplete();
    });
  }

  async function updateTransactionData(isComplete = false) {
    const currentSessionUser = await Auth.currentSession();
    const userId = currentSessionUser.accessToken.payload.sub;
    const isAuthorized = currentSessionUser.getIdToken().payload.email_verified;
    function isOneOrZeroOrNull(value) {
      if (value === true) return 1;
      if (value === false) return 0;
      return null;
    }
    let companyName;
    let companyId;
    try {
      if (!retrievedCompanyName || !retrievedCompanyId) {
        ({ companyName, companyId } = await retrieveAndSetLocalCompanyIdAndName());
      } else {
        companyName = retrievedCompanyName;
        companyId = retrievedCompanyId;
      }
      const fourZeroNineAInputData = {
        companyId,
        companyName,
        insertUserId: userId,
        progress: isComplete ? 'completed' : 'In Progress',
        transactionDate: isComplete ? moment().format('YYYY-MM-DD') : null,
        industryVertical: industryVertical ? industryVertical.title : '',
        verticalSubcategory: industryVertical ? industryVertical.subcategory : '',
        verticalCategory: industryVertical ? industryVertical.category : '',
        currentMonthlyCashBurn: wiz2Data.currentMonthlyCashBurn,
        totalConvertibleDebt: wiz2Data.totalConvertibleDebt,
        totalCurrentCash: wiz2Data.totalCurrentCash,
        totalNonConvertibleDebt: wiz2Data.totalNonConvertibleDebt,
        totalCurrentFiscalYearRevenue: wiz2Data.totalCurrentFiscalYearRevenue,
        totalNextFiscalYearRevenue: wiz2Data.totalNextFiscalYearRevenue,
        totalCurrentFiscalYearEBITDA: wiz2Data.totalCurrentFiscalYearEBITDA,
        totalNextFiscalYearEBITDA: wiz2Data.totalNextFiscalYearEBITDA,
        documents: {
          articles_of_incorporation: articlesOfIncorporationUploaded,
          cap_table_files: capTableFilesUploaded,
          option_ledger_files: optionLedgerFilesUploaded,
          financial_files: financialFilesUploaded,
          safe_or_convertible_note_agreement: safeOrConvertibleNoteAgreement,
          secondary_transaction: secondaryTransactionDocs,
          term_sheets: termsSheets,
        },
        whenIsExitLikelyToHappen,
        whatTypeOfExit,
        previousFundingRoundDate: previousFundingRoundDate.format('YYYY-MM-DD'),
        currentFundingDate: currentFundingDate.format('YYYY-MM-DD'),
        ntmOptionsPerc: wiz2Data.ntmOptionsPerc,
        capTableProvider,
        secondaryCommonStockTransactionTotalAmount,
        chosenFunding,
        totalAmountRaised,
        totalSafeOrConvertible,
        interestRate,
        valuationCap,
        hasRaisedPreviousRound: isOneOrZeroOrNull(hasRaisedPreviousRound),
        preOrPostMoneyNotes,
        isThisCompanysFirstRound: isOneOrZeroOrNull(isThisCompanysFirstRound),
        previousFundingRoundType,
        receivedFutureTerms: isOneOrZeroOrNull(receivedFutureTerms),
        anySecondaryCommonStockTransactions:
          isOneOrZeroOrNull(anySecondaryCommonStockTransactions),
        percentageSecondaryCommonStockSold,
        transactionId,
      };
      const fourZeroNineAReqOptions = await createAuthHeaders('post', fourZeroNineAInputData, isAuthorized);
      let transactionResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions`,
        fourZeroNineAReqOptions,
      );
      transactionResponse = await transactionResponse.json();
      setTransactionId(transactionResponse.Body.transactionId);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      if (isComplete) {
        try {
          const accountDetails = await fetch(
            // eslint-disable-next-line max-len
            `${process.env.REACT_APP_BACKEND_URL}/accounts/${companyId}&${userId}`,
            await createAuthHeaders('get', {}, true),
          );
          const { Body } = await accountDetails.json();
          const { email, firstName } = Body;
          await sendHtmlEmail(
            email,
            'free409AReceived',
            [firstName, companyName],
          );
        } catch (e) {
          setShowErrorMessage(e.toString());
        }
      } else {
        setGlobalHomepageState('409A-InProgress');
      }
    }
  }

  function submissionInfoSucceeded() {
    // intentionally omitting await for improved UX
    setGlobalHomepageState('409A-Completed');
    updateTransactionData(true); // isComplete flag set to true
    setPaymentCompleted(true);
    setInWiz(false);
    wizSteps.currentStep = 1;
    setWizSteps(copy(wizSteps));
    nav('/409a-info');
  }

  function home() {
    return (
      <>
        {
          showDataLoadingOverlay && (
            <div className="loading-wrapper">
              <LoadingSpinner className="custom-loading-spinner" />
            </div>
          )
        }
        {!steps[0].isComplete() ?
          <h1>Start a new 409A valuation</h1> :
          <h1>Continue your 409A valuation</h1>}
        <img src={rocketShip} className="rocketShip" alt="RocketShipGraphic" />
        {!steps[0].isComplete() ? (
          <p>
            It&apos;s fast! It typically takes less than half an hour to provide us
            with all the information and documentation we need.
            If you stop in the middle, don&apos;t worry, your progress will be saved as you go.
            Once our work begins you&apos;ll receive your 409A in days not weeks.
          </p>
        ) : (
          <p>
            If you stop in the middle, don&apos;t worry,
            your progress will be saved as you go.
            Once our work begins you&apos;ll receive your 409A in days not weeks.
          </p>
        )}

        <Box sx={{ maxWidth: 400 }}>
          <Stepper
            activeStep={activeWizard}
            orientation="vertical"
          >
            {
              steps.map(({ label, totalSteps, isComplete }, i) => {
                const { icon, status } = determineIcon(isComplete, i);

                return (
                  <Step
                    key={label}
                    disabled={false}
                    className="individual-step"
                    onClick={(e) => {
                      e.preventDefault();

                      if (!hasStarted) setHasStarted(true);

                      openNewWizard(i, totalSteps());
                    }}
                  >
                    <StepLabel
                      className={status}
                      StepIconComponent={() => icon || i + 1}
                    >
                      {label}
                      {/completed/ig.test(status) && <CreateOutlinedIcon />}
                    </StepLabel>
                  </Step>
                );
              })
            }
          </Stepper>
        </Box>
        <Button
          variant="contained"
          className="start-btn"
          sx={{ borderRadius: 8 }}
          onClick={() => {
            if (!hasStarted) setHasStarted(true);
            const { totalSteps } = steps[activeWizard];
            openNewWizard(activeWizard, totalSteps());
          }}
        >
          {
            hasStarted ? 'Next' : 'Start'
          }
        </Button>
      </>
    );
  }
  function wiz() {
    if (activeWizard === 0) {
      return (
        <Wiz1
          currentStep={wizSteps.currentStep}
          setWhenIsExitLikelyToHappenParent={setWhenIsExitLikelyToHappen}
          setWhatTypeOfExitParent={setWhatTypeOfExit}
          industryVerticalParent={industryVertical}
          setIndustryVerticalParent={setIndustryVertical}
          whenIsExitLikelyToHappenParent={whenIsExitLikelyToHappen}
          whatTypeOfExitParent={whatTypeOfExit}
        />
      );
    }
    if (activeWizard === 1) {
      return (
        <Wiz2
          currentStep={wizSteps.currentStep}
          wiz2DataParent={wiz2Data}
          setWiz2DataParent={setWiz2Data}
          setEBITDAChecked={setEBITDAChecked}
          EBITDAChecked={EBITDAChecked}
        />
      );
    }
    if (activeWizard === 2) {
      return (
        <Wiz3
          currentStep={wizSteps.currentStep}
          // Step #1
          setChosenFundingParent={setChosenFunding}
          chosenFundingParent={chosenFunding}
          // Step #2
          currentFundingDateParent={currentFundingDate}
          setCurrentFundingDateParent={setCurrentFundingDate}
          // ------------ spacer ------------
          previousFundingRoundDateParent={previousFundingRoundDate}
          setPreviousFundingRoundDateParent={setPreviousFundingRoundDate}
          // ------------ spacer ------------
          totalAmountRaisedParent={totalAmountRaised}
          setTotalAmountRaisedParent={setTotalAmountRaised}
          // ------------ spacer ------------
          totalSafeOrConvertibleParent={totalSafeOrConvertible}
          setTotalSafeOrConvertibleParent={setTotalSafeOrConvertible}
          // ------------ spacer ------------
          interestRateParent={interestRate}
          setInterestRateParent={setInterestRate}
          // ------------ spacer ------------
          valuationCapParent={valuationCap}
          setValuationCapParent={setValuationCap}
          // ------------ spacer ------------
          hasRaisedPreviousRoundParent={hasRaisedPreviousRound}
          setHasRaisedPreviousRoundParent={setHasRaisedPreviousRound}
          // ------------ spacer ------------
          preOrPostMoneyNotesParent={preOrPostMoneyNotes}
          setPreOrPostMoneyNotesParent={setPreOrPostMoneyNotes}
          // Step #3
          isThisCompanysFirstRoundParent={isThisCompanysFirstRound}
          setIsThisCompanysFirstRoundParent={setIsThisCompanysFirstRound}
          // ------------ spacer ------------
          previousFundingRoundTypeParent={previousFundingRoundType}
          setPreviousFundingRoundTypeParent={setPreviousFundingRoundType}
          // Step #4
          receivedFutureTermsParent={receivedFutureTerms}
          setReceivedFutureTermsParent={setReceivedFutureTerms}
          // Step #5
          anySecondaryCommonStockTransactionsParent={anySecondaryCommonStockTransactions}
          setAnySecondaryCommonStockTransactionsParent={setAnySecondaryCommonStockTransactions}
          // ------------ spacer ------------
          secondaryCommonStockTransactionTotalAmountParent={
            secondaryCommonStockTransactionTotalAmount
          }
          setSecondaryCommonStockTransactionTotalAmountParent={
            setSecondaryCommonStockTransactionTotalAmount
          }
          // ------------ spacer ------------
          percentageSecondaryCommonStockSoldParent={percentageSecondaryCommonStockSold}
          setPercentageSecondaryCommonStockSoldParent={setPercentageSecondaryCommonStockSold}
        />
      );
    }
    if (activeWizard === 3) {
      return (
        <Wiz4
          currentStep={wizSteps.currentStep}
          capTableProviderParent={capTableProvider}
          setCapTableProviderParent={setCapTableProvider}
          capTableFilesUploadedParent={capTableFilesUploaded}
          setCapTableFilesUploadedParent={setCapTableFilesUploaded}
          optionLedgerFilesUploadedParent={optionLedgerFilesUploaded}
          setOptionLedgerFilesUploadedParent={setOptionLedgerFilesUploaded}
          financialFilesUploadedParent={financialFilesUploaded}
          setFinancialFilesUploadedParent={setFinancialFilesUploaded}
          articlesOfIncorporationUploadedParent={articlesOfIncorporationUploaded}
          setArticlesOfIncorporationUploadedParent={setArticlesOfIncorporationUploaded}
          secondaryTransactionDocsParent={secondaryTransactionDocs}
          setSecondaryTransactionDocsParent={setSecondaryTransactionDocs}
          termsSheetsParent={termsSheets}
          setTermsSheetsParent={setTermsSheets}
          safeOrConvertibleNoteAgreementParent={safeOrConvertibleNoteAgreement}
          setSafeOrConvertibleNoteAgreementParent={setSafeOrConvertibleNoteAgreement}
          anySecondaryCommonStockTransactionsParent={anySecondaryCommonStockTransactions}
          safeOrConvertibleNoteParent={!!safeOrConvertibleForCurrentOrPreviousRounds}
          receivedFutureTermsParent={receivedFutureTerms}
        />
      );
    }
    if (activeWizard === 4) {
      if (!hasVisitedReviewAndCheckoutWiz4 && !show409AReview) {
        setHasVisitedReviewAndCheckoutWiz4(true);
      }

      return (
        <ReviewAndCheckout
          submissionInfoSucceeded={submissionInfoSucceeded}
          openNewWizard={openNewWizard}
          stepsParent={steps}
          currentStep={wizSteps.currentStep}
          areAll4DataWizardsComplete={all4DataWizardsComplete()}
          // Wiz1
          whenIsExitLikelyToHappen={whenIsExitLikelyToHappen}
          whatTypeOfExit={whatTypeOfExit}
          industryVertical={industryVertical}
          // Wiz2
          wiz2Data={wiz2Data}
          EBITDAChecked={EBITDAChecked}
          // Wiz3
          chosenFunding={chosenFunding}
          totalAmountRaised={totalAmountRaised}
          currentFundingDate={currentFundingDate.format('MM/DD/YYYY')}
          totalSafeOrConvertible={totalSafeOrConvertible}
          interestRate={interestRate}
          valuationCap={valuationCap}
          preOrPostMoneyNotes={preOrPostMoneyNotes}
          receivedFutureTerms={receivedFutureTerms}
          anySecondaryCommonStockTransactions={anySecondaryCommonStockTransactions}
          percentageSecondaryCommonStockSold={percentageSecondaryCommonStockSold}
          secondaryCommonStockTransactionTotalAmount={secondaryCommonStockTransactionTotalAmount}
          // Wiz4
          capTableProvider={capTableProvider}
          capTableFilesUploaded={capTableFilesUploaded}
          optionLedgerFilesUploaded={optionLedgerFilesUploaded}
          financialsFilesUploaded={financialFilesUploaded}
          articlesOfIncorporationUploaded={articlesOfIncorporationUploaded}
          secondaryTransactionDocs={secondaryTransactionDocs}
          termsSheets={termsSheets}
          safeOrConvertibleNoteAgreement={safeOrConvertibleNoteAgreement}
          showErrors={showErrors}
          setShowErrors={setShowErrors}
          // Read-only review
          show409AReview={show409AReview}
          showDataLoadingOverlay={showDataLoadingOverlay}
        />
      );
    }

    return (
      <div>
        <h1>Test, which should never render</h1>
      </div>
    );
  }

  if (paymentCompleted) {
    return (
      <div className="InfoForm409A success-container">
        <h1>Success!</h1>
        <SuccessCheckmark />
        <div className="success-msg-container">
          <p>
            {`You've successfully submitted (and paid for) a 409A valuation form
            on behalf of ${retrievedCompanyName}. The submitted information will soon be reviewed by
            one of our expert analysts. We'll email you within 7 business days when your
            409A sandbox is ready. The sandbox is where you can, in
            real-time, customize your 409A before a final valuation gets locked-in.`}
          </p>
        </div>
        <Button
          className="payment-success-link"
          onClick={() => {
            nav('/');
            setReloadHomepage(true);
          }}
        >
          Go to homepage
        </Button>
        <Button
          className="payment-success-link"
          onClick={async () => {
            setIsSigningOut(true);
            await Auth.signOut();
            setIsAuthenticated(false);
            nav('/login');
            setIsSigningOut(false);
          }}
        >
          Sign out
        </Button>
      </div>
    );
  }

  return (
    <div className="InfoForm409A">
      <div>
        <div className="bread-crumb" style={{ marginBottom: !show409AReview ? '40px' : '10px' }}>
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={(e) => {
              e.preventDefault();

              if (inWiz && !show409AReview) {
                setInWiz(false);
                nav('/409a-info');
                setCheckForLastCompletedStep(true);
              } else {
                nav('/');
              }
            }}
          >
            <ArrowBackIcon className="back-icon" />
            <span className="bread-crumb-title">
              {inWiz && !show409AReview ? '409A' : 'Home'}
            </span>
          </button>
        </div>
        <span style={stepLabelStyles} className="step-top-label">
          {inWiz && !show409AReview ? steps[activeWizard].label : !show409AReview && 'New 409A valuation'}
        </span>
      </div>

      <div className="valuation-form-container" ref={valuationFormRef}>
        {
          inWiz && !show409AReview && (
            <ProgressIndicatorLine
              stepUserIsCurrentlyOn={wizSteps.currentStep}
              totalSteps={wizSteps.totalSteps}
              customStyles={{ marginBottom: '24px' }}
            />
          )
        }
        <div className="valuation-form" style={!inWiz ? { padding: '40px 140px 40px 140px' } : {}}>
          {inWiz ? wiz() : home()}
        </div>
        {
          inWiz && (
            <div className="bottom-btn-container">
              {
                (
                  hasVisitedReviewAndCheckoutWiz4 &&
                  (activeWizard !== 4)
                ) && (
                  <Button
                    variant="text"
                    className="back-to-review"
                    onClick={(e) => {
                      e.preventDefault();
                      updateTransactionData(); // intentionally omitting await for improved UX
                      openNewWizard(4, steps[4].totalSteps(), 1);
                    }}
                  >
                    Back to review page
                  </Button>
                )
              }
              {!show409AReview && (
                <Button
                  variant="outlined"
                  className="back-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    updateTransactionData(); // intentionally omitting await for improved UX
                    if (wizSteps.currentStep === 1) {
                      setInWiz(false);
                      setCheckForLastCompletedStep(true);
                      nav('/409a-info');
                    } else {
                      wizSteps.currentStep -= 1;
                      setWizSteps(copy(wizSteps));
                    }
                  }}
                >
                  Back
                </Button>
              )}

              <Button
                variant="contained"
                className="next-btn"
                onClick={(e) => {
                  e.preventDefault();
                  if (show409AReview) {
                    nav('/');
                    return;
                  }
                  if (!isInReviewAndCheckoutFirstStepFinalWizard()) {
                    updateTransactionData(); // intentionally omitting await for improved UX
                  }
                  if (
                    isInReviewAndCheckoutFirstStepFinalWizard() &&
                    !all4DataWizardsComplete()
                  ) {
                    setShowErrors(true);
                    return;
                  }

                  if (isInReviewAndCheckoutFirstStepFinalWizard()) {
                    submissionInfoSucceeded();
                    return;
                  }

                  if (wizSteps.currentStep === wizSteps.totalSteps) {
                    let nextActiveWiz = activeWizard + 1;
                    if (!steps[nextActiveWiz]) nextActiveWiz = steps.length - 1;
                    const newStepsVisitedArray = copy(stepsVisited);
                    newStepsVisitedArray.push(activeWizard);
                    setStepsVisited(newStepsVisitedArray);
                    setActiveWizard(nextActiveWiz);
                    setInWiz(false);
                    wizSteps.currentStep = 1;
                    setWizSteps(copy(wizSteps));
                    nav('/409a-info');
                  } else {
                    wizSteps.currentStep += 1;
                    setWizSteps(copy(wizSteps));
                  }
                }}
              >
                {
                  show409AReview ? 'Back' :
                    isInReviewAndCheckoutFirstStepFinalWizard() ?
                      'Submit information' :
                      (isInReviewAndCheckoutSecondStepFinalWizard() ? 'Make payment' : 'Next')
                }
              </Button>
            </div>
          )
        }
      </div>
    </div>
  );
}
