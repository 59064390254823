export const partnerAssociatedWithFirm = 'partnerAssociatedWithFirm';

export const partnerNotAssociatedWithFirm = 'partnerNotAssociatedWithFirm';

export const passwordRequirements = [
  {
    reg: (pass) => /[A-Z]/g.test(pass),
    text: 'One uppercase character',
  },
  {
    reg: (pass) => /[^A-Za-z0-9]/g.test(pass),
    text: 'One special character',
  },
  {
    reg: (pass) => /[a-z]/g.test(pass),
    text: 'One lowercase character',
  },
  {
    reg: (pass) => /[0-9]/g.test(pass),
    text: 'One number',
  },
  // {
  //   reg: (pass) => /^[^\s]+(\s+[^\s]+)*$/.test(pass),
  //   text: 'Cannot start or end with spaces',
  // },
  {
    reg: (pass) => pass.length >= 8,
    text: '8 characters minimum',
  },
];

export const passwordStrengthLabels = [
  {
    title: 'Weak',
    color: '#B3271E',
  },
  {
    title: 'Okay',
    color: '#E46962',
  },
  {
    title: 'Good',
    color: '#006C4D',
  },
  {
    title: 'Great',
    color: '#0095F7',
  },
];

export const legalFirmInfo = [
  {
    name: 'Wilson Sonsini Goodrich & Rosati',
    value: 'wilsonSonsini',
    domain: 'wsgr.com',
    type: 'Legal',
  },
  {
    name: 'Fenwick & West',
    value: 'fenwickWest',
    domain: 'fenwick.com',
    type: 'Legal',
  },
  {
    name: 'Sidley Austin',
    value: 'sidleyAustin',
    domain: 'sidley.com',
    type: 'Legal',
  },
  {
    name: 'Gunderson Dettmer',
    value: 'gundersonDettmer',
    domain: 'gunder.com',
    type: 'Legal',
  },
  {
    name: 'Seward & Kissel',
    value: 'sewardKissel',
    domain: 'sewkis.com',
    type: 'Legal',
  },
  {
    name: 'Karr Tuttle Campbell',
    value: 'karrTuttleCampbell',
    domain: 'karrtuttle.com',
    type: 'Legal',
  },
];

export const accountingFirmInfo = [
  {
    name: 'FLG Partners',
    value: 'flgPartners',
    domain: 'flgpartners.com',
    type: 'Financial',
  },
  {
    name: 'Kruze Consulting',
    value: 'kruzeConsulting',
    domain: 'kruzeconsulting.com',
    type: 'Financial',
  },
  {
    name: 'Standish Management',
    value: 'standishManagement',
    domain: 'standishmanagement.com',
    type: 'Financial',
  },
  {
    name: 'First Republic Bank',
    value: 'firstRepublicBank',
    domain: 'firstrepublic.com',
    type: 'Banking',
  },
  {
    name: 'Silicon Valley Bank',
    value: 'siliconValleyBank',
    domain: 'svb.com',
    type: 'Banking',
  },
  {
    name: 'Aduro Advisors',
    value: 'aduroAdvisors',
    domain: 'aduroadvisors.com',
    type: 'Financial',
  },
];

export const firmInfo = legalFirmInfo.concat(accountingFirmInfo);

export const dropdownAnimationTimeMS = 500;
export const dropdownAnimationTimeS = 0.5;

// Financing info

export const SAFE = 'SAFE Note';

export const convertibleNote = 'Convertible Note';

export const noFunding = 'No funding';

export const fundingType = [
  SAFE,
  convertibleNote,
  'Angel',
  'Pre-Seed',
  'Series Seed',
  'Series A',
  'Series B',
  'Series C',
  'Series D',
  'Series E',
  'Series F',
];

// US States list (for intake form)

export const USStates = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];


export default {
  partnerAssociatedWithFirm,
  partnerNotAssociatedWithFirm,
  passwordRequirements,
  passwordStrengthLabels,
  legalFirmInfo,
  accountingFirmInfo,
  firmInfo,
  dropdownAnimationTimeMS,
  dropdownAnimationTimeS,
  // Financing info
  SAFE,
  convertibleNote,
  noFunding,
  fundingType,
  USStates,
};
