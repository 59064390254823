import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';

export default function PerShareSummary({
  perShareSummaryTableData,
  pagination,
  numberOfPages,
  pageNumber,
  isLastPage,
  moveTitleToHeader,
  isSingleScenario,
}) {
  const tableMetaData = [
    {
      dataName: 'Class',
      cellWidth: '200px',
    },
    ...(isSingleScenario ? [
      {
        dataName: 'Aggregate\nvalue',
        cellWidth: '160px',
      },
      {
        dataName: 'Allocated\nper share',
        cellWidth: '160px',
      },
    ] : [
      {
        dataName: 'Early scenario\naggregate value',
        cellWidth: '160px',
      },
      {
        dataName: 'Early Allocated\nper share',
        cellWidth: '256px',
      },
      {
        dataName: 'Late scenario\naggregate value',
        cellWidth: '160px',
      },
      {
        dataName: 'Late Allocated\nper share',
        cellWidth: '160px',
      },
      {
        dataName: 'Weighted\nvalue',
        cellWidth: '160px',
      },
    ]),
  ];

  const addTwo = isLastPage ? 0 : 2;
  function rowHeight() {
    if (perShareSummaryTableData.tableData.length >= 8 + addTwo && perShareSummaryTableData.tableData.length <= 12 + addTwo) return '32px';
    if (perShareSummaryTableData.tableData.length >= 13 + addTwo) return '24px';
    return '48px';
  }
  function addToRowHeight() {
    if (perShareSummaryTableData.tableData.length >= 13 + addTwo) {
      return `${parseInt(rowHeight().replace('px', ''), 10) + 8}px`;
    }
    return `${parseInt(rowHeight().replace('px', ''), 10) + 16}px`;
  }
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative' }}
    >
      <TablePageHeader
        pageName={`Per Share Summary ${numberOfPages > 1 ? `(page ${pageNumber} of ${numberOfPages})` : ''}`}
        moveTitleToHeader={moveTitleToHeader}
      />
      <View
        style={{
          marginTop: '26px',
          marginLeft: '80px',
          display: 'flex',
          flexDirection: 'column',
          width: '1064px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: '48px',
            backgroundColor: '#F0F0F3',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          {tableMetaData.map((tableData) => {
            return (
              <View
                key={tableData.dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: `${tableData.cellWidth}`,
                  marginTop: 'auto',
                  paddingBottom: '2px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                  lineHeight: '0.55mm',
                }}
              >
                <Text>
                  {tableData.dataName.includes('per share') && !isSingleScenario ? 'Allocated\nvalue' : tableData.dataName}
                </Text>
              </View>
            );
          })}
        </View>
        {perShareSummaryTableData.tableData.map((cellData, cellDataIndex) => {
          return (
            <View
              key={cellData.Class}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: `${cellDataIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              {
                tableMetaData.map((tableData) => {
                  return (
                    <Text
                      key={tableData.dataName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        color: '#49454F',
                        width: `${tableData.cellWidth}`,
                        paddingLeft: '16px',
                        letterSpacing: '0.4px',
                        paddingRight: '16px',
                        paddingTop: '4px',
                      }}
                    >
                      {cellData[tableData.dataName.replace('\n', ' ')]}
                    </Text>
                  );
                })
              }
            </View>
          );
        })}
        {isLastPage && (
          <>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: addToRowHeight(),
                backgroundColor: '#FAFAFD',
                borderTop: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '200px',
                  marginTop: perShareSummaryTableData.tableData.length >= 13 + addTwo ? '8px' : '16px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                {`${!isSingleScenario ? 'Weighted ' : ''}${perShareSummaryTableData.tableData[0].Class} value`}
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '132px',
                  marginTop: perShareSummaryTableData.tableData.length >= 13 + addTwo ? '8px' : '16px',
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {perShareSummaryTableData.commonWeighted}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: '#F0F0F3',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '200px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                DLOM
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '132px',
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {perShareSummaryTableData.dlom}
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: addToRowHeight(),
                backgroundColor: '#F0F0F3',
                borderTop: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '200px',
                  marginTop: perShareSummaryTableData.tableData.length >= 13 + addTwo ? '8px' : '16px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                Concluded Value
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '132px',
                  marginTop: perShareSummaryTableData.tableData.length >= 13 + addTwo ? '8px' : '16px',
                  paddingLeft: '16px',
                  letterSpacing: '0.4px',
                }}
              >
                {perShareSummaryTableData.concludedValue}
              </Text>
            </View>
          </>
        )}
      </View>
      <BottomPagination
        pageNumberLeft={pagination[0]}
        pageNumberRight={pagination[1]}
      />
    </Page>
  );
}

PerShareSummary.propTypes = {
  perShareSummaryTableData: PropTypes.object,
  pagination: PropTypes.array,
  numberOfPages: PropTypes.number,
  pageNumber: PropTypes.number,
  isLastPage: PropTypes.bool,
  moveTitleToHeader: PropTypes.bool,
  isSingleScenario: PropTypes.bool,
};
