import React from 'react';
import PropTypes from 'prop-types';

import {
  Text,
  View,
  Image,
} from '@react-pdf/renderer';

import LightBlueGradient from './LightBlueGradient';

import initioTexture from '../assets/images/header-initio-texture.png';
import profitIcon from '../assets/images/profit-icon.png';

export default function TablePageHeader({ pageName, moveTitleToHeader, isCapV2 }) {
  return (
    <>
      <View
        style={{
          backgroundColor: '#303D5C',
          height: '104px',
          position: 'relative',
        }}
      >
        <Image
          src={initioTexture}
          style={{
            position: 'absolute',
            height: '104px',
            width: '273px',
          }}
        />
        <View
          style={{
            position: 'absolute',
            top: '24px',
            left: '48px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            gap: '15px',
          }}
        >
          <Image
            src={profitIcon}
            style={{
              width: '48px',
              height: '48px',
            }}
          />
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              color: '#FFFFFF',
            }}
          >
            {`409A Valuation Summary${moveTitleToHeader ? ` - ${pageName}` : ''}`}
          </Text>
        </View>
      </View>
      <LightBlueGradient
        width="1224px"
        height="412px"
        customPosition={{
          bottom: '0px',
        }}
      />
      {!moveTitleToHeader && (
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '24px',
            color: '#49454F',
            marginTop: '31px',
            marginLeft: isCapV2 ? '48px' : '80px',
          }}
        >
          {pageName}
        </Text>
      )}
      <View
        style={{
          height: '100%',
          width: '50%',
          position: 'absolute',
          left: '0px',
          zIndex: -1,
          borderRight: '1px',
          borderColor: '#CCCCCC',
        }}
      />
    </>
  );
}

TablePageHeader.propTypes = {
  pageName: PropTypes.string,
  moveTitleToHeader: PropTypes.bool,
  isCapV2: PropTypes.bool,
};
