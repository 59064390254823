import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Storage } from 'aws-amplify';
import AnimateHeight from 'react-animate-height';
import moment from 'moment';

import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import StatusChip from './StatusChip';
import RotatingChevron from './RotatingChevron';

import { ErrorMessageContext } from '../../lib/contextLib';

import {
  copy,
  getUserId,
  createAuthHeaders,
  getDBTransactionData,
} from '../../utils';

import './TransactionStatusBlock.scss';

export default function TransactionStatusBlock({
  companyToViewData,
  setCompanyToViewData,
  setTabToView,
  currentAdminName,
  updatePriority,
  setViewAccountInfo,
  setDropdownBlockIsOpen,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [dropdownHeight, setDropdownHeight] = useState(0);
  const [fileDropdownHeight, setFileDropdownHeight] = useState(0);

  const [fileList, setFileList] = useState([]);
  const [fileListIsLoading, setFileListIsLoading] = useState(true);

  const [noteList, setNoteList] = useState([]);
  const [noteContent, setNoteContent] = useState('');
  const [editNoteContent, setEditNoteContent] = useState('');

  const [noteMenuAnchorEl, setNoteMenuAnchorEl] = useState(null);
  const [noteMenuIsOpen, setNoteMenuIsOpen] = useState(false);
  const [editNoteIndex, setEditNoteIndex] = useState(-1);

  const [priorityDropdownIsOpen, setPriorityDropdownIsOpen] = useState(false);
  const [priority, setPriority] =
    useState(companyToViewData.transactionData?.adminProgress === 'completed' ? 2 : companyToViewData.companyData.priority || 2);

  const [insideElementIsBeingHovered, setInsideElementIsBeingHovered] = useState(false);
  const [noteIsFocussed, setNoteIsFocussed] = useState(false);

  async function postNote(isEdit, noteIndex) {
    const userId = await getUserId();
    const createdDatetime = moment().format('YYYY-MM-DD HH:mm:ss');
    const noteToSend = {
      companyId: companyToViewData.companyData.companyId,
      note: isEdit ? editNoteContent : noteContent,
      insertUserId: userId,
      noteAuthor: currentAdminName,
      createdDatetime,
    };
    const requestOptions = await createAuthHeaders('post', noteToSend, true);
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/transactions/write-note`, requestOptions);
    const noteResponse = await response.json();
    if (noteResponse) {
      const cloneArray = copy(noteList);
      if (isEdit) {
        cloneArray.splice(noteIndex, 1, noteResponse);
        setNoteList(copy(cloneArray));
      } else {
        cloneArray.unshift(noteResponse);
        setNoteList(cloneArray);
      }
    } else {
      setNoteList(companyToViewData.notes);
    }
  }

  function handleNoteMenuClose() {
    setNoteMenuIsOpen(false);
    setNoteMenuAnchorEl(null);
  }

  function handleNoteMenuOpen(e, noteIndex) {
    setNoteMenuAnchorEl(e.currentTarget);
    setNoteMenuIsOpen(true);
    setEditNoteIndex(noteIndex);
  }

  async function deletingNote(noteToDelete, noteIndex, andEdit) {
    const requestOptions = await createAuthHeaders('post', noteToDelete, true);
    try {
      await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/transactions/deleted-note/${noteToDelete.companyId}&${noteToDelete.noteId}&${noteToDelete.userId}`,
        requestOptions,
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      if (andEdit) {
        await postNote(true, noteIndex);
      } else {
        noteList.splice(noteIndex, 1);
        setNoteList(copy(noteList));
      }
    }
  }

  async function deleteNote(noteIndex, andEdit) {
    const deleteNoteData = {
      companyId: noteList[noteIndex].companyId,
      transactionId: noteList[noteIndex].transactionId,
      userId: noteList[noteIndex].insertUserId,
      noteId: noteList[noteIndex].resource,
      createdDatetime: noteList[noteIndex].createdDatetime,
      noteAuthor: noteList[noteIndex].currentAdminName,
    };
    const newNoteList = copy(noteList);
    newNoteList[noteIndex].isBeingEdited = andEdit;
    newNoteList[noteIndex].editNote = false;
    setNoteList(newNoteList);
    await deletingNote(deleteNoteData, noteIndex, andEdit);
  }

  async function editNote() {
    await deleteNote(editNoteIndex, true);
  }

  function switchNoteToEdit(reset) {
    const newNoteArr = [];
    noteList.forEach((note) => {
      newNoteArr.push({
        ...note,
        editNote: false,
      });
    });
    if (!reset) newNoteArr[editNoteIndex].editNote = true;
    setNoteList(newNoteArr);
  }

  async function fetchFileList() {
    async function returnArrOfLinks(uploadData, fileType) {
      return Promise.all(uploadData.map(async (file) => {
        return Storage.get(`${companyToViewData.companyData.companyId}/${file.dateTime}-${file.fileName}`, { expires: 2400 }).then((link) => {
          return ({
            fileType,
            fileName: file.fileName,
            link,
          });
        });
      }));
    }
    if (fileList.length === 0) {
      setFileListIsLoading(true);
      const userId = await getUserId();
      const DBData = await getDBTransactionData(companyToViewData.companyData.companyId, companyToViewData.transactionData.transactionId, userId);
      const capTableFiles = [...await returnArrOfLinks(DBData.documents.cap_table_files, 'Cap Table')];
      const articlesOfIncorporation = [...await returnArrOfLinks(DBData.documents.articles_of_incorporation, 'Articles of Incorporation')];
      const secondaryTransactionDocs = [...await returnArrOfLinks(DBData.documents.secondary_transaction, 'Secondary Transaction')];
      const termsSheets = [...await returnArrOfLinks(DBData.documents.term_sheets, 'Term Sheets')];
      const safeOrConvertibleNoteAgreement =
        [...await returnArrOfLinks(DBData.documents.safe_or_convertible_note_agreement, 'Safe or Convertible Agreement')];
      const newFileListArr =
        fileList.concat(capTableFiles, articlesOfIncorporation, secondaryTransactionDocs, termsSheets, safeOrConvertibleNoteAgreement);
      setFileList(newFileListArr);
      setFileListIsLoading(false);
    }
  }

  function priorityIconToShow() {
    if (priority === 1) return <NotificationImportantOutlinedIcon className="notif-icon" />;
    if (priority === 3) return <ArrowDownwardIcon />;
    return null;
  }

  useEffect(() => {
    const newNoteArr = [];
    companyToViewData.notes.forEach((note) => {
      newNoteArr.push({
        ...note,
        editNote: false,
      });
    });
    setNoteList(newNoteArr);
  }, []);

  useEffect(() => setDropdownBlockIsOpen(dropdownHeight), [dropdownHeight]);

  const showStatusChip = () => (
    (!companyToViewData.transactionData && companyToViewData.paymentData) ||
    (companyToViewData.transactionData && companyToViewData.transactionData.progress !== 'completed') ||
    companyToViewData.transactionData?.isCalculating ||
    companyToViewData.transactionData?.adminProgress === 'calculation failed' ||
    companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
    companyToViewData.transactionData?.adminProgress === 'review sandbox' ||
    companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
    companyToViewData.transactionData?.adminProgress === 'completed'
  );

  return (
    <div className="TransactionStatusBlock">
      <div
        role="button"
        className={'transaction-block-btn ' +
          `${!insideElementIsBeingHovered && !noteIsFocussed && !priorityDropdownIsOpen && !noteMenuIsOpen ? 'can-hover' : ''}`}
        onClick={() => {
          if (!insideElementIsBeingHovered && !noteIsFocussed && !priorityDropdownIsOpen && !noteMenuIsOpen) {
            setCompanyToViewData(companyToViewData);
            setTabToView(1);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && (!insideElementIsBeingHovered && !noteIsFocussed && !priorityDropdownIsOpen && !noteMenuIsOpen)) {
            setCompanyToViewData(companyToViewData);
            setTabToView(1);
          }
        }}
        tabIndex={0}
      >
        <div className="transaction-block-header">
          <div className="transaction-block-info-wrapper">
            <span className="accounting-service-name">
              {companyToViewData.transactionData?.type || '409A'}
            </span>
            <div className="transaction-name-and-info-wrapper">
              <span className="top-transaction-name">
                {companyToViewData.accountData?.companyName}
              </span>
              <span className="bottom-transaction-info">
                {companyToViewData.transactionData?.chosenFunding ? companyToViewData.transactionData?.chosenFunding :
                  !companyToViewData.transactionData && companyToViewData.paymentData ?
                    `Paid ${moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD')}` :
                    companyToViewData.transactionData && companyToViewData.transactionData.progress !== 'completed' ?
                      `Started ${companyToViewData.transactionData.transactionType} ` +
                      `${moment(companyToViewData.transactionData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD')}` :
                      `Signed up ${moment(companyToViewData.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD')}`}
              </span>
            </div>
            <div className="priority-icon-and-or-chevron-btn">
              {!showStatusChip() && priorityIconToShow()}
              <button
                type="button"
                className="right-chevron-btn"
                onClick={(e) => {
                  e.stopPropagation();
                  setDropdownHeight(dropdownHeight ? 0 : 'auto');
                }}
              >
                <RotatingChevron downwards0Condition={dropdownHeight} />
              </button>
            </div>
          </div>
          <div className="priority-icon-and-or-status-chip">
            {!!showStatusChip() && (
              <>
                {priorityIconToShow()}
                <StatusChip companyData={companyToViewData} />
              </>
            )}
          </div>
        </div>
        <AnimateHeight
          duration={500}
          height={dropdownHeight}
        >
          <hr className="above-dropdown-spacer" />
          <div className="dropdown-content">
            {companyToViewData.transactionData?.progress === 'completed' && (
              <div
                className="files-list"
                onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                onMouseLeave={() => setInsideElementIsBeingHovered(false)}
              >
                <div className="files-list-header">
                  <FolderOpenIcon />
                  <span>Client files for this transaction</span>
                  <div className="file-list-chevron-btn">
                    <button
                      type="button"
                      className="right-chevron-btn"
                      onClick={(e) => {
                        e.stopPropagation();
                        setFileDropdownHeight(fileDropdownHeight ? 0 : 'auto');
                        fetchFileList();
                      }}
                    >
                      <RotatingChevron downwards0Condition={fileDropdownHeight} />
                    </button>
                  </div>
                </div>
                <AnimateHeight
                  duration={500}
                  height={fileDropdownHeight}
                >
                  <hr className="above-dropdown-spacer" />
                  {
                    fileListIsLoading ? (
                      <div className="loading-file-list">
                        <LoadingSpinner className="custom-loading-spinner" />
                      </div>
                    ) : fileList.map((file) => {
                      return (
                        <div key={file.link} className="file-el">
                          <FileOpenOutlinedIcon />
                          <span>
                            {file.fileType}
                            :
                            {' '}
                            <a href={file.link}>
                              {(file.fileName.length >= 30) ? `${file.fileName.slice(0, 30).trim()}...` : file.fileName}
                            </a>
                          </span>
                        </div>
                      );
                    })
                  }
                </AnimateHeight>
              </div>
            )}
            <FormControl
              onClick={(e) => { e.stopPropagation(); }}
              onKeyDown={(e) => { if (e.key === 'Enter') e.stopPropagation(); }}
            >
              <TextField
                multiline
                maxRows={4}
                placeholder="Type note"
                className="note-field"
                value={noteContent}
                onChange={(e) => setNoteContent(e.target.value)}
                onFocus={() => setNoteIsFocussed(true)}
                onBlur={() => setNoteIsFocussed(false)}
                onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                onMouseLeave={() => setInsideElementIsBeingHovered(false)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={(e) => {
                        e.stopPropagation();
                        postNote();
                        setNoteContent('');
                      }}
                    >
                      <SendOutlinedIcon />
                    </InputAdornment>
                  ),
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{ maxLength: 500 }}
              />
              <div className="note-length">
                {noteContent.length}
                / 500
              </div>
            </FormControl>
            <div
              onMouseEnter={() => setInsideElementIsBeingHovered(true)}
              onMouseLeave={() => setInsideElementIsBeingHovered(false)}
              className="note-list"
            >
              {noteList.map((note, noteIndex) => {
                if (note.editNote) {
                  return (
                    <React.Fragment key={`${moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('X')}`}>
                      <div className="edit-note">
                        <TextField
                          multiline
                          maxRows={4}
                          placeholder="Type note"
                          className="note-field"
                          value={editNoteContent}
                          onFocus={() => setNoteIsFocussed(true)}
                          onBlur={() => setNoteIsFocussed(false)}
                          onChange={(e) => setEditNoteContent(e.target.value)}
                          inputProps={{ maxLength: 500 }}
                        />
                        <div className="edit-note-btns">
                          <Button
                            className="edit-note-cancel-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              switchNoteToEdit(true);
                            }}
                          >
                            Cancel
                          </Button>
                          <Button
                            className="edit-note-save-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              editNote(noteIndex);
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                      <div className="note-length">
                        {noteContent.length}
                        / 500
                      </div>
                    </React.Fragment>
                  );
                }
                return (
                  <div
                    key={`${moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('x')}`}
                    className={`note${note.isBeingEdited ? ' isEditing' : ''}`}
                  >
                    <div className="loading-note">
                      <LoadingSpinner className="custom-loading-spinner" />
                    </div>
                    <span className="note-date-time">
                      <span className="note-author">{note.noteAuthor}</span>
                      <span className="timestamp">
                        {' '}
                        {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('MM/DD/YY')}
                        {' '}
                        at
                        {' '}
                        {moment(note.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}
                      </span>
                    </span>
                    <div className="note-content">
                      <span>
                        {note.note}
                      </span>
                    </div>
                    <IconButton
                      className="note-vert-icon"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleNoteMenuOpen(e, noteIndex);
                      }}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      className="note-menu"
                      anchorEl={noteMenuAnchorEl}
                      open={noteMenuIsOpen}
                      onClose={handleNoteMenuClose}
                      disableScrollLock
                    >
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          switchNoteToEdit();
                          setEditNoteContent(noteList[editNoteIndex].note);
                          handleNoteMenuClose();
                        }}
                      >
                        <EditIcon />
                        Edit
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteNote(editNoteIndex);
                          handleNoteMenuClose();
                        }}
                      >
                        <DeleteOutlineIcon />
                        Delete
                      </MenuItem>
                    </Menu>
                  </div>
                );
              })}
              <div className="bottom-wrapper">
                <div
                  className="submit-info"
                  onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                  onMouseLeave={() => setInsideElementIsBeingHovered(false)}
                >
                  {companyToViewData.transactionData?.progress === 'completed' ?
                    `Client submitted ${moment(
                      companyToViewData.transactionData.submittedDatetime || companyToViewData.transactionData.transactionDate,
                      'YYYY-MM-DD HH:mm:ss',
                    ).format('MM/DD/YY')} at ` +
                    `${moment(companyToViewData.transactionData.submittedDatetime ||
                      companyToViewData.transactionData.transactionDate, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}` :
                    `Client signed up ${moment(
                      companyToViewData.companyData.createdDatetime,
                      'YYYY-MM-DD HH:mm:ss',
                    ).format('MM/DD/YY')} at ` +
                    `${moment(companyToViewData.companyData.createdDatetime, 'YYYY-MM-DD HH:mm:ss').format('hh:mma')}`}
                  <Button
                    className="account-detail"
                    onClick={() => {
                      setViewAccountInfo(true);
                      setCompanyToViewData(companyToViewData);
                      setTabToView(1);
                    }}
                  >
                    {`Go to ${companyToViewData.accountData.companyName} details`}
                  </Button>
                </div>
                {(!companyToViewData.transactionData || companyToViewData.transactionData?.adminProgress !== 'completed') && (
                  <Select
                    className="priority-select"
                    open={priorityDropdownIsOpen}
                    onOpen={() => setPriorityDropdownIsOpen(true)}
                    onClose={() => setPriorityDropdownIsOpen(false)}
                    onChange={(e) => {
                      setPriority(e.target.value);
                      updatePriority(companyToViewData, e.target.value);
                    }}
                    value={priority}
                    renderValue={() => <span className="priority-label">Priority</span>}
                    IconComponent={ExpandMoreIcon}
                    MenuProps={{ disableScrollLock: true, disablePortal: true }}
                    onMouseEnter={() => setInsideElementIsBeingHovered(true)}
                    onMouseLeave={() => setInsideElementIsBeingHovered(false)}
                  >
                    <MenuItem value={1}>
                      <NotificationImportantOutlinedIcon className="high-icon" />
                      High
                    </MenuItem>
                    <MenuItem value={2}>
                      <ArrowDownwardIcon sx={{ visibility: 'hidden', marginRight: '18px' }} />
                      Normal
                    </MenuItem>
                    <MenuItem value={3}>
                      <ArrowDownwardIcon className="low-icon" />
                      Low
                    </MenuItem>
                  </Select>
                )}
                <div className="bottom-info">
                  {companyToViewData.paymentData && (companyToViewData.transactionData?.progress !== 'completed' ||
                    (companyToViewData.transactionData.progress === 'completed' &&
                      (!companyToViewData.transactionData.adminProgress || companyToViewData.transactionData.adminProgress === 'not started'))) ?
                    `Paid ${moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('MM/DD/YY')} at ` +
                    `${moment.utc(companyToViewData.paymentData.date_time, 'YYYY-MM-DD HH:mm:ss').local().format('hh:mma')}` :
                    companyToViewData.transactionData?.progress !== 'completed' ?
                      'Waiting for client to finalize account' :
                      companyToViewData.transactionData?.lastModified && companyToViewData.transactionData?.lastModifiedBy ?
                        `${!showStatusChip() ? 'Edited' : 'Submitted'} ` +
                        `${moment(companyToViewData.transactionData?.lastModified, 'YYYY-MM-DD HH:mm:ss').fromNow()} by ` +
                        `${companyToViewData.transactionData?.lastModifiedBy || 'unknown'}` : ''}
                </div>
              </div>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
}

TransactionStatusBlock.propTypes = {
  companyToViewData: PropTypes.object,
  setCompanyToViewData: PropTypes.func,
  setTabToView: PropTypes.func,
  currentAdminName: PropTypes.string,
  updatePriority: PropTypes.func,
  setViewAccountInfo: PropTypes.func,
  setDropdownBlockIsOpen: PropTypes.func,
};
