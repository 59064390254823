import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Storage } from 'aws-amplify';

import { BlobProvider } from '@react-pdf/renderer';
import { Document, Page, pdfjs } from 'react-pdf';

import PDFReport from './PDFReport';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import {
  commaEvery3rdChar,
  removeCommas,
  copy,
  getUserId,
  createAuthHeaders,
} from '../../utils';

import { ErrorMessageContext } from '../../lib/contextLib';

import './PDFReportViewer.scss';

export default function PDFReportViewer({
  userData,
  setNumberOfPages,
  pageNumber,
  setPageNumber,
  setDownloadLink,
  recreateReport,
  setRecreateReport,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [companyName, setCompanyName] = useState('');
  const [transactionDate, setTransactionDate] = useState('');
  const [sandboxData, setSandboxData] = useState({});
  const [capTableData, setCapTableData] = useState({});
  const [breakpoint1TableData, setBreakpoint1TableData] = useState({});
  const [breakpoint2TableData, setBreakpoint2TableData] = useState({});
  const [earlyOPMTableData, setEarlyOPMTableData] = useState({});
  const [lateOPMTableData, setLateOPMTableData] = useState({});
  const [perShareSummaryTableData, setPerShareSummaryTableData] = useState({});
  const [marketAdjustmentValue, setMarketAdjustmentValue] = useState('');
  const [chosenFunding, setChosenFunding] = useState(false);
  const [companyIndustry, setCompanyIndustry] = useState('');
  const [SAFEPrincipal, setSAFEPrincipal] = useState(false);
  const [GPCAndBacksolve, setGPCAndBacksolve] = useState(false);
  const [GPCOnly, setGPCOnly] = useState(false);
  const [SAFEValCap, setSAFEValCap] = useState('');
  const [pdfLoading, setPdfLoading] = useState(true);

  const [isSingleScenario, setIsSingleScenario] = useState(false);

  const [reportExists, setReportExists] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');

  pdfjs.GlobalWorkerOptions.workerSrc = '//unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js';

  async function parseTableData(DBData) {
    const isSingleScenarioData = DBData.reportType === 'singleScenario';
    setIsSingleScenario(isSingleScenarioData);
    // Helper functions
    function sortDataByClassName(dataArr, tableName) {
      const tempArr = [];
      const className = tableName === 'opmTable' ? 'Allocation' : 'Class';
      dataArr.forEach((val) => {
        if (val[className] !== 'NTM Options' &&
          val[className] !== 'Remaining Option Pool'
        ) {
          tempArr.push(val);
        }
      });
      tempArr.push(dataArr.find((el) => el[className] === 'NTM Options'));
      if (tableName === 'CapTable') tempArr.push(dataArr.find((el) => el[className] === 'Remaining Option Pool'));
      return tempArr;
    }

    function convertTimeToWords(monthCount) {
      function getPlural(number, word) {
        return (number === 1 && word.one) || word.other;
      }

      const months = { one: 'month', other: 'months' };
      const years = { one: 'year', other: 'years' };
      const yrs = { one: 'yr', other: 'yrs' };
      const mos = { one: 'mo', other: 'mos' };
      const m = Math.round((monthCount % 12) * 100) / 100;
      const y = Math.floor(monthCount / 12);
      const result = [];

      if (y && m) {
        if (y) result.push(`${y} ${getPlural(y, yrs)}`);
        if (m) result.push(`${m} ${getPlural(m, mos)}`);
      } else {
        if (y) result.push(`${y} ${getPlural(y, years)}`);
        if (m) result.push(`${m} ${getPlural(m, months)}`);
      }
      return result.join(' ');
    }

    // Setting other needed values
    setCompanyName(userData.companyName);
    setTransactionDate(moment(DBData.late.valuationDate, 'YYYY-MM-DD').format('MMMM DD, YYYY'));
    setMarketAdjustmentValue(DBData.marketAdjustment);
    setSAFEValCap(DBData.latestSafeValuationCap ? commaEvery3rdChar(DBData.latestSafeValuationCap.toString()) : '');
    setChosenFunding(userData.chosenFunding);
    setCompanyIndustry(userData.companyIndustry);
    // TODO
    setSAFEPrincipal(false);
    setGPCAndBacksolve(false);
    setGPCOnly(false);

    // Setting SandBox Data
    const {
      perSharePrice,
      concludedPercentOfPreferred,
      averageOptionExercise,
      prior409A,
      latestRoundIssued,
      medianMarketPercOfPreferred,
      averageIndustryVolatility,
      term,
      ntmOptionPercent,
      volatility,
      dlom,
    } = DBData;

    setSandboxData({
      perSharePrice,
      concludedPercentOfPreferred,
      averageOptionExercise,
      prior409A,
      latestRoundIssued,
      medianMarketPercOfPreferred,
      averageIndustryVolatility,
      term,
      ntmOptionPercent,
      volatility,
      dlom,
      totalOutcomes: commaEvery3rdChar(DBData.totalOutcomes?.toString() || 3427),
      selectedPriceOutcomes: commaEvery3rdChar(DBData.selectedPriceOutcomes?.toString() || 150),
    });


    // Setting Table Data

    // -------------- CapTable Data --------------- //

    let hasDividends = false;
    const formatDividendValue = (val) => {
      if (val === 0) return 'No';
      if (val === 1) {
        hasDividends = true;
        return 'Yes';
      }
      return 'N/A';
    };

    let isComplexData = false;
    const formatRatio = (val) => {
      if (val && parseFloat(val) > 1) isComplexData = true;
      if (val) return `${val}x`;
      return 'N/A';
    };

    const { breakpoints, preferred } = DBData.late.breakpoints;
    const capTableArr = [];
    // Adding Breakpoints Data
    breakpoints['Ownership Class'].forEach((entry, index) => {
      capTableArr[index] = { Class: entry };
    });
    breakpoints['Original Issue Price'].forEach((entry, index) => {
      capTableArr[index] = {
        ...capTableArr[index],
        'Issue price': entry ? `$${parseFloat(entry).toFixed(2)}` : 'N/A',
      };
    });
    breakpoints['Total Shares Outstanding'].forEach((entry, index) => {
      capTableArr[index] = {
        ...capTableArr[index],
        Outstanding: commaEvery3rdChar(entry.toString()),
      };
    });
    // Adding additional Preferred Data
    Object.keys(preferred).forEach((key, index) => {
      capTableArr.forEach((value, arrIndex) => {
        if (value.Class === key) {
          capTableArr[arrIndex] = {
            ...capTableArr[arrIndex],
            Dividends: formatDividendValue(Object.values(preferred)[index].isDividends),
            'Liquidation ratio': formatRatio(Object.values(preferred)[index].liquidationPreference),
            'Conversion ratio': formatRatio(Object.values(preferred)[index].conversionRatio),
            'Liquidation Preference ($/share)': `$${Object.values(preferred)[index].preferencePerSharePrice.toFixed(2)}`,
            'Total Liquidation Preference':
              `$${commaEvery3rdChar(((parseFloat(Object.values(preferred)[index].liquidationPreference) *
                Object.values(preferred)[index].shares *
                parseFloat(Object.values(preferred)[index].issuePrice)).toFixed(0)).toString())}`,
          };
        }
      });
    });
    // Adding Remaining Option Pool Data from Calc Object
    capTableArr.push({
      Class: 'Remaining Option Pool',
      Outstanding: commaEvery3rdChar(DBData.remainingOptionsPool),
    });

    // Calculating "Total Outstanding" and "Total Fully Diluted"
    let totalOutstanding = 0;
    let totalFullyDiluted = 0;
    let totalLiquidation = 0;
    capTableArr.forEach((data, index) => {
      totalOutstanding += parseInt(removeCommas(data.Outstanding), 10);
      const currentRatio = data['Conversion ratio'] && data['Conversion ratio'] !== 'N/A' ? parseInt(data['Conversion ratio'], 10) : 1;
      const currentFullyDiluted = parseInt(removeCommas(data.Outstanding), 10) * currentRatio;
      capTableArr[index]['Fully diluted'] = commaEvery3rdChar(currentFullyDiluted.toString());
      totalFullyDiluted += currentFullyDiluted;
      if (data['Total Liquidation Preference']) {
        totalLiquidation += parseInt(removeCommas(data['Total Liquidation Preference'].replaceAll('$', '')), 10);
      }
    });

    // Calculating Percents of totals
    capTableArr.forEach((data, index) => {
      capTableArr[index]['Percent of total'] = `${((parseInt(removeCommas(data.Outstanding), 10) / totalOutstanding) * 100).toFixed(2)}%`;
      capTableArr[index]['Full Percent of total'] = `${((parseInt(removeCommas(data['Fully diluted']), 10) / totalFullyDiluted) * 100).toFixed(2)}%`;
    });

    // Replacing Exercise Price value with Issue Price as needed
    capTableArr.forEach((data, index) => {
      if (data.Class === 'NTM Options' || data.Class.includes('@')) {
        capTableArr[index]['Exercise price'] = capTableArr[index]['Issue price'];
        delete capTableArr[index]['Issue price'];
      }
    });

    // Setting the Data
    setCapTableData({
      tableData: sortDataByClassName(capTableArr, 'CapTable'),
      totalData: {
        Outstanding: commaEvery3rdChar(totalOutstanding.toString()),
        'Fully diluted': commaEvery3rdChar(totalFullyDiluted.toString()),
        'Percent of total': '100%',
        'Full Percent of total': '100%',
        'Total Liquidation Preference': `$${commaEvery3rdChar(totalLiquidation.toString())}`,
      },
      isComplexData,
      hasDividends,
    });

    // -------------- First Breakpoint Table --------------- //

    const breakpointValues = Object.keys(breakpoints);
    const breakpointsValuesArr = [];
    breakpointValues.forEach((breakVal) => {
      if (breakVal.includes(' | ')) {
        breakpointsValuesArr.push(breakVal);
      }
    });
    // Sorting breakpoints in ascending order (based on first encountered $ value in string)
    const dollarValue = (val) => {
      const targetVal = val.split(' to ')[0].split(' ');
      return parseFloat(targetVal[targetVal.length - 1].replace('$', ''));
    };
    breakpointsValuesArr.sort((a, b) => dollarValue(a) - dollarValue(b));

    // Adding sorted value to column titles array with also "Class" and "All Participate"
    const columnTitlesArr = [];
    columnTitlesArr.push({
      dataName: 'Class',
    });
    breakpointsValuesArr.forEach((column) => {
      columnTitlesArr.push({
        dataName: column.split(' | ')[0],
        breakpoint: column.split(' | ')[1],
      });
    });
    columnTitlesArr.push({
      dataName: 'All Participate',
      breakpoint: `${columnTitlesArr[columnTitlesArr.length - 1].breakpoint.split(' to ')[1]} +`,
    });

    // Parsing Breakpoint Data
    const breakpointsDataArr = [];
    breakpoints['Ownership Class'].forEach((entry, index) => {
      breakpointsDataArr[index] = { Class: entry };
    });

    breakpointValues.forEach((column, index) => {
      if (column.includes(' | ')) {
        breakpointsDataArr.forEach((data, dataIndex) => {
          breakpointsDataArr[dataIndex] = {
            ...breakpointsDataArr[dataIndex],
            [column.split(' | ')[0]]: Object.values(breakpoints)[index][dataIndex],
            'All Participate': '∞',
          };
        });
      }
    });

    // Setting Breakpoint Data
    setBreakpoint1TableData({
      columnData: columnTitlesArr,
      tableData: sortDataByClassName(breakpointsDataArr),
    });

    // -------------- Second Breakpoint Table --------------- //

    // Calculating percentage values of second table based on first's data
    const breakpoint2DataArr = copy(breakpointsDataArr);
    const columnSums = {};
    Object.keys(breakpoint2DataArr[0]).forEach((key) => {
      if (key !== 'Class' && key !== 'All Participate') {
        columnSums[key] = 0;
      }
    });
    breakpointsDataArr.forEach((data) => {
      Object.values(data).forEach((value, valIndex) => {
        if (typeof value === 'number') {
          columnSums[Object.keys(data)[valIndex]] += value;
        }
      });
    });
    let totalSum = 0;
    Object.values(columnSums).forEach((sum) => {
      totalSum += sum;
    });
    breakpointsDataArr.forEach((data, dataIndex) => {
      let rowSum = 0;
      Object.values(data).forEach((value, valIndex) => {
        if (typeof value === 'number') rowSum += value;
        if (typeof value === 'number' && value !== 0) {
          breakpoint2DataArr[dataIndex][Object.keys(data)[valIndex]] = `${((100 * value) / columnSums[Object.keys(data)[valIndex]]).toFixed(0)}%`;
        }
      });
      breakpoint2DataArr[dataIndex]['All Participate'] = `${((100 * rowSum) / totalSum).toFixed(0)}%`;
    });

    // Setting the calculated values
    setBreakpoint2TableData({
      columnData: columnTitlesArr,
      tableData: sortDataByClassName(breakpoint2DataArr),
    });

    // -------------- Early and Late OPM Table --------------- //

    function parseEarlyAndLateOPMData(earlyOrLate) {
      // Parsing OPM data
      const OPMData = {
        'Equity Value': `$${commaEvery3rdChar(DBData[earlyOrLate].totalEquityValue.toString())}`,
        Term: convertTimeToWords((parseFloat(DBData[earlyOrLate].term) * 12).toFixed(0)),
        'Dividend Yield': `${(parseFloat(DBData[earlyOrLate].dividendYield) * 100).toFixed(2)}%`,
        Volatility: `${(parseFloat(DBData[earlyOrLate].volatility) * 100).toFixed(2)}%`,
        'Risk free rate': `${(parseFloat(DBData[earlyOrLate].riskFreeRate) * 100).toFixed(2)}%`,
      };

      // Parsing OPM mid-table data
      const breakpointOPMValues = {};
      const valueOfCallOption = {};
      const incrementalOptionValue = {};
      Object.keys(DBData[earlyOrLate].optionPricingModel).forEach((key, keyIndex) => {
        breakpointOPMValues[key.split(' | ')[0]] = Object.values(DBData[earlyOrLate].optionPricingModel)[keyIndex].breakpointValue;
        valueOfCallOption[key.split(' | ')[0]] = Object.values(DBData[earlyOrLate].optionPricingModel)[keyIndex].value_of_call_option;
        incrementalOptionValue[key.split(' | ')[0]] = Object.values(DBData[earlyOrLate].optionPricingModel)[keyIndex].incremental_option_value;
      });

      // Parsing through aggregate values
      const aggregateValues = [];

      DBData[earlyOrLate].aggregateValuePerBreakpointPerEquityClass['Ownership Class'].forEach((classData, dataIndex) => {
        let newObject = {};
        newObject = {
          ...newObject,
          Allocation: classData,
          Aggregate: DBData[earlyOrLate].aggregateValuePerBreakpointPerEquityClass['Total Aggregate Values'][dataIndex],
        };
        Object.keys(DBData[earlyOrLate].aggregateValuePerBreakpointPerEquityClass).forEach((key) => {
          if (key.includes(' | ') || key === 'All Participate') {
            newObject[key.split(' | ')[0]] = DBData[earlyOrLate].aggregateValuePerBreakpointPerEquityClass[key][dataIndex];
          }
        });
        aggregateValues.push(newObject);
      });

      // Calculating Aggregate total
      let aggregateTotal = 0;
      aggregateValues.forEach((aggregateValue) => {
        aggregateTotal += aggregateValue.Aggregate;
      });

      // Setting parsed through data
      if (earlyOrLate === 'early') {
        setEarlyOPMTableData({
          columnData: columnTitlesArr.slice(1),
          earlyOPMData: OPMData,
          earlyBreakpointValues: breakpointOPMValues,
          earlyValueOfCallOption: valueOfCallOption,
          earlyIncrementalOptionValue: incrementalOptionValue,
          earlyAggregateValues: sortDataByClassName(aggregateValues, 'opmTable'),
          earlyAggregateTotal: aggregateTotal,
        });
      } else if (earlyOrLate === 'late') {
        setLateOPMTableData({
          columnData: columnTitlesArr.slice(1),
          lateOPMData: OPMData,
          lateBreakpointValues: breakpointOPMValues,
          lateValueOfCallOption: valueOfCallOption,
          lateIncrementalOptionValue: incrementalOptionValue,
          lateAggregateValues: sortDataByClassName(aggregateValues, 'opmTable'),
          lateAggregateTotal: aggregateTotal,
        });
      }
    }
    parseEarlyAndLateOPMData('late');
    if (!isSingleScenarioData) parseEarlyAndLateOPMData('early');

    // -------------- Per Share Summary Table --------------- //

    const perShareSummaryValues = [];
    let commonWeighted;

    DBData.late.aggregateValuePerBreakpointPerEquityClass['Ownership Class'].forEach((classData, dataIndex) => {
      let newObject = {};
      const fetchAggregateVal = (earlyOrLate, index) => {
        return `$${commaEvery3rdChar(
          DBData[earlyOrLate].aggregateValuePerBreakpointPerEquityClass['Total Aggregate Values'][index].toFixed(0).toString(),
        )}`;
      };
      if (dataIndex === 0) commonWeighted = `$${DBData.perShareSummary[classData].weightedValue.toFixed(2)}`;
      if (!isSingleScenarioData) {
        newObject = {
          ...newObject,
          Class: classData,
          'Early scenario aggregate value': fetchAggregateVal('early', dataIndex),
          'Early Allocated per share': `$${DBData.perShareSummary[classData].earlyAllocatedValPerShare.toFixed(2)}`,
          'Late scenario aggregate value': fetchAggregateVal('late', dataIndex),
          'Late Allocated per share': `$${DBData.perShareSummary[classData].lateAllocatedValPerShare.toFixed(2)}`,
          'Weighted value': `$${DBData.perShareSummary[classData].weightedValue.toFixed(2)}`,
        };
      } else {
        newObject = {
          ...newObject,
          Class: classData,
          'Aggregate value': fetchAggregateVal('late', dataIndex),
          'Allocated per share': `$${DBData.perShareSummary[classData].lateAllocatedValPerShare.toFixed(2)}`,
        };
      }
      perShareSummaryValues.push(newObject);
    });

    setPerShareSummaryTableData({
      tableData: perShareSummaryValues,
      dlom: `${DBData.dlom}%`,
      commonWeighted,
      concludedValue: `$${perSharePrice}`,
    });
  }

  async function parseDBData() {
    // Fetch Transaction Data for PDF
    try {
      const userId = await getUserId();
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      const endpointData = `${userData.companyId}&${userData.transactionId}&report_409A_val&${userId}`;
      let reportData = await fetch(
        `${backendURL}/reports/409A/${endpointData}`,
        await createAuthHeaders('get', {}, true),
      );
      reportData = await reportData.json();
      const url = await Storage.get(reportData.Body.folderStructure.replace('409AValReport', ''), {
        customPrefix: { public: '409AValReport' },
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
      });
      let reportJSONData = await fetch(url);
      reportJSONData = await reportJSONData.json();
      await parseTableData(reportJSONData);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setDataIsLoading(false);
    }
  }

  async function retrieveS3Link() {
    let url = '';
    try {
      url = await Storage.get(`${userData.companyName}-${userData.transactionId}-409AReport.pdf`, {
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
        expires: 2400,
      });
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setPdfUrl(url);
      setDownloadLink(url);
    }
  }

  async function checkIfPdfExists() {
    try {
      const { results } = await Storage.list('', {
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
        pageSize: 'ALL',
      });
      if (results.some((bucketObject) => bucketObject.key === `${userData.companyName}-${userData.transactionId}-409AReport.pdf`)) {
        setReportExists(true);
      } else {
        setReportExists(false);
      }
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  async function deleteS3PDFAndRecreate() {
    try {
      await Storage.remove(`${userData.companyName}-${userData.transactionId}-409AReport.pdf`, {
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
      });
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setReportExists(false);
      setRecreateReport(false);
    }
  }

  useEffect(() => {
    checkIfPdfExists();
  }, []);

  useEffect(() => {
    if (reportExists === true) {
      retrieveS3Link();
    } else if (reportExists === false) {
      parseDBData();
    }
  }, [reportExists]);

  useEffect(() => {
    if (recreateReport) deleteS3PDFAndRecreate();
  }, [recreateReport]);

  async function saveToBackendAndS3(url) {
    const userId = await getUserId();
    try {
      const { results } = await Storage.list('', {
        bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
        pageSize: 'ALL',
      });
      results.filter(async (bucketObject) => {
        if (bucketObject.key === `${companyName}-${userData.transactionId}-409AReport.pdf`) {
          return setReportExists(true);
        }
        return setReportExists(false);
      });
      if (!reportExists) {
        try {
          await Storage.put(`${companyName}-${userData.transactionId}-409AReport.pdf`, url, {
            bucket: `${process.env.REACT_APP_CLIENT_FILES_BUCKET}-${process.env.REACT_APP_ENV_LABEL}`,
          });
          const bucketName = process.env.REACT_APP_CLIENT_FILES_BUCKET;
          const reportData = {
            companyId: userData.companyId,
            transactionId: userData.transactionId,
            s3Location: `s3://${bucketName}-${process.env.REACT_APP_ENV_LABEL}/public/${companyName}-${userData.transactionId}-409AReport.pdf`,
            createdDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
            reportType: '409A',
            status: 'not approved',
            adminUserId: userId,
          };
          await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/save-409A-report`,
            await createAuthHeaders('post', reportData, true),
          );
        } catch (e) {
          setShowErrorMessage(e.toString());
        } finally {
          setReportExists(true);
        }
      }
      retrieveS3Link();
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumberOfPages(numPages);
    setPageNumber(1);
    setPdfLoading(false);
  }

  if (pdfUrl && reportExists) {
    return (
      <>
        {pdfLoading && (
          <div className="pdf-loading-wrapper">
            <LoadingSpinner className="custom-loading-spinner" />
            <span>Loading PDF...</span>
          </div>
        )}
        <div className="report-viewer">
          <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} loading=" " error=" ">
            <Page pageNumber={pageNumber} renderAnnotationLayer={false} renderTextLayer={false} />
          </Document>
        </div>
      </>
    );
  }

  if (reportExists === false) {
    return (
      <>
        <div className="pdf-loading-wrapper">
          <LoadingSpinner className="custom-loading-spinner" />
          <span>Generating PDF...</span>
        </div>
        {!dataIsLoading && (
          <BlobProvider
            document={(
              <PDFReport
                companyName={companyName}
                transactionDate={transactionDate}
                valuationDate={moment(userData.transactionDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}
                sandboxData={sandboxData}
                capTableData={capTableData}
                breakpoint1TableData={breakpoint1TableData}
                breakpoint2TableData={breakpoint2TableData}
                earlyOPMTableData={earlyOPMTableData}
                lateOPMTableData={lateOPMTableData}
                perShareSummaryTableData={perShareSummaryTableData}
                marketAdjustmentValue={marketAdjustmentValue}
                isSingleScenario={isSingleScenario}
                chosenFunding={chosenFunding}
                SAFEValCap={SAFEValCap}
                companyIndustry={companyIndustry}
                SAFEPrincipal={SAFEPrincipal}
                GPCAndBacksolve={GPCAndBacksolve}
                GPCOnly={GPCOnly}
              />
            )}
            fileName={`${companyName}-${userData.transactionId}-409AReport.pdf`}
          >
            {({ blob }) => {
              if (blob) saveToBackendAndS3(blob);
            }}
          </BlobProvider>
        )}
      </>
    );
  }
}

PDFReportViewer.propTypes = {
  userData: PropTypes.object,
  setNumberOfPages: PropTypes.func,
  pageNumber: PropTypes.number,
  setPageNumber: PropTypes.func,
  setDownloadLink: PropTypes.func,
  recreateReport: PropTypes.bool,
  setRecreateReport: PropTypes.func,
};
