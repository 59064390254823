import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import ErrorIcon from '@mui/icons-material/Error';

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';

import { ErrorMessageContext } from '../../lib/contextLib';

import './Demo.scss';
import RequestReceived from './RequestReceived';
import { createAuthHeaders, getUserId, sendHtmlEmail } from '../../utils';
import { userInfoDefaultValues, userInfoFormContent } from '../ClientIntakeForm/formFieldsContents';

export default function Demo() {
  const nav = useNavigate();
  const fieldName = 'userFormField';

  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [submissionReceived, setSubmissionReceived] = useState(false);
  const [contactPreference, setContactPreference] = useState([]);
  const [userInfo, setUserInfo] = useState(userInfoFormContent);
  const [submitType, setSubmitType] = useState('');
  const [currentCompanyId, setCurrentCompanyId] = useState('');
  const [userInput, setUserInput] = useState(userInfoDefaultValues);
  const [inputHasError, setInputHasError] = useState({});
  const [disable, setDisable] = useState(false);

  const handleContactMethod = (e, newMethod) => {
    e.preventDefault();
    setContactPreference(newMethod);
  };

  // function to set state with any state setter sent to it, when dealing with setting an new object
  function setNewState(targetedState, stateSetter, inputName, newValue) {
    const newState = { ...targetedState };
    newState[inputName] = newValue;
    return stateSetter(newState);
  }

  // function to check the specified format of an individual input if there is one
  function onChange(inputName, inputValue, fieldIndex, formatter) {
    const newInputValue = formatter ? formatter(inputValue) : inputValue;
    return setNewState(userInput, setUserInput, inputName, newInputValue, fieldIndex);
  }

  function clearError(inputName, fieldIndex) {
    const newErrorValue = false;
    return setNewState(inputHasError, setInputHasError, inputName, newErrorValue, fieldIndex);
  }

  function pathToName(currentInput, currentField, fieldIndex) {
    if (Array.isArray(currentInput[fieldName])) {
      return currentInput[fieldName][fieldIndex][currentField.name];
    }
    return currentInput[currentField.name];
  }

  function checkForError(inputName, inputValue, fieldIndex, checkFormat) {
    // we run the 'checkFormat' method to check if the input is valid,
    // the new error state is the opposite of what that method returns `!checkFormat()`
    const newErrorValue = !checkFormat(inputValue);
    return setNewState(inputHasError, setInputHasError, inputName, newErrorValue, fieldIndex);
  }

  useEffect(() => {
    // this function may be useful in the utils file
    // this is added to the input field as a format to check if the input is blank
    function ifInputIsBlank(str) {
      return !/^\s*$/.test(str);
    }
    const moddedUserInfoFormContent = [...userInfoFormContent];
    moddedUserInfoFormContent.forEach((inputField) => {
      /* eslint no-param-reassign: "error" */
      inputField.required = true;
      if (!inputField.formatChecker) {
        inputField.formatChecker = ifInputIsBlank;
      }
      inputHasError[inputField.name] = false;
    });
    setUserInfo(moddedUserInfoFormContent);
    async function getUserData() {
      const userId = await getUserId();
      try {
        const userCompanyData = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/companies/${userId}`,
          await createAuthHeaders('get', {}, true),
        );
        const { Body } = await userCompanyData.json();
        setCurrentCompanyId(Body.companyId);
      } catch (e) {
        setShowErrorMessage(e.toString());
      }
    }
    getUserData();
  }, []);

  // this effect is used to check if an input no longer has an error
  // so the "send" button is no longer disabled
  useEffect(() => {
    const errors = Object.values(inputHasError);
    const errorCheck = errors.filter((value) => value === true);
    if (!errorCheck.length) setDisable(false);
  }, [inputHasError]);

  // function to check if there is no errors in the inputs on submit
  function checkForErrorOnSubmit(info, inputFromUser) {
    const newState = { ...inputHasError };
    info.forEach((input) => {
      const newErrorValue = !input.formatChecker(inputFromUser[input.name]);
      newState[input.name] = newErrorValue;
    });
    setInputHasError(newState);
    // if errors exists in any number of the inputs send button is disabled and
    // function returns false
    if (Object.values(newState).filter((value) => value === true).length) {
      setDisable(true);
      return false;
    }
    // if the error check finds no error or errors, the button is no longer disabled and return true
    // to allow the data to be sent to the database.
    setDisable(false);
    return true;
  }

  async function handleSubmit() {
    const userId = await getUserId();
    try {
      userInput.contactPreference = contactPreference;
      userInput.accountId = userId;
      userInput.companyId = currentCompanyId;
      const newUserInput = {
        ...userInput,
      };
      const demoRequest = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/support/demo-request`,
        await createAuthHeaders('post', newUserInput, true),
      );
      const demoRequestParsed = await demoRequest.json();
      const metadata = demoRequestParsed.Body;
      await sendHtmlEmail(
        newUserInput.email,
        'demoRequestSuccess',
        [
          newUserInput.firstName,
        ],
        metadata,
      );
      // submissionReceived is now true to show the success page
      setSubmissionReceived(true);
      // the submitType is passed to the component to specify which success message the user is shown
      setSubmitType('demo');
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  if (!submissionReceived) {
    return (
      <div className="demo-page-container">
        <div className="bread-crumb">
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={(e) => {
              e.preventDefault();
              nav('/');
            }}
          >
            <ArrowBackIcon className="back-icon" />
            <span className="bread-crumb-title">
              Home
            </span>
          </button>
        </div>
        <div>
          <div className="header">
            <p>Request a demo</p>
          </div>
          <div className="main">
            <form className="message-container">
              <FormControl className="message-form">
                <p>
                  Please fill this form out so we can contact you to discuss your valuation needs and schedule a demo.
                </p>
                <Grid container spacing={2} className="input-grid">
                  {userInfo.map((lab, index) => (
                    <Grid key={lab.name} item md="auto" className="inner-input-grid">
                      <TextField
                        className="user-inputs"
                        label={lab.label + (lab.isOptional ? ' (optional)' : '')}
                        name={lab.name}
                        type={lab.type}
                        value={pathToName(userInput, lab, index)}
                        onChange={(e) => {
                          e.preventDefault();
                          checkForError(
                            e.target.name,
                            e.target.value,
                            index,
                            lab.formatChecker,
                          );
                          onChange(e.target.name, e.target.value, index, lab.formatter);
                        }}
                        onFocus={(e) => clearError(e.target.name, index)}
                        onBlur={(e) => {
                          e.preventDefault();
                          checkForError(
                            e.target.name,
                            e.target.value,
                            index,
                            lab.formatChecker,
                          );
                          onChange(e.target.name, e.target.value.trim(), index);
                        }}
                        error={
                          pathToName(inputHasError, lab, index)
                        }
                      />
                      <FormHelperText style={{
                        visibility: pathToName(inputHasError, lab, index) ? 'visible' : 'hidden',
                        marginBottom: 0,
                      }}
                      >
                        <ErrorIcon />
                        {lab.helperText || 'input required'}
                      </FormHelperText>
                    </Grid>
                  ))}
                </Grid>
                <p className="last-paragraph">
                  How would you prefer we contact you? Please select from the options below, feel free to select more than one.
                </p>
                <div className="toggle-btns">
                  <ToggleButtonGroup
                    value={contactPreference}
                    onChange={handleContactMethod}
                    aria-label="Contact method"
                  >
                    <ToggleButton value="email" className={contactPreference.includes('email') ? 'toggle-btn-active' : 'toggle-btn'}>
                      {contactPreference.includes('email') ? (
                        <>
                          Email Me
                          <CheckCircleIcon />
                        </>
                      ) : (
                        <>
                          Email Me
                        </>
                      )}
                    </ToggleButton>
                    <ToggleButton value="call" className={contactPreference.includes('call') ? 'toggle-btn-active' : 'toggle-btn'}>
                      {contactPreference.includes('call') ? (
                        <>
                          Call Me
                          <CheckCircleIcon />
                        </>
                      ) : (
                        <>
                          Call Me
                        </>
                      )}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
              </FormControl>
              <Button
                type="submit"
                className="send-request"
                onClick={(e) => {
                  e.preventDefault();
                  if (checkForErrorOnSubmit(userInfo, userInput)) handleSubmit(userInfo, userInput);
                  else setDisable(true);
                }}
                disabled={disable}
              >
                <SendOutlinedIcon />
                Send
              </Button>
            </form>
            <Box className="info">
              <div className="info-container">
                <ForumOutlinedIcon className="contact-icon" />
                <h2>Other ways to connect</h2>
                <p>
                  Contact us at:
                  <br />
                  (415) 472-9175
                  <br />
                  <br />
                  We&apos;re available:
                  <br />
                  Monday to Friday
                  <br />
                  9am to 5pm PST
                  <br />
                  <br />
                  If we can&apos;t get back to you
                  right away, we&apos;ll get back
                  <br />
                  to you within one
                  <br />
                  business day.
                </p>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
  if (submissionReceived) {
    return <RequestReceived submitType={submitType} />;
  }
}
