import React from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import {
  Button,
} from '@mui/material';
import { ReactComponent as SuccessCheckmark } from '../../images/success_checkmark.svg';
import './RequestReceived.scss';

export default function RequestReceived({ submitType, ticketNumber }) {
  const nav = useNavigate();

  return (
    <div className="recieved-page-container">
      <div className="bread-crumb">
        <button
          type="button"
          className="bread-crumb-btn"
          onClick={(e) => {
            e.preventDefault();
            nav('/');
          }}
        >
          <ArrowBackIcon className="back-icon" />
          <span className="bread-crumb-title">
            Home
          </span>
        </button>
      </div>
      <div>
        <div className="header">
          {submitType === 'support' ? (
            <p>Customer Support</p>
          ) : (
            <p>Request a demo</p>
          )}
          {submitType === 'support' && (
            <Button
              onClick={() => nav('/demo')}
            >
              Request a demo
            </Button>
          )}
        </div>
        <div className="recieved-main">
          <div className={submitType ? `message-container-${submitType}` : `message-container-${submitType}`}>
            {submitType === 'support' && (
              <h1>Sent successfully</h1>
            )}
            <div className="success-checkmark">
              <SuccessCheckmark />
            </div>
            <div className="message-contents">
              {submitType === 'support' ? (
                <p>
                  We&apos;ll get back to you within on business day. Please note
                  <br />
                  your customer support number:
                  {submitType === 'support' ? ` ${ticketNumber.toUpperCase()}` : 'XXXXXXXX'}
                  .
                  <br />
                  <br />
                  We&apos;ve sent you an email with this number. If you call or email
                  <br />
                  customer support abou this issue, please provide them with
                  <br />
                  this number.
                </p>
              ) : (
                <p>
                  You&apos;ve successfully submitted a request for a demo. We&apos;ll
                  <br />
                  contact you soon to descuss your valuation needs and
                  <br />
                  schedule a demo.
                </p>
              )}
            </div>
          </div>
          <Box className={`info-${submitType}`}>
            <div className="info-container">
              {submitType === 'support' ? (
                <ContactPhoneOutlinedIcon className="contact-icon" />
              ) : (
                <ForumOutlinedIcon className="contact-icon" />
              )}
              <h2>Need Help?</h2>
              <p>
                Contact us at:
                <br />
                (415) 472-9175
                <br />
                <br />
                We&apos;re available:
                <br />
                Monday to Friday
                <br />
                9am to 5pm PST
                <br />
                <br />
                If we can&apos;t get back to you
                right away, we&apos;ll get back
                <br />
                to you within one
                <br />
                business day.
              </p>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}

RequestReceived.propTypes = {
  submitType: PropTypes.string,
  ticketNumber: PropTypes.string,
};
