import { assignDefaultValues } from './calcTablesUtils';

import {
  commaEvery3rdChar,
  formatUSCurrency,
  formatUSCurrencyWithFourDecimals,
  concatCharacter,
} from '../../utils';

export function createSharesTable(
  ownershipClass,
  outstanding,
  percentOfTotal,
  conversionRatio,
  fullyDilutedSharesOutstanding,
  percentOfFullyDilutedSharesOutstanding,
) {
  return {
    ownershipClass,
    outstanding,
    percentOfTotal,
    conversionRatio,
    fullyDilutedSharesOutstanding,
    percentOfFullyDilutedSharesOutstanding,
  };
}

export const sharesTableSchema = [
  {
    headTitle: 'Ownership Class',
    name: 'ownershipClass',
    value: '',
    cellWidth: '180px',
    nonEditable: true,
  },
  {
    headTitle: 'Outstanding',
    name: 'outstanding',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    cellWidth: '172px',
    nonEditable: true,
    hasTotal: true,
  },
  {
    headTitle: 'Percent of total',
    name: 'percentOfTotal',
    value: '',
    formatter: (value) => concatCharacter(value, '%'),
    cellWidth: '172px',
    nonEditable: true,
    hasTotal: true,
  },
  {
    headTitle: 'Conversion ratio',
    name: 'conversionRatio',
    value: '',
    formatter: (value) => concatCharacter(value.toFixed(2), 'x'),
    cellWidth: '172px',
    nonEditable: true,
  },
  {
    headTitle: 'Fully diluted shares outstanding',
    name: 'fullyDilutedSharesOutstanding',
    value: '',
    formatter: (value) => commaEvery3rdChar(value.toString()),
    cellWidth: '172px',
    nonEditable: true,
    hasTotal: true,
  },
  {
    headTitle: 'Percent of fully diluted shares outstanding',
    name: 'percentOfFullyDilutedSharesOutstanding',
    value: '',
    formatter: (value) => concatCharacter(value, '%'),
    cellWidth: '172px',
    nonEditable: true,
    hasTotal: true,
  },
];

// TODO Where will we get info and variable names for these tables?

export function createLiquidPreferencesTable(
  ownershipClass,
  liquidationRank,
  liquidationRatio,
  issuePriceStrikePrice,
  liquidationPreference,
  totalLiquidationPreference,
  participationRights,
  participationCap,
  mandatoryDividends,
  dividendsPercentage,
  accruedDividend,
  totalLiquidationAndDividends,
) {
  return {
    ownershipClass,
    liquidationRank,
    liquidationRatio,
    issuePriceStrikePrice,
    liquidationPreference,
    totalLiquidationPreference,
    participationRights,
    participationCap,
    mandatoryDividends,
    dividendsPercentage,
    accruedDividend,
    totalLiquidationAndDividends,
  };
}

export const liquidPreferencesTableSchema = [
  {
    headTitle: 'Ownership class',
    name: 'ownershipClass',
    value: '',
    cellWidth: '180px',
    nonEditable: true,
  },
  {
    headTitle: 'Liquidation rank',
    name: 'liquidationRank',
    value: '',
    cellWidth: '82px',
    nonEditable: true,
  },
  {
    headTitle: 'Liquidation ratio',
    name: 'liquidationRatio',
    value: '',
    formatter: (value) => value && (!value.toFixed(2).toString().includes('x') ? `${value.toFixed(2).toString()}x` : value),
    cellWidth: '82px',
    nonEditable: true,
  },
  {
    headTitle: 'Issue price/ strike price',
    name: 'issuePriceStrikePrice',
    value: '',
    formatter: (value) => formatUSCurrencyWithFourDecimals.format(parseFloat(value.toString().replace(/[^\d.-]/g, ''), 10)),
    cellWidth: '92px',
    nonEditable: true,
  },
  {
    headTitle: 'Liquidation preference ($/share)',
    name: 'liquidationPreference',
    value: '',
    formatter: (value) => formatUSCurrencyWithFourDecimals.format(parseFloat(value.toString().replace(/[^\d.-]/g, ''), 10)),
    cellWidth: '136px',
    nonEditable: true,
  },
  {
    headTitle: 'Total liquidation preference',
    name: 'totalLiquidationPreference',
    value: '',
    formatter: (value) => formatUSCurrency.format(parseFloat(value.toString().replace(/[^\d.-]/g, ''), 10)),
    cellWidth: '120px',
    nonEditable: true,
    hasTotal: true,
  },
  {
    headTitle: 'Participation rights',
    name: 'participationRights',
    value: '',
    cellWidth: '108px',
    nonEditable: true,
  },
  {
    headTitle: 'Participation cap',
    name: 'participationCap',
    value: '',
    cellWidth: '94px',
    nonEditable: true,
  },
  {
    headTitle: 'Mandatory dividends',
    name: 'mandatoryDividends',
    value: '',
    cellWidth: '94px',
    nonEditable: true,
  },
  {
    headTitle: 'Dividends percentage',
    name: 'dividendsPercentage',
    value: '',
    cellWidth: '80px',
    nonEditable: true,
  },
  {
    headTitle: 'Accrued dividend',
    name: 'accruedDividend',
    value: '',
    cellWidth: '100px',
    nonEditable: true,
  },
  {
    headTitle: 'Total liquidation and dividends',
    name: 'totalLiquidationAndDividends',
    value: '',
    formatter: (value) => formatUSCurrency.format(parseFloat(value.toString().replace(/[^\d.-]/g, ''), 10)),
    cellWidth: '134px',
    nonEditable: true,
    hasTotal: true,
  },
];

export const defaultSharesTableData =
  createSharesTable(...assignDefaultValues(sharesTableSchema, 1));
export const defaultLiquidPreferencesTableData =
  createLiquidPreferencesTable(...assignDefaultValues(liquidPreferencesTableSchema, 1));

export default {
  sharesTableSchema,
  createSharesTable,
  defaultSharesTableData,
  liquidPreferencesTableSchema,
  createLiquidPreferencesTable,
  defaultLiquidPreferencesTableData,
};
