import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';

const tableMetaData = [
  {
    dataName: 'Class',
    cellWidth: '164px',
  },
  {
    dataName: 'Outstanding',
    cellWidth: '112px',
  },
  {
    dataName: 'Percent\nof total',
    cellWidth: '72px',
  },
  {
    dataName: 'Conversion\nratio',
    cellWidth: '72px',
  },
  {
    dataName: 'Fully\ndiluted',
    cellWidth: '112px',
  },
  {
    dataName: 'Full Percent\nof total',
    cellWidth: '72px',
  },
  {
    dataName: 'Issue\nprice',
    cellWidth: '112px',
  },
  {
    dataName: 'Exercise\nprice',
    cellWidth: '112px',
  },
  {
    dataName: 'Liquidation\nratio',
    cellWidth: '72px',
  },
  {
    dataName: 'Liquidation\nPreference\n($/share)',
    cellWidth: '112px',
  },
  {
    dataName: 'Total\nLiquidation\nPreference',
    cellWidth: '112px',
  },
];

const dividendTableMetaData = [
  {
    dataName: 'Class',
    cellWidth: '164px',
  },
  {
    dataName: 'Dividend\npercentage',
    cellWidth: '112px',
  },
  {
    dataName: 'Accrued\ndividend',
    cellWidth: '112px',
  },
  {
    dataName: 'Total\nliquidation\n& dividends',
    cellWidth: '112px',
  },
];

export default function CapTableV2({
  capTableData,
  pagination,
  hasDividends,
  numberOfPages,
  pageNumber,
  isLastPage,
  moveTitleToHeader,
}) {
  const addOne = isLastPage ? 0 : 1;
  function rowHeight() {
    if (capTableData.tableData.length >= 9 + addOne && capTableData.tableData.length <= 14 + addOne) return '32px';
    if (capTableData.tableData.length >= 15 + addOne) return '24px';
    return '48px';
  }
  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TablePageHeader
          pageName={`Capitalization Table ${numberOfPages > 1 ? `(page ${pageNumber} of ${numberOfPages})` : ''}`}
          moveTitleToHeader={moveTitleToHeader}
          isCapV2
        />
        <View
          style={{
            marginTop: '26px',
            marginLeft: '16px',
            display: 'flex',
            flexDirection: 'column',
            width: '1192px',
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: rowHeight(),
              backgroundColor: '#EAE9EC',
            }}
          >
            <Text
              style={{
                width: '164px',
              }}
            />
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: '665px',
                marginTop: 'auto',
                marginRight: 'auto',
                paddingLeft: '12px',
                paddingBottom: '2px',
                letterSpacing: '0.5px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              Shares
            </Text>
            <Text
              style={{
                width: '18px',
              }}
            />
            <Text
              style={{
                fontFamily: 'RobotoMed',
                fontSize: '12px',
                color: '#49454F',
                width: '361px',
                marginTop: 'auto',
                marginRight: 'auto',
                paddingBottom: '2px',
                paddingLeft: '12px',
                letterSpacing: '0.5px',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              Liquidation
            </Text>
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              height: capTableData.tableData.length > 8 ? '48px' : '72px',
              backgroundColor: '#F0F0F3',
              borderBottom: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            {tableMetaData.map((tableData, index) => {
              return (
                <React.Fragment key={tableData.dataName}>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      fontSize: '12px',
                      color: '#49454F',
                      width: `${tableData.cellWidth}`,
                      marginTop: 'auto',
                      paddingLeft: index === 0 ? '16px' : '0px',
                      paddingBottom: '2px',
                      letterSpacing: '0.5px',
                      lineHeight: '0.5mm',
                      textAlign: index === 0 ? 'left' : 'center',
                    }}
                  >
                    {tableData.dataName === 'Full Percent\nof total' ? 'Percent\nof total' : tableData.dataName}
                  </Text>
                  {index === 5 && (
                    <View style={{ width: '24px' }} />
                  )}
                </React.Fragment>
              );
            })}
          </View>
          {capTableData.tableData.map((cellData, cellDataIndex) => {
            return (
              <View
                key={cellData.Class}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: rowHeight(),
                  backgroundColor: `${cellDataIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                }}
              >
                {
                  tableMetaData.map((tableData, index) => {
                    return (
                      <React.Fragment key={`${tableData.dataName}&_&_`}>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            color: '#49454F',
                            width: `${tableData.cellWidth}`,
                            paddingLeft: '16px',
                          }}
                        >
                          {cellData[tableData.dataName.replaceAll('\n', ' ')] || 'N/A'}
                        </Text>
                        {index === 5 && (
                          <View style={{ width: '24px' }} />
                        )}
                      </React.Fragment>
                    );
                  })
                }
              </View>
            );
          })}
          {isLastPage && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: `${capTableData.tableData.length % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                borderTop: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '164px',
                  paddingLeft: '16px',
                  letterSpacing: '0.5px',
                }}
              >
                Total
              </Text>
              {
                tableMetaData.map((tableData, index) => {
                  if (index !== 0) {
                    return (
                      <React.Fragment key={`${tableData.dataName}&_&_`}>
                        <Text
                          style={{
                            fontFamily: 'RobotoMed',
                            fontSize: '12px',
                            color: '#49454F',
                            width: `${tableData.cellWidth}`,
                            paddingLeft: '16px',
                            letterSpacing: '0.5px',
                          }}
                        >
                          {capTableData.totalData[tableData.dataName.replaceAll('\n', ' ')] || ''}
                        </Text>
                        {index === 5 && (
                          <View style={{ width: '24px' }} />
                        )}
                      </React.Fragment>
                    );
                  }
                  return null;
                })
              }
            </View>
          )}
        </View>
        <BottomPagination
          pageNumberLeft={pagination[0]}
          pageNumberRight={pagination[1]}
        />
      </Page>
      {hasDividends && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative' }}
        >
          <TablePageHeader
            pageName="Capitalization Table"
            isLongTable={capTableData.tableData.length >= 20 + addOne}
            isCapV2
          />
          <View
            style={{
              marginTop: '26px',
              marginLeft: '16px',
              display: 'flex',
              flexDirection: 'column',
              width: '1192px',
            }}
          >
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: rowHeight(),
                backgroundColor: '#EAE9EC',
              }}
            >
              <Text
                style={{
                  width: '164px',
                }}
              />
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  width: '1044px',
                  marginTop: 'auto',
                  marginRight: 'auto',
                  paddingLeft: '12px',
                  paddingBottom: '2px',
                  letterSpacing: '0.5px',
                  borderBottom: '1px',
                  borderColor: '#CCCCCC',
                }}
              >
                Dividends
              </Text>
            </View>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                height: capTableData.tableData.length > 8 ? '48px' : '72px',
                backgroundColor: '#F0F0F3',
                borderBottom: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              {dividendTableMetaData.map((tableData, index) => {
                return (
                  <Text
                    key={tableData.dataName}
                    style={{
                      fontFamily: 'RobotoMed',
                      fontSize: '12px',
                      color: '#49454F',
                      width: `${tableData.cellWidth}`,
                      marginTop: 'auto',
                      paddingLeft: index === 0 ? '16px' : '0px',
                      paddingBottom: '2px',
                      letterSpacing: '0.5px',
                      lineHeight: '0.5mm',
                      textAlign: index === 0 ? 'left' : 'center',
                    }}
                  >
                    {tableData.dataName}
                  </Text>
                );
              })}
            </View>
            {capTableData.tableData.map((cellData, cellDataIndex) => {
              return (
                <View
                  key={cellData.Class}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: rowHeight(),
                    backgroundColor: `${cellDataIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  }}
                >
                  {
                    dividendTableMetaData.map((tableData) => {
                      return (
                        <Text
                          key={`${tableData.dataName}&_&_`}
                          style={{
                            fontFamily: 'Roboto',
                            fontSize: '12px',
                            color: '#49454F',
                            width: `${tableData.cellWidth}`,
                            paddingLeft: '16px',
                          }}
                        >
                          {cellData[tableData.dataName.replaceAll('\n', ' ')] || 'N/A'}
                        </Text>
                      );
                    })
                  }
                </View>
              );
            })}
            {isLastPage && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  height: rowHeight(),
                  backgroundColor: `${capTableData.tableData.length % 2 ? '#F0F0F3' : '#FAFAFD'}`,
                  borderTop: '1px',
                  borderColor: '#CCCCCC',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    fontSize: '12px',
                    color: '#49454F',
                    width: '164px',
                    paddingLeft: '16px',
                    letterSpacing: '0.5px',
                  }}
                >
                  Total
                </Text>
                {
                  dividendTableMetaData.map((tableData, index) => {
                    if (index !== 0) {
                      return (
                        <Text
                          key={`${tableData.dataName}&_&_`}
                          style={{
                            fontFamily: 'RobotoMed',
                            fontSize: '12px',
                            color: '#49454F',
                            width: `${tableData.cellWidth}`,
                            paddingLeft: '16px',
                            letterSpacing: '0.5px',
                          }}
                        >
                          {capTableData.totalData[tableData.dataName.replaceAll('\n', ' ')] || ''}
                        </Text>
                      );
                    }
                    return null;
                  })
                }
              </View>
            )}
          </View>
          <BottomPagination
            pageNumberLeft={pagination[2]}
            pageNumberRight={pagination[3]}
          />
        </Page>
      )}
    </>
  );
}

CapTableV2.propTypes = {
  capTableData: PropTypes.object,
  pagination: PropTypes.array,
  hasDividends: PropTypes.bool,
  numberOfPages: PropTypes.number,
  pageNumber: PropTypes.number,
  isLastPage: PropTypes.bool,
  moveTitleToHeader: PropTypes.bool,
};
