import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';

import TopHeader from '../components/TopHeader';
import LightBlueGradient from '../components/LightBlueGradient';
import BottomPagination from '../components/BottomPagination';

import rainierVis from '../assets/images/rainier-report-vis.jpg';
import davePortrait from '../assets/images/dave.jpg';
import halfSeparatorLine from '../assets/images/half-line.png';
import horizontalInitioLogo from '../assets/images/horizontal-initio-logo.png';
import fairValueTexture from '../assets/images/fair-value-texture.png';


export default function Introduction({
  companyName,
  valuationDate,
  sandboxData,
}) {
  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '50%',
            borderRight: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          <Image
            src={rainierVis}
            style={{
              height: '100%',
            }}
          />
        </View>
        <View
          style={{
            width: '50%',
          }}
        >
          <TopHeader
            leftTitle="Purpose and Scope"
          />
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '24px',
              marginTop: '140px',
              marginLeft: '48px',
              color: '#49454F',
            }}
          >
            {companyName}
          </Text>
          <View
            style={{
              marginTop: '16px',
              marginLeft: '48px',
              width: '516px',
            }}
          >
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#49454F',
                fontSize: '14px',
                lineHeight: '0.57mm',
                letterSpacing: '0.25px',
              }}
            >
              {`INITIO SOFTWARE INC. (“initio”) has been engaged by \
${companyName}, (“${companyName}” or the ”Company”) for the purpose of estimating the fair \
market value per common share of ${companyName}. The assumptions and conclusions \
of our analysis are detailed in the report (“Report”) below. \
Where appropriate and applicable, the analysis considers differences \
in share class liquidation preferences, conversion rights, voting rights, \
and other features. The Report is based upon the information \
provided to initio by the Company. There may exist matters of a legal or \
financial nature having a bearing on the Company's financial \
condition with respect to which initio has not been consulted. \
The information set forth herein is as of ${valuationDate}. \
Except as otherwise noted, initio assumes no obligation to \
advise the Company of changes which may subsequently be brought to our attention.`}
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#49454F',
                fontSize: '14px',
                lineHeight: '0.57mm',
                letterSpacing: '0.25px',
                marginTop: '20px',
              }}
            >
              This letter is furnished solely for your information in connection
              with the Report contemplated herein and is not to be quoted in whole or
              in part or otherwise referred to any governmental agency or other persons,
              without the prior written consent of this firm.
            </Text>
            <Text
              style={{
                fontFamily: 'Roboto',
                color: '#49454F',
                fontSize: '14px',
                lineHeight: '0.57mm',
                letterSpacing: '0.25px',
                marginTop: '20px',
              }}
            >
              It is our understanding that
              {' '}
              {companyName}
              {' '}
              intends to grant stock-based compensation in the form of options on its common stock and,
              in connection with these activities, has sought our advice to assist with its financial
              and tax reporting requirements in connection therewith. The purpose of this engagement is to assist
              {' '}
              {companyName}
              {' '}
              management (“Management”) with estimating the fair market value
              per share of common stock, which may be used in granting certain common stock options.
            </Text>
          </View>
        </View>
        <View
          style={{
            position: 'absolute',
            bottom: '0px',
            height: '36px',
            width: '100%',
            paddingLeft: '32px',
            paddingRight: '32px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Image
            src={horizontalInitioLogo}
            style={{
              width: '110px',
              height: '16px',
              marginLeft: 'auto',
              marginRight: '14px',
            }}
          />
          <Text
            style={{
              fontFamily: 'Roboto',
              fontSize: '12px',
              color: '#79747E',
            }}
          >
            4
          </Text>
        </View>
        <Image
          src={halfSeparatorLine}
          style={{
            position: 'absolute',
            width: '50%',
            right: '0px',
            bottom: '34px',
          }}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader
          leftTitle="Introduction"
          rightTitle="Fair Value Statement"
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginLeft: '96px',
                marginTop: '160px',
              }}
            >
              <Image
                src={davePortrait}
                style={{
                  width: '200px',
                  height: '200px',
                }}
              />
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '24px',
                  color: '#49454F',
                  marginTop: '16px',
                  lineHeight: '0.46mm',
                }}
              >
                David Pezeshki
                {'\n'}
                <Text
                  style={{
                    fontSize: '16px',
                  }}
                >
                  CFA, CPA
                  <Text
                    style={{
                      lineHeight: '0.48mm',
                    }}
                  >
                    {'\n'}
                    Founder & CEO
                  </Text>
                </Text>
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  color: '#49454F',
                  width: '421px,',
                  marginTop: '24px',
                  lineHeight: '0.55mm',
                }}
              >
                Thank you for choosing initio as your trusted valuation provider.
                We created this platform for early-stage companies as early-stage founders ourselves.
                With decades of valuation experience, we are committed to providing you with a quality
                product that meets your expectations and needs as if they were our own. We look forward
                to watching your company grow and assisting with any valuation needs along the way.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  color: '#49454F',
                  marginTop: '20px',
                }}
              >
                Dave
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
            }}
          >
            <View
              style={{
                height: '100%',
              }}
            >
              <LightBlueGradient
                width="612px"
                height="690px"
                customPosition={{
                  bottom: '0px',
                  right: '0px',
                }}
              />
              <Image
                src={fairValueTexture}
                style={{
                  position: 'absolute',
                  bottom: '0',
                  right: '0',
                  width: '100%',
                }}
              />
              <View
                style={{
                  marginLeft: '80px',
                  marginTop: '192px',
                  width: '452px',
                  height: '298px',
                  backgroundColor: '#FAFAFD',
                  borderRadius: '24px',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: '23px',
                    color: '#49454F',
                    paddingTop: '32px',
                    paddingLeft: '56px',
                    paddingRight: '56px',
                    lineHeight: '0.64mm',
                  }}
                >
                  It is our opinion that the Fair Value of the common equity of the Company,
                  on a minority, non-marketable basis, as of
                  {' '}
                  {valuationDate}
                  {' '}
                  is reasonably estimated to be:
                </Text>
              </View>
              <View
                style={{
                  marginLeft: '80px',
                  marginTop: '10px',
                  width: '452px',
                  height: '154px',
                  backgroundColor: '#42A179',
                  borderRadius: '24px',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'ManropeLight',
                    fontSize: '64px',
                    color: '#FFFFFF',
                  }}
                >
                  {`$${sandboxData.perSharePrice}`}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Manrope',
                    fontSize: '32px',
                    color: '#FFFFFF',
                  }}
                >
                  per share
                </Text>
              </View>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={5}
          pageNumberRight={6}
        />
      </Page>
    </>
  );
}


Introduction.propTypes = {
  companyName: PropTypes.string,
  valuationDate: PropTypes.string,
  sandboxData: PropTypes.object,
};
