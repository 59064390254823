import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// MUI
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
// This project
import { industryVerticalsAlphabetizedAndSorted } from '../../utils/IndustryVerticals';

import ToolTip from './ToolTip';
import PanelButtons from './PanelButtons';
import './Wiz1.scss';

export default function Wiz1({
  currentStep,
  setWhenIsExitLikelyToHappenParent,
  setWhatTypeOfExitParent,
  setIndustryVerticalParent,
  whenIsExitLikelyToHappenParent,
  whatTypeOfExitParent,
  industryVerticalParent,
}) {
  const [whenIsExitLikelyToHappen, setWhenIsExitLikelyToHappen] = useState('');
  const [whatTypeOfExit, setWhatTypeOfExit] = useState('');
  const [industryVertical, setIndustryVertical] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setWhenIsExitLikelyToHappen(whenIsExitLikelyToHappenParent);
    setWhatTypeOfExit(whatTypeOfExitParent);
    setIndustryVertical(industryVerticalParent);
  }, []);

  useEffect(() => {
    setWhenIsExitLikelyToHappenParent(whenIsExitLikelyToHappen);
  }, [whenIsExitLikelyToHappen]);

  useEffect(() => {
    setWhatTypeOfExitParent(whatTypeOfExit);
  }, [whatTypeOfExit]);

  useEffect(() => {
    setIndustryVerticalParent(industryVertical);
  }, [industryVertical]);

  if (currentStep === 1) {
    return (
      <div className="Wiz1 step-1">
        <h4>Industry</h4>
        <Autocomplete
          disablePortal
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
          onOpen={() => {
            setIsOpen(true);
          }}
          options={industryVerticalsAlphabetizedAndSorted}
          groupBy={(option) => option.category}
          getOptionLabel={(option) => option.title}
          inputValue={industryVertical ? industryVertical.title : ''}
          onInputChange={(e, value) => {
            if (e) {
              e.preventDefault();

              if (!value) {
                setIndustryVertical(null);
                return;
              }

              const newValue = industryVerticalsAlphabetizedAndSorted
                .find((val) => val.title === value);

              setIndustryVertical(newValue || { title: value });
            }
          }}
          renderInput={(params) => <TextField {...params} label="Company" />}
        />
        <ToolTip
          content={(
            <p>
              <a
                href="https://www.bls.gov/iag/tgs/iag_index_alpha.htm"
                target="_blank"
                rel="noreferrer"
              >
                Click here
              </a>
              {' '}
              to open a new tab with a complete list of company industries.
            </p>
          )}
        />
      </div>
    );
  }
  if (currentStep === 2) {
    const exitOptions = [
      'Merger and acquisition (M&A)',
      'Initial public offering (IPO)',
      'Don\'t know',
      'Other',
    ];

    return (
      <div className="Wiz1 step-2">
        <h4>What type of exit event do you think is most likely?</h4>
        <p>
          We know you can&apos;t predict the future, so provide
          information based on what you think is most likely, and
          we&apos;ll take care of the rest.
        </p>
        <PanelButtons
          key="what-type-of-exit"
          parentSetter={setWhatTypeOfExit}
          options={exitOptions}
          parentSelectedOption={whatTypeOfExit}
        />
      </div>
    );
  }
  if (currentStep === 3) {
    const exitTimeOptions = [
      'Less than 1 year',
      '1 year',
      '2 years',
      '3 years',
      '4 years',
      '5 years or more',
    ];

    return (
      <div className="Wiz1 step-3">
        <h4>When is this exit event most likely to happen?</h4>
        <p>
          Provide your best guess as to when the exit event is most likely to occur.
          If it is equal or greater than 5 years, please select 5 years or more.
        </p>
        <PanelButtons
          key="when-is-the-exit-likely-to-happen"
          parentSetter={setWhenIsExitLikelyToHappen}
          options={exitTimeOptions}
          leftJustified={false}
          parentSelectedOption={whenIsExitLikelyToHappen}
        />
      </div>
    );
  }

  return (
    <div>
      <h3>Not a valid step for the 1st 409a Wizard</h3>
    </div>
  );
}

Wiz1.propTypes = {
  currentStep: PropTypes.number.isRequired,
  setWhenIsExitLikelyToHappenParent: PropTypes.func.isRequired,
  setWhatTypeOfExitParent: PropTypes.func.isRequired,
  setIndustryVerticalParent: PropTypes.func.isRequired,
  whenIsExitLikelyToHappenParent: PropTypes.string.isRequired,
  whatTypeOfExitParent: PropTypes.string.isRequired,
  industryVerticalParent: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
};
