import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CircleIcon from '@mui/icons-material/Circle';
import { passwordRequirements, passwordStrengthLabels } from '../../utils/globals';
import './GenPasswordReqList.scss';

export default function GenPasswordReqList({
  setIsPasswordValidHandler,
  isParentPasswordValid,
  password,
  containerStyles = {},
}) {
  const [isTheLocalPasswordValid, setIsTheLocalPasswordValid] = useState(false);
  let doesPasswordPassTests = true;
  let numberOfRequirementsThePasswordPasses = 0;

  useEffect(() => {
    if (isTheLocalPasswordValid !== isParentPasswordValid) {
      setIsPasswordValidHandler(isTheLocalPasswordValid);
    }
  }, [isParentPasswordValid, setIsPasswordValidHandler, isTheLocalPasswordValid]);

  const passwordRequirementsList = passwordRequirements.map(({ text, reg }, i) => {
    const bulletStyles = {
      fontSize: '8px',
    };
    const textStyles = {
      display: 'block',
    };
    const enteredPasswordPasses = reg(password);

    if (enteredPasswordPasses) numberOfRequirementsThePasswordPasses += 1;

    if (!enteredPasswordPasses) {
      bulletStyles.color = '#D9D9D9';
      textStyles.color = '#8B868F';
      if (doesPasswordPassTests) doesPasswordPassTests = false;
    } else {
      bulletStyles.color = '#4295F3';
    }

    const isFinalIteration = (i === (passwordRequirements.length - 1));
    const newIsPassValid = doesPasswordPassTests ? enteredPasswordPasses : false;
    if (isFinalIteration && (isTheLocalPasswordValid !== newIsPassValid)) {
      setIsTheLocalPasswordValid(newIsPassValid);
    }

    return (
      <div
        key={text.replaceAll(' ', '-')}
        className="bulleted-pass-requirement-wrapper"
      >
        <CircleIcon style={bulletStyles} />
        <span style={textStyles}>
          {text}
        </span>
      </div>
    );
  });

  const halfLength = Math.ceil(passwordRequirementsList.length / 2);
  const firstHalfOfPasswordRequirements = passwordRequirementsList.slice(0, halfLength);
  const secondHalfOfPasswordRequirements = passwordRequirementsList.slice(halfLength);

  function passwordStrengthIndicator() {
    const numerator = passwordStrengthLabels.length * numberOfRequirementsThePasswordPasses;
    const ratioOfPassReq = Math.round(numerator / passwordRequirements.length);
    const ratioOfPassReqIndex = (ratioOfPassReq === 0) ? ratioOfPassReq : (ratioOfPassReq - 1);
    const {
      color: indicatorColor,
      title: indicatorTitle,
    } = passwordStrengthLabels[ratioOfPassReqIndex];

    return (
      <div className="PasswordStrengthIndicator">
        <div className="indicator-container">
          {
            passwordStrengthLabels.map(({ title }, i) => {
              const defaultColor = '#C7CBDC';
              const backgroundColor = (
                (i <= ratioOfPassReqIndex) ?
                  (
                    password.trim().length ?
                      indicatorColor :
                      defaultColor
                  ) :
                  defaultColor
              );

              const customStyles = {};
              if (i === 0) {
                customStyles.borderTopLeftRadius = '5px';
                customStyles.borderBottomLeftRadius = '5px';
              } else if (i === (passwordStrengthLabels.length - 1)) {
                customStyles.borderTopRightRadius = '5px';
                customStyles.borderBottomRightRadius = '5px';
              }

              return (
                <div
                  key={title}
                  style={{
                    ...customStyles,
                    width: '24%',
                    height: '8px',
                    backgroundColor,
                  }}
                />
              );
            })
          }
        </div>
        <div className="password-strength-label-container">
          <span
            style={{ color: indicatorColor }}
            className={password.trim().length ? '' : 'hidden-label'}
          >
            {indicatorTitle}
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="GenPasswordReqList" style={containerStyles}>
      {passwordStrengthIndicator()}
      <div className="requirements-container">
        <div>
          {firstHalfOfPasswordRequirements}
        </div>
        <div>
          {secondHalfOfPasswordRequirements}
        </div>
      </div>
    </div>
  );
}

GenPasswordReqList.propTypes = {
  setIsPasswordValidHandler: PropTypes.func.isRequired,
  password: PropTypes.string.isRequired,
  isParentPasswordValid: PropTypes.bool.isRequired,
  containerStyles: PropTypes.object,
};
