import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment/moment';
import { Auth } from 'aws-amplify';

import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';


// import UndoIcon from '@mui/icons-material/Undo';
// import RedoIcon from '@mui/icons-material/Redo';
// import ZoomInIcon from '@mui/icons-material/ZoomIn';
// import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
// import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SubjectIcon from '@mui/icons-material/Subject';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StorefrontIcon from '@mui/icons-material/Storefront';
import NotificationImportantOutlinedIcon from '@mui/icons-material/NotificationImportantOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import { ErrorMessageContext } from '../../lib/contextLib';

import StatusChip from './StatusChip';
import TabsContent from './TabsContent';
import AccountInfo from './AccountInfo';

import {
  defaultPreferredTableData,
  defaultSafeConvertibleTableData,
  defaultCommonTableData,
  defaultOptionsTableData,
  defaultWarrantsTableData,
  defaultRemainingOptionsPoolTableData,
  preferredTableSchema,
  safeConvertibleTableSchema,
  commonTableSchema,
  optionsTableSchema,
  warrantsTableSchema,
} from './tab1TableData';

import {
  defaultOpmInputsTableData,
  opmInputsTableSchema,
  defaultOpmInputsTableData2,
  opmInputsTableSchema2,
} from './tab2TableData';

import {
  defaultSharesTableData,
  defaultLiquidPreferencesTableData,
} from './tab3TableData';


import { allocateDBData, checkForErrorOnTable, convertToDBData } from './calcTablesUtils';

import {
  copy,
  concatCharacter,
  removeCommas,
  getUserId,
  createAuthHeaders,
} from '../../utils';

import './Tabs.scss';

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

export default function Tabs({
  tabToView,
  setTabToView,
  companyToViewData,
  setCompanyToViewData,
  updateAdminProgress,
  updatePriority,
  viewAccountInfo,
  setViewAccountInfo,
  currentAdminName,
  updateModifiedBy,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  // Tab1 Data
  const [preferredTableData, setPreferredTableData] =
    useState([defaultPreferredTableData]);
  const [safeConvertibleTableData, setSafeConvertibleTableData] =
    useState([defaultSafeConvertibleTableData]);
  const [commonTableData, setCommonTableData] =
    useState([defaultCommonTableData]);
  const [optionsTableData, setOptionsTableData] =
    useState([defaultOptionsTableData]);
  const [warrantsTableData, setWarrantsTableData] =
    useState([defaultWarrantsTableData]);
  const [remainingOptionsPoolTableData, setRemainingOptionsPoolTableData] =
    useState([defaultRemainingOptionsPoolTableData]);

  const [tab1DBData, setTab1DBData] = useState(null);

  // Tab2 Data
  const [opmInputsTableData, setOpmInputsTableData] = useState([defaultOpmInputsTableData]);
  const [opmInputsTableData2, setOpmInputsTableData2] = useState([defaultOpmInputsTableData2]);

  const [tab2DBData, setTab2DBData] = useState(null);

  // Tab 3 Data
  const [sharesTableData, setSharesTableData] = useState([defaultSharesTableData]);
  const [liquidPreferencesTableData, setLiquidPreferencesTableData] =
    useState([defaultLiquidPreferencesTableData]);

  // Tab 4 Data
  const [priceSandbox, setPriceSandbox] = useState(null);

  // Fixed Data
  const [metaData, setMetaData] = useState({});
  const [calcRequestData, setCalcRequestData] = useState({});
  const [ompFixedValues, setOpmFixedValues] = useState({});

  // For handling loading states
  const [DBDataIsLoading, setDBDataIsLoading] = useState(false);
  const [dataIsSaving, setDataIsSaving] = useState(false);
  const [calcTableHasError, setCalcTableHasError] = useState(false);

  // Calculation Flow
  const [showCalcConfirmDialog, setShowCalcConfirmDialog] = useState(false);
  const [showReCalcConfirmDialog, setShowReCalcConfirmDialog] = useState(false);
  const [calculationIsInProgress, setCalculationIsInProgress] = useState(false);
  const [showCalculationSuccess, setShowCalculationSuccess] = useState(false);
  const [showCalculationFailure, setShowCalculationFailure] = useState(false);
  const [calcFailureErrorMessage, setCalcFailureErrorMessage] = useState(false);

  // PDF Report
  const [numberOfPages, setNumberOfPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [downloadLink, setDownloadLink] = useState(false);
  const [recreateReport, setRecreateReport] = useState(false);

  // Snackbars
  const [showSendReportToClientSnack, setShowSendReportToClientSnack] = useState(false);
  const [reportEmailIsSending, setReportEmailIsSending] = useState(false);
  const [reportEmailHasBeenSent, setReportEmailHasBeenSent] = useState(false);

  const [showSendSandboxToClientSnack, setShowSendSandboxToClientSnack] = useState(false);
  const [sandboxEmailIsSending, setSandboxEmailIsSending] = useState(false);
  const [sandboxEmailHasBeenSent, setSandboxEmailHasBeenSent] = useState(false);

  const sendReportEmailTimerRef = useRef(null);
  const sendSandboxEmailTimerRef = useRef(null);

  const [priorityDropdownIsOpen, setPriorityDropdownIsOpen] = useState(false);
  const [priority, setPriority] = useState(companyToViewData.companyData.priority || 2);

  // Error Handling for Preferred and Safe Convertible
  const [inputsHaveError, setInputsHaveError] = useState([{}]);
  const [safeInputsHaveError, setSafeInputsHaveError] = useState([{}]);

  const [calcDataHasBeenFetched, setCalcDataHasBeenFetched] = useState(false);

  const [changeHasBeenMade, setChangeHasBeenMade] = useState(false);

  const nav = useNavigate();

  async function getDBTableData(hideLoadingModal) {
    if (!hideLoadingModal) setDBDataIsLoading(true);
    try {
      const userId = await getUserId();
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      const endpointData = `${companyToViewData.transactionData?.companyId}&${companyToViewData.transactionData?.transactionId}&${userId}`;
      const DBData = await fetch(
        `${backendURL}/calculations/get-calc-info/${endpointData}`,
        await createAuthHeaders('get', {}, true),
      );
      const tableDataParsed = await DBData.json();
      const { Body } = tableDataParsed;
      setMetaData({
        companyId: companyToViewData.transactionData?.companyId,
        transactionId: companyToViewData.transactionData?.transactionId,
        insertUserId: userId,
        isReviewed: Body.isReviewed,
        progress: Body.progress,
        calcObjectId: Body.calcObjectId,
        reviewerUserId: Body.reviewerUserId,
        companyName: companyToViewData.accountData.companyName,
        transactionDate: companyToViewData.transactionData?.transactionDate,
        chosenFunding: companyToViewData.transactionData?.chosenFunding,
        companyIndustry: companyToViewData.transactionData?.industryVertical,
      });
      setCalcRequestData(Body.calcRequest);
      setTab1DBData({
        preferred: Body.preferred,
        safeConvertible: Body.safeConvertible,
        common: Body.common,
        option: Body.option,
        warrant: Body.warrant,
        remainingOptionsPool: Body.remainingOptionsPool,
      });
      setTab2DBData(Body.opmInputs);
      setOpmFixedValues({
        earlyVolatilityAnnualEstimate: Body.opmInputs.earlyVolatilityAnnualEstimate,
        earlyVolatilityLowerAnnualEstimate: Body.opmInputs.earlyVolatilityLowerAnnualEstimate,
        lateVolatilityMeanAnnualEstimate: Body.opmInputs.lateVolatilityMeanAnnualEstimate,
      });
      setSandboxEmailHasBeenSent(companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
        companyToViewData.transactionData?.adminProgress === 'review report' ||
        companyToViewData.transactionData?.adminProgress === 'completed');
      setReportEmailHasBeenSent(companyToViewData.transactionData?.adminProgress === 'completed');
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setDBDataIsLoading(false);
      setCalcDataHasBeenFetched(true);
    }
  }

  async function getCalcErrorMessage() {
    const userId = await getUserId();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const endpointData = `${companyToViewData.transactionData?.companyId}&${companyToViewData.transactionData?.transactionId}&${userId}`;
    const calcData = await fetch(
      `${backendURL}/calculations/check-calc-engine-status/${endpointData}`,
      await createAuthHeaders('get', {}, true),
    );
    const calcResponse = await calcData.json();
    setCalcFailureErrorMessage(calcResponse.Message);
  }

  useEffect(() => {
    if (companyToViewData.transactionData?.isCalculating && !calculationIsInProgress && !showCalculationFailure && !showCalculationSuccess) {
      setCalculationIsInProgress(true);
      getDBTableData(true);
    } else if (companyToViewData.transactionData?.adminProgress === 'calculation completed') {
      setCalculationIsInProgress(false);
      setShowCalculationSuccess(true);
      getDBTableData(true);
    } else if (companyToViewData.transactionData?.adminProgress === 'calculation failed') {
      getCalcErrorMessage();
      setCalculationIsInProgress(false);
      setShowCalculationFailure(true);
      getDBTableData(true);
    } else if (companyToViewData.transactionData?.progress === 'completed' && !viewAccountInfo && !calcDataHasBeenFetched) {
      getDBTableData();
    } else if (!companyToViewData.paymentData) {
      setViewAccountInfo(true);
    }
  }, [viewAccountInfo, companyToViewData.transactionData?.adminProgress]);

  useEffect(() => {
    if (tabToView !== 5) setNumberOfPages(null);
  }, [tabToView]);

  useEffect(() => {
    nav(`/?tabToView=${tabToView}&cId=${companyToViewData.companyData.companyId}`);
  }, [tabToView, companyToViewData]);

  // This formats the data from the DB to the front-end format
  useEffect(() => {
    function assignRemainingOptionsPoolValues() {
      const newArray = [];
      const newObject = copy(defaultRemainingOptionsPoolTableData);
      newArray.push({
        ...newObject,
        available: {
          ...newObject.available,
          value: tab1DBData.remainingOptionsPool || '',
        },
        ntmOptionPercent: {
          ...newObject.ntmOptionPercent,
          value: concatCharacter(calcRequestData.ntmOptionsPerc || '', '%'), // TODO Check this
        },
      });
      return newArray;
    }
    if (tab1DBData) {
      setPreferredTableData(tab1DBData.preferred ?
        allocateDBData(tab1DBData.preferred, preferredTableSchema, defaultPreferredTableData) :
        [defaultPreferredTableData]);
      setSafeConvertibleTableData(tab1DBData.safeConvertible ?
        allocateDBData(
          tab1DBData.safeConvertible,
          safeConvertibleTableSchema,
          defaultSafeConvertibleTableData,
        ) : [defaultSafeConvertibleTableData]);
      setCommonTableData(tab1DBData.common ?
        allocateDBData(tab1DBData.common, commonTableSchema, defaultCommonTableData, 'common') :
        [defaultCommonTableData]);

      setOptionsTableData(tab1DBData.option ?
        allocateDBData(
          tab1DBData.option,
          optionsTableSchema,
          defaultOptionsTableData,
          'options',
        ) :
        [defaultOptionsTableData]);
      setWarrantsTableData(tab1DBData.warrant ?
        allocateDBData(
          tab1DBData.warrant,
          warrantsTableSchema,
          defaultWarrantsTableData,
          'warrants',
        ) :
        [defaultWarrantsTableData]);
      setRemainingOptionsPoolTableData(
        tab1DBData.remainingOptionsPool || calcRequestData.ntmOptionsPerc ?
          assignRemainingOptionsPoolValues() : [defaultRemainingOptionsPoolTableData],
      );
    }
  }, [tab1DBData]);

  useEffect(() => {
    function assignOpmInputsDBValues(DBData, tableSchema, defaultTableValues) {
      const newData = [];
      const newTableValues = copy(defaultTableValues);
      tableSchema.forEach((data, dataIndex) => {
        if (dataIndex === 0) {
          newTableValues[data.name].rowID = `${data.name}+-+-${0}`;
        }
        newTableValues[data.name].value =
          (DBData[data.name] && (data.formatter ?
            data.formatter(DBData[data.name]) : DBData[data.name])) ||
          defaultTableValues[data.name].value;
      });
      newData.push(newTableValues);
      return newData;
    }
    if (tab2DBData) {
      setOpmInputsTableData(
        assignOpmInputsDBValues(tab2DBData, opmInputsTableSchema, defaultOpmInputsTableData),
      );
      setOpmInputsTableData2(
        assignOpmInputsDBValues(tab2DBData, opmInputsTableSchema2, defaultOpmInputsTableData2),
      );
    }
  }, [tab2DBData]);

  // , 'Cap table' implemented later
  const tabsLabels = ['409A basic inputs', 'OPM inputs', '409A Sandbox', '409A Price Sandbox', '409A Report', 'Client inputs'];

  async function saveDataToDB(newDBData, progress) {
    if ((!companyToViewData.transactionData?.adminProgress ||
      companyToViewData.transactionData?.adminProgress === 'not started' ||
      companyToViewData.transactionData?.adminProgress === 'in progress' ||
      companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
      companyToViewData.transactionData?.adminProgress === 'review sandbox')) {
      try {
        const currentSessionUser = await Auth.currentSession();
        const isAuthorized = currentSessionUser.getIdToken().payload.email_verified;
        const newTableReqOptions = await createAuthHeaders('post', { ...newDBData, adminName: currentAdminName }, isAuthorized);
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/calculations/submit-calculation`,
          newTableReqOptions,
        );
      } catch (e) {
        setShowErrorMessage(e.toString());
      } finally {
        setDataIsSaving(false);
        if (progress !== 'ready for calculation') updateModifiedBy(companyToViewData, progress);
      }
    }
  }

  function convertAllocatedDataToDBData(progress) {
    setDataIsSaving(true);
    metaData.progress = progress;
    setMetaData(metaData);
    if (companyToViewData.transactionData?.adminProgress !== progress) {
      updateAdminProgress(companyToViewData, progress);
    }
    // Format Data to DB format
    let commonData = null;
    commonTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (row.outstanding.value.length !== 0) {
        title = `${row.common.value ? row.common.value : ''}`;
        values = {
          common: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          issuePrice: null,
          participationRights: null,
          prior: null,
        };
        if (!commonData) commonData = {};
        commonData = {
          ...commonData,
          [title]: values,
        };
      }
    });

    // TODO  possibly have them be all in the one function in utils with different arguments
    let optionsData = null;
    optionsTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (
        (
          (row.outstanding.value.length !== 0) &&
          (row.strikePrice.value.length !== 0)
        ) ||
        (row.options.value.length > 0)
      ) {
        title = `${row.options.value ? row.options.value : ''} Option @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.options.type ? row.options.type : null,
          preferredClass: row.options.value ? row.options.value : null,
        };
        if (!optionsData) optionsData = {};
        optionsData = {
          ...optionsData,
          [title.trim()]: values,
        };
      }
    });
    let warrantsData = null;
    warrantsTableData.forEach((row) => {
      let title = '';
      let values = {};
      if (
        (
          (row.outstanding.value.length !== 0) &&
          (row.strikePrice.value.length !== 0)
        ) ||
        (row.warrants.value.length > 0)
      ) {
        title = `${row.warrants.value ? row.warrants.value : ''} Warrant @ ${row.strikePrice.value}`;
        values = {
          quantity: row.outstanding.value ? removeCommas(row.outstanding.value.toString()) : null,
          strikePrice: row.strikePrice.value ? parseFloat(row.strikePrice.value.replace(/[$,]/g, '')).toString() : null,
          type: row.warrants.type ? row.warrants.type : null,
          preferredClass: row.warrants.value ? row.warrants.value : null,
        };
        if (!warrantsData) warrantsData = {};
        warrantsData = {
          ...warrantsData,
          [title.trim()]: values,
        };
      }
    });
    const { available } = remainingOptionsPoolTableData[0];
    function convertOPMTableToDBData(tableData) {
      let valuesToSave;
      tableData.forEach((row) => {
        Object.keys(row).forEach((key) => {
          valuesToSave = {
            ...valuesToSave,
            [row[key].name]: (row[key].value &&
              (row[key].dbFormat ? row[key].dbFormat(row[key].value) :
                row[key].value)) || null,
          };
        });
      });
      return valuesToSave;
    }
    const preferred = convertToDBData(preferredTableData);
    const safeConvertible = convertToDBData(safeConvertibleTableData);
    const preferredErrorCheck = checkForErrorOnTable(preferredTableData);
    const safeErrorCheck = checkForErrorOnTable(safeConvertibleTableData);
    if (safeErrorCheck.tableErrors.length) setSafeInputsHaveError(safeErrorCheck.tableErrors);
    if (preferredErrorCheck.tableErrors.length) setInputsHaveError(preferredErrorCheck.tableErrors);
    const newTableData = {
      ...metaData,
      calcRequest: calcRequestData,
      opmInputs: {
        ...convertOPMTableToDBData(opmInputsTableData),
        ...convertOPMTableToDBData(opmInputsTableData2),
        ...ompFixedValues,
      },
      preferred,
      safeConvertible,
      common: commonData,
      option: optionsData,
      warrant: warrantsData,
      remainingOptionsPool: available.value,
    };
    if (
      safeErrorCheck.tableErrors.every((row) => !Object.keys(row).length) &&
      preferredErrorCheck.tableErrors.every((row) => !Object.keys(row).length) &&
      !calcTableHasError
    ) {
      if (changeHasBeenMade || progress === 'ready for calculation') saveDataToDB(newTableData, progress);
      else setDataIsSaving(false);
    } else setDataIsSaving(false);
  }

  async function sendReportToClient(status) {
    setReportEmailIsSending(true);
    async function sendReportEmail() {
      const userId = await getUserId();
      try {
        const emailData = {
          recipient: companyToViewData.accountData.email,
          templateName: 'reportReady',
          templateData: [companyToViewData.accountData.firstName, companyToViewData.accountData.companyName],
          attachmentName: `${companyToViewData.accountData.companyName}-409A_report.pdf`,
          attachmentLink: downloadLink,
        };
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/email/send-html-email-with-attachment`,
          await createAuthHeaders('post', emailData, true),
        );
        const reportData = {
          companyId: companyToViewData.accountData.companyId,
          transactionId: companyToViewData.transactionData?.transactionId,
          s3Location: `s3://${process.env.REACT_APP_CLIENT_FILES_BUCKET}-` +
            `${process.env.REACT_APP_ENV_LABEL}/public/${companyToViewData.accountData.companyName}-` +
            `${companyToViewData.transactionData?.transactionId}-409AReport.pdf`,
          createdDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
          reportType: '409A',
          status: 'approved',
          adminUserId: userId,
        };
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/save-409A-report`,
          await createAuthHeaders('post', reportData, true),
        );
      } catch (e) {
        setShowErrorMessage(e.toString());
      } finally {
        setReportEmailIsSending(false);
        setReportEmailHasBeenSent(true);
        setShowSendReportToClientSnack(false);
        updateAdminProgress(companyToViewData, 'completed');
      }
    }
    if (!status) {
      sendReportEmailTimerRef.current = setTimeout(() => {
        sendReportEmail();
      }, 10000);
    } else if (status === 'undo') {
      clearTimeout(sendReportEmailTimerRef.current);
    } else if (status === 'dismissed') {
      clearTimeout(sendReportEmailTimerRef.current);
      sendReportEmail();
    }
  }

  const showStatusChip = () => (
    (!companyToViewData.transactionData && companyToViewData.paymentData) ||
    (companyToViewData.transactionData && companyToViewData.transactionData?.progress !== 'completed') ||
    companyToViewData.transactionData?.isCalculating ||
    companyToViewData.transactionData?.adminProgress === 'calculation failed' ||
    companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
    companyToViewData.transactionData?.adminProgress === 'review sandbox' ||
    companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
    companyToViewData.transactionData?.adminProgress === 'completed'
  );

  const transactionHasSandbox = () => companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
    companyToViewData.transactionData?.adminProgress === 'review sandbox' ||
    companyToViewData.transactionData?.adminProgress === 'sandbox sent to client' ||
    companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'completed';

  const transactionHasReport = () => companyToViewData.transactionData?.adminProgress === 'review report' ||
    companyToViewData.transactionData?.adminProgress === 'completed';

  async function sendSandboxToClient(status) {
    setSandboxEmailIsSending(true);
    async function sendSandboxEmail() {
      const userId = await getUserId();
      try {
        const accountDetails = await fetch(
          // eslint-disable-next-line max-len
          `${process.env.REACT_APP_BACKEND_URL}/accounts/${companyToViewData.accountData.companyId}&${userId}`,
          await createAuthHeaders('get', {}, true),
        );
        const { Body } = await accountDetails.json();
        const { email, firstName } = Body;
        const emailData = {
          recipient: email,
          templateName: 'sandboxReady',
          templateData: [firstName],
          metadata: {},
        };
        await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/email/send-html-email`,
          await createAuthHeaders('post', emailData, true),
        );
      } catch (e) {
        setShowErrorMessage(e.toString());
      } finally {
        setSandboxEmailIsSending(false);
        setSandboxEmailHasBeenSent(true);
        setShowSendSandboxToClientSnack(false);
        updateAdminProgress(companyToViewData, 'sandbox sent to client');
      }
    }
    if (!status) {
      sendSandboxEmailTimerRef.current = setTimeout(() => {
        sendSandboxEmail();
      }, 10000);
    } else if (status === 'undo') {
      clearTimeout(sendSandboxEmailTimerRef.current);
    } else if (status === 'dismissed') {
      clearTimeout(sendSandboxEmailTimerRef.current);
      sendSandboxEmail();
    }
  }

  async function getSandboxDefaults() {
    try {
      const userId = await getUserId();
      const backendURL = process.env.REACT_APP_BACKEND_URL;
      const priceSandboxDefaults = await fetch(
        // eslint-disable-next-line
        `${backendURL}/sandbox/price-sandbox/${companyToViewData.transactionData?.companyId}&${companyToViewData.transactionData?.transactionId}&${userId}`,
        await createAuthHeaders('get', {}, true),
      );
      const priceSandboxDefaultsParsed = await priceSandboxDefaults.json();
      if (priceSandboxDefaultsParsed.Message === 'No Price Sandbox found') setPriceSandbox(null);
      else setPriceSandbox(priceSandboxDefaultsParsed);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    getSandboxDefaults();
    // eslint-disable-next-line no-console
    console.log(companyToViewData);
  }, []);

  // changing which PDF page is viewed
  function changePage(offset) {
    if (offset < 0) setPageNumber((prevPageNumber) => prevPageNumber + offset);
    else setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  return (
    <main className={`Tabs ${(tabToView === 3 || tabToView === 4) && transactionHasSandbox() ? 'sandbox-width' :
      tabToView === 5 && transactionHasReport() ? 'report-width' : tabToView !== 0 && tabToView !== 6 ? 'table-width' : ''}`}
    >
      <div className="tables-pg-top-nav-buttons">
        <button
          className="top-nav-button"
          type="button"
          onClick={() => {
            nav('/');
            setTabToView(0);
            setCompanyToViewData(false);
            if (companyToViewData.transactionData?.progress === 'completed' && !viewAccountInfo) {
              convertAllocatedDataToDBData(
                (!companyToViewData.transactionData?.adminProgress ||
                  companyToViewData.transactionData?.adminProgress === 'not started') && changeHasBeenMade ?
                  'in progress' :
                  companyToViewData.transactionData?.adminProgress || 'not started',
              );
            }
          }}
        >
          <ArrowBackIcon />
        </button>
        <button
          className="top-nav-button"
          type="button"
          onClick={() => {
            nav('/');
            setTabToView(0);
            setCompanyToViewData(false);
            if (companyToViewData.transactionData?.progress === 'completed' && !viewAccountInfo) {
              convertAllocatedDataToDBData(
                (!companyToViewData.transactionData?.adminProgress ||
                  companyToViewData.transactionData?.adminProgress === 'not started') && changeHasBeenMade ?
                  'in progress' :
                  companyToViewData.transactionData?.adminProgress,
              );
            }
          }}
        >
          <HomeOutlinedIcon />
          Home
        </button>
        <button
          className={`top-nav-button ${viewAccountInfo ? 'active' : ''}`}
          type="button"
          onClick={() => setViewAccountInfo(true)}
        >
          <StorefrontIcon />
          {companyToViewData.accountData.companyName}
        </button>
        {companyToViewData.transactionData && (
          <button
            className={`top-nav-button ${!viewAccountInfo ? 'active' : ''}`}
            type="button"
            onClick={() => {
              setViewAccountInfo(false);
              if (!tabToView) setTabToView(1);
            }}
          >
            <SubjectIcon />
            {`${companyToViewData.transactionData?.transactionType ? `${companyToViewData.transactionData?.transactionType} valuation` : ''}` +
              `${companyToViewData.transactionData?.transactionDate ?
                ` | ${moment(companyToViewData.transactionData?.transactionDate, 'YYYY-MM-DD').format('MM-DD-YY')}` : ''}`}
          </button>
        )}
        <div className="top-nav-right">
          {companyToViewData?.companyData && companyToViewData.transactionData?.adminProgress !== 'completed' && (
            <Select
              open={priorityDropdownIsOpen}
              className="priority-dropdown"
              onOpen={() => setPriorityDropdownIsOpen(true)}
              onClose={() => setPriorityDropdownIsOpen(false)}
              onChange={(e) => {
                setPriority(e.target.value);
                updatePriority(companyToViewData, e.target.value);
              }}
              value={priority}
              renderValue={() => {
                if (priority === 1) return <NotificationImportantOutlinedIcon />;
                if (priority === 3) return <ArrowDownwardIcon />;
                return 'Priority';
              }}
              IconComponent={ExpandMoreIcon}
              MenuProps={{ disableScrollLock: true, disablePortal: true }}
            >
              <MenuItem className={priority === 1 ? 'active' : ''} value={1}>
                <NotificationImportantOutlinedIcon className="high-icon" />
                High
              </MenuItem>
              <MenuItem className={priority === 2 ? 'active' : ''} value={2}>
                <ArrowDownwardIcon sx={{ visibility: 'hidden' }} />
                Normal
              </MenuItem>
              <MenuItem className={priority === 3 ? 'active' : ''} value={3}>
                <ArrowDownwardIcon className="low-icon" />
                Low
              </MenuItem>
            </Select>
          )}
          {!!showStatusChip() && <StatusChip companyData={companyToViewData} />}
        </div>
      </div>
      {viewAccountInfo ||
        (companyToViewData.paymentData && (!companyToViewData.transactionData || companyToViewData.transactionData?.progress !== 'completed')) ?
        (
          <div className="tables-container">
            <AccountInfo viewAccountInfo={viewAccountInfo} setTabToView={setTabToView} companyToViewData={companyToViewData} />
          </div>
        ) : (
          <>
            <div className="tables-container">
              <div className={`top-tabs-nav${tabToView === 6 ? ' client-inputs' : ''}`}>
                {
                  tabsLabels.map((tabLabel, i) => {
                    return (
                      <button
                        type="button"
                        key={tabLabel}
                        className={`tab-button ${i + 1 === tabToView ? 'active-tab' : ''}`}
                        onClick={() => {
                          setTabToView(i + 1);
                        }}
                      >
                        {tabLabel}
                      </button>
                    );
                  })
                }
              </div>
              <TabsContent
                tabToView={tabToView}
                companyToViewData={companyToViewData.transactionData}
                preferredTableData={preferredTableData}
                setPreferredTableData={setPreferredTableData}
                safeConvertibleTableData={safeConvertibleTableData}
                setSafeConvertibleTableData={setSafeConvertibleTableData}
                commonTableData={commonTableData}
                setCommonTableData={setCommonTableData}
                optionsTableData={optionsTableData}
                setOptionsTableData={setOptionsTableData}
                warrantsTableData={warrantsTableData}
                setWarrantsTableData={setWarrantsTableData}
                remainingOptionsPoolTableData={remainingOptionsPoolTableData}
                setRemainingOptionsPoolTableData={setRemainingOptionsPoolTableData}
                opmInputsTableData={opmInputsTableData}
                setOpmInputsTableData={setOpmInputsTableData}
                opmInputsTableData2={opmInputsTableData2}
                setOpmInputsTableData2={setOpmInputsTableData2}
                sharesTableData={sharesTableData}
                setSharesTableData={setSharesTableData}
                liquidPreferencesTableData={liquidPreferencesTableData}
                setLiquidPreferencesTableData={setLiquidPreferencesTableData}
                setCalcTableHasError={setCalcTableHasError}
                metaData={metaData}
                numberOfPages={numberOfPages}
                setNumberOfPages={setNumberOfPages}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                setDownloadLink={setDownloadLink}
                recreateReport={recreateReport}
                setRecreateReport={setRecreateReport}
                inputsHaveError={inputsHaveError}
                safeInputsHaveError={safeInputsHaveError}
                setSafeInputsHaveError={setSafeInputsHaveError}
                setInputsHaveError={setInputsHaveError}
                setChangeHasBeenMade={setChangeHasBeenMade}
                priceSandbox={priceSandbox}
              />
            </div>
            <div className="tables-pg-bottom-nav-buttons">
              <div className="left-buttons">
                {/* TODO NOTE - Those buttons don't do anything rn + do we actually need them? */}
                {/* <ButtonGroup variant="contained">
            <Button><LocalPrintshopOutlinedIcon /></Button>
          </ButtonGroup> */}
                <ButtonGroup variant="contained">
                  {numberOfPages && (
                    <Button
                      disableFocusRipple
                      className="download-btn"
                      href={downloadLink}
                    >
                      <DownloadOutlinedIcon />
                    </Button>
                  )}
                  {/* {!numberOfPages && (
              <>
                <Button><UndoIcon /></Button>
                <Button><RedoIcon /></Button>
              </>
            )} */}
                </ButtonGroup>
                <ButtonGroup variant="contained">
                  {numberOfPages && !reportEmailHasBeenSent && (
                    <Tooltip
                      disableInteractive
                      title="Refresh to update report"
                      PopperProps={{ className: 'bottom-arrow-tooltip' }}
                      placement="top"
                      arrow
                    >
                      <Button disableFocusRipple onClick={() => setRecreateReport(true)}><RefreshOutlinedIcon /></Button>
                    </Tooltip>
                  )}
                  {/* {!numberOfPages && (
              <Button><ZoomInIcon /></Button>
            )} */}
                </ButtonGroup>
              </div>
              {numberOfPages && (
                <ButtonGroup variant="contained">
                  <Button
                    disableFocusRipple
                    type="button"
                    disabled={pageNumber <= 1}
                    onClick={() => changePage(-1)}
                  >
                    <KeyboardArrowLeftIcon />
                  </Button>
                  <Button
                    disableFocusRipple
                    type="button"
                    disabled={pageNumber >= numberOfPages}
                    onClick={() => changePage(1)}
                  >
                    <KeyboardArrowRightIcon />
                  </Button>
                </ButtonGroup>
              )}
              {numberOfPages && (
                <div className="right-buttons">
                  <Button
                    className="send-btn"
                    onClick={() => {
                      sendReportToClient();
                      setShowSendReportToClientSnack(true);
                    }}
                    disabled={reportEmailIsSending || reportEmailHasBeenSent}
                  >
                    {reportEmailHasBeenSent ? (
                      <>
                        <CheckCircleIcon />
                        Sent to client
                      </>
                    ) : reportEmailIsSending ? (
                      <>
                        <LoadingSpinner className="loading-spinner" />
                        Sending to client
                      </>
                    ) : (
                      <>
                        <SendOutlinedIcon />
                        Send to client
                      </>
                    )}
                  </Button>
                </div>
              )}
              {!numberOfPages && (
                <div className="right-buttons">
                  {(tabToView === 1 || tabToView === 2) &&
                    (!companyToViewData.transactionData?.adminProgress ||
                      companyToViewData.transactionData?.adminProgress === 'not started' ||
                      companyToViewData.transactionData?.adminProgress === 'in progress' ||
                      companyToViewData.transactionData?.adminProgress === 'calculation completed' ||
                      companyToViewData.transactionData?.adminProgress === 'review sandbox') &&
                    (
                      <>
                        <Button
                          onClick={() => {
                            if (companyToViewData.transactionData?.progress === 'completed') {
                              convertAllocatedDataToDBData(
                                (!companyToViewData.transactionData?.adminProgress ||
                                  companyToViewData.transactionData?.adminProgress === 'not started') && changeHasBeenMade ?
                                  'in progress' :
                                  companyToViewData.transactionData?.adminProgress || 'not started',
                              );
                            }
                          }}
                          disabled={dataIsSaving}
                        >
                          {!dataIsSaving ? (
                            <>
                              <SaveOutlinedIcon />
                              Save
                            </>
                          ) : (
                            <>
                              <LoadingSpinner className="loading-spinner" />
                              Saving
                            </>
                          )}
                        </Button>
                        <Tooltip
                          disableInteractive
                          title="Calculate takes up to 45 minutes"
                          PopperProps={{ className: 'bottom-arrow-tooltip' }}
                          placement="top"
                          arrow
                        >
                          <Button
                            className="blue-btn"
                            onClick={() => {
                              if (companyToViewData.transactionData?.hasBeenCalculated) setShowReCalcConfirmDialog(true);
                              else setShowCalcConfirmDialog(true);
                            }}
                          >
                            <CalculateOutlinedIcon />
                            {companyToViewData.transactionData?.hasBeenCalculated ? 'Recalculate' : 'Calculate'}
                          </Button>
                        </Tooltip>
                      </>
                    )}
                  {tabToView === 4 && (
                    <Button
                      className="blue-btn"
                      onClick={() => {
                        sendSandboxToClient();
                        setShowSendSandboxToClientSnack(true);
                      }}
                      disabled={sandboxEmailIsSending || sandboxEmailHasBeenSent}
                    >
                      {sandboxEmailHasBeenSent ? (
                        <>
                          <CheckCircleIcon />
                          Sent to client
                        </>
                      ) : sandboxEmailIsSending ? (
                        <>
                          <LoadingSpinner className="loading-spinner" />
                          Sending to client
                        </>
                      ) : (
                        <>
                          <SendOutlinedIcon />
                          Send to client
                        </>
                      )}
                    </Button>
                  )}
                </div>
              )}
            </div>
            <Dialog
              open={DBDataIsLoading && !showCalculationSuccess && !showCalculationFailure}
              className="loading-inputs-dialog"
              disableScrollLock
            >
              <div className="loading-wrapper">
                <LoadingSpinner className="custom-loading-spinner" />
              </div>
              Loading Data . . .
            </Dialog>
            <Dialog
              open={showCalcConfirmDialog}
              className="calc-start-dialog"
              disableScrollLock
            >
              <div className="box-header">
                <WarningAmberOutlinedIcon />
                <h4>Please confirm you want to calculate</h4>
              </div>
              <p style={{ fontSize: '18px' }}>A few things to consider before you confirm:</p>
              <p>It can take up to 45 minutes to complete the calculation.</p>
              <p style={{ marginBottom: '0' }}>During calculation:</p>
              <ul>
                <li>the calculation can not be cancelled</li>
                <li>this client&apos;s 409A calculation form will not be editable</li>
                <li>you&apos;ll have the freedom to navigate away and work on other clients</li>
              </ul>
              <div className="box-buttons">
                <Button
                  className="cancel-btn"
                  onClick={() => setShowCalcConfirmDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    convertAllocatedDataToDBData('ready for calculation');
                    setShowCalcConfirmDialog(false);
                    setCalculationIsInProgress(true);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Dialog>
            <Dialog
              open={showReCalcConfirmDialog}
              className="re-calc-start-dialog"
              disableScrollLock
            >
              <div className="box-header">
                <WarningAmberOutlinedIcon />
                <h4>Recalculate? Please confirm</h4>
              </div>
              <p style={{ fontSize: '18px' }}>Things to consider:</p>
              <ul>
                <li style={{ fontWeight: 600 }}>you will no longer have access to any previous calculations</li>
                <li>it can take up to 45 minutes to complete the recalculation</li>
                <li>once the recalculation starts it can not be cancelled</li>
                <li>this client&apos;s 409A calculation form will not be editable during recalculation</li>
                <li>you&apos;ll have the freedom to navigate away and work on other clients</li>
              </ul>
              <div className="box-buttons">
                <Button
                  className="cancel-btn"
                  onClick={() => setShowReCalcConfirmDialog(false)}
                >
                  Cancel
                </Button>
                <Button
                  className="confirm-btn"
                  onClick={() => {
                    convertAllocatedDataToDBData('ready for calculation');
                    setShowReCalcConfirmDialog(false);
                    setCalculationIsInProgress(true);
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Dialog>
            <Dialog
              open={calculationIsInProgress}
              className="calc-is-in-progress-dialog"
              disableScrollLock
            >
              <LoadingSpinner className="loading-spinner" />
              <h4>Calculation in progress</h4>
              <div className="dialog-content">
                <p>
                  Calculation started at
                  {' '}
                  {companyToViewData.transactionData?.calculationStartTime ?
                    moment(companyToViewData.transactionData?.calculationStartTime, 'YYYY-MM-DD HH:mm:ss').format('h:mm A') :
                    moment(Date.now(), 'x').format('h:mm A')}
                  {' '}
                  and can take up to 45 minutes to complete.
                </p>
                <p>
                  To keep our data clean and the calculation engine running smoothly the calculation can
                  not be cancelled while it&apos;s in progress.
                </p>
              </div>
              <Button
                className="homepage-btn"
                onClick={() => {
                  nav('/');
                  setTabToView(0);
                  setCompanyToViewData(false);
                }}
              >
                Go to homepage
              </Button>
            </Dialog>
            <Dialog
              open={showCalculationSuccess}
              className="calc-success-dialog"
              disableScrollLock
            >
              <IconButton
                className="close-icon"
                onClick={() => {
                  updateAdminProgress(companyToViewData, 'review sandbox');
                  setShowCalculationSuccess(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className="box-header calc-success">
                <CheckIcon />
                <h4>Calculation completed successfully</h4>
              </div>
              <p>
                Close this modal to see the updates made to this client&apos;s 409A valuation.
                Updates include late equity in the OPM inputs tab and the Sandbox in the Sandbox tab.
              </p>
            </Dialog>
            <Dialog
              open={showCalculationFailure}
              className="calc-failure-dialog"
              disableScrollLock
            >
              <IconButton
                className="close-icon"
                onClick={() => {
                  updateAdminProgress(companyToViewData, 'in progress');
                  setShowCalculationFailure(false);
                }}
              >
                <CloseIcon />
              </IconButton>
              <div className="box-header calc-failure">
                <WarningAmberOutlinedIcon />
                <h4>Something went wrong</h4>
              </div>
              <p>
                We ran into a problem during calculation. Contact tech support to remedy the problem.
                Once you know what caused the problem, please communicate it to UX to track issues and design solutions.
              </p>
              <p className="calc-error-message">{calcFailureErrorMessage}</p>
            </Dialog>
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={showSendSandboxToClientSnack}
              onClose={() => {
                if (!sandboxEmailHasBeenSent && !sandboxEmailIsSending) sendSandboxToClient('dismissed');
                setShowSendSandboxToClientSnack(false);
              }}
              TransitionComponent={SlideTransition}
              message={(
                <>
                  <span>Report sent to client</span>
                  <Button
                    onClick={() => {
                      sendSandboxToClient('undo');
                      setSandboxEmailIsSending(false);
                      setShowSendSandboxToClientSnack(false);
                    }}
                  >
                    Undo
                  </Button>
                  <Button
                    onClick={() => {
                      sendSandboxToClient('dismissed');
                      setShowSendSandboxToClientSnack(false);
                    }}
                  >
                    Dismiss
                  </Button>
                </>
              )}
              autoHideDuration={10500}
              ClickAwayListenerProps={{ onClickAway: () => null }}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={showSendReportToClientSnack}
              onClose={() => {
                if (!reportEmailHasBeenSent && !reportEmailIsSending) sendReportToClient('dismissed');
                setShowSendReportToClientSnack(false);
              }}
              TransitionComponent={SlideTransition}
              message={(
                <>
                  <span>Report sent to client</span>
                  <Button
                    onClick={() => {
                      sendReportToClient('undo');
                      setReportEmailIsSending(false);
                      setShowSendReportToClientSnack(false);
                    }}
                  >
                    Undo
                  </Button>
                  <Button
                    onClick={() => {
                      sendReportToClient('dismissed');
                      setShowSendReportToClientSnack(false);
                    }}
                  >
                    Dismiss
                  </Button>
                </>
              )}
              autoHideDuration={10500}
              ClickAwayListenerProps={{ onClickAway: () => null }}
            />
          </>
        )}
    </main>
  );
}

Tabs.propTypes = {
  tabToView: PropTypes.number,
  setTabToView: PropTypes.func,
  companyToViewData: PropTypes.object,
  setCompanyToViewData: PropTypes.func,
  updateAdminProgress: PropTypes.func,
  updatePriority: PropTypes.func,
  viewAccountInfo: PropTypes.bool,
  setViewAccountInfo: PropTypes.func,
  currentAdminName: PropTypes.string,
  updateModifiedBy: PropTypes.func,
};
