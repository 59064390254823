import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';

import { AuthContext } from '../lib/contextLib';

export default function AuthenticatedRoute({ children }) {
  const { isAuthenticated } = useContext(AuthContext);
  const { pathname, search } = useLocation();

  if (!isAuthenticated) return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  return children;
}

AuthenticatedRoute.propTypes = { children: PropTypes.element };
