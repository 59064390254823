import React, { useEffect, useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import ErrorIcon from '@mui/icons-material/Error';

import GenPasswordReqList from './GenPasswordReqList';

import LoopingIcon from '../../components/LoopingIcon';

import { AuthContext, ErrorMessageContext } from '../../lib/contextLib';

import {
  setUserInfoToLocalStorage,
  removeUserInfoFromLocalStorage,
} from '../../utils';

import './ResetPassword.scss';

export default function ResetPassword() {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [codeHasBeenSent, setCodeHasBeenSent] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [verificationEmail, setVerificationEmail] = useState('');
  const [isConfirming, setIsConfirming] = useState(false);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(false);
  const [newPasswordHasError, setNewPasswordHasError] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [passwordCapIsOn, setPasswordCapIsOn] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSigningIn, setIsSigningIn] = useState(false);

  // Globals
  const nav = useNavigate();
  const codeSentToEmail = 'codeSentToEmail';

  useEffect(() => {
    const urlParamsOnPgLoad = new URLSearchParams(window.location.search);
    const urlVerificationCode = urlParamsOnPgLoad.get('verificationCode');
    const urlVerificationEmail = urlParamsOnPgLoad.get('userEmail');
    if (urlVerificationCode) setVerificationCode(urlVerificationCode);
    if (urlVerificationEmail) setVerificationEmail(urlVerificationEmail);
  }, []);

  async function handleSendCodeClick(event) {
    event.preventDefault();

    setIsSendingCode(true);
    try {
      await Auth.forgotPassword(email);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setUserInfoToLocalStorage(codeSentToEmail, email);
      setCodeHasBeenSent(true);
      setIsSendingCode(false);
    }
  }

  async function handleConfirmClick(event) {
    event.preventDefault();

    if (!isNewPasswordValid) {
      setNewPasswordHasError(true);
      return;
    }
    setIsConfirming(true);
    try {
      await Auth.forgotPasswordSubmit(
        verificationEmail,
        verificationCode,
        newPassword,
      );
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      removeUserInfoFromLocalStorage(codeSentToEmail);
      setIsConfirming(false);
      setSuccess(true);
    }
  }

  async function signIn() {
    setIsSigningIn(true);
    try {
      await Auth.signIn(verificationEmail, newPassword);
    } catch (e) {
      setShowErrorMessage(e.toString());
    } finally {
      setIsAuthenticated(true);
      setIsSigningIn(false);
      nav('/');
    }
  }

  function renderRequestCodeForm() {
    return (
      <form className="forgot-password-container" onSubmit={handleSendCodeClick}>
        <h4>Forgot password?</h4>
        <p>Enter your email address and we&apos;ll send you a link to reset your password.</p>
        <FormControl variant="outlined">
          <InputLabel htmlFor="email">
            Email
          </InputLabel>
          <OutlinedInput
            id="email"
            label="Email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormHelperText>
            Use your company email address
          </FormHelperText>
        </FormControl>
        <Button
          variant="contained"
          type="submit"
          className="confirm-form-btn"
          disabled={isSendingCode}
        >
          {
            isSendingCode ? <LoopingIcon /> : 'Send reset link'
          }
        </Button>
        <Button
          className="back-link"
          onClick={() => nav('/login')}
        >
          Back
        </Button>
      </form>
    );
  }

  function renderCodeSent() {
    return (
      <div className="forgot-password-container">
        <div className="top-icon">
          <MarkEmailReadOutlinedIcon />
        </div>
        <h4>Reset link sent</h4>
        <p>
          We sent an email to
          {' '}
          {email}
          {' '}
          with a reset link to reset your password.
        </p>
        <Button
          className="send-new-link"
          disabled={isSendingCode}
          onClick={async (e) => {
            // TODO Add some sort of confirmation new code
            // has been sent, instead of same page...
            removeUserInfoFromLocalStorage(codeSentToEmail);
            await handleSendCodeClick(e);
          }}
        >
          Send new reset link
        </Button>
        <Button
          className="back-to-login"
          onClick={() => nav('/login')}
        >
          Remember your password? Login
        </Button>
      </div>
    );
  }

  function renderNewPasswordForm() {
    return (
      <form className="forgot-password-container" onSubmit={handleConfirmClick}>
        <h4>Reset password</h4>
        <h5>{email}</h5>
        <FormControl
          className={newPasswordHasError ? 'has-error' : ''}
          variant="outlined"
        >
          <InputLabel htmlFor="newPassword">
            Password
          </InputLabel>
          <OutlinedInput
            id="newPassword"
            label="Password"
            type={showNewPassword ? 'text' : 'password'}
            value={newPassword}
            error={newPasswordHasError}
            onChange={(e) => setNewPassword(e.target.value)}
            onKeyDown={(e) => setPasswordCapIsOn(e.getModifierState('CapsLock'))}
            onFocus={() => setNewPasswordHasError(false)}
            onBlur={() => (newPassword ?
              (!isNewPasswordValid && setNewPasswordHasError(true)) : setNewPasswordHasError(false)
            )}
            endAdornment={(
              <InputAdornment position="end">
                {passwordCapIsOn && <KeyboardCapslockIcon />}
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setShowNewPassword(!showNewPassword);
                  }}
                  edge="end"
                >
                  {
                    showNewPassword ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />
                  }
                </IconButton>
              </InputAdornment>
            )}
          />
          <FormHelperText>
            <span style={{ visibility: !newPasswordHasError ? 'hidden' : '' }} className="error-message">
              <ErrorIcon />
              Password doesn&apos;t meet requirements
            </span>
            { /* TODO there is no current check for this and no option in cognito */}
            {/* Cannot be a previously used password */}
          </FormHelperText>
        </FormControl>
        <GenPasswordReqList
          setIsPasswordValidHandler={setIsNewPasswordValid}
          isParentPasswordValid={isNewPasswordValid}
          password={newPassword}
        />
        <Button
          variant="contained"
          type="submit"
          className="confirm-form-btn"
          disabled={isConfirming}
        >
          {
            isConfirming ? <LoopingIcon /> : 'Reset'
          }
        </Button>
        {/* TODO Handle logic for sending new link */}
        {/* <Button
          className="send-new-link"
          disableFocusRipple
          disableRipple
          onClick={() => {}}
        >
          Send new reset link
        </Button> */}
        <Button
          className="back-to-login"
          onClick={() => nav('/login')}
        >
          Remember your password? Login
        </Button>
      </form>
    );
  }

  function renderSuccess() {
    return (
      <div className="forgot-password-container">
        <div className="success-alert">
          <CheckCircleIcon className="custom-check-icon" />
          <span style={{ fontSize: '16px' }}>
            Password successfully reset
          </span>
        </div>
        <Button
          className="return-to-portal"
          disabled={isSigningIn}
          onClick={async () => {
            await signIn();
          }}
        >
          {
            isSigningIn ? <LoopingIcon /> : 'Navigate to portal'
          }
        </Button>
      </div>
    );
  }

  function RequestCodeConfirmOrSuccess() {
    if (success) {
      return renderSuccess();
    }
    if (verificationCode) {
      return renderNewPasswordForm();
    }
    if (codeHasBeenSent) {
      return renderCodeSent();
    }
    return renderRequestCodeForm();
  }

  return (
    <div className="ResetPassword">
      {RequestCodeConfirmOrSuccess()}
    </div>
  );
}
