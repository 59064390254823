/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { timeOfDayGreeting } from '../../utils';
import './index.scss';
import ValReport from './ValReport';

export default function FinalHomePage({
  userFirstAndLastName,
  reportIsReady,
  hasOldSandbox,
}) {
  const nav = useNavigate();

  const [viewReportPage, setViewReportPage] = useState(false);

  if (viewReportPage) return <ValReport setViewReportPage={setViewReportPage} reportIsReady={reportIsReady} />;

  return (
    <div className="FinalHomePage">
      <h4>
        Good
        {' '}
        {timeOfDayGreeting()}
        {', '}
        <span className="user-name">
          {`${userFirstAndLastName?.firstName || ''} ${userFirstAndLastName?.lastName || ''}`}
        </span>
      </h4>
      <div className="content-wrapper">
        <p>
          Below you will find all artifacts related to your 409A valuation.
          You can view everything and download or print your final report at any time.
        </p>
        <div className="sandbox-report-review-wrapper">
          <div className="TransactionInfoBlock item">
            <div className="overlay" onClick={() => setViewReportPage(true)} />
            <div className="title-background-image report-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <span className="final-info title">
                  409A valuation report
                </span>
                {reportIsReady ? (
                  <div className="in-progress">
                    Completed
                  </div>
                ) : (
                  <div className="in-progress">
                    In Progress
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="TransactionInfoBlock item">
            <div className="overlay" onClick={() => nav(hasOldSandbox ? '/409a-sandbox' : '/409a-price-sandbox')} />
            <div className="title-background-image sandbox-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <span className="final-info title">
                  409A sandbox
                </span>
              </div>
            </div>
          </div>
          <div className="TransactionInfoBlock item">
            <div className="overlay" onClick={() => nav('/409a-info')} />
            <div className="title-background-image submission-homepage-thumb" />
            <div className="transaction-content">
              <div className="left-side">
                <span className="final-info title">
                  409A valuation form
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

FinalHomePage.propTypes = {
  userFirstAndLastName: PropTypes.object,
  reportIsReady: PropTypes.bool,
  hasOldSandbox: PropTypes.bool,
};
