import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import './PanelButtons.scss';

// helper
function returnNon0LengthStr(localChosen, parentChosen) {
  if ((localChosen !== undefined) && (localChosen.length === 0)) {
    return parentChosen;
  }
  if ((parentChosen !== undefined) && (parentChosen.length === 0)) {
    return localChosen;
  }

  return parentChosen; // ===
}

export default function PanelButtons({
  parentSetter,
  options,
  customOptionStyles = {},
  leftJustified = true,
  withIcon = false,
  parentSelectedOption,
}) {
  const [optionChosen, setOptionChosen] = useState('');
  let potentialIcon = withIcon;
  const iconStyles = {
    display: 'inline-block',
    marginRight: '0px',
    marginTop: '-3px',
  };

  if (withIcon) {
    if (/AttachMoneyIcon/ig.test(withIcon)) {
      potentialIcon = <AttachMoneyIcon style={iconStyles} />;
    }
  }

  useEffect(() => {
    if (parentSelectedOption !== optionChosen) {
      setOptionChosen(parentSelectedOption);
    }
  }, []);

  useEffect(() => {
    parentSetter(optionChosen);
  }, [optionChosen]);

  return (
    <div
      className={`PanelButtons ${leftJustified ? 'left-justified' : 'centered'}`}
    >
      {
        options.map((option) => {
          const isOptionSelected = (
            returnNon0LengthStr(optionChosen, parentSelectedOption) === option
          );

          return (
            <button
              key={option.replaceAll(' ', '--')}
              type="button"
              className={`option ${isOptionSelected ? 'is-selected' : ''}`}
              style={customOptionStyles}
              onClick={(e) => {
                e.preventDefault();
                setOptionChosen(option);
              }}
            >
              <span className="general-option-text option-text">
                {potentialIcon}
                {option}
              </span>
              { isOptionSelected && <CheckCircleIcon /> }
            </button>
          );
        })
      }
    </div>
  );
}

PanelButtons.propTypes = {
  parentSetter: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  customOptionStyles: PropTypes.object,
  leftJustified: PropTypes.bool,
  withIcon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  parentSelectedOption: PropTypes.string, // TODO .isRequired,
};
