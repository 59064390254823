import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Button from '@mui/material/Button';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';

import Login from '../Home/Login';

import { ErrorMessageContext } from '../../lib/contextLib';

import './VerifyEmail.scss';

export default function VerifyEmail() {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [userEmail, setUserEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isConfirmationInProgress, setConfirmationInProgress] = useState(false);

  const [hasError, setHasError] = useState(false);
  const [codeIsExpired, setCodeIsExpired] = useState(false);

  const [isSendingNewCode, setIsSendingNewCode] = useState(false);
  const [newCodeHasBeenSent, setNewCodeHasBeenSent] = useState(false);

  const nav = useNavigate();

  useEffect(() => {
    setUserEmail(new URLSearchParams(window.location.search).get('userEmail'));
    setVerificationCode(new URLSearchParams(window.location.search).get('verificationCode'));
  }, []);

  useEffect(() => {
    async function verifyEmail() {
      if (userEmail) {
        try {
          let isEmailVerified = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/accounts/is-verified/${userEmail}?access_token=${process.env.REACT_APP_BACKEND_API_KEY}`,
          );
          isEmailVerified = await isEmailVerified.json();

          if (isEmailVerified?.detail) return; // .detail = 'Not Found'

          if (isEmailVerified?.userExists === 0) {
            alert('User doesn\'t exists');
            setHasError(true);
          } else if (isEmailVerified?.isVerified === 1) {
            setHasError(false);
            setConfirmationInProgress(false);
          } else if (verificationCode) {
            try {
              await Auth.confirmSignUp(userEmail, verificationCode);
            } catch (e) {
              // TODO Handle more possible errors =>
              if (e.toString().includes('ExpiredCodeException')) {
                setCodeIsExpired(true);
                setHasError(true);
              } else {
                setShowErrorMessage(e.toString());
                setHasError(true);
              }
            } finally {
              setConfirmationInProgress(false);
            }
          }
        } catch (e) {
          setShowErrorMessage(e.toString());
        }
      }
    }
    if (verificationCode || userEmail) {
      setConfirmationInProgress(true);
      verifyEmail();
    }
  }, [userEmail, verificationCode]);

  return (
    <div className="VerifyEmail">
      {isConfirmationInProgress && (
        <div className="loading-wrapper">
          <LoadingSpinner className="custom-loading-spinner" />
        </div>
      )}
      {!isConfirmationInProgress && codeIsExpired && (
        <div className="code-is-expired">
          <div className="top-icon">
            <LinkOffIcon />
          </div>
          <h4>Your verification link has expired</h4>
          <p>{`This verification link has expired for security purposes, but don't worry we can email a new verification link to ${userEmail}.`}</p>
          <Button
            className="send-new-link"
            disabled={isSendingNewCode}
            onClick={async () => {
              setIsSendingNewCode(true);
              try {
                await Auth.resendSignUp(userEmail);
              } catch (e) {
                setShowErrorMessage(e.toString());
              } finally {
                setIsSendingNewCode(false);
                setNewCodeHasBeenSent(true);
                setCodeIsExpired(false);
              }
            }}
          >
            Send new verification link
          </Button>
          <Button
            className="login-link"
            onClick={() => nav('/login')}
          >
            Already have an account? Login
          </Button>
        </div>
      )}
      {!isConfirmationInProgress && newCodeHasBeenSent && (
        <div className="new-verif-email">
          <MarkEmailReadOutlinedIcon className="top-icon" />
          <h4>Verification email sent</h4>
          <p>{`To complete the sign up process you must verify your email address. We have sent a verification email to ${userEmail}.`}</p>
        </div>
      )}
      {!isConfirmationInProgress && !hasError && (
        <Login
          hasVerifiedEmail
          verifiedEmail={userEmail}
        />
      )}
    </div>
  );
}
