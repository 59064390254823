import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// NOTE: I used snakecase here because camelcase was triggering
// "React does not recognize the `customBorderColor` prop on a DOM element..."
// So, this gets around that React error. ¯\_(ツ)_/¯
const BorderLinearProgress = styled(LinearProgress)(({
  custom_background_color, // eslint-disable-line camelcase
  custom_border_color, // eslint-disable-line camelcase
}) => {
  const backgroundGradientCol = (
    (custom_background_color.length > 0) ? // eslint-disable-line camelcase
      custom_background_color : // eslint-disable-line camelcase
      'linear-gradient(90deg, #707ADB 2.86%, #9AA4FF 52.88%, #707ADB 99.84%)'
  );
  const borderColorVal = (
    (custom_border_color.length > 0) ? // eslint-disable-line camelcase
      custom_border_color : // eslint-disable-line camelcase
      '#707ADB'
  );

  return {
    // { theme }
    height: 14,
    borderRadius: 7,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: borderColorVal,
    [`&.${
      linearProgressClasses.colorPrimary
    }`]: { // backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
      backgroundColor: '#FFF',
    },
    [`& .${
      linearProgressClasses.bar
    }`]: {
      borderRadius: 8,
      background: backgroundGradientCol,
      // backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  };
});

export default function ProgressIndicatorLine({
  stepUserIsCurrentlyOn,
  totalSteps,
  customStyles = {},
  customBackgroundColor = '',
  customBorderColor = '',
}) {
  return (
    <Box sx={customStyles}>
      <Box sx={{
        display: 'block',
        width: '100%',
        textAlign: 'right',
      }}
      >
        <Typography
          variant="body2"
          color="text.secondary"
        >
          {stepUserIsCurrentlyOn}
          {' '}
          of
          {' '}
          {totalSteps}
        </Typography>
      </Box>

      <Box sx={{ width: '100%', display: 'block' }}>
        <BorderLinearProgress
          variant="determinate"
          value={Math.round((stepUserIsCurrentlyOn / totalSteps) * 100)}
          custom_border_color={customBorderColor}
          custom_background_color={customBackgroundColor}
        />
      </Box>
    </Box>
  );
}

ProgressIndicatorLine.propTypes = {
  stepUserIsCurrentlyOn: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
  customStyles: PropTypes.object,
  customBackgroundColor: PropTypes.string,
  customBorderColor: PropTypes.string,
};
