import React, {
  useState,
  useEffect,
  useRef,
  useContext,
} from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Auth, Storage } from 'aws-amplify';

import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import ApprovalIcon from '@mui/icons-material/Approval';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import Button from '@mui/material/Button';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { ReactComponent as SuccessCheckmark } from '../../images/success_checkmark.svg';
import { ReactComponent as LoadingSpinner } from '../../images/loading-spinner.svg';
import RightInfoBlock from '../Sandbox/RightInfoBlock';
import {
  AuthContext,
  HomepageStateContext,
  IsSigningOutContext,
  ErrorMessageContext,
} from '../../lib/contextLib';
import {
  createAuthHeaders,
  getUserId,
  getClientStatus,
  grabCompanyIdAndName,
  getTransactionIdFromBackend,
} from '../../utils';
import './index.scss';
import SharePriceSlider from './SharePriceSlider';
import SharePriceCards from './SharePriceCards';

const backendURL = process.env.REACT_APP_BACKEND_URL;

function SlideTransition(props) {
  return <Slide {...props} direction="left" />;
}

const defaultSliderCardValues = [
  { color: 'responsive', title: 'Share price', data: ' ' },
  { color: 'responsive', title: 'Defensible outcomes', data: ' ' },
  { color: 'non-response', title: 'Total Iterations', data: ' ' },
  { color: 'non-response', title: 'Term', data: ' ' },
  { color: 'non-response', title: 'Volatility', data: ' ' },
  { color: 'non-response', title: 'NTM Options', data: ' ' },
  { color: 'non-response', title: 'DLOM', data: ' ' },
];

export default function PriceSandbox({ userData, priceSandboxData }) {
  const { setIsAuthenticated } = useContext(AuthContext);
  const { globalHomepageState, setGlobalHomepageState } = useContext(HomepageStateContext);
  const { setIsSigningOut } = useContext(IsSigningOutContext);
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [checkingSandboxStatus, setCheckingSandboxStatus] = useState(false);
  const [showDataLoadingOverlay, setShowDataLoadingOverlay] = useState(false);
  const [currentTransactionId, setCurrentTransactionId] = useState('');
  const [currentCompanyId, setCurrentCompanyId] = useState('');
  const [concludedPercentOfPreferred, setConcludedPercentOfPreferred] = useState('');
  const [averageOption, setAverageOption] = useState('');
  const [averageOptionExercise, setAverageOptionExercise] = useState('');
  const [medianMarketPercent, setMedianMarketPercent] = useState(27);
  const [lastRoundIssued, setLastRoundIssued] = useState('1.00');
  const [prior409AValue, setPrior409AValue] = useState('');
  const [disablePriceSlider, setDisablePriceSlider] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(true);

  // Audit Risk
  const [centerVal, setCenterVal] = useState();
  const [sliderMarks, setSliderMarks] = useState(
    [
      { value: 0, label: null },
      { value: 50, label: null },
      { value: 100, label: null },
    ],
  );
  const [selectedPrice, setSelectedPrice] = useState(50);
  const [perSharePrices, setPerSharePrices] = useState();
  const [pricesData, setPricesData] = useState();
  const [displayedPrice, setDisplayedPrice] = useState(defaultSliderCardValues);
  const [incrementalToolTip, setIncrementalToolTip] = useState(false);
  const [showIncrementalToolTip, setShowIncrementalToolTip] = useState(false);
  const [priceStats, setPriceStats] = useState({});
  const [singlePrice, setSinglePrice] = useState(false);

  // Non-slider props
  const [auditRiskLabel, setAuditRiskLabel] = useState('low');
  const [averageIndustryVolatility, setAverageIndustryVolatility] = useState('');
  const [numDefensible, setNumDefensible] = useState('');

  // Optimize Dropdown
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const options = ['Company Optimize', 'Employee Optimize'];
  const [currentOptimizeSelection, setCurrentOptimizeSelection] = useState('');

  const [userHasFinalized, setUserHasFinalized] = useState(false);
  const [sandboxIsFinalized, setSandboxIsFinalized] = useState(false);
  const [sandboxIsFinalizing, setSandboxIsFinalizing] = useState(false);

  // No Outcome Modal
  const [noOutcome, setNoOutcome] = useState(false);

  // User Finalized Snackbar
  const [showSandboxFinalizedSnackbar, setShowSandboxFinalizedSnackbar] = useState(false);

  const finalizeTimerRef = useRef(null);

  const nav = useNavigate();

  function doSetOfAllSandboxValues(sandboxData, dynamoPriceSandbox) {
    setCheckingSandboxStatus(true);
    const {
      auditRisk, cards, prices, stats, metaData,
    } = sandboxData;
    setNumDefensible(stats.totalOutcomes);
    setIncrementalToolTip(metaData.incremental);
    setPriceStats(stats);

    // #1: Price Slider
    const { center } = auditRisk;
    const indexOfCenter = metaData.sortedPrices.indexOf(center);

    if (sandboxData) {
      const marks = metaData.sortedPrices.map((value, index) => {
        if (index === 0 || index === metaData.sortedPrices.length - 1) {
          return {
            value: index,
            label: `$${value}`,
          };
        }
        if (metaData.sortedPrices.length <= 20) {
          return {
            value: index,
          };
        }
        return {
          null: null,
        };
      });
      setPerSharePrices(metaData.sortedPrices);
      setSliderMarks(marks);
      setPricesData(prices);
      setCenterVal(center);
      if (metaData.totalIndex === 0) {
        setDisablePriceSlider(true);
        setSelectedPrice(0);
        setSinglePrice(true);
      }
      if (dynamoPriceSandbox.finalSelection) {
        setSelectedPrice(metaData.sortedPrices.indexOf(dynamoPriceSandbox.finalSelection));
        setSandboxIsFinalized(true);
        setDisablePriceSlider(true);
      } else setSelectedPrice(indexOfCenter);
      setCheckingSandboxStatus(false);
    }

    // // #3 - Cards
    setPrior409AValue(cards.prior409A);
    setLastRoundIssued(cards.latestRoundIssued);
    setMedianMarketPercent(cards.medianMarketPercOfPreferred);
    setAverageOptionExercise(cards.averageOptionExercise);
    setAverageIndustryVolatility(parseFloat(cards.averageIndustryVolatility).toFixed(0).toString());
  }


  useEffect(() => {
    if (perSharePrices) {
      if (perSharePrices[selectedPrice]) {
        setDisplayedPrice(
          [
            {
              color: 'responsive',
              title: 'Share price',
              data: `$${pricesData[perSharePrices[selectedPrice]].sharePrice}`,
            },
            {
              color: 'responsive',
              title: 'Defensible outcomes',
              data: `${pricesData[perSharePrices[selectedPrice]].outcomes}`,
            },
            {
              color: 'non-response',
              title: 'Total Iterations',
              data: `${numDefensible}`,
            },
            {
              color: 'non-response',
              title: 'Term',
              data: `${pricesData[perSharePrices[selectedPrice]].term} ${pricesData[perSharePrices[selectedPrice]].term > 1 ? 'yrs' : 'yr'}`,
            },
            {
              color: 'non-response',
              title: 'Volatility',
              data: `${pricesData[perSharePrices[selectedPrice]].volatility}%`,
            },
            {
              color: 'non-response',
              title: 'NTM Options',
              data: `${pricesData[perSharePrices[selectedPrice]].ntmOptionPercent}%`,
            },
            {
              color: 'non-response',
              title: 'DLOM',
              data: `${pricesData[perSharePrices[selectedPrice]].dlom}%`,
            },
          ],
        );
        setAuditRiskLabel(pricesData[perSharePrices[selectedPrice]].auditRisk);
        setAverageOption(
          pricesData[perSharePrices[selectedPrice]].averageOptionExercise ?
            pricesData[perSharePrices[selectedPrice]].averageOptionExercise :
            averageOptionExercise,
        );
        setConcludedPercentOfPreferred(pricesData[perSharePrices[selectedPrice]].concludedPercentOfPreferred);
      }
    }
  }, [selectedPrice, disablePriceSlider]);

  async function getSandboxData(companyId, transactionId) {
    const userId = await getUserId();
    const priceSandboxDefaults = await fetch(
      // eslint-disable-next-line
      `${backendURL}/sandbox/price-sandbox/${companyId}&${transactionId}&${userId}`,
      await createAuthHeaders('get', {}, true),
    );
    if (!priceSandboxDefaults.ok) throw new Error('ERROR');
    const priceSandboxDefaultsParsed = await priceSandboxDefaults.json();
    return priceSandboxDefaultsParsed;
  }

  async function getSandboxDefaults() {
    setShowDataLoadingOverlay(true);
    setCheckingSandboxStatus(true);

    let companyId;
    let transactionId;
    if (userData) {
      companyId = userData.companyId;
      setCurrentCompanyId(userData.companyId);
      setCurrentTransactionId(userData.transactionId);
    } else {
      companyId = (await grabCompanyIdAndName()).companyId;
      transactionId = (await getTransactionIdFromBackend(companyId));
    }

    setCurrentCompanyId(companyId);
    setCurrentTransactionId(transactionId);

    async function pullDownS3JSON(preSignedURL, dynamoPriceSandbox) {
      try {
        const fetchResponse = await fetch(preSignedURL);
        const priceSandbox = await fetchResponse.json();
        doSetOfAllSandboxValues(priceSandbox, dynamoPriceSandbox);
      } catch (err) {
        alert(err);
      }
      return null;
    }
    try {
      let homepageState;
      if (!userData) homepageState = globalHomepageState;
      if (!homepageState) homepageState = await getClientStatus(companyId);

      const finalizedSandboxStates = ['Sandbox-Finalized', 'HasReport'];
      setSandboxIsFinalized(finalizedSandboxStates.includes(homepageState));
      setCheckingSandboxStatus(false);

      let fetchSandboxData;
      if (!priceSandboxData) fetchSandboxData = await getSandboxData(companyId, transactionId);

      const { s3Location } = priceSandboxData || fetchSandboxData;
      if (s3Location) {
        const storageStructure =
          priceSandboxData ? priceSandboxData.folderStructure.split('/').slice(1).join('/') :
            fetchSandboxData.folderStructure.split('/').slice(1).join('/');
        const customPrefix =
          priceSandboxData ? priceSandboxData.folderStructure.split('/').slice(0, 1).join('/') :
            fetchSandboxData.folderStructure.split('/').slice(0, 1).join('/');
        const url = await Storage.get(`${storageStructure}`, {
          customPrefix: { public: `${customPrefix}/` },
          bucket: s3Location,
          expires: 2400,
        });
        pullDownS3JSON(url, priceSandboxData || fetchSandboxData);
      }
    } catch (error) {
      alert(error);
    } finally {
      setShowDataLoadingOverlay(false);
    }
  }
  useEffect(() => {
    getSandboxDefaults();
    const hasVisited = localStorage.getItem('hasVisited');
    if (hasVisited) setOpenToolTip(false);
    else setOpenToolTip(true);
  }, []);

  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    if (index === 0) {
      setCurrentOptimizeSelection('Company Optimize');
    } else {
      setCurrentOptimizeSelection('Employee Optimize');
    }
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function userFinalizedSelection(status) {
    setUserHasFinalized(true);
    async function finalizeSandbox() {
      setSandboxIsFinalizing(true);
      setGlobalHomepageState('Sandbox-Finalized');
      const userId = await getUserId();
      const rootUrl = `${backendURL}/price-sandbox/final-selection/`;
      const companyTransaction = `${currentCompanyId}&${currentTransactionId}&${userId}`;
      const sliderParams = `&${perSharePrices[selectedPrice]}`;
      try {
        await fetch(
          `${rootUrl}${companyTransaction}${sliderParams}`,
          await createAuthHeaders('get', {}, true),
        );
      } catch (e) {
        setShowErrorMessage(e.toString());
        setGlobalHomepageState('Sandbox-Open');
      } finally {
        setSandboxIsFinalizing(false);
        setSandboxIsFinalized(true);
        setDisablePriceSlider(true);
      }
    }
    if (!status) {
      finalizeTimerRef.current = setTimeout(() => {
      }, 10000);
    } else if (status === 'undo') {
      setDisplayedPrice(defaultSliderCardValues);
      setUserHasFinalized(false);
      getSandboxDefaults();
      clearTimeout(finalizeTimerRef.current);
    } else if (status === 'dismissed') {
      clearTimeout(finalizeTimerRef.current);
      finalizeSandbox();
    }
  }

  if (userHasFinalized) {
    return (
      <div className="Sandbox success-container">
        <h1>Success!</h1>
        <SuccessCheckmark />
        <div className="success-msg-container">
          <p>
            You&apos;ve successfully finalized your 409A valuation.
          </p>
        </div>
        <Button
          className="success-link"
          onClick={() => {
            if (!sandboxIsFinalizing && !sandboxIsFinalized) userFinalizedSelection('dismissed');
            nav('/');
          }}
        >
          Go to homepage
        </Button>
        <Button
          className="success-link"
          onClick={async () => {
            if (!sandboxIsFinalizing && !sandboxIsFinalized) await userFinalizedSelection('dismissed');
            setIsSigningOut(true);
            await Auth.signOut();
            setIsAuthenticated(false);
            nav('/login');
            setIsSigningOut(false);
          }}
        >
          Sign out
        </Button>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={showSandboxFinalizedSnackbar}
          onClose={() => {
            if (!sandboxIsFinalizing && !sandboxIsFinalized) userFinalizedSelection('dismissed');
            setShowSandboxFinalizedSnackbar(false);
          }}
          TransitionComponent={SlideTransition}
          message={(
            <>
              <span>Sandbox finalized</span>
              <Button
                onClick={() => {
                  userFinalizedSelection('undo');
                  setShowSandboxFinalizedSnackbar(false);
                }}
              >
                Undo
              </Button>
              <Button
                onClick={() => {
                  userFinalizedSelection('dismissed');
                  setShowSandboxFinalizedSnackbar(false);
                }}
              >
                Dismiss
              </Button>
            </>
          )}
          autoHideDuration={10500}
          ClickAwayListenerProps={{ onClickAway: () => null }}
        />
      </div>
    );
  }

  return (
    <div className="Sandbox PriceSandbox">
      <div className="back-link-block" style={{ visibility: userData ? 'hidden' : '' }}>
        <button
          type="button"
          className="back-link-container"
          onClick={() => { nav('/'); }}
        >
          <ArrowBackIcon />
          <h4>Home</h4>
        </button>
      </div>
      <div className="valuation-container">
        <div className="headline-container">
          <h2>
            {`${sandboxIsFinalized ? 'Finalized ' : ''}`}
            409A Valuation Sandbox
          </h2>
          {!checkingSandboxStatus && (
            <>
              <Button
                variant="contained"
                className="secondary-top-btn printer-btn top-btn-instance"
                onClick={() => {
                  window.print();
                }}
              >
                <PrintOutlinedIcon />
                Print Exhibit
              </Button>
              {!sandboxIsFinalized ? (
                <>
                  <Button
                    variant="contained"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                    className="secondary-top-btn optimize-btn top-btn-instance"
                  >
                    <AutoGraphIcon />
                    Optimize
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className="optimize-dropdown"
                  >
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        className={option === currentOptimizeSelection ? 'active' : ''}
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </Menu>
                  <Button
                    variant="contained"
                    className="secondary-top-btn refresh-btn top-btn-instance"
                    disabled={showDataLoadingOverlay}
                    onClick={async () => {
                      setSelectedPrice(selectedPrice ? `${selectedPrice}` : '');
                      await getSandboxDefaults();
                    }}
                  >
                    <RefreshOutlinedIcon />
                    Reset
                  </Button>
                  {(!userData && !showDataLoadingOverlay) ? (
                    <Tooltip
                      open={openToolTip}
                      title={(
                        <>
                          Once you finalize you can&apos;t
                          <br />
                          make changes to the Sandbox.
                          <button
                            type="button"
                            className="exit-tooltip"
                            onClick={() => {
                              setOpenToolTip(false);
                              localStorage.setItem('hasVisited', true);
                            }}
                          >
                            <CloseIcon />
                          </button>
                        </>
                      )}
                      placement="left-start"
                      arrow
                      PopperProps={{
                        className: 'finalize-arrow-tooltip',
                      }}
                    >
                      <Button
                        className="top-btn-instance finalize"
                        variant="contained"
                        onClick={() => {
                          userFinalizedSelection();
                          setShowSandboxFinalizedSnackbar(true);
                        }}
                      >
                        <ApprovalIcon />
                        Finalize
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      className="top-btn-instance finalize"
                      variant="contained"
                      disabled
                    >
                      Finalize
                    </Button>
                  )}
                </>
              ) : !userData && (
                <Button
                  className="top-btn-instance finalize"
                  variant="contained"
                  onClick={() => { nav('/'); }}
                >
                  Back
                </Button>
              )}
            </>
          )}
        </div>
        <div className={`sandbox-scrolling-wrapper ${!userData ? 'user-view' : ''}`}>
          <div className="top-container">
            <div className="top-explanation">
              <p>
                Move the &apos;Share price slider&apos; to see your 409A valuation outcomes change in real-time.
                Click &apos;Optimize&apos; to optimize outcomes for your company or employees. If you decide
                you want to reset all values back to their defaults, simply click &lsquo;Reset&rsquo;.
              </p>
            </div>
            <div
              className="concluded-value"
              style={{
                backgroundColor: /low/ig.test(auditRiskLabel) ? '#42A179' : '#F4C543',
                color: /low/ig.test(auditRiskLabel) ? '#FFFFFF' : '#49454F',
              }}
            >
              <h6>Concluded value</h6>
              {!showDataLoadingOverlay && (
                <div className="price-container">
                  <div className="share-price">
                    <span>$</span>
                    {perSharePrices ? `${perSharePrices[selectedPrice]}` : ''}
                  </div>
                  <p>per share</p>
                </div>
              )}
            </div>
          </div>
          <div className="info-container">
            <div className="info-container-left-col">
              <div>
                <div
                  className="audit-risk-info-and-slider"
                  style={{
                    padding: `${incrementalToolTip ? '24px 32px 0px' : '24px 32px 32px'}`,
                  }}
                >
                  <h3>
                    Audit risk:
                    <span style={{ color: /low/ig.test(auditRiskLabel) ? '#42A179' : '#F4C543' }}>
                      {` ${auditRiskLabel}`}
                    </span>
                  </h3>
                  <div className="slider-wrapper">
                    {showDataLoadingOverlay && (
                      <div className="loading-wrapper">
                        <LoadingSpinner className="custom-loading-spinner" />
                      </div>
                    )}
                    {!showDataLoadingOverlay && (
                      <div className={`slider-mirage ${sandboxIsFinalized ? 'finalized' : ''} ${singlePrice ? 'single-price' : ''}`}>
                        <div className="slider-mirage-inner" />
                      </div>
                    )}
                    <h6 className="price-slider-header">Share price slider</h6>
                    {!showDataLoadingOverlay && (
                      <SharePriceSlider
                        sliderMarks={sliderMarks}
                        setSelectedPrice={setSelectedPrice}
                        selectedPrice={selectedPrice}
                        auditRiskLabel={auditRiskLabel}
                        currentOptimizeSelection={currentOptimizeSelection}
                        disablePriceSlider={disablePriceSlider}
                        showDataLoadingOverlay={showDataLoadingOverlay}
                        perSharePrices={perSharePrices}
                        sandboxIsFinalized={sandboxIsFinalized}
                        stats={priceStats}
                        singlePrice={singlePrice}
                      />
                    )}
                    <div className="iterations-bar">
                      {
                        displayedPrice.map(({ color, title, data }) => (
                          <SharePriceCards
                            color={color}
                            title={title}
                            data={data}
                            auditRiskLabel={auditRiskLabel}
                            priceInfo={displayedPrice}
                            key={title.replaceAll(' ', '-+-+-')}
                          />
                        ))
                      }
                    </div>
                  </div>
                  {incrementalToolTip && (
                    <div className="increment-info">
                      <p>
                        We scale the slider for ease of use.
                      </p>
                      <Tooltip
                        /* eslint-disable-next-line max-len */
                        title="The slider is incremented by 1% of your mode  share price. This allows you granular control while also making the slider itself easier to use."
                        placement="top-end"
                        arrow
                        open={showIncrementalToolTip}
                        componentsProps={{
                          tooltip: {
                            sx: {
                              padding: '13px 24px',
                              width: '286px',
                              height: '112px',
                              background: '#313833',
                              color: '#FFFFFF',
                              fontSize: '12px',
                              letterSpacing: '0.4px',
                              lineHeight: '20px',
                              borderRadius: '8px',
                              fontWeight: '400',
                              fontFamily: 'Roboto',
                            },
                          },
                          arrow: {
                            sx: {
                              color: '#313833',
                            },
                          },
                        }}
                      >
                        <Button
                          className="incrementalBtn"
                          onClick={() => (setShowIncrementalToolTip(!showIncrementalToolTip))}
                          onMouseEnter={() => { setShowIncrementalToolTip(!showIncrementalToolTip); }}
                          onMouseLeave={() => { setShowIncrementalToolTip(false); }}
                        >
                          <InfoOutlinedIcon className="info-icon" />
                        </Button>
                      </Tooltip>
                    </div>
                  )}
                </div>
                <div className="outcomes-text-block">
                  <p className="outcomes-text">
                    All outcomes generated here are your most defensible outcomes based on
                    the assumptions you provided initio. Yellow outcomes do not increase your chance
                    <br />
                    of audit. The difference between a green and yellow outcome is a yellow may
                    <br />
                    generate slightly more questions from auditors than those that fall in the green.
                  </p>
                </div>
                <div className="key-terms-block">
                  <h3 className="key-terms-title">Key terms</h3>
                  {
                    [
                      {
                        title: `$${centerVal} mode.`,
                        // eslint-disable-next-line max-len
                        paragraph: 'This is your company\'s most defensible outcome. It\'s the most frequently concluded price per share of all iterations calculated.',
                      },
                      {
                        title: `${numDefensible} total outcomes.`,
                        // eslint-disable-next-line max-len
                        paragraph: 'This is the total number of times initio\'s proprietary calculation engine iterated on your company\'s share price. It processed multiple methodologies, that our valuation experts reviewed and finalized.',
                      },
                    ].map(({ title, paragraph }) => {
                      return (
                        <div key={paragraph.replaceAll(' ', '+-=!')} className="key-terms-entry">
                          <p className="key-terms-explanation">
                            <span className="key-term">
                              {title}
                            </span>
                            {' '}
                            {paragraph}
                          </p>
                        </div>
                      );
                    })
                  }
                </div>
              </div>
            </div>
            <div className="info-container-right-col">
              <div className="right-col-wrapper">
                <h3>Insights</h3>
                <h4>Company data</h4>
                {
                  [
                    {
                      title: 'Concluded percent of preferred',
                      label: `${concludedPercentOfPreferred !== 'N/A' ? `${concludedPercentOfPreferred}%` : concludedPercentOfPreferred}`,
                    },
                    {
                      title: 'Average option exercise price',
                      label: `${(averageOption === 'N/A' || !averageOption) ? 'N/A' : `$${Number(averageOption).toFixed(2)}`}`,
                    },
                    {
                      title: 'Prior 409A value',
                      label: (prior409AValue.length ? `$${prior409AValue}` : 'N/A'),
                    },
                    {
                      title: 'Latest round issue price',
                      label: (Number(lastRoundIssued) > 0 ? `$${lastRoundIssued}` : 'N/A'),
                    },
                  ].map(({ title, label }) => (
                    <RightInfoBlock
                      title={title}
                      label={label}
                      showDataLoadingOverlay={showDataLoadingOverlay}
                      key={title.replaceAll(' ', '-+-+-')}
                    />
                  ))
                }
                <hr />
                <h4>Market data</h4>
                {
                  [
                    {
                      title: 'Median Market % of preferred',
                      label: `${medianMarketPercent}%`,
                    },
                    {
                      title: 'Average industry volatility',
                      label: `${averageIndustryVolatility}%`,
                    },
                  ].map(({ title, label }) => (
                    <RightInfoBlock
                      title={title}
                      label={label}
                      showDataLoadingOverlay={showDataLoadingOverlay}
                      key={title.replaceAll(' ', '-+-+-')}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={noOutcome}
        className="no-outcome-dialog"
        disableScrollLock
      >
        <div className="box-header">
          <ErrorOutlineIcon />
          <h4>No outcome available</h4>
        </div>
        <p>
          This selected combination of inputs is either indefensible or not within the set of calculated values.
          <br />
          <br />
          Please select a different combination to find an available price.
        </p>
        <Button
          className="got-it-btn"
          variant="contained"
          onClick={() => {
            setNoOutcome(false);
          }}
        >
          Got it
        </Button>
      </Dialog>
    </div>
  );
}

PriceSandbox.propTypes = {
  userData: PropTypes.object,
  priceSandboxData: PropTypes.object,
};
