import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';

import LightBlueGradient from '../components/LightBlueGradient';
import BottomPagination from '../components/BottomPagination';

import tableOfContentTexture from '../assets/images/table-of-contents-texture.png';

export default function TableOfContents({ valuationDate, paginationData, isSingleScenario }) {
  const tableOfContentsData = [
    { pageName: 'Purpose and Scope', pageNumber: 4 },
    { pageName: 'Introduction', pageNumber: 5 },
    { pageName: 'Fair Value Statement', pageNumber: 6 },
    { pageName: 'Concluded Value', pageNumber: 7 },
    { pageName: 'Insights', pageNumber: 8 },
    { pageName: 'Capitalization Table', pageNumber: 9 },
    { pageName: 'Breakpoints', pageNumber: 9 + paginationData.capTablePageTotal },
    { pageName: 'Option Value Allocation', pageNumber: 9 + paginationData.breakpoints1PageTotal },
    ...(isSingleScenario ? [
      { pageName: 'Option Pricing Model', pageNumber: 9 + paginationData.breakpoints2PageTotal },
    ] : [
      { pageName: 'Early Scenario OPM', pageNumber: 9 + paginationData.breakpoints2PageTotal },
      { pageName: 'Late Scenario OPM', pageNumber: 9 + paginationData.earlyOPMPageTotal },
    ]),
    { pageName: 'Per Share Summary', pageNumber: 9 + paginationData.lateOPMPageTotal },
    { pageName: 'Assumptions & Limiting Conditions', pageNumber: 9 + paginationData.allTablesPageTotal },
    { pageName: 'Valuation Team', pageNumber: 13 + paginationData.allTablesPageTotal },
    { pageName: 'AICPA Representation', pageNumber: 15 + paginationData.allTablesPageTotal },
    { pageName: 'USPAP Certification', pageNumber: 16 + paginationData.allTablesPageTotal },
    { pageName: 'Appendix', pageNumber: 17 + paginationData.allTablesPageTotal },
  ];
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          width: '831px',
          height: '792px',
          backgroundColor: '#303D5C',
        }}
      >
        <View
          style={{
            position: 'absolute',
            left: '611px',
            height: '792px',
            borderRight: '1px',
            borderColor: '#070707',
          }}
        />
        <Image
          src={tableOfContentTexture}
          style={{
            position: 'absolute',
            width: '831px',
            height: '792px',
            top: '0px',
            left: '0px',
          }}
        />
      </View>
      <Text
        style={{
          position: 'absolute',
          top: '100px',
          left: '102px',
          width: '300px',
          fontFamily: 'ManropeBold',
          fontSize: '12px',
          color: '#C0C6DC',
          letterSpacing: '2px',
        }}
      >
        409A VALUATION |
        {` ${valuationDate.toUpperCase()}`}
      </Text>
      <View
        style={{
          width: '393px',
          padding: '32px',
        }}
      >
        <LightBlueGradient
          width="393px"
          height="792px"
          customPosition={{
            bottom: '0px',
            right: '0px',
          }}
        />
        <View
          style={{
            width: '329px',
            height: '708px',
            backgroundColor: '#FAFAFD',
            borderRadius: '24px',
            paddingLeft: '36px',
          }}
        >
          <Text
            style={{
              fontFamily: 'ManropeBold',
              fontSize: '12px',
              color: '#49454F',
              letterSpacing: '2px',
              marginTop: '40px',
            }}
          >
            TABLE OF CONTENTS
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: '24px',
            }}
          >
            {tableOfContentsData.map((contentData) => {
              return (
                <View
                  key={contentData.pageNumber}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    height: '38px',
                    width: '237px',
                    borderTop: '1px',
                    borderColor: '#CCCCCC',
                  }}
                >
                  <View
                    style={{
                      width: '40px',
                      height: '40px',
                      borderTop: '3px',
                      borderColor: '#CCCCCC',
                    }}
                  >
                    <Text
                      style={{
                        fontFamily: 'ManropeMed',
                        fontSize: '20px',
                        color: '#49454F',
                        letterSpacing: '2px',
                        marginTop: '2px',
                      }}
                    >
                      {contentData.pageNumber.toString().length === 1 ? `o${contentData.pageNumber}` : contentData.pageNumber}
                    </Text>
                  </View>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: '12px',
                      color: '#49454F',
                      letterSpacing: '0.4px',
                    }}
                  >
                    {contentData.pageName}
                  </Text>
                </View>
              );
            })}
          </View>
        </View>
      </View>
      <BottomPagination
        pageNumberLeft={1}
        pageNumberRight={2}
      />
    </Page>
  );
}

TableOfContents.propTypes = {
  valuationDate: PropTypes.string,
  paginationData: PropTypes.object,
  isSingleScenario: PropTypes.bool,
};
