import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
} from '@react-pdf/renderer';

import {
  commaEvery3rdChar,
} from '../../../../utils';

import TablePageHeader from '../../components/TablePageHeader';
import BottomPagination from '../../components/BottomPagination';

export default function Breakpoints1({
  breakpoint1TableData,
  pagination,
  numberOfPages,
  tableNumberOfPages,
  pageNumber,
  tablePageNumber,
  moveTitleToHeader,
}) {
  function formatOptionTitle(val) {
    let optionPrice;
    if (val.includes('Converts')) [optionPrice] = val.split('$')[1].split(' Converts');
    if (val.includes('Exercise')) [optionPrice] = val.split('$')[1].split(' Exercise');
    return val.replace(optionPrice, parseFloat(optionPrice).toString()).replace('@', '\n@');
  }

  function formatOptionClass(val) {
    const optionPrice = val.split('$')[1];
    return val.replace(optionPrice, parseFloat(optionPrice).toString());
  }

  function formatBreakpoints(val) {
    if (breakpoint1TableData.tableData.length <= 5) {
      return val.replace('to', ' - ');
    }
    return val.replace('to', '-').replace(' Million', 'mil.');
  }

  function breakLine(val) {
    if (val.includes('Preference')) return 'Preferred\nPreference';
    if (val === 'NTM Options') return 'NTM Options\nExercise';
    if (val.includes('@')) return formatOptionTitle(val);
    if (val.split(' ').length === 2 && val.split(' ')[0].length >= 3 && val.split(' ')[1].length >= 3) return val.replace(' ', '\n');
    return val;
  }

  function returnDollarValue(val) {
    if (val === 0) return '—';
    if (val === '∞') return val;
    return `$${commaEvery3rdChar(val.toFixed(0).toString())}`;
  }

  function rowHeight() {
    if (breakpoint1TableData.tableData.length >= 9 && breakpoint1TableData.tableData.length <= 14) return '32px';
    if (breakpoint1TableData.tableData.length >= 15) return '24px';
    return '48px';
  }

  function colWidth(index) {
    if (breakpoint1TableData.columnData.length === 7 && index === 0) return '164px';
    if (breakpoint1TableData.columnData.length === 7 && index >= 3) return '164px';
    if (breakpoint1TableData.columnData.length === 8 && index === 0) return '164px';
    if (breakpoint1TableData.columnData.length === 9 && index === 0) return '200px';
    if (breakpoint1TableData.columnData.length === 9 && index <= 3) return '164px';
    if (breakpoint1TableData.columnData.length === 10 && index <= 4) return '140px';
    if (breakpoint1TableData.columnData.length === 7 && index === 3) return '144x';
    if (breakpoint1TableData.columnData.length === 6) return '176px';
    if (breakpoint1TableData.columnData.length === 7) return '120px';
    if (breakpoint1TableData.columnData.length === 8) return '136px';
    if (breakpoint1TableData.columnData.length === 9) return '140px';
    if (index === 0) return '200px';
    return '184px';
  }

  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{ position: 'relative' }}
    >
      <TablePageHeader
        pageName={`Breakpoints ${tableNumberOfPages > 1 && numberOfPages > 1 ? `(Table ${tablePageNumber}${pageNumber > 1 ? ' continued...' : ''})` :
          numberOfPages > 1 ? `(page ${pageNumber} of ${numberOfPages})` :
            tableNumberOfPages > 1 ? `(page ${tablePageNumber} of ${tableNumberOfPages})` : ''}`}
        moveTitleToHeader={moveTitleToHeader}
      />
      <View
        style={{
          marginTop: '26px',
          marginLeft: breakpoint1TableData.columnData.length > 8 ? '16px' : '80px',
          display: 'flex',
          flexDirection: 'column',
          width: breakpoint1TableData.columnData.length > 8 ? '1192px' : '1064px',
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '96px',
            backgroundColor: '#F0F0F3',
            borderBottom: '1px',
            borderColor: '#CCCCCC',
          }}
        >
          {breakpoint1TableData.columnData.map((tableData, tableDataIndex) => {
            return (
              <View
                key={tableData.dataName}
                style={{
                  fontFamily: 'RobotoMed',
                  fontSize: '12px',
                  color: '#49454F',
                  letterSpacing: '0.5px',
                  width: colWidth(tableDataIndex),
                  paddingLeft: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: '12px',
                  paddingBottom: '6px',
                  justifyContent: `${tableData.breakpoint ? 'space-between' : 'flex-end'}`,
                }}
              >
                <Text style={{ lineHeight: tableDataIndex !== 0 ? '0.55mm' : 'auto' }}>
                  {breakLine(tableData.dataName)}
                </Text>
                {tableData.breakpoint && (
                  <Text>
                    {formatBreakpoints(tableData.breakpoint)}
                  </Text>
                )}
              </View>
            );
          })}
        </View>
        {breakpoint1TableData.tableData.map((cellData, cellDataIndex) => {
          return (
            <View
              key={cellData.Class}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                height: rowHeight(),
                backgroundColor: `${cellDataIndex % 2 ? '#F0F0F3' : '#FAFAFD'}`,
              }}
            >
              {
                breakpoint1TableData.columnData.map((tableData, tableDataIndex) => {
                  if (tableDataIndex === 0) {
                    return (
                      <Text
                        key={tableData.dataName}
                        style={{
                          fontFamily: 'Roboto',
                          fontSize: '12px',
                          color: '#49454F',
                          width: colWidth(tableDataIndex),
                          paddingLeft: '16px',
                          letterSpacing: '0.4px',
                        }}
                      >
                        {colWidth(0) !== '200px' && breakpoint1TableData.tableData.length < 9 && cellData[tableData.dataName].includes('@') ?
                          cellData[tableData.dataName].replace(' @', '\n@') : cellData[tableData.dataName].includes('@') ?
                            formatOptionClass(cellData[tableData.dataName]) : cellData[tableData.dataName]}
                      </Text>
                    );
                  }
                  return (
                    <Text
                      key={tableData.dataName}
                      style={{
                        fontFamily: 'Roboto',
                        fontSize: '12px',
                        color: '#49454F',
                        width: colWidth(tableDataIndex),
                        paddingLeft: '16px',
                        letterSpacing: '0.4px',
                      }}
                    >
                      {returnDollarValue(cellData[tableData.dataName])}
                    </Text>
                  );
                })
              }
            </View>
          );
        })}
      </View>
      <BottomPagination
        pageNumberLeft={pagination[0]}
        pageNumberRight={pagination[1]}
      />
    </Page>
  );
}

Breakpoints1.propTypes = {
  breakpoint1TableData: PropTypes.object,
  pagination: PropTypes.array,
  numberOfPages: PropTypes.number,
  tableNumberOfPages: PropTypes.number,
  pageNumber: PropTypes.number,
  tablePageNumber: PropTypes.number,
  moveTitleToHeader: PropTypes.bool,
};
