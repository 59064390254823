import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EditIcon from '@mui/icons-material/Edit';
import ExpandCircleDownOutlinedIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import AnimateHeight from 'react-animate-height';
import {
  dropdownAnimationTimeMS,
  dropdownAnimationTimeS,
} from '../../utils/globals';
import './ReviewDropdown.scss';

export default function ReviewDropdown({
  openNewWizard,
  stepsParent,
  headline,
  content,
  activeWiz,
  currentStep = 1,
  isReadOnly,
}) {
  const [height, setHeight] = useState('auto');

  return (
    <div className="ReviewDropdown">
      <div
        role="button"
        className="header-row"
        onClick={() => { setHeight(height ? 0 : 'auto'); }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') setHeight(height ? 0 : 'auto');
        }}
        tabIndex={0}
      >
        <button
          type="button"
          className="chevron-btn"
          onClick={() => { setHeight(height ? 0 : 'auto'); }}
        >
          <ExpandCircleDownOutlinedIcon
            className={`${height ? 'upward' : 'downward'}-circle-chevron`}
            sx={{ transition: `transform ${dropdownAnimationTimeS}s linear` }}
          />
        </button>
        <h5>
          {headline}
        </h5>
        {!isReadOnly && (
          <button
            type="button"
            className="edit-in-wizard-btn"
            onClick={() => {
              openNewWizard(activeWiz, stepsParent[activeWiz].totalSteps(), currentStep);
            }}
          >
            <EditIcon />
          </button>
        )}
      </div>
      <div>
        <AnimateHeight
          duration={dropdownAnimationTimeMS}
          height={height}
        >
          {content}
        </AnimateHeight>
        <hr />
      </div>
    </div>
  );
}

ReviewDropdown.propTypes = {
  openNewWizard: PropTypes.func.isRequired,
  stepsParent: PropTypes.arrayOf(PropTypes.object).isRequired,
  headline: PropTypes.string.isRequired,
  activeWiz: PropTypes.number.isRequired,
  content: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  currentStep: PropTypes.number,
  isReadOnly: PropTypes.bool,
};
