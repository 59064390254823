import React, { useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';

import CircularProgress from '@mui/material/CircularProgress';

import Login from './Login';
import ClientIntakeForm from '../ClientIntakeForm';
import SplashPage from './SplashPage';
import FinalHomePage from '../FinalHomePage';
import CalcForm409A from '../409aCalcForm';

import {
  toTitleCase,
  getCompanyIdFromBackend,
  getUserInfoFromLocalStorage,
  getHomepageDataFromDB,
} from '../../utils';

import {
  AuthContext,
  HomepageStateContext,
  FirstAndLastNameContext,
  ReloadHomepageContext,
  NavBarStateContext,
  HasOldSandboxContext,
} from '../../lib/contextLib';

import './Home.scss';

export default function Home() {
  const { isAuthenticated } = useContext(AuthContext);
  const { globalHomepageState, setGlobalHomepageState } = useContext(HomepageStateContext);
  const { globalFirstAndLastName, setGlobalFirstAndLastName } = useContext(FirstAndLastNameContext);
  const { reloadHomepage, setReloadHomepage } = useContext(ReloadHomepageContext);
  const { navBarState, setNavBarState } = useContext(NavBarStateContext);
  const { hasOldSandbox, setHasOldSandbox } = useContext(HasOldSandboxContext);

  async function checkHomepageStatus() {
    if ((await Auth.currentSession()).accessToken.payload['cognito:groups']?.includes('initio-admins')) {
      if (!navBarState) setNavBarState({ userType: 'admin' });
      setGlobalHomepageState('isAdmin');
      return;
    }

    const currentCompanyId = getUserInfoFromLocalStorage('companyId') || await getCompanyIdFromBackend();
    const currentHomePageState = await getHomepageDataFromDB(currentCompanyId, 'homepageState');
    const hasOldSandboxStatus = getUserInfoFromLocalStorage('hasOldSandbox') || await getHomepageDataFromDB(currentCompanyId, 'hasOldSandbox');
    if (!globalFirstAndLastName) {
      const firstAndLastName = getUserInfoFromLocalStorage('firstAndLastName') || await getHomepageDataFromDB(currentCompanyId, 'firstAndLastName');
      if (firstAndLastName.firstName && firstAndLastName.lastName) {
        setGlobalFirstAndLastName({ firstName: toTitleCase(firstAndLastName.firstName), lastName: toTitleCase(firstAndLastName.lastName) });
      }
    }
    setHasOldSandbox(hasOldSandboxStatus);
    setGlobalHomepageState(currentHomePageState);
    setReloadHomepage(false);
  }

  useEffect(() => { if (isAuthenticated) checkHomepageStatus(); }, [isAuthenticated]);
  useEffect(() => { if (reloadHomepage) setGlobalHomepageState(''); }, [reloadHomepage]);
  useEffect(() => { if (reloadHomepage) checkHomepageStatus(); }, [globalHomepageState]);

  if (!isAuthenticated) return <Login />;

  if (globalHomepageState === 'isAdmin') return <CalcForm409A />;

  if (globalHomepageState === 'clientIntake') return <ClientIntakeForm />;

  if (
    globalHomepageState === '409A-NotStarted' ||
    globalHomepageState === '409A-InProgress' ||
    globalHomepageState === '409A-Completed' ||
    globalHomepageState === 'Sandbox-Open'
  ) {
    return <SplashPage currentUserState={globalHomepageState} userFirstAndLastName={globalFirstAndLastName} />;
  }

  if (globalHomepageState === 'Sandbox-Finalized') {
    return <FinalHomePage userFirstAndLastName={globalFirstAndLastName} hasOldSandbox={hasOldSandbox} />;
  }

  if (globalHomepageState === 'HasReport') {
    return <FinalHomePage userFirstAndLastName={globalFirstAndLastName} reportIsReady hasOldSandbox={hasOldSandbox} />;
  }

  return <CircularProgress size={90} />;
}
