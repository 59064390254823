import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import {
  Button,
  FormControl,
  FormLabel,
  TextField,
} from '@mui/material';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import './index.scss';
import RequestReceived from './RequestReceived';

import { ErrorMessageContext } from '../../lib/contextLib';

import {
  createAuthHeaders,
  getUserEmail,
  sendHtmlEmail,
  getUserId,
} from '../../utils';

export default function Support() {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [submissionReceived, setSubmissionReceived] = useState(false);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [submitType, setSubmitType] = useState('');
  const [ticketNum, setTicketNum] = useState('');
  const [currentCompanyId, setCurrentCompanyId] = useState('');

  const nav = useNavigate();

  useEffect(() => {
    async function getUserData() {
      const userId = await getUserId();
      try {
        const userInfo = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/accounts/companies/${userId}`,
          await createAuthHeaders('get', {}, true),
        );
        const { Body } = await userInfo.json();
        setCurrentCompanyId(Body.companyId);
      } catch (e) {
        setShowErrorMessage(e.toString());
      }
    }
    getUserData();
  }, []);

  async function handleEmail(supportRequest) {
    const supportRequestParsed = await supportRequest.json();
    const metadata = supportRequestParsed.Body;
    const { ticketNumber } = supportRequestParsed.Body;
    const { firstName, lastName } = supportRequestParsed.Body.accountDetails;
    setTicketNum(ticketNumber);
    const email = await getUserEmail();
    const name = `${firstName} ${lastName}`;
    await sendHtmlEmail(
      email,
      'supportRequestSuccess',
      [
        name,
        ticketNumber.toUpperCase(),
        subject,
        message,
      ],
      metadata,
    );
  }

  async function handleSubmit() {
    const userId = await getUserId();
    try {
      const requestData = {
        companyId: currentCompanyId,
        accountId: userId,
        subject,
        message,
      };
      const supportRequest = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/support/request`,
        await createAuthHeaders('post', requestData, true),
      );
      await handleEmail(supportRequest);
      setSubmissionReceived(true);
      setSubmitType('support');
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  if (!submissionReceived) {
    return (
      <div className="page-container">
        <div className="bread-crumb">
          <button
            type="button"
            className="bread-crumb-btn"
            onClick={(e) => {
              e.preventDefault();
              nav('/');
            }}
          >
            <ArrowBackIcon className="back-icon" />
            <span className="bread-crumb-title">
              Home
            </span>
          </button>
        </div>
        <div>
          <div className="header">
            <p>Customer Support</p>
            <Button
              onClick={() => nav('/demo')}
            >
              Request a demo
            </Button>
          </div>
          <div className="main">
            <form className="message-container">
              <FormControl className="message-form">
                <p className="first-paragraph">
                  If it&apos;s outside of our business hours,
                  or it&apos;s not an urgent issue, please send
                  us a message and we will get back to you within one business day.
                </p>
                <FormLabel htmlFor="message">
                  Your Message
                </FormLabel>
                <TextField
                  name="message"
                  type="text"
                  className="subject"
                  placeholder="Subject (optional, but helpful)"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                />
                <p>
                  If you&apos;re having a problem please describe
                  the issue you&apos;re having. Please include details,
                  and be as specific as possible.
                </p>
                <TextField
                  className="note"
                  variant="outlined"
                  multiline
                  placeholder="Type Note"
                  onChange={(e) => setMessage(e.target.value)}
                />
              </FormControl>
              <Button onClick={() => handleSubmit()} className="send-note">Send</Button>
            </form>
            <Box className="info">
              <div className="info-container">
                <ContactPhoneOutlinedIcon className="contact-icon" />
                <h2>Need Help?</h2>
                <p>
                  Contact us at:
                  <br />
                  (415) 472-9175
                  <br />
                  <br />
                  We&apos;re available:
                  <br />
                  Monday to Friday
                  <br />
                  9am to 5pm PST
                  <br />
                  <br />
                  If we can&apos;t get back to you
                  right away, we&apos;ll get back
                  <br />
                  to you within one
                  <br />
                  business day.
                </p>
              </div>
            </Box>
          </div>
        </div>
      </div>
    );
  }
  if (submissionReceived) {
    return <RequestReceived submitType={submitType} ticketNumber={ticketNum} />;
  }
}
