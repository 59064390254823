import React from 'react';
import PropTypes from 'prop-types';

import {
  Page,
  Text,
  Image,
} from '@react-pdf/renderer';

import initioLogo from '../assets/images/initio-logo.png';
import separatorLine from '../assets/images/line.png';
import coverPageTexture from '../assets/images/cover-page-texture.png';

export default function CoverPage({
  companyName,
  valuationDate,
  isLastPage,
}) {
  return (
    <Page
      size="TABLOID"
      orientation="landscape"
      style={{
        backgroundColor: '#303D5C',
      }}
    >
      <Image
        src={coverPageTexture}
        style={{
          position: 'absolute',
          width: '1224px',
          height: '792px',
          top: '0px',
          left: '0px',
        }}
      />
      {!isLastPage && (
        <>
          <Image
            src={initioLogo}
            style={{
              width: '107px',
              height: '37px',
              marginTop: '170px',
              marginLeft: '704px',
            }}
          />
          <Text
            style={{
              fontFamily: 'ManropeSemi',
              fontSize: '28px',
              marginTop: '11px',
              marginLeft: '704px',
              color: '#FFFFFF',
            }}
          >
            {companyName}
          </Text>
          <Text
            style={{
              fontFamily: 'ManropeBold',
              fontSize: '12px',
              position: 'absolute',
              bottom: '43px',
              right: '140px',
              color: '#C0C6DC',
              letterSpacing: '2px',
            }}
          >
            409A VALUATION |
            {` ${valuationDate.toUpperCase()}`}
          </Text>
        </>
      )}
      <Image
        src={separatorLine}
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '34px',
        }}
      />
    </Page>
  );
}


CoverPage.propTypes = {
  companyName: PropTypes.string,
  valuationDate: PropTypes.string,
  isLastPage: PropTypes.bool,
};
