import moment from 'moment';

import { assignDefaultValues } from './calcTablesUtils';

import {
  concatCharacter,
  commaEvery3rdChar,
  removeCommas,
} from '../../utils';

function convertTimeToWords(monthCount) {
  function getPlural(number, word) {
    return (number === 1 && word.one) || word.other;
  }

  const months = { one: 'mo', other: 'mos' };
  const years = { one: 'yr', other: 'yrs' };
  const m = Math.round((monthCount % 12) * 100) / 100;
  const y = Math.floor(monthCount / 12);
  const result = [];

  if (y) result.push(`${y} ${getPlural(y, years)}`);
  if (m) result.push(`${m} ${getPlural(m, months)}`);
  return result.join(' ');
}

function convertTimeWordsToNumber(string) {
  const splitString = string.split(' ');
  let years = 0;
  let months = 0;
  if (splitString.includes('yrs') || splitString.includes('yr')) {
    years = parseFloat(splitString[0]);
    months = splitString[2] ? parseFloat(splitString[2]) : 0;
  } else {
    months = parseFloat(splitString[0]);
  }
  return (12 * years + months).toString();
}

export function createOpmInputsTable(
  lateEquityValue,
  earlyEquityValue,
  dateOfLiquidityEvent,
  earlyTermToLiquidityEvent,
  lateTermToLiquidityEvent,
  dividendYield,
  lateVolatilityLowerAnnualEstimate,
  lateVolatilityAnnualEstimate,
  earlyRiskFreeRate,
  lateRiskFreeRate,
  earlyPercentage,
  latePercentage,
) {
  return {
    lateEquityValue,
    earlyEquityValue,
    dateOfLiquidityEvent,
    earlyTermToLiquidityEvent,
    lateTermToLiquidityEvent,
    dividendYield,
    lateVolatilityLowerAnnualEstimate,
    lateVolatilityAnnualEstimate,
    earlyRiskFreeRate,
    lateRiskFreeRate,
    earlyPercentage,
    latePercentage,
  };
}

export function createOpmInputsTable2(
  marketAdjustment,
) {
  return {
    marketAdjustment,
  };
}

export const opmInputsTableSchema = [
  {
    headTitle: 'Late equity value',
    name: 'lateEquityValue',
    value: '',
    formatter: (value) => {
      if (!value.toString().includes('$')) return `$${commaEvery3rdChar(value.toString())}`;
      return commaEvery3rdChar(value.toString());
    },
    dbFormat: (value) => parseInt(removeCommas(value.replace('$', '')), 10),
    cellWidth: '120px',
    isLateEquity: true,
  },
  {
    headTitle: 'Early equity value',
    name: 'earlyEquityValue',
    value: '',
    formatter: (value) => {
      if (!value.toString().includes('$')) return `$${commaEvery3rdChar(value.toString())}`;
      return commaEvery3rdChar(value.toString());
    },
    dbFormat: (value) => parseInt(removeCommas(value.replace('$', '')), 10),
    cellWidth: '140px',
  },
  {
    headTitle: 'Date of liquidity event',
    name: 'dateOfLiquidityEvent',
    value: moment().format('MM/DD/YYYY'),
    formatter: (value) => moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY'),
    dbFormat: (value) => moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD'),
    cellWidth: '120px',
    nonEditable: true,
  },
  {
    headTitle: 'Early term to liquidity event (in mos)',
    name: 'earlyTermToLiquidityEvent',
    value: '',
    formatter: (value) => convertTimeToWords(parseFloat(value)),
    dbFormat: (value) => convertTimeWordsToNumber(value),
    cellWidth: '140px',
  },
  {
    headTitle: 'Late term to liquidity event (in mos)',
    name: 'lateTermToLiquidityEvent',
    value: '',
    formatter: (value) => convertTimeToWords(parseFloat(value)),
    dbFormat: (value) => convertTimeWordsToNumber(value),
    cellWidth: '140px',
  },
  {
    headTitle: 'Dividend yield',
    name: 'dividendYield',
    value: '',
    cellWidth: '100px',
    nonEditable: true,
  },
  {
    headTitle: 'Lower Volatility',
    name: 'lateVolatilityLowerAnnualEstimate',
    value: '',
    cellWidth: '140px',
  },
  {
    headTitle: 'Upper Volatility',
    name: 'lateVolatilityAnnualEstimate',
    value: '',
    cellWidth: '140px',
  },
  {
    headTitle: 'Early risk free rate',
    name: 'earlyRiskFreeRate',
    value: '',
    cellWidth: '88px',
    nonEditable: true,
  },
  {
    headTitle: 'Late risk free rate',
    name: 'lateRiskFreeRate',
    value: '',
    cellWidth: '88px',
    nonEditable: true,
  },
  {
    headTitle: 'Early percentage',
    name: 'earlyPercentage',
    value: '',
    formatter: (value) => concatCharacter(value, '%'),
    dbFormat: (value) => value.replaceAll('%', ''),
    charLimit: 3,
    valLimit: 100,
    cellWidth: '88px',
  },
  {
    headTitle: 'Late percentage',
    name: 'latePercentage',
    value: '',
    formatter: (value) => concatCharacter(value, '%'),
    dbFormat: (value) => value.replaceAll('%', ''),
    cellWidth: '88px',
    nonEditable: true,
  },
];

export const opmInputsTableSchema2 = [
  {
    headTitle: 'Market adjustment',
    name: 'marketAdjustment',
    value: 'N/A',
    formatter: (value) => (value ? concatCharacter(value, '%') : 'N/A'),
    dbFormat: (value) => (value !== 'N/A' ? value.replaceAll('%', '') : null),
    charLimit: 3,
    valLimit: 100,
    cellWidth: '114px',
  },
];

export const defaultOpmInputsTableData =
  createOpmInputsTable(...assignDefaultValues(opmInputsTableSchema, 1));

export const defaultOpmInputsTableData2 =
  createOpmInputsTable2(...assignDefaultValues(opmInputsTableSchema2, 1));

export default {
  opmInputsTableSchema,
  createOpmInputsTable,
  defaultOpmInputsTableData,
  opmInputsTableSchema2,
  createOpmInputsTable2,
  defaultOpmInputsTableData2,
};
