import React from 'react';
import PropTypes from 'prop-types';
// import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import Chip from '@mui/material/Chip';
import './StatusChip.scss';

export default function StatusChip({
  companyData,
}) {
  const chipColors = { // finalized
    fillAndColor: '#008862',
    backgroundCol: '#ECF9F6',
    borderCol: '#46DFA8',
  };
  let chipLabel = '409A Complete';

  if (companyData.transactionData?.adminProgress === 'calculation completed' ||
    companyData.transactionData?.adminProgress === 'review sandbox') {
    chipColors.fillAndColor = '#007BCC';
    chipColors.backgroundCol = '#EAF1FF';
    chipColors.borderCol = '#9ECAFF';

    chipLabel = 'Review sandbox';
  }

  if (companyData.transactionData?.adminProgress === 'review report' || companyData.transactionData?.adminProgress === 'calculation failed') {
    chipColors.fillAndColor = '#EB603D';
    chipColors.backgroundCol = '#FFEDEA';
    chipColors.borderCol = '#FFB4AB';

    chipLabel = companyData.transactionData?.adminProgress === 'review report' ? 'Review report' : 'Calculation Failed';
  }

  if (companyData.transactionData?.adminProgress === 'sandbox sent to client' || companyData.transactionData?.isCalculating ||
    (!companyData.transactionData && companyData.paymentData) ||
    companyData.transactionData?.progress !== 'completed') {
    chipColors.fillAndColor = '#49454F';
    chipColors.backgroundCol = '#EDF1FA';
    chipColors.borderCol = '#C9C5CA';

    chipLabel = companyData.transactionData?.isCalculating ? 'Calculation in progress' :
      (!companyData.transactionData && companyData.paymentData) ? 'Client not started 409A' :
        (companyData.transactionData && companyData.transactionData.progress !== 'completed') ? 'Client 409A in-progress' :
          'Sandbox Sent to client';
  }

  // TODO Other statuses + where do we get status from

  return (
    <div className="StatusChipWrapper">
      <Chip
        className="status-chip"
        // icon={hasIcon && <NotificationImportantIcon sx={{ fill: chipColors.fillAndColor }} />}
        label={chipLabel}
        sx={{
          color: chipColors.fillAndColor,
          background: chipColors.backgroundCol,
          border: `1px solid ${chipColors.borderCol}`,
        }}
      />
    </div>
  );
}

StatusChip.propTypes = {
  companyData: PropTypes.object,
};
