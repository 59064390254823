import React from 'react';
import AutorenewIcon from '@mui/icons-material/Autorenew';

export default function LoopingIcon() {
  return (
    <AutorenewIcon
      sx={{
        animation: 'spin 2s linear infinite',
        '@keyframes spin': {
          '0%': {
            transform: 'rotate(0deg)',
          },
          '100%': {
            transform: 'rotate(360deg)',
          },
        },
      }}
    />
  );
}
