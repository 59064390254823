/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Text,
  View,
  Image,
} from '@react-pdf/renderer';

import separatorLine from '../assets/images/line.png';
import horizontalInitioLogo from '../assets/images/horizontal-initio-logo.png';

export default function BottomPagination({
  pageNumberLeft,
  pageNumberRight,
}) {
  return (
    <>
      <View
        style={{
          position: 'absolute',
          bottom: '0px',
          height: '36px',
          width: '100%',
          paddingLeft: '32px',
          paddingRight: '32px',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            color: '#79747E',
            marginRight: '25px',
          }}
        >
          {pageNumberLeft}
        </Text>
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '8px',
            letterSpacing: '0.4px',
            color: '#79747E',
          }}
        >
          ©
          {new Date().getFullYear()}
          {' '}
          initio software. All rights reserved.
        </Text>
        <Image
          src={horizontalInitioLogo}
          style={{
            width: '110px',
            height: '16px',
            marginLeft: 'auto',
            marginRight: '14px',
          }}
        />
        <Text
          style={{
            fontFamily: 'Roboto',
            fontSize: '12px',
            color: '#79747E',
          }}
        >
          {pageNumberRight}
        </Text>
      </View>
      <Image
        src={separatorLine}
        style={{
          position: 'absolute',
          width: '100%',
          bottom: '34px',
        }}
      />
    </>
  );
}

BottomPagination.propTypes = {
  pageNumberLeft: PropTypes.number,
  pageNumberRight: PropTypes.number,
};
