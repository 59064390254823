import React from 'react';
import PropTypes from 'prop-types';
import {
  Page,
  View,
  Text,
  Image,
} from '@react-pdf/renderer';

import TopHeader from '../components/TopHeader';
import BottomPagination from '../components/BottomPagination';
import CoverPage from './CoverPage';

import mountainValTeam from '../assets/images/mountain-valTeam.png';

export default function FinalPages({
  companyName,
  companyIndustry,
  transactionDate,
  valuationDate,
  lastTablePage,
  marketAdjustmentValue,
  chosenFunding,
  SAFEValCap,
  SAFEPrincipal,
  GPCAndBacksolve,
  GPCOnly,
}) {
  function getCurrentFinancialYear() {
    let fiscalYear = '';
    const today = new Date();
    if ((today.getMonth() + 1) <= 3) {
      fiscalYear = `${today.getFullYear() - 1}-${today.getFullYear()}`;
    } else {
      fiscalYear = `${today.getFullYear()}-${today.getFullYear() + 1}`;
    }
    return fiscalYear;
  }

  return (
    <>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader
          leftTitle="Assumptions and Limiting conditions"
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '146px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '14px',
                }}
              >
                The scope of our work included an estimate of the fair market value
                and the fair value of a single share of
                {' '}
                {companyName}
                {' '}
                common stock, on a fully-diluted,
                minority, non-marketable basis as of
                {' '}
                {transactionDate}
                {' '}
                . Fair market value is the standard
                of value generally used when conducting a valuation for US tax purposes.
                The basis of fair market value is determined in the Internal Revenue Service (“IRS”)
                Ruling 59-60, and has been further established in numerous court decisions dealing
                with fair market value issues. For this valuation, fair market value is defined as:
                {' '}
                <Text
                  style={{
                    fontFamily: 'RobotoItalic',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  “The price, expressed in terms of cash equivalents, at which property would change
                  hands between a hypothetical willing and able buyer and a hypothetical willing and
                  able seller, acting at arm&apos;s length in an open and unrestricted market, when neither
                  is under compulsion to buy or sell and when both have reasonable knowledge of the relevant facts.”
                </Text>
              </Text>

              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '14px',
                }}
              >
                Fair Value is the premise of value required for valuations performed under U.S
                Generally Accepted Accounting Principles. Fair value is defined in FASB Accounting
                Standards Codification Topic 820, Fair Value Measurements (“ASC 820”), as follows:
                <Text
                  style={{
                    fontFamily: 'RobotoItalic',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  “The price that would be received to sell an asset or paid to transfer a liability
                  in an orderly transaction between market participants at the measurement date.”
                </Text>
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '14px',
                }}
              >
                For purposes of valuing the common stock of
                {' '}
                {companyName}
                , INITIO SOFTWARE INC. believes that fair value
                and fair market value are equivalent and will refer to them collectively as “Fair Value” herein
                ad throughout the attached Report.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                Our analyses, this letter and the Report have been prepared solely for the purpose
                of assisting you in your compliance with ASC 718 and IRC 409A.
                It is intended solely for the internal use by the Client.
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
            }}
          >
            <View
              style={{
                marginTop: '146px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '14px',
                }}
              >
                During the course of our analyses, we were provided with historical, pro-forma and/or
                forecast financial and operational information regarding
                {' '}
                {companyName}
                . Without independent verification, we have relied upon this data as
                accurately reflecting the operating results and financial position
                of the Company. Economic, industry, and stock market information was
                obtained through various sources, including Industry Research Reports
                published by IBISWorld and the Capital IQ subscription database.
                Public information and industry and statistical information have been
                obtained from sources we believe to be reliable. We, as valuation advisors,
                have not audited these data and express no opinion or any other form of assurance
                regarding their accuracy. Additional Assumptions and Limiting Conditions to which
                our analyses and the Report are subject are presented in Appendix B attached to the Report.
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  The conclusion of value arrived at herein is valid only for the stated
                  purpose as of the date of the valuation.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Financial statements and other related information provided by the Company
                  or its representatives, in the course of this engagement, have been
                  accepted without any verification as fully and correctly reflecting the
                  enterprise&apos;s business conditions and operating results for the respective periods,
                  except as specifically noted herein. initio Software has not audited, reviewed,
                  or compiled the financial information provided to us and, accordingly,
                  we express no audit opinion or any other form of assurance on this information.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Public information and industry and statistical information have been obtained
                  from sources we believe to be reliable. However, we make no representation as
                  to the accuracy or completeness of such information and have performed no procedures
                  to corroborate the information.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 9}
          pageNumberRight={lastTablePage + 10}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader
          leftTitle="Assumptions and Limiting Conditions"
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '146px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  We do not provide assurance on the achievability of the results
                  forecasted by the Company because events and circumstances frequently
                  do not occur as expected; differences between actual and expected results
                  may be material; and achievement of the forecasted results is dependent
                  on actions, plans, and assumptions of management.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  The conclusion of value arrived at herein is based on the assumption
                  that the current level of management expertise and effectiveness
                  would continue to be maintained, and that the character and integrity
                  of the enterprise through any sale, reorganization, exchange,
                  or diminution of the owners&apos; participation would not be
                  materially or significantly changed.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  This report and the conclusion of value arrived at herein are for
                  the exclusive use of our client for the sole and specific purposes
                  as noted herein. They may not be used for any other purpose or
                  by any other party for any purpose. Furthermore, the report
                  and conclusion of value are not intended by the author and
                  should not be construed by the reader to be investment advice
                  in any manner whatsoever. The conclusion of value represents
                  the considered opinion of initio Software, based on information
                  furnished to them by the Company and other sources.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Neither all nor any part of the contents of this report
                  (especially the conclusion of value, the identity of any
                  valuation specialist(s), or the firm with which such valuation
                  specialists are connected or any reference to any of their
                  professional designations) should be disseminated to the public
                  through advertising media, public relations, news media, sales media, mail,
                  direct transmittal, or any other means of communication without the prior written
                  consent and approval of initio Software.
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '50%',
            }}
          >
            <View
              style={{
                marginTop: '146px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Future services regarding the subject matter of this report, including,
                  but not limited to testimony or attendance in court, shall not be
                  required of initio Software unless previous arrangements have been made in writing.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  No change of any item in this appraisal report shall be made
                  by anyone other than initio Software, and we shall have
                  no responsibility for any such unauthorized change.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  If prospective financial information approved by management
                  has been used in our work, we have not examined or
                  compiled the prospective financial information and therefore,
                  do not express an audit opinion or any other form of assurance
                  on the prospective financial information or the related assumptions.
                  Events and circumstances frequently do not occur as expected and there
                  will usually be differences between prospective financial information
                  and actual results, and those differences may be material.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  We have conducted interviews with the current management of the Company
                  concerning the past, present, and prospective operating results of the Company.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Except as noted, we have relied on the representations of the owners,
                  management, and other third parties concerning the value and useful
                  condition of all equipment, real estate, investments used in the business,
                  and any other assets or liabilities, except as specifically stated to the
                  contrary in this report. We have not attempted to confirm whether or not
                  all assets of the Company are free and clear of liens and encumbrances or
                  that the entity has good title to all assets.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 11}
          pageNumberRight={lastTablePage + 12}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader
          leftTitle="Valuation Team"
        />
        <Image
          src={mountainValTeam}
          style={{
            height: '212px',
            marginTop: '104px',
          }}
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '358px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.59mm',
                  letterSpacing: '0.25px',
                }}
              >
                David Pezeshki, CFA, CPA
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Master of Science in Finance, Louisiana State University - Baton Rouge
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Bachelor of Science in Accounting, Louisiana State University - Baton Rouge
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  20+ years business experience in the high-technology arena
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Public accounting, public corporation, private corporation, and
                  start-up business experience
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Performance of similar valuations on companies of similar sizes, stages,
                  and technology specifically for the purpose of determining fair value
                  with respect to acquisitions of stock
                </Text>
              </View>
            </View>
            <View
              style={{
                marginTop: '24px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.59mm',
                  letterSpacing: '0.25px',
                }}
              >
                Alexander Beard
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Master of Corporate Finance, Henley Business School
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Bachelor of Accounting, Saint Mary&apos;s College of California
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  6+ years of financial services experience, including valuation, M&A advisory, and financial due diligence
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '10px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  Experience valuing private companies, securities, and transactions
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '50%',
            }}
          >
            <View
              style={{
                marginTop: '358px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The individual(s) perform valuations similar to this on a regular basis,
                are qualified to determine the value of the subject interest based on their
                experience and credentials and are not in any way related to the Company for
                which this valuation determination is being made, as defined in Reg.
                Sec. 1.170A-13(c)(5)(iv). The individual adheres to the AICPA,
                “Statements on Standards for Valuation Services #1” and related guidance.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The fees charged and associated with this fair value determination have not
                been based in any way on the outcome of this valuation or related in any
                way to any known or contemplated outcome or transaction that may occur as
                a result of this valuation.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                All relevant information provided by the Company, available market information,
                and the legal review of contracts and litigation disclosures have been considered
                in this determination. The determination of fair value and disclosure of all
                material facts are the responsibility of management.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 13}
          pageNumberRight={lastTablePage + 14}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader
          leftTitle="AICPA Representation"
          rightTitle="USPAP Certification"
        />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '134px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                AICPA Representation of the Valuation Analysts
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The analyses and conclusion of value included in the valuation report are subject
                  to the specified assumptions and limiting conditions, and they are the personal
                  analyses and conclusion of value of the valuation analysts.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The economic and industry data included in the valuation report have been obtained
                  from various printed or electronic reference sources that the valuation analysts
                  believe to be reliable. The valuation analysts have not performed any corroborating
                  procedures to substantiate that data.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  The valuation engagement was performed in accordance with the American Institute
                  of Certified Public Accountants Statement on Standards for Valuation Services.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginTop: '-2px',
                  }}
                >
                  The parties for which the information and use of the valuation report is restricted are identified;
                  the valuation report is not intended to be and should not be used by anyone other than such parties.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The analyst&apos;s compensation is fee-based and is not contingent on the outcome of the valuation.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Unless otherwise disclosed herein, the valuation analysts did not use the work
                  of one or more outside specialists to assist during the valuation engagement.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The valuation analysts have no obligation to update the report or the conclusion of
                  value for information that comes to their attention after the date of the report.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The person(s) assuming responsibility for the valuation
                  have signed the report transmittal in their own name(s).
                  The names of those providing significant professional assistance,
                  if applicable, have been identified in the initio Software Valuation
                  Team Overview section herein.
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              width: '50%',
            }}
          >
            <View
              style={{
                marginTop: '134px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                USPAP 2020-2021 Certification (Extended Through December 31, 2023)
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                The persons assuming responsibility for this report certify that to the best of their knowledge and belief:
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The statements of fact contained in this report are true and correct.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  The reported analyses, opinions, and conclusions are limited only by the reported assumptions and
                  limiting conditions and are their personal, impartial, and unbiased professional analyses, opinions, and conclusions.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  I have no present or prospective interest in the property that is the subject of this report,
                  and I have no personal interest with respect to the parties involved.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  I have performed no services, as an appraiser or in any other capacity,
                  regarding the property that is the subject of this report within the three-year
                  period immediately preceding the agreement to perform this assignment.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  I have no bias with respect to the property that is the subject of this report
                  or to the parties involved with this assignment.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  My engagement in this assignment was not contingent upon developing or reporting predetermined results.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  My compensation for completing this assignment is not contingent upon the development or
                  reporting of a predetermined value or direction in value that favors the cause of the client,
                  the amount of the value opinion, the attainment of a stipulated result, or the occurrence
                  of a subsequent event directly related to the intended use of this appraisal.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  My analyses, opinions, and conclusions were developed, and this report has been prepared,
                  in conformity with the Uniform Standards of Professional Appraisal Practice.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  No one, except the individuals included in the initio Software Valuation Team
                  Overview herein, provided significant business and/or intangible asset appraisal
                  assistance to the person(s) signing this report.
                </Text>
              </View>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 15}
          pageNumberRight={lastTablePage + 16}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix A: Purpose & Scope" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Purpose and Scope
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                It is our understanding that
                {' '}
                {companyName}
                {' '}
                intends to grant stock-based compensation in the form of options on its common stock and,
                in connection with these activities, has sought our advice to assist with its tax reporting
                requirements in connection therewith. The purpose of this engagement is to assist
                {' '}
                {companyName}
                {' '}
                management (“Management”) with estimating the fair market value per share of common stock,
                which may be used in granting certain common stock options.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The scope of our work included an estimate of the fair market value
                of a single share of
                {' '}
                {companyName}
                {' '}
                common stock, on a fully-diluted, minority, non-marketable basis as of
                {' '}
                {transactionDate}
                {' '}
                .Fair market value is the standard of value generally used when conducting
                a valuation for US tax purposes. The basis of fair market value is determined
                in the Internal Revenue Service (“IRS”) Ruling 59-60, and has been further
                established in numerous court decisions dealing with fair market value issues.
                For this valuation, fair market value is defined as follows:
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                “The price, expressed in terms of cash equivalents, at which property would change hands between a hypothetical
                willing and able buyer and a hypothetical willing and able seller, acting at arm&apos;s length
                in an open and unrestricted market, when neither is under compulsion to buy or sell and when both
                have reasonable knowledge of the relevant facts.”
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Assumptions and Limiting Conditions
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                During the course of our analyses, we were provided with historical, pro-forma and/or
                forecast financial and operational information regarding
                {' '}
                {companyName}
                . Without independent verification, we have relied upon this data as accurately
                reflecting the operating results and financial position of the Company. Economic,
                industry, and stock market information was obtained through various sources,
                including Industry Research Reports published by IBISWorld and the S&P Capital
                IQ subscription database. Public information and industry and statistical
                information have been obtained from sources we believe to be reliable. We,
                as valuation advisors, have not audited these data and express no opinion or
                any other form of assurance regarding their accuracy.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Initio is in no way affiliated with
                {' '}
                {companyName}
                {' '}
                and has no current or expected interest in the Client or its assets.
                The results of our analyses were in no way influenced by the compensation paid for our services or work product.
                We believe the valuation set forth in this Report to be reasonable.
                Our valuation has taken into account applicable valuation factors and was performed by persons with significant,
                appropriate and applicable knowledge, experience and training. Any events subsequent
                to this Report may modify or render inapplicable this Report. This valuation shall
                not be considered to be reasonable if it is i) more than twelve (12) months old or ii)
                if a material event has occurred that has impacted the underlying value of the Company.
                A material event, for example, in the context of an early stage company, may be a round
                of funding with new terms, a technological breakthrough or setback, the signing of a
                significant contract or loss of a customer, or the addition or mitigation of the risks listed in this report.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 17}
          pageNumberRight={lastTablePage + 18}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix B: Valuation Approaches" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Income Approach
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The income approach derives value by estimating reasonable future
                cash flows to the firm and/or equity holders and discounting them to
                present value using a risk-adjusted discount rate or capitalization rate
                consistent with the riskiness of the forecasts. Limitations under this
                approach rest on the validity of the forecasts and the underlying assumptions.
                The income approach is more effective for later-stage companies due to there
                being an established operating and financial history on which forecasts are predicated.
                We did not utilize the concluded value derived from this approach.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Market Approach
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The market approach uses various financial statement metrics of similar
                enterprises&apos; equity securities, assets, or investments to estimate the fair market value
                of the Company&apos;s equity securities, assets, or investments.
                A significant limitation to this method is the likelihood that substantially similar enterprises or
                assets do not exist, and in instances where similar enterprises or transactions do exist,
                discounts that might be applied to facilitate direct comparisons could potentially reduce
                the usefulness of the underlying data being compared.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                The best determinate for ascertaining the fair market value of equity securities
                of a company is the quoted market price of an active market for the equity securities.
                By definition, illiquid stock (stock that is not traded on an active market)
                has no quoted market price. In some instances, a company may enter into arms-length
                transactions for the sale of its equity securities.
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                These transactions may qualify as a quoted market price and be considered the best measure of
                fair market value provided that the equities sold in the transaction are the same securities
                for which fair market value is being determined, and the transaction is a current transaction
                between willing parties (not forced or in a liquidation sale).
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The three most commonly applied market approach methodologies are the guideline public
                company method, the comparable transaction method, and the subject company transaction method.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Guideline Public Company Method (“Public Company Method”): In this method, an appraiser identifies
                and selects publicly traded companies with financial and operating characteristics similar to
                the company being valued. Thereafter, valuation multiples can be calculated using comparable
                company data and then subsequently applied to the subject company. We did not utilize the concluded
                value derived from this approach.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Comparable Transaction Method (“Transaction Method”): This method involves
                determining valuation multiples from sales of companies with similar financial and operating
                characteristics and applying the valuation multiples derived from these transactions to the subject company.
                A search for guideline transactions was conducted and some transactions were identified
                that provide meaningful valuation multiples. We did not utilize the concluded value
                derived from this approach.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 19}
          pageNumberRight={lastTablePage + 20}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix B: Valuation Approaches" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              {chosenFunding === 'SAFE Note' ? (
                <>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    Subject Company Transaction Method (“Backsolve”): This method examines prior transactions
                    in the same or related equity of the Company. For venture backed companies, typically
                    transactions of a preferred stock round are used to imply a value for the common stock,
                    after accounting for differences in rights and preferences. Given the latest financing through
                    a convertible security, this method was not utilized.
                  </Text>
                  {SAFEPrincipal ? (
                    <>
                      <Text
                        style={{
                          fontFamily: 'RobotoMed',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '16px',
                        }}
                      >
                        Cost Approach
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '24px',
                        }}
                      >
                        Total Invested Capital (“Cap Method”): This method utilizes the total
                        invested capital of the Company as the basis for equity value. In the
                        case of a young Company with no priced financing rounds, this method is
                        typically employed. We utilized this approach as discussed below.
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'RobotoMed',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '16px',
                        }}
                      >
                        Total Invested Capital
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '24px',
                        }}
                      >
                        Due to the Company&apos;s current circumstances, we can conclude an equity
                        value in line with Company&apos;s total invested capital resulting from its
                        convertible debt and equity instruments.We believe this to be a reasonable
                        approach due to:
                      </Text>
                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginBottom: '1px',
                        marginLeft: '16px',
                      }}
                      >
                        <Text style={{
                          width: '12px',
                          fontSize: '12px',
                          color: '#49454F',
                          marginTop: '1px',
                        }}
                        >
                          {'\u2022 '}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          The lack of an arms-length equity financing;
                        </Text>
                      </View>
                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginBottom: '1px',
                        marginLeft: '16px',
                      }}
                      >
                        <Text style={{
                          width: '12px',
                          fontSize: '12px',
                          color: '#49454F',
                          marginTop: '1px',
                        }}
                        >
                          {'\u2022 '}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          The Company&apos;s early stage nature, and;
                        </Text>
                      </View>
                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginBottom: '1px',
                        marginLeft: '16px',
                      }}
                      >
                        <Text style={{
                          width: '12px',
                          fontSize: '12px',
                          color: '#49454F',
                          marginTop: '1px',
                        }}
                        >
                          {'\u2022 '}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          The approach allocates some current value to common
                          shareholders through an option pricing model framework.
                        </Text>
                      </View>
                    </>
                  ) : (
                    <>
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '24px',
                        }}
                      >
                        Convertible Debt Valuation Cap Method (“Cap Method”): This method utilizes the valuation cap of a
                        convertible note. For venture backed companies, typically convertible debt transactions
                        can be used to imply a value for the common stock in certain scenarios (in this case,
                        the successful / high exit scenario), after accounting for the difference in rights and preferences.
                        We considered this approach in our application of the Multi-Scenario OPM as discussed below.
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'RobotoMed',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '16px',
                        }}
                      >
                        Cap Method
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                        }}
                      >
                        Utilizing the round of convertible debt, we can conclude a value equal to the valuation cap of
                        {' $'}
                        {SAFEValCap}
                        {' '}
                        in a successful or high exit event as the valuation cap is intended
                        to protect the debtholders percentage interest in a success scenario and, thus, the cap
                        may be a reasonable proxy of a successful exit. We utilized the valuation cap for the Later
                        Liquidity Event Scenario. We concluded that this is a reasonable market transaction for the following reasons:
                      </Text>
                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginBottom: '1px',
                        marginLeft: '16px',
                      }}
                      >
                        <Text style={{
                          width: '12px',
                          fontSize: '12px',
                          color: '#49454F',
                          marginTop: '1px',
                        }}
                        >
                          {'\u2022 '}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          The round was robustly-negotiated by sophisticated investors.
                        </Text>
                      </View>
                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginBottom: '1px',
                        marginLeft: '16px',
                      }}
                      >
                        <Text style={{
                          width: '12px',
                          fontSize: '12px',
                          color: '#49454F',
                          marginTop: '1px',
                        }}
                        >
                          {'\u2022 '}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          The round is current; and
                        </Text>
                      </View>
                      <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'flex-start',
                        marginBottom: '1px',
                        marginLeft: '16px',
                      }}
                      >
                        <Text style={{
                          width: '12px',
                          fontSize: '12px',
                          color: '#49454F',
                          marginTop: '1px',
                        }}
                        >
                          {'\u2022 '}
                        </Text>
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          The approach allocates some current value to common shareholders.
                        </Text>
                      </View>
                    </>
                  )}
                </>
              ) : GPCAndBacksolve ? (
                <>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    Subject Company Transaction Method (“Backsolve”): This method examines prior
                    transactions in the same or related equity
                    of the Company. For venture backed companies, typically transactions of
                    a preferred stock round are used to imply a value for the common stock,
                    after accounting for differences in rights and preferences. We did not
                    utilize the concluded value derived from this approach.
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '16px',
                    }}
                  >
                    Public Company Method
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    The Public Company Method is based upon the premise that
                    indications of value for a given entity can be estimated
                    based upon the observed valuation multiples of comparable
                    guideline public companies, the equity of which is freely-traded
                    by investors in the public securities markets. We performed the
                    following steps in our Public Company Method analysis:
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    1. Performed a detailed search and analysis to select a peer group of companies
                    from which relevant data can be obtained.
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    2. Calculated the relevant valuation multiple or multiples for each
                    company in the peer group.
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    3. Selected and applied appropriate multiples to the relevant financial
                    metrics of the Company. Depending upon the nature of the multiple,
                    the resulting value indication may be adjusted for assets or liabilities not
                    reflected in the specific multiple(s) applied.
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    In order to identify guideline public companies comparable to
                    {' '}
                    {companyName}
                    {' '}
                    we searched S&P Capital IQ&apos;s database to find comparable companies
                    and derive relevant guideline comparable company data sourced from the guideline
                    companies&apos; public filings. Our initial screen broadly identified publicly traded
                  </Text>
                </>
              ) : (
                <>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    Subject Company Transaction Method (“Backsolve”): This method examines prior transactions
                    in the same or related equity of the Company. For venture backed companies, typically transactions
                    of a preferred stock round are used to imply a value for the common stock, after accounting
                    for differences in rights and preferences. Given the valuation inherent in the Company&apos;s
                    most recent preferred stock financing round, we utilized this method.
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '16px',
                    }}
                  >
                    Backsolve
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    Utilizing the Backsolve, it is possible to “solve for” the aggregate equity value that allocates a value to the
                    {' '}
                    {chosenFunding}
                    {' '}
                    preferred shares consistent with the amount investors paid for those shares.
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    As shown in our Option Pricing Model, we “backsolved” for the Company&apos;s aggregate equity value that
                    would result in an aggregate value for the
                    {' '}
                    {chosenFunding}
                    {' '}
                    preferred shares that causes the weighted concluded value for Series Seed on Exhibit 1 to equal
                    the price paid for each share in the most recent transaction. Exhibit 4 shows the option pricing model
                    resulting from backsolving to the equity value for the Later Liquidity Event Scenario. We concluded that this
                    is a reasonable market transaction for the following reasons:
                  </Text>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginBottom: '1px',
                    marginLeft: '16px',
                  }}
                  >
                    <Text style={{
                      width: '12px',
                      fontSize: '12px',
                      color: '#49454F',
                      marginTop: '1px',
                    }}
                    >
                      {'\u2022 '}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                      }}
                    >
                      The latest financing round is considered to be at arm&apos;s length;
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginBottom: '1px',
                    marginLeft: '16px',
                  }}
                  >
                    <Text style={{
                      width: '12px',
                      fontSize: '12px',
                      color: '#49454F',
                      marginTop: '1px',
                    }}
                    >
                      {'\u2022 '}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                      }}
                    >
                      The round was robustly negotiated by sophisticated investors;
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginBottom: '1px',
                    marginLeft: '16px',
                  }}
                  >
                    <Text style={{
                      width: '12px',
                      fontSize: '12px',
                      color: '#49454F',
                      marginTop: '1px',
                    }}
                    >
                      {'\u2022 '}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                      }}
                    >
                      The round occurred in the last twelve months;
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginBottom: '1px',
                    marginLeft: '16px',
                  }}
                  >
                    <Text style={{
                      width: '12px',
                      fontSize: '12px',
                      color: '#49454F',
                      marginTop: '1px',
                    }}
                    >
                      {'\u2022 '}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                      }}
                    >
                      The round represents a significant portion of the equity value; and
                    </Text>
                  </View>
                  <View style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'flex-start',
                    marginBottom: '1px',
                    marginLeft: '16px',
                  }}
                  >
                    <Text style={{
                      width: '12px',
                      fontSize: '12px',
                      color: '#49454F',
                      marginTop: '1px',
                    }}
                    >
                      {'\u2022 '}
                    </Text>
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                      }}
                    >
                      The approach allocates some current value to common shareholders.
                    </Text>
                  </View>
                </>
              )}
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              {GPCAndBacksolve ? (
                <>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    companies in
                    {' '}
                    {companyIndustry}
                    {' '}
                    and related industries. We narrowed our search to
                    companies that were engaged in the development and sale of similar products and/or had
                    substantially similar exposure to the customers and end user markets of
                    {' '}
                    {companyName}
                    , excluding companies for various reasons, including the lack of available
                    data upon which to calculate reasonable valuation multiples.
                    These guideline public companies represent a range of companies that offer products or
                    services that could be considered competitive or complementary to
                    {' '}
                    {companyName}
                    &apos;s offerings. We believe that these companies represent the bucket of companies
                    that the public investing universe would use in its valuation of the Company.
                    {marketAdjustmentValue && marketAdjustmentValue !== 'N/A' && (
                      <>
                        For the Current Fiscal Year (“CFY” or
                        {' '}
                        {getCurrentFinancialYear()}
                        ), we selected a multiple in line with the median of the guideline public
                        companies due to the Company&apos;s strong revenue forecasts. While the Company
                        is not as large or diversified as the guideline comparable companies, it has
                        higher sales growth and has operations that would generally command sales multiples
                        near the median of these comparable companies.
                      </>
                    )}
                  </Text>
                  {(!marketAdjustmentValue || marketAdjustmentValue === 'N/A') && (
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                        marginBottom: '24px',
                      }}
                    >
                      For the Current Fiscal Year (“CFY” or
                      {' '}
                      {getCurrentFinancialYear()}
                      ), we selected a multiple in line with the median of the guideline public
                      companies due to the Company&apos;s strong revenue forecasts. While the Company
                      is not as large or diversified as the guideline comparable companies, it has
                      higher sales growth and has operations that would generally command sales multiples
                      near the median of these comparable companies.
                    </Text>
                  )}
                  {!GPCOnly ? (
                    <>
                      <Text
                        style={{
                          fontFamily: 'RobotoMed',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '16px',
                        }}
                      >
                        Backsolve
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '24px',
                        }}
                      >
                        Utilizing the Backsolve, it is possible to “solve for” the aggregate equity value that allocates a value
                        to the Series A preferred shares consistent with the amount investors paid for those shares.
                      </Text>
                      <Text
                        style={{
                          fontFamily: 'Roboto',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: '24px',
                        }}
                      >
                        As shown in our Option Pricing Model, we “backsolved” for the Company&apos;s aggregate equity
                        value that would result in an aggregate value for the Series
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text
                        style={{
                          fontFamily: 'RobotoMed',
                          color: '#49454F',
                          fontSize: '14px',
                          lineHeight: '0.55mm',
                          letterSpacing: '0.25px',
                          marginBottom: marketAdjustmentValue && marketAdjustmentValue !== 'N/A' ? '0px' : '16px',
                        }}
                      >
                        Market Equity Adjustment
                      </Text>
                      {marketAdjustmentValue && marketAdjustmentValue !== 'N/A' ? (
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          We also considered a market equity adjustment to the marketable common per share value
                          to account for changes in macroeconomic conditions, the Company&apos;s industry, and the
                          Company&apos;s performance between the Transaction Date
                          {' '}
                          {`(${transactionDate})`}
                          {' '}
                          and the Current Valuation Date
                          {' '}
                          {`(${valuationDate})`}
                          . We examined the change in market capitalization
                          values of the guideline companies and major indices over this period as well as the
                          Company&apos;s performance. We note that the guideline companies had lower market values
                          and market indices were generally had increased in market value. Due to this, we applied a
                          {' '}
                          {marketAdjustmentValue}
                          % market equity adjustment to determine a reasonable equity value as of the Valuation Date.
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontFamily: 'Roboto',
                            color: '#49454F',
                            fontSize: '14px',
                            lineHeight: '0.55mm',
                            letterSpacing: '0.25px',
                          }}
                        >
                          A Market Equity Adjustment is typically applied on a per share basis to account
                          for changes in macroeconomic conditions during the period between the Company&apos;s
                          latest funding round and the Valuation Date. Based on our assessment of the Company&apos;s
                          needs and market conditions over the applicable period, we have not applied a Market Equity
                          Adjustment to your common per share value.
                        </Text>
                      )}
                    </>
                  )}
                </>
              ) : (
                <>
                  <Text
                    style={{
                      fontFamily: 'RobotoMed',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '16px',
                    }}
                  >
                    Market Equity Adjustment
                  </Text>
                  {marketAdjustmentValue && marketAdjustmentValue !== 'N/A' ? (
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                        marginBottom: '24px',
                      }}
                    >
                      We also considered a market equity adjustment to the marketable common per share value
                      to account for changes in macroeconomic conditions, the Company&apos;s industry, and the
                      Company&apos;s performance between the Transaction Date
                      {' '}
                      {`(${transactionDate})`}
                      {' '}
                      and the Current Valuation Date
                      {' '}
                      {`(${valuationDate})`}
                      . We examined the change in market capitalization
                      values of the guideline companies and major indices over this period as well as the
                      Company&apos;s performance. We note that the guideline companies had lower market values
                      and market indices were generally had increased in market value. Due to this, we applied a
                      {' '}
                      {marketAdjustmentValue}
                      % market equity adjustment to determine a reasonable equity value as of the Valuation Date.
                    </Text>
                  ) : (
                    <Text
                      style={{
                        fontFamily: 'Roboto',
                        color: '#49454F',
                        fontSize: '14px',
                        lineHeight: '0.55mm',
                        letterSpacing: '0.25px',
                        marginBottom: '24px',
                      }}
                    >
                      A Market Equity Adjustment is typically applied on a per share basis to account
                      for changes in macroeconomic conditions during the period between the Company&apos;s
                      latest funding round and the Valuation Date. Based on our assessment of the Company&apos;s
                      needs and market conditions over the applicable period, we have not applied a Market Equity
                      Adjustment to your common per share value.
                    </Text>
                  )}
                </>
              )}
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + 21}
          pageNumberRight={lastTablePage + 22}
        />
      </Page>
      {GPCAndBacksolve && !GPCOnly && (
        <Page
          size="TABLOID"
          orientation="landscape"
          style={{ position: 'relative' }}
        >
          <TopHeader leftTitle="Appendix B: Valuation Approaches" />
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: -1,
              height: '100%',
            }}
          >
            <View
              style={{
                width: '50%',
                borderRight: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <View
                style={{
                  marginTop: '140px',
                  marginLeft: '48px',
                  marginRight: '48px',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginBottom: '24px',
                  }}
                >
                  Seed preferred shares that causes the weighted concluded value for Series Seed on Exhibit 1
                  to equal the price paid for each share in the most recent transaction. Exhibit 4 shows the option
                  pricing model resulting from backsolving to the equity value.
                  We concluded that this is a reasonable market transaction for the following reasons:
                </Text>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginBottom: '1px',
                  marginLeft: '16px',
                }}
                >
                  <Text style={{
                    width: '12px',
                    fontSize: '12px',
                    color: '#49454F',
                    marginTop: '1px',
                  }}
                  >
                    {'\u2022 '}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    The latest financing round is considered to be at arm&apos;s length;
                  </Text>
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginBottom: '1px',
                  marginLeft: '16px',
                }}
                >
                  <Text style={{
                    width: '12px',
                    fontSize: '12px',
                    color: '#49454F',
                    marginTop: '1px',
                  }}
                  >
                    {'\u2022 '}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    The round was robustly negotiated by sophisticated investors;
                  </Text>
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginBottom: '1px',
                  marginLeft: '16px',
                }}
                >
                  <Text style={{
                    width: '12px',
                    fontSize: '12px',
                    color: '#49454F',
                    marginTop: '1px',
                  }}
                  >
                    {'\u2022 '}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    The round occurred in the last twelve months;
                  </Text>
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginBottom: '1px',
                  marginLeft: '16px',
                }}
                >
                  <Text style={{
                    width: '12px',
                    fontSize: '12px',
                    color: '#49454F',
                    marginTop: '1px',
                  }}
                  >
                    {'\u2022 '}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                    }}
                  >
                    The round represents a significant portion of the equity value; and
                  </Text>
                </View>
                <View style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'flex-start',
                  marginBottom: '1px',
                  marginLeft: '16px',
                }}
                >
                  <Text style={{
                    width: '12px',
                    fontSize: '12px',
                    color: '#49454F',
                    marginTop: '1px',
                  }}
                  >
                    {'\u2022 '}
                  </Text>
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    The approach allocates some current value to common shareholders.
                  </Text>
                </View>
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginBottom: '16px',
                  }}
                >
                  Weighting
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginBottom: '24px',
                  }}
                >
                  Due to the relevance of  each of the selected methodologies, we weighted the Public
                  Company method at XX.X% and the Backsolve at XX.X%.
                  {/* TODO Where do I get those values?? */}
                </Text>
              </View>
            </View>
            <View
              style={{
                width: '50%',
                borderRight: '1px',
                borderColor: '#CCCCCC',
              }}
            >
              <View
                style={{
                  marginTop: '140px',
                  marginLeft: '48px',
                  marginRight: '48px',
                }}
              >
                <Text
                  style={{
                    fontFamily: 'RobotoMed',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginBottom: '16px',
                  }}
                >
                  Market Equity Adjustment
                </Text>
                {marketAdjustmentValue && marketAdjustmentValue !== 'N/A' ? (
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    We also considered a market equity adjustment to the marketable common per share value
                    to account for changes in macroeconomic conditions, the Company&apos;s industry, and the
                    Company&apos;s performance between the Transaction Date
                    {' '}
                    {`(${transactionDate})`}
                    {' '}
                    and the Current Valuation Date
                    {' '}
                    {`(${valuationDate})`}
                    . We examined the change in market capitalization
                    values of the guideline companies and major indices over this period as well as the
                    Company&apos;s performance. We note that the guideline companies had lower market values
                    and market indices were generally had increased in market value. Due to this, we applied a
                    {' '}
                    {marketAdjustmentValue}
                    % market equity adjustment to determine a reasonable equity value as of the Valuation Date.
                  </Text>
                ) : (
                  <Text
                    style={{
                      fontFamily: 'Roboto',
                      color: '#49454F',
                      fontSize: '14px',
                      lineHeight: '0.55mm',
                      letterSpacing: '0.25px',
                      marginBottom: '24px',
                    }}
                  >
                    A Market Equity Adjustment is typically applied on a per share basis to account
                    for changes in macroeconomic conditions during the period between the Company&apos;s
                    latest funding round and the Valuation Date. Based on our assessment of the Company&apos;s
                    needs and market conditions over the applicable period, we have not applied a Market Equity
                    Adjustment to your common per share value.
                  </Text>
                )}
              </View>
            </View>
          </View>
          <BottomPagination
            pageNumberLeft={lastTablePage + 23}
            pageNumberRight={lastTablePage + 24}
          />
        </Page>
      )}
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix C: Allocation Approaches" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The AICPA Guide suggests four methodologies for allocating the equity value
                to the different classes of equity; the probability-weighted expected return
                method, the current value method, the option pricing method, and the hybrid method.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Probability-Weighted Expected Return Method (“PWERM”)
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                PWERM is rooted in a decision-tree analysis and models potential future expected outcomes
                (sale or merger; IPO; dissolution; or continuation as a going concern). The PWERM encompasses the following steps:
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  1.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Estimation of future values for each potential outcome - For each possible future event,
                  the expected values of the Company are estimated at certain future points in time.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  2.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Allocation of value to each share class - For each event value and date, the rights and preferences of each shareholder
                  class are considered to determine the appropriate allocation of value between the member classes.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  3.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Discounting to present value, by class, these potential future values - For each possible event,
                  an expected future value is calculated for each shareholder class. This future value is then discounted
                  to a present value using an appropriate risk-adjusted discount rate.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  4.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Assign probabilities to each outcome - A probability is estimated for each possible event based on the
                  facts and circumstances as of the valuation date and based on a “good faith” estimation of the likelihood of each potential event.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '24px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  5.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Estimate share value by aggregating the probability-weighted outcomes.
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                Given the subjectivity and difficulty associated with estimating exit values, as well as
                the lack of empirical data to support the values, the PWERM was not selected.
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Current Value Method (“CVM”)
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The CVM assumes senior equity holders maximize value through an imminent liquidity event.
                The CVM allocates the enterprise&apos;s current value to each class of security based on its present rights,
                preferences and conversion features and ignores the preferred stock&apos;s ability to defer the decision to convert
                into common stock. CVM is generally appropriate in two limited circumstances:
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  1.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  When a liquidity event is imminent that does not force the conversion of preferred into common.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  2.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  When the enterprise is at such an early stage of development that
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '24px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  No material progress has been made on the business plan;
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '24px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  No significant common equity value has been created; and
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '24px',
                marginLeft: '24px',
              }}
              >
                <Text style={{
                  width: '12px',
                  fontSize: '12px',
                  color: '#49454F',
                  marginTop: '1px',
                }}
                >
                  {'\u2022 '}
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  There is no reasonable basis for estimating the amount and timing of probable future exit events.
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Since the aforementioned circumstances are not met in this instance, the CVM was not applied.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Option Pricing Method (“OPM”)
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                The OPM treats common and preferred stock as call options on the aggregate enterprise value.
                Traditionally models, including Black-Scholes option pricing models (“BSOPM”) or binomial models,
                are utilized to calculate share values. The OPM is useful for valuing securities when there is a high
                degree of uncertainty regarding their potential future values. However, one component of the valuation
                is a measure of volatility that does not exist for private companies. In this case, the volatility of a
                list of publicly traded comparable companies can be substituted.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 25 : 23)}
          pageNumberRight={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 26 : 24)}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix C: Allocation Approaches" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                One shortcoming of the OPM in the context of private company valuation is that it does not
                capture the effects of potential future radical spikes in value as well as the PWERM.
                This is of primary importance in the case of private equity-backed companies, where the
                assumption of significant value creation must be assumed.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                The following steps are applied in the Option Pricing Model:
              </Text>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  1.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Establishment of total enterprise or equity value.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  2.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Analysis of the key equity rights for each class to establish valuation breakpoints.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  3.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Select an appropriate model, usually a BSOPM or binomial model, for valuation purposes.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  4.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                  }}
                >
                  Determine key valuation inputs, including volatility, risk-free rate, and expected term to exit.
                </Text>
              </View>
              <View style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                marginBottom: '1px',
                marginLeft: '6px',
              }}
              >
                <Text style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
                >
                  5.&nbsp;
                </Text>
                <Text
                  style={{
                    fontFamily: 'Roboto',
                    color: '#49454F',
                    fontSize: '14px',
                    lineHeight: '0.55mm',
                    letterSpacing: '0.25px',
                    marginBottom: '24px',
                  }}
                >
                  Compute the fair market value of the subject security.
                </Text>
              </View>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                }}
              >
                Due to the expected term to liquidity and the lack of precise information regarding
                the timing and likelihood of potential exit events, we relied on the OPM method to
                allocate value to shares instead of other methods. More specifically,
                we relied upon the Multi-Scenario OPM, which places weight on an early
                exit scenario and a late exit scenario by utilizing two separate option pricing models.
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Hybrid Method
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The hybrid method is a combination of the PWERM and the OPM.
                Under this but the OPM is used to allocate value within one or
                more of these scenarios. Similar to the PWERM, the hybrid method
                was not selected due to the subjectivity and difficulty associated
                with estimating exit values, as well as the lack of empirical data to support the values.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Selected Allocation Method
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Based on our review of the facts and circumstances, the OPM was selected as the most reliable
                method given the reasonable expectation of lognormal distributions of potential outcomes at the
                liquidity event and the difficulty selecting and supporting appropriate exit values.
                The CVM, the PWERM, and the hybrid method were not deemed appropriate for the reasons cited above.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 27 : 25)}
          pageNumberRight={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 28 : 26)}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix D: Discount for Lack of Marketability" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The Income Approach, Market Approach, and the Cost Approach each generally produce marketable
                indications of value. As such, an adjustment is necessary to reflect the fact that the interest
                valued is not fully marketable. Marketability deals with the liquidity (or lack thereof) of an interest.
                The ability to convert an investment to cash is considered to be of high value to an investor.
                The benchmark for marketability is the active public securities market. In this market,
                securities may be converted to cash in just a few days. For interests such as the subject
                there is an extremely small market, if any, in which to liquidate investments.
                Consequently, the level of marketability is very low relative to the public market benchmark.
                Considering that closely held equity interests lack the inherent marketability of publicly traded securities,
                it is an accepted valuation practice to discount the value of closely held equity interests to reflect this disparity.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Quantitative Models
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Option-pricing models were originally developed for use in estimating the value of exchange
                traded stock options. An exchange-traded stock option is traded on a regulated exchange where
                the terms of each option are standardized by the exchange. The contract is standardized so
                the underlying asset, quantity, expiration date, and the strike price are known in advance.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Protective Put Method:
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The protective put method for estimating a discount for lack of marketability
                was first described by David Chaffe in 1993. The cost of the put option is considered
                to be the cost of marketability. The Black-Scholes model has historically been the
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                most frequently used model to value exchange-traded options.
                Black-Scholes was designed to measure European-style options
                (options that can be exercised only on the expiration date) but can be used for other purposes.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The basic inputs for the Black-Scholes model include: (1) stock price, (2) strike price, (3)
                risk-free interest rate, (4) expected option life, and (5) expected volatility.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The strike price is typically set to be equal to the market value of the underlying
                stock as of the valuation date. The expected option life is equal to the assumed holding period,
                the risk-free rate is equal to the treasury bond of equal duration to the option life,
                and volatility refers to the rate at which a stock&apos;s price moves up and down.
                Since a closely held company&apos;s stock does not trade it is necessary to estimate the
                subject stock&apos;s volatility by using comparable publicly traded company data.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                European put options are considered standard contracts; all that matters is the underlying
                share price at maturity and not how it got there. The exhibits at the end of this report
                present the calculation of the discount for lack of marketability using a European protective
                put option model. A European protective put, however, only addresses the downside risk of the
                common stock price, allowing for upside appreciation in value, which potentially overstates the discount.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Asian put options, however, offer some protection from price manipulations as the payoffs
                for these options are not determined by the underlying share price.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 29 : 27)}
          pageNumberRight={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 30 : 28)}
        />
      </Page>
      <Page
        size="TABLOID"
        orientation="landscape"
        style={{ position: 'relative' }}
      >
        <TopHeader leftTitle="Appendix D: Discount for Lack of Marketability" />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'absolute',
            top: 0,
            left: 0,
            zIndex: -1,
            height: '100%',
          }}
        >
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Two studies examine prices paid in private transactions for shares of companies that
                subsequently became publicly traded through an IPO. The Robert W. Baird & Company (“Emory”)
                studies and the Willamette Management Associates studies both consist of multi-year examinations
                of these price differentials. The pre-IPO studies show higher reported discounts than the restricted
                stock studies, averaging approximately 45 percent versus 34 percent for restricted stock.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                The restricted stock studies (discussed below) analyze transactions in restricted shares
                where marketability at the end of the required holding period was virtually assured,
                since the subject companies&apos; shares were already publicly traded. The pre-IPO studies
                analyze transactions where marketability at the end of the holding period is contingent
                on a successful public offering. Many practitioners believe that the pre-IPO studies do not
                sufficiently control the impact of such variables as the “survivor bias”
                (i.e., only successful firms go public) inherent in the data and the inclusion of a compensation
                factor in the restricted stock price for investors that provide services to the investee firm.
                Another factor that tends to reduce the reliability of pre-IPO studies is the time period between
                the valuation date and the IPO date; stock prices may change due to market and/or internal company
                changes unrelated to marketability. Due to the presence of these factors, we place less weight on
                the pre-IPO studies in estimating the lack of marketability discount.
              </Text>
              <Text
                style={{
                  fontFamily: 'RobotoMed',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '16px',
                }}
              >
                Restricted Stock Studies
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                Restricted stock studies compare the discounted price of individual restricted
                securities with the respective securities of the same company that are not
              </Text>
            </View>
          </View>
          <View
            style={{
              width: '50%',
              borderRight: '1px',
              borderColor: '#CCCCCC',
            }}
          >
            <View
              style={{
                marginTop: '140px',
                marginLeft: '48px',
                marginRight: '48px',
              }}
            >
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                restricted, but traded on a free and open exchange. Listed on the following page are the
                studies referenced and the average discounts taken to arrive at the value of the restricted security.
              </Text>
              <Text
                style={{
                  fontFamily: 'Roboto',
                  color: '#49454F',
                  fontSize: '14px',
                  lineHeight: '0.55mm',
                  letterSpacing: '0.25px',
                  marginBottom: '24px',
                }}
              >
                [1] Finnerty, John D., An Average-Strike Put Option Model of the Marketability Discount,
                The Journal of Derivatives, Summer 2012.
              </Text>
            </View>
          </View>
        </View>
        <BottomPagination
          pageNumberLeft={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 31 : 29)}
          pageNumberRight={lastTablePage + (GPCAndBacksolve && !GPCOnly ? 32 : 30)}
        />
      </Page>
      <CoverPage isLastPage />
    </>
  );
}

FinalPages.propTypes = {
  companyName: PropTypes.string,
  valuationDate: PropTypes.string,
  transactionDate: PropTypes.string,
  lastTablePage: PropTypes.number,
  marketAdjustmentValue: PropTypes.string,
  chosenFunding: PropTypes.string,
  SAFEValCap: PropTypes.string,
  companyIndustry: PropTypes.string,
  SAFEPrincipal: PropTypes.bool,
  GPCAndBacksolve: PropTypes.bool,
  GPCOnly: PropTypes.bool,
};
