import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CircularProgress from '@mui/material/CircularProgress';

import CheckoutForm from './CheckoutForm';

import { ErrorMessageContext } from '../lib/contextLib';

import {
  createAuthHeaders,
  getUserId,
  grabCompanyIdAndName,
} from '../utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE_KEY);

export default function Payment({
  makePaymentButtonRef,
  setPaymentFormIsLoading,
  setPaymentIsProcessing,
  setPaymentIsCompleted,
  setDisplayAmount,
}) {
  const { setShowErrorMessage } = useContext(ErrorMessageContext);

  const [clientSecret, setClientSecret] = useState('');

  async function loadClientSecret() {
    try {
      const userId = await getUserId();
      const { companyId, companyName } = await grabCompanyIdAndName();

      const stripeAmount = process.env.REACT_APP_STRIPE_PRICE_AMOUNT.toString();
      const returnClientSecret = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_STRIPE_API_ENDPOINT}`,
        await createAuthHeaders(
          'post',
          {
            currency: 'usd',
            amount: stripeAmount,
            paymentMethodType: ['card'],
            metadata: {
              accountId: userId,
              companyId,
              companyName,
              code_applied: false,
              discount_percentage: 0,
              original_amount: null,
            },
          },
          true,
        ),
      );

      if (returnClientSecret.status === 404) {
        alert('Error creating payment intent');
        return;
      }

      const secret = await returnClientSecret.json();

      setClientSecret(JSON.parse(secret).clientSecret);
    } catch (e) {
      setShowErrorMessage(e.toString());
    }
  }

  useEffect(() => {
    loadClientSecret();
  }, []);

  useEffect(() => {
    if (clientSecret.length > 0) setPaymentFormIsLoading(false);
    else setPaymentFormIsLoading(true);
  }, [clientSecret]);


  if (clientSecret.length > 0) {
    return (
      <Elements
        key={clientSecret}
        stripe={stripePromise}
        options={{
          clientSecret,
        }}
      >
        <CheckoutForm
          makePaymentButtonRef={makePaymentButtonRef}
          setPaymentIsProcessing={setPaymentIsProcessing}
          setPaymentIsCompleted={setPaymentIsCompleted}
          clientSecret={clientSecret}
          setDisplayAmount={setDisplayAmount}
        />
      </Elements>
    );
  }

  const stripePaymentContainerWidth = { width: '380px' };
  const CircularProgressLoadingStyles = {
    color: 'gray',
    display: 'block',
    margin: '35px auto',
  };
  return (
    <div style={stripePaymentContainerWidth}>
      <CircularProgress size={90} sx={CircularProgressLoadingStyles} />
    </div>
  );
}

Payment.propTypes = {
  makePaymentButtonRef: PropTypes.object.isRequired,
  setPaymentIsProcessing: PropTypes.func.isRequired,
  setPaymentIsCompleted: PropTypes.func.isRequired,
  setPaymentFormIsLoading: PropTypes.func.isRequired,
  setDisplayAmount: PropTypes.func,
};
