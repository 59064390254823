/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Svg,
  Defs,
  LinearGradient,
  Stop,
  Rect,
} from '@react-pdf/renderer';

export default function LightBlueGradient({
  width,
  height,
  customPosition,
}) {
  return (
    <Svg
      style={{
        position: 'absolute',
        zIndex: -1,
        ...customPosition,
        width,
        height,
      }}
    >
      <Defs>
        <LinearGradient id="lightBlueGradient" x1="29.42%" y1="-10.08%" x2="70.58%" y2="110.08%">
          <Stop offset="8.61%" stopColor="#C5D3E8" />
          <Stop offset="35.51%" stopColor="#E5EBF7" />
          <Stop offset="106.03%" stopColor="#ACBDDA" />
        </LinearGradient>
      </Defs>
      <Rect width={width} height={height} fill="url('#lightBlueGradient')" />
    </Svg>
  );
}

LightBlueGradient.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  customPosition: PropTypes.object,
};
