import React from 'react';
import PropTypes from 'prop-types';

import CapTable from './tables/CapTable';
import CapTableV2 from './tables/CapTableV2';
import Breakpoints1 from './tables/Breakpoints1';
import Breakpoints2 from './tables/Breakpoints2';
import EarlyScenarioOPM from './tables/EarlyScenarioOPM';
import LateScenarioOPM from './tables/LateScenarioOPM';
import PerShareSummary from './tables/PerShareSummary';

export default function Tables({
  capTableData,
  breakpoint1TableData,
  breakpoint2TableData,
  earlyOPMTableData,
  lateOPMTableData,
  perShareSummaryTableData,
  paginationData,
  isSingleScenario,
}) {
  function divideTableData(data, numPerPage, addFirstColumn) {
    const splitArr = [];
    const arrToCalc = [...data];
    if (addFirstColumn) arrToCalc.shift();
    let numberOfPages = Math.ceil(arrToCalc.length / numPerPage);
    const numberOfRowsPerPage = arrToCalc.length / numberOfPages;
    while (numberOfPages !== 0) {
      let firstSlice;
      let secondSlice;
      if (numberOfRowsPerPage % 1 !== 0 && numberOfPages !== 1) {
        firstSlice = (numberOfPages - 1) * Math.floor(numberOfRowsPerPage) + 1;
      } else {
        firstSlice = (numberOfPages - 1) * Math.floor(numberOfRowsPerPage);
      }
      if (numberOfRowsPerPage % 1 !== 0) {
        secondSlice = numberOfPages * Math.floor(numberOfRowsPerPage) + 1;
      } else {
        secondSlice = numberOfPages * Math.floor(numberOfRowsPerPage);
      }
      splitArr.unshift({
        pageNumber: numberOfPages,
        pageData: addFirstColumn ?
          data.slice(0, 1).concat(arrToCalc.slice(firstSlice, secondSlice)) :
          arrToCalc.slice(firstSlice, secondSlice),
      });
      numberOfPages -= 1;
    }
    return splitArr;
  }

  const moveTitleToHeader = () => {
    let response = false;
    if (capTableData.isComplexData) {
      divideTableData(capTableData.tableData, 21).forEach((tableData, index, rootArr) => {
        response = tableData.pageData.length >= (20 + (index === rootArr.length - 1 ? 0 : 1));
      });
    } else {
      divideTableData(capTableData.tableData, 21).forEach((tableData, index, rootArr) => {
        response = tableData.pageData.length >= (19 + (index === rootArr.length - 1 ? 0 : 1));
      });
    }
    if (response) return response;
    divideTableData(breakpoint1TableData.tableData, 21).forEach((tableData) => {
      response = tableData.pageData.length >= 19;
    });
    if (response) return response;
    divideTableData(breakpoint2TableData.tableData, 21).forEach((tableData) => {
      response = tableData.pageData.length >= 19;
    });
    if (response) return response;
    divideTableData(perShareSummaryTableData.tableData, 18).forEach((tableData, index, rootArr) => {
      response = tableData.pageData.length >= (19 + (index === rootArr.length - 1 ? 0 : 2));
    });
    return response;
  };

  return (
    <>
      {capTableData.isComplexData ? divideTableData(capTableData.tableData, 21).map((tableData, index, rootArr) => {
        return (
          <CapTableV2
            key={tableData.pageNumber}
            capTableData={{
              tableData: tableData.pageData,
              totalData: capTableData.totalData,
            }}
            pagination={capTableData.hasDividends ?
              [9 + index * 4, 9 + index * 4 + 1, 9 + index * 4 + 2, 9 + index * 4 + 3] :
              [9 + index * 2, 9 + index * 2 + 1]}
            hasDividends={capTableData.hasDividends}
            numberOfPages={rootArr.length}
            pageNumber={index + 1}
            isLastPage={index === rootArr.length - 1}
            moveTitleToHeader={moveTitleToHeader()}
          />
        );
      }) : divideTableData(capTableData.tableData, 21).map((tableData, index, rootArr) => {
        return (
          <CapTable
            key={tableData.pageNumber}
            capTableData={{
              tableData: tableData.pageData,
              totalData: capTableData.totalData,
            }}
            pagination={[9 + index * 2, 9 + index * 2 + 1]}
            hasDividends={capTableData.hasDividends}
            numberOfPages={rootArr.length}
            pageNumber={index + 1}
            isLastPage={index === rootArr.length - 1}
            moveTitleToHeader={moveTitleToHeader()}
          />
        );
      })}
      {divideTableData(breakpoint1TableData.tableData, 21).map((tableData, tableIndex, tableRootArr) => {
        return divideTableData(breakpoint1TableData.columnData, 8, true).map((columnData, index, rootArr) => {
          return (
            <Breakpoints1
              key={tableData.pageNumber}
              breakpoint1TableData={{
                columnData: columnData.pageData,
                tableData: tableData.pageData,
              }}
              pagination={[9 + paginationData.capTablePageTotal +
                ((((tableIndex * rootArr.length) + (index))) * 2), 9 +
                paginationData.capTablePageTotal + ((((tableIndex * rootArr.length) + (index))) * 2) + 1]}
              numberOfPages={rootArr.length}
              tableNumberOfPages={tableRootArr.length}
              pageNumber={index + 1}
              tablePageNumber={tableIndex + 1}
              moveTitleToHeader={moveTitleToHeader()}
            />
          );
        });
      })}
      {divideTableData(breakpoint2TableData.tableData, 21).map((tableData, tableIndex, tableRootArr) => {
        return divideTableData(breakpoint2TableData.columnData, 8, true).map((columnData, index, rootArr) => {
          return (
            <Breakpoints2
              key={tableData.pageNumber}
              breakpoint2TableData={{
                columnData: columnData.pageData,
                tableData: tableData.pageData,
              }}
              pagination={[9 + paginationData.breakpoints1PageTotal +
                ((((tableIndex * rootArr.length) + (index))) * 2), 9 +
                paginationData.breakpoints1PageTotal + ((((tableIndex * rootArr.length) + (index))) * 2) + 1]}
              numberOfPages={rootArr.length}
              tableNumberOfPages={tableRootArr.length}
              pageNumber={index + 1}
              tablePageNumber={tableIndex + 1}
              moveTitleToHeader={moveTitleToHeader()}
            />
          );
        });
      })}
      {!isSingleScenario && divideTableData(earlyOPMTableData.earlyAggregateValues, 11).map((tableData, tableIndex, tableRootArr) => {
        return divideTableData(earlyOPMTableData.columnData, 8).map((columnData, index, rootArr) => {
          return (
            <EarlyScenarioOPM
              key={tableData.pageNumber}
              earlyOPMTableData={{
                ...earlyOPMTableData,
                columnData: columnData.pageData,
                earlyAggregateValues: tableData.pageData,
              }}
              pagination={[9 + paginationData.breakpoints2PageTotal +
                ((((tableIndex * rootArr.length) + (index))) * 2), 9 +
                paginationData.breakpoints2PageTotal + ((((tableIndex * rootArr.length) + (index))) * 2) + 1]}
              numberOfPages={rootArr.length}
              tableNumberOfPages={tableRootArr.length}
              pageNumber={index + 1}
              tablePageNumber={tableIndex + 1}
              isLastPage={tableIndex === tableRootArr.length - 1}
              moveTitleToHeader={moveTitleToHeader()}
            />
          );
        });
      })}
      {divideTableData(lateOPMTableData.lateAggregateValues, 11).map((tableData, tableIndex, tableRootArr) => {
        return divideTableData(lateOPMTableData.columnData, 8).map((columnData, index, rootArr) => {
          return (
            <LateScenarioOPM
              key={tableData.pageNumber}
              lateOPMTableData={{
                ...lateOPMTableData,
                columnData: columnData.pageData,
                lateAggregateValues: tableData.pageData,
              }}
              pagination={[9 + paginationData.earlyOPMPageTotal +
                ((((tableIndex * rootArr.length) + (index))) * 2), 9 +
                paginationData.earlyOPMPageTotal + ((((tableIndex * rootArr.length) + (index))) * 2) + 1]}
              numberOfPages={rootArr.length}
              tableNumberOfPages={tableRootArr.length}
              pageNumber={index + 1}
              tablePageNumber={tableIndex + 1}
              isLastPage={tableIndex === tableRootArr.length - 1}
              moveTitleToHeader={moveTitleToHeader()}
              isSingleScenario={isSingleScenario}
            />
          );
        });
      })}
      {divideTableData(perShareSummaryTableData.tableData, 18).map((tableData, index, rootArr) => {
        return (
          <PerShareSummary
            key={tableData.pageNumber}
            perShareSummaryTableData={{
              ...perShareSummaryTableData,
              tableData: tableData.pageData,
            }}
            pagination={[9 + paginationData.lateOPMPageTotal + index * 2, 9 + paginationData.lateOPMPageTotal + index * 2 + 1]}
            numberOfPages={rootArr.length}
            pageNumber={index + 1}
            isLastPage={index === rootArr.length - 1}
            moveTitleToHeader={moveTitleToHeader()}
            isSingleScenario={isSingleScenario}
          />
        );
      })}
    </>
  );
}

Tables.propTypes = {
  capTableData: PropTypes.object,
  breakpoint1TableData: PropTypes.object,
  breakpoint2TableData: PropTypes.object,
  earlyOPMTableData: PropTypes.object,
  lateOPMTableData: PropTypes.object,
  perShareSummaryTableData: PropTypes.object,
  paginationData: PropTypes.object,
  isSingleScenario: PropTypes.bool,
};
